import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  type GetGuidesForUserQueryInDashboardQuery,
  type Scalars,
} from 'src/__generated__/graphql'
import { dashboardGuidesIncludeStatuses } from './dashboardConstants'

export const getGuidesForUserQuery = gql(`
  query GetGuidesForUserQueryInDashboard($after: String, $first: Int = 10, $userGuidesArgs: UserGuidesArgs!) {
    currentUser {
      id
      userGuides(after: $after, first: $first, userGuidesArgs: $userGuidesArgs) {
        edges {
          node {
            id
            addresses {
              id
              addressLine1
              city
              country
              googlePlaceId
              lat
              long
              state
            }
            collectedCount
            description
            imageUrl
            isCollected
            likedByCurrentUser
            name
            numberOfLikes
            owner {
              id
            }
            ownerProfile {
              id
              accountHandle
              displayName
              isUserOwner
              profileImageUrl
              profileImageSource
              userId
            }
            paginatedImages(first: 1) {
              edges {
                node {
                  id
                  source
                  url
                }
              }
            }
            shareCount
            status
            strippedId
            tags {
              id
              name
            }
            timeZone
            updatedAt
            viewCount
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`)

type UseGetDashboardGuidesQuery = {
  hasError: ApolloError
  dashboardGuidesData: GetGuidesForUserQueryInDashboardQuery['currentUser']['userGuides']
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

const useGetDashboardGuidesQuery = (): UseGetDashboardGuidesQuery => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
  } = useQuery(getGuidesForUserQuery, {
    /** @todo remove errorPolicy when BE errors are fixed. */
    errorPolicy: 'ignore',
    variables: {
      userGuidesArgs: {
        includeStatuses: dashboardGuidesIncludeStatuses,
      },
    },
  })

  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          currentUser: {
            ...previousResult.currentUser,
            userGuideDrafts: {
              ...fetchMoreResult?.currentUser?.userGuides,
              edges: previousResult?.currentUser?.userGuides?.edges?.concat(
                fetchMoreResult?.currentUser?.userGuides?.edges
              ),
            },
          },
        }),
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  return {
    dashboardGuidesData: data?.currentUser?.userGuides,
    hasError,
    isLoading,
    onGetMoreResults,
  }
}

export { type UseGetDashboardGuidesQuery, useGetDashboardGuidesQuery }

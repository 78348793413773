import type { BragMetricOld } from '../types'

const BragBubble = ({ label, value }) => (
  <div className='flex min-w-28 flex-col items-center'>
    <div className='align-center min-h-50px h-50px min-w-50px w-50px border-1 border-grey-400 flex flex-col items-center justify-center rounded-full border-solid bg-white'>
      <div className='type-subtitle-1 text-center'>{value}</div>
    </div>
    <p className='type-body-1 mt-2'>{label}</p>
  </div>
)

interface BragMetricsOldProps {
  justifyCenter?: boolean
  metrics?: BragMetricOld[]
}

export const BragMetricsOld = ({
  justifyCenter,
  metrics: bragMetrics,
}: BragMetricsOldProps) => (
  <div
    className={`space-between flex h-16 w-full gap-2 space-x--12 md:space-x-0 ${justifyCenter ? 'my-5 justify-center' : 'mt-20 justify-end'}`}
  >
    {bragMetrics?.map(metric => (
      <BragBubble
        key={`brag-${metric?.label}`}
        label={metric?.label}
        value={metric?.value?.toString()}
      />
    ))}
  </div>
)

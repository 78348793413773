import { useEffect, useState } from 'react'
import { useFetcher, useLocation } from '@remix-run/react'
import type { action } from 'app/routes/login'
import type { User } from 'firebase/auth'
import Cookies from 'js-cookie'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { BrowserRouter, Outlet } from 'react-router-dom'
import { TpgThemeProvider } from 'src/common/components'
import {
  anonymousUserKey,
  clientSideID,
} from 'src/config/feature-flags/launchDarklyConstants'
import { auth } from 'src/config/firebase/firebaseUtils'
import { useInitializeGoogleMaps } from './common/hooks'
import { initializeLucency } from './config/analytics/lucencyIntegration'
import { useLucencyNumber } from './config/analytics/useLucencyNumber'
import { useVwoUserData } from './config/analytics/useVwoUserData'
import { GenerateAccessToken } from './config/firebase/GenerateAccessToken'
import { GoogleOneTapLoginWrapper } from './config/googleCloud/GoogleOneTapLoginWrapper'
import { ForterProtection } from './config/security/forter/ForterProtection'
import { extendDayjs } from './utils'
import '@travelpass/design-system/dist/style.css'
import 'rc-slider/assets/index.css'
import 'react-circular-progressbar/dist/styles.css'
import 'virtual:uno.css'
import 'src/config/datadog'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import './global.css'

extendDayjs()

export const App = ({ isServerRendered }: { isServerRendered?: boolean }) => {
  const [user, setUser] = useState<User | 'anonymous'>(null)
  const { updateLucency } = useLucencyNumber()
  // const { pathname } = useLocation()
  // const fetcher = useFetcher<typeof action>()

  useVwoUserData()

  useInitializeGoogleMaps()

  useEffect(() => {
    if (!import.meta.env.IS_LOCAL_ENV) {
      initializeLucency()
    }
  }, [])

  useEffect(() => {
    if (user && user !== 'anonymous') {
      updateLucency({
        user: {
          displayName: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          uid: user.uid,
          isAnonymous: user.isAnonymous,
        },
      })
    }
  }, [user])

  useEffect(() => {
    updateLucency({ vwo_uuid: Cookies.get('_vwo_uuid') })

    const unsubscribeUser = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser('anonymous')
      }
    })

    // this is the remix code that we need to use once we have it working
    // useEffect(() => {
    //   updateLucency({ vwo_uuid: Cookies.get('_vwo_uuid') })

    //   const unsubscribeUser = auth.onIdTokenChanged(async user => {
    //     if (user) {
    //       setUser(user)
    //       setHasAccessToken(true)
    // const idToken = await user.getIdToken()
    // const refreshToken = user?.refreshToken
    // fetcher.submit(
    //   { idToken, refreshToken },
    //   { method: 'post', action: '/login' }
    // )
    // } else {
    //   setHasAccessToken(false)
    //   const { user: anonUser } = await signInAnonymously(auth)
    // const idToken = await anonUser.getIdToken()
    // const refreshToken = user?.refreshToken
    // fetcher.submit(
    //   { idToken, refreshToken },
    //   { method: 'post', action: '/login' }
    // )
    //   }
    // })

    return () => unsubscribeUser()
  }, [])

  const app = (
    <>
      <ForterProtection />
      {user !== null && <GenerateAccessToken />}
    </>
  )

  const renderApp = isServerRendered ? (
    app
  ) : (
    <BrowserRouter>{app}</BrowserRouter>
  )

  return (
    <LDProvider
      clientSideID={clientSideID}
      context={{ key: anonymousUserKey, anonymous: true }}
    >
      <TpgThemeProvider>
        <GoogleOneTapLoginWrapper />
        {renderApp}
      </TpgThemeProvider>
    </LDProvider>
  )
}

import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type { Options, RoomFilters } from 'src/__generated__/graphql'
import { createRateRequest } from './hotelRoomsApolloUtils'
import { constructHotelId } from '../../../utils'

const HOTEL_ROOMS = gql(`
    query HotelRooms($rateRequest: FlattenedRateRequest!) {
        mappedHotelRoomDetails(rateRequest: $rateRequest) {
            rooms {
                amenities
                beds {
                    count
                    type
                }
                description
                images {
                    caption
                    href
                }
                name
                occupancyType
                rates {
                    currencyCode
                    cancelationPolicy {
                        fullyRefundableUntil
                        nonRefundableAfter
                        shortText
                        partiallyRefundableUntil
                        policyText
                        providerOverrideText
                        refundType
                    }
                    category {
                        supplier
                        type
                    }
                    dueLater
                    allInTotal {
                        amount
                        currency
                    }
                    grandtotal
                    maxOccupancy
                    nightlyAverage
                    rateToken
                    subtotal
                    standardAttributes
                    strikethrough
                }
            }
        }
    }
`)

export const useHotelRoomsQuery = (
  filters: RoomFilters,
  options: Options,
  skip = false
) => {
  const [searchParams] = useSearchParams()
  const { hotelId } = useParams()
  const excludedProviders = useMemo(
    () => searchParams.get('excludeProvider')?.split(','),
    []
  )

  const constructOptions = (): Options =>
    excludedProviders
      ? { ...options, excludeProviders: excludedProviders }
      : options

  const { data, error, loading, ...rest } = useQuery(HOTEL_ROOMS, {
    skip,
    variables: {
      ...createRateRequest({
        filters,
        standardHotelId: constructHotelId(hotelId),
        options: constructOptions(),
        searchParams,
      }),
    },
  })

  return {
    rooms: data?.mappedHotelRoomDetails?.rooms,
    error,
    loading,
    ...rest,
  }
}

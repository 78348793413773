import { UnderlineTab, useScreenQuery } from '@travelpass/design-system'
import type { ProfileByAccountHandleQuery } from 'src/__generated__/graphql'
import { VideoPreview } from 'src/common/components'
import type { FollowersModalType } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useFlag } from 'src/common/hooks'
import { ProfileCompetitionVoteCta } from 'src/pages/profile/common'
import { BragMetricsOld } from './BragMetricsOld'
import { ProfileGuides } from './ProfileGuides'
import { ProfileStatistics } from './ProfileStatistics'
import { BioSection } from '../information-section/BioSection'
import type { UseGetProfileGuidesQueryOld } from '../useGetProfileGuidesQueryOld'

interface ProfileContentSectionProps {
  onGetMoreResults: UseGetProfileGuidesQueryOld['onGetMoreResults']
  profile: ProfileByAccountHandleQuery['viewUserProfile']
  profileGuidesData: UseGetProfileGuidesQueryOld['profileGuidesData']
  toggleModal: (viewType: FollowersModalType) => void
}

const headerContainerStyles = {
  tabsAlone: 'flex sm:justify-center md:justify-start mb-4',
  tabsWithButton: 'flex justify-between mb-4',
}

let tabs = [
  {
    label: 'Guides',
    onClick: () => {},
  },
]

export const ProfileContentSection = ({
  onGetMoreResults,
  profile,
  profileGuidesData,
  toggleModal,
}: ProfileContentSectionProps) => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const { isDesktopScreen } = useScreenQuery()
  const showVoteForCta = enableCompetition2024

  return (
    <div className='grow-3 flex w-full flex-col gap-2 self-start'>
      <div className='hidden lg:grid'>
        <BragMetricsOld
          metrics={[
            {
              label: profile?.bragContinents === 1 ? 'Continent' : 'Continents',
              value: profile?.bragContinents || 0,
            },
            {
              label: profile?.bragCountries === 1 ? 'Country' : 'Countries',
              value: profile?.bragCountries || 0,
            },
            {
              label: profile?.bragStates === 1 ? 'State' : 'States',
              value: profile?.bragStates || 0,
            },
          ]}
        />
        <ProfileStatistics
          guidesCount={profileGuidesData?.totalCount}
          profile={profile}
          toggleModal={toggleModal}
        />
        {showVoteForCta && <ProfileCompetitionVoteCta />}
        <div className='flex gap-10 py-6'>
          <VideoPreview
            embedVideoLink={profile?.introVideoEmbed}
            fullWidth={!profile?.bio || profile?.bio?.length === 0}
          />
          {isDesktopScreen && <BioSection bio={profile?.bio} />}
        </div>
      </div>
      <div className='flex flex-col'>
        <div
          className={
            profile?.isUserOwner
              ? headerContainerStyles.tabsWithButton
              : headerContainerStyles.tabsAlone
          }
        >
          <UnderlineTab activeIndex={0} tabs={tabs} willScroll={false} />
        </div>
        <ProfileGuides
          profile={profile}
          profileGuidesData={profileGuidesData}
          onGetMoreResults={onGetMoreResults}
        />
      </div>
      <AddToCollectionsModalListener />
    </div>
  )
}

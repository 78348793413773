import { Button, useSnackbar } from '@travelpass/design-system'
import type { AvailableIcons, ButtonVariant } from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import { GuideStatus } from 'src/__generated__/graphql'
import {
  useFollowMutation,
  useGetCurrentUserProfileAccountHandleAndAvatar,
  useUnfollowMutation,
} from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { getFeedFromCurrentUserQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { RECOMMENDED_TRAVELERS_QUERY } from 'src/pages/travelers/hooks/useRecommendedTravelers'

const FOLLOWERS_QUERY = gql(`
  query FollowersOld($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      followCount {
        countOfFollowing
        countOfFollowed
      }
      followedUsers {
        ...FollowUserProfileFields
      }
      followingUsers {
        ...FollowUserProfileFields
      }
      isFollowed
      isUserOwner
      userId
    }
  }
`)

interface FollowButtonOldProps {
  displayName: string
  endIcon?: AvailableIcons
  fullWidth?: boolean
  isFollowed: boolean
  isRecommendedUser?: boolean
  recommendedUserIndex?: number
  label: string
  startIcon?: AvailableIcons
  userId: string
  variant: ButtonVariant
  onFollow?: () => void
  accountHandle: string
}

export const FollowButtonOld = ({
  displayName,
  endIcon,
  fullWidth,
  isFollowed,
  isRecommendedUser = false,
  label,
  recommendedUserIndex,
  startIcon,
  userId,
  variant,
  onFollow,
}: FollowButtonOldProps) => {
  const { isAnonymous } = useFirebaseUser()
  const { addErrorSnack } = useSnackbar()
  //GraphQl
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const mutationOptions = {
    refetchQueries: [
      {
        query: FOLLOWERS_QUERY,
        variables: {
          accountHandle: data?.currentUser?.userProfile?.accountHandle,
          userId,
        },
      },
      {
        query: getFeedFromCurrentUserQuery,
        variables: {
          userGuidesArgs: {
            includeStatuses: [GuideStatus.Published],
          },
        },
      },
      { query: RECOMMENDED_TRAVELERS_QUERY },
    ],
  }
  const [follow, { loading: followLoading }] = useFollowMutation({
    ...mutationOptions,
    onCompleted: () => {
      onFollow?.()
    },
  })
  const [unfollow, { loading: unfollowLoading }] =
    useUnfollowMutation(mutationOptions)

  const isLoading = followLoading || unfollowLoading

  const onClick = async () => {
    if (isAnonymous) {
      dispatch('openSignin', {
        isCreateAccount: false,
      })
      return
    }

    let response = null
    const variables = {
      input: {
        userId,
      },
    }

    if (!isFollowed) {
      variables.input['isRecommendedUser'] = isRecommendedUser
      if (!isNaN(recommendedUserIndex)) {
        variables.input['rank'] = recommendedUserIndex
      }
    }

    if (isFollowed) response = await unfollow({ variables })
    else response = await follow({ variables })

    if (response?.errors) {
      addErrorSnack({
        title: `An unknown error happened while trying to ${isFollowed ? 'unfollow' : 'follow'} ${displayName}.`,
      })
    }
  }

  return (
    <Button
      aria-label={`${isFollowed ? 'Unfollow' : 'Follow'} ${displayName}`}
      endIcon={!isLoading ? endIcon : null}
      fullWidth={fullWidth}
      label={label}
      size='small'
      startIcon={startIcon}
      type='button'
      variant={variant}
      onClick={onClick}
    />
  )
}

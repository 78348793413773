import type { GetViewUserProfileInProfileQuery } from 'src/__generated__/graphql'
import type { HelmetProps } from 'src/common/components/Helmet/Helmet'
import { getProfilePageUrl } from 'src/utils'
import { profileBannerAltMapping } from './profileConstants'

const getProfileBannerAlt = (bannerImage: string) => {
  const bannerImageKey = Object.keys(profileBannerAltMapping).find(key =>
    bannerImage?.includes(key)
  )

  return profileBannerAltMapping?.[bannerImageKey] ?? ''
}

const getProfileMetaData = (
  profileData: GetViewUserProfileInProfileQuery['viewUserProfile']
): HelmetProps | null => {
  if (!profileData) return null

  return {
    canonicalUrl: getProfilePageUrl(profileData?.accountHandle),
    metaDescription:
      'Check out my Travelpass profile! Find tips, recommendations, travel Guides, and more.',
    metaImage: profileData?.profileImageUrl ?? '',
    pageName: getProfileMetaPageName(profileData),
  }
}

const getProfileMetaPageName = (
  profileData: GetViewUserProfileInProfileQuery['viewUserProfile']
) => {
  if (!profileData) return ''

  const { accountHandle, displayName, isUserOwner } = profileData ?? {}

  if (isUserOwner) return `My Profile | ${displayName || accountHandle}`

  if (displayName) return `${displayName} | Profile | ${accountHandle}`

  return `Profile | ${accountHandle}`
}

export { getProfileBannerAlt, getProfileMetaData, getProfileMetaPageName }

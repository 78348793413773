import { useState } from 'react'
import { Icon, Tag, useSnackbar } from '@travelpass/design-system'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import {
  useIncrementGuideShareCountMutation,
  useShareGuide,
} from 'src/pages/guides'
import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideAddress, getGuideDetailsUrl, getImageResized } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardAction } from './DashboardFeedCardAction'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import { getDashboardFeedGuideImages } from '../../../dashboardUtils'
import type { DashboardFeedItem } from '../../../types'

interface DashboardFeedCardCreatedGuideProps {
  dashboardFeedItem: DashboardFeedItem
}

export const DashboardFeedCardCreatedGuide = ({
  dashboardFeedItem,
}: DashboardFeedCardCreatedGuideProps) => {
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  const { addresses, description, eventCounts, id, name } =
    dashboardFeedItem?.guide ?? {}
  const { numberEvents } = eventCounts ?? {}
  const address = getGuideAddress({
    city: addresses?.[0]?.city,
    country: addresses?.[0]?.country,
    state: addresses?.[0]?.state,
  })
  const images = getDashboardFeedGuideImages({
    images:
      dashboardFeedItem?.guide?.paginatedImages?.edges?.map(
        ({ node }) => node
      ) ?? [],
    imageUrl: dashboardFeedItem?.guide?.imageUrl,
  })
  const url = getGuideDetailsUrl({
    id,
    name,
  })

  const onAddGuideToCollections = () =>
    dispatchToggleAddToCollectionsModal({
      isModalOpen: true,
      item: { id, name, type: CollectionItemIdType.Guide },
    })

  const onShareGuide = async () => {
    await shareGuide({
      shareUrl: `${window.location.origin}${url}`,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
      },
    })
    if (!hasShared) {
      incrementShareCount({
        variables: {
          guideId: id,
        },
      })
      setHasShared(true)
    }
  }

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide
          dashboardFeedItem={{
            ...dashboardFeedItem,
            guide: {
              ...dashboardFeedItem.guide,
              updatedAt: null,
            },
          }}
        >
          <p className='type-body-2 flex flex-row items-center gap-1'>
            <Icon name='viewList' size='small' />
            <span className='min-w-fit'>Published a new Guide</span>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div className='space-y-4'>
          <div className='h-27 lg:h-50 pointer-events-none flex flex-row items-center gap-1'>
            {images.map(({ url }, index) => (
              <GuideImage
                key={index}
                src={getImageResized({
                  imageUrl: url,
                  shape: index === 0 ? 'landscape' : 'square',
                  width: index === 0 ? 1500 : 1000,
                })}
              />
            ))}
          </div>
          <div className='space-y-2'>
            <h2 className='type-body-1-medium-mobile type-subtitle-1-desktop line-clamp-2'>
              {name}
            </h2>
            <div className='flex flex-row items-center gap-3'>
              {address && (
                <div className='flex flex-row items-center gap-2'>
                  <Icon name='placeOutline' size='small' />
                  <p className='type-body-2 c-black'>{address}</p>
                </div>
              )}
              {address && !!numberEvents && <div className='c-grey-700'>|</div>}
              {!!numberEvents && (
                <p className='type-body-2 c-black'>
                  {numberEvents}{' '}
                  {numberEvents > 1 ? 'recommendations' : 'recommendation'}
                </p>
              )}
            </div>
          </div>
          {description && (
            <p className='type-body-2 c-black lg:line-clamp-3'>{description}</p>
          )}
          <div className='relative space-x-2 text-right'>
            <DashboardFeedCardAction
              aria-label='Add Guide to collections'
              onClick={onAddGuideToCollections}
            >
              <Tag color='newForestLight' startIcon='add' text='Add to' />
            </DashboardFeedCardAction>
            <DashboardFeedCardAction
              aria-label='Share Guide'
              onClick={onShareGuide}
            >
              <Tag color='newForestLight' startIcon='iosShare' text='Share' />
            </DashboardFeedCardAction>
          </div>
        </div>
      </article>
    </DashboardFeedCard>
  )
}

import { useState, useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Helmet } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useFlag } from 'src/common/hooks'
import { baseUrl, destinationsSegment } from 'src/constants'
import { DestinationHero } from './DestinationHero'
import {
  DestinationLocation,
  DestinationLocationBestAreas,
  DestinationLocationBestAttractions,
  DestinationLocationExplore,
  DestinationLocationTimeToVisit,
  DestinationLocationFaqs,
} from './DestinationLocation'
import type { DestinationType } from './destinationConstants'
import { destinationTitlePrefix } from './destinationConstants'
import { constructDestination } from './destinationUtils'
import { Experiences } from '../destinations/Experiences'
import { Guides } from '../destinations/Guides'
import { Hotels } from '../destinations/Hotels'

export const Destination = () => {
  const isEnabled = useFlag('isDestinationCarouselEnabled')
  const navigate = useNavigate()
  const { city, stateCode } = useParams()
  const [_, setSearchParams] = useSearchParams()
  const [destination, setDestination] = useState<DestinationType>(null)

  useEffect(() => {
    const constructedDestination = constructDestination({
      city: city ?? '',
      stateCode: stateCode ?? '',
    })

    if (isEmpty(constructedDestination)) navigate('/')
    else {
      const {
        city: location,
        latitude,
        longitude,
      } = constructedDestination?.address ?? {}
      setDestination(constructedDestination)
      setSearchParams({ latitude, location, longitude }, { replace: true })
    }
  }, [])

  return !isEmpty(destination) ? (
    <div className='p-b-20'>
      <Helmet
        canonicalUrl={`${baseUrl}/${destinationsSegment}/${stateCode}/${city}`}
        metaDescription={`${destination.metaDescription}`}
        pageName={`${destinationTitlePrefix} ${destination?.address?.city}, ${destination?.address?.stateCode}`}
      />

      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
      <DestinationHero destination={destination} />
      <div className='flex flex-col gap-20'>
        <section>
          <DestinationLocation destination={destination} />
        </section>
        <section>
          <Hotels />
        </section>
        <section>
          <Experiences />
        </section>
        <section>
          <Guides />
        </section>
      </div>
      {isEnabled && (
        <>
          <DestinationLocationBestAreas destination={destination} />
          <DestinationLocationBestAttractions destination={destination} />
          <DestinationLocationExplore destination={destination} />
          <DestinationLocationTimeToVisit destination={destination} />
          <DestinationLocationFaqs destination={destination} />
        </>
      )}
    </div>
  ) : null
}

import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import type { AvailableIcons } from '@travelpass/design-system'
import { Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import type { MappedImage } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { constructPopularHotelAmenities, getPrice } from 'src/utils'
import { HotelRoomCardImage } from './HotelRoomCardImage'
import { useHotelDetailsStandardHotel } from '../../../hooks/useHotelDetailsStandardQuery'
import { HotelRoomModal } from '../../HotelRooms-old/HotelRoom/HotelRoomModal'
import { HotelRoomRates } from '../HotelRoomRates'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'
import { getHotelRoomBedCountWithType } from '../hotelRoomsUtils'

interface HotelRoomCardProps {
  room: HotelRoomType
  isSelected?: boolean
  handleRoomSelection?: (index: number, selectOnly: boolean) => void
  roomIndex: number
}

export const HotelRoomCard = ({
  room,
  isSelected,
  handleRoomSelection,
  roomIndex,
}: HotelRoomCardProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [isHotelRoomModalOpen, setIsHotelRoomModalOpen] = useState(false)
  const [images, setImages] = useState([])
  const { data: hotelDetails } = useHotelDetailsStandardHotel()
  const {
    name,
    images: initialImages,
    amenities,
    beds,
    occupancyType: sleeps,
    rates,
  } = { ...room }
  const bedCountWithType = getHotelRoomBedCountWithType(beds)
  const topAmenity = constructPopularHotelAmenities(
    amenities.concat(hotelDetails.amenities)
  ).shift()

  useEffect(() => setImages(initialImages), [initialImages])

  const onHotelRoomModalClose = () => setIsHotelRoomModalOpen(false)

  const onHotelRoomModalOpen = () => setIsHotelRoomModalOpen(true)

  const onHotelRoomModalImagesError = (errorImage: MappedImage) =>
    setImages(images => images.filter(({ href }) => errorImage?.href !== href))

  const renderAmenity = (icon: AvailableIcons, label: string) => (
    <div className='flex items-center gap-1'>
      <Icon name={icon} />
      <div>{label}</div>
    </div>
  )

  return (
    <>
      {isHotelRoomModalOpen && (
        <HotelRoomModal
          hotelRoomData={room}
          images={images}
          onClose={onHotelRoomModalClose}
          onImageError={onHotelRoomModalImagesError}
        />
      )}
      <div className={roomCardCss(isSelected)}>
        <button
          aria-label='Select Room'
          className={selectRoomButtonCss}
          onClick={event => {
            event.currentTarget.blur()
            handleRoomSelection(roomIndex, true)
          }}
        />
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className='relative h-[200px] w-full cursor-pointer lg:h-[134px] lg:w-auto'
          onClick={event => {
            event.currentTarget.blur()
            handleRoomSelection(roomIndex, true)
          }}
        >
          <HotelRoomCardImage images={images} roomName={name} />
          <div className='shadow-2 rounded-10 absolute bottom-3 right-3 bg-white sm:flex md:block lg:hidden'>
            <IconButton
              color='transparent'
              icon='mapExpand'
              onClick={onHotelRoomModalOpen}
            />
          </div>
        </div>
        <div className='flex w-full flex-col gap-2 p-4 lg:p-2'>
          <h5 className='type-h5 lg:max-w-[calc(300px-2rem)] lg:truncate'>
            {name}
          </h5>
          <div
            className={classNames('flex', {
              'flex-row': !newHotelDetails,
              'sm:flex-col lg:flex-row': newHotelDetails,
            })}
          >
            <div
              className={classNames('text-body-2 c-grey-800 flex-grow', {
                'flex flex-row items-center gap-x-10 lg:flex-col lg:items-start':
                  newHotelDetails,
              })}
            >
              {topAmenity && (
                <>{renderAmenity(topAmenity.icon, topAmenity.label)}</>
              )}
              {renderAmenity('personOutline', `Sleeps ${sleeps || 1}`)}
              {bedCountWithType && (
                <>{renderAmenity('bed', bedCountWithType)}</>
              )}
            </div>
            <div
              className={classNames('flex flex-col', {
                'sm:hidden lg:flex': isSelected,
                'sm:flex lg:flex': !isSelected,
              })}
            >
              <div className='flex-grow'></div>
              <div
                className={classNames('c-forest text-right', {
                  'type-body-1': !newHotelDetails,
                  'type-h4': newHotelDetails,
                })}
              >
                {getPrice(rates[0]?.nightlyAverage)}
              </div>
              <div className='type-body-2 c-grey-800 text-right'>per night</div>
            </div>
          </div>
        </div>
        <div className='relative block w-full lg:hidden'>
          <Accordion collapsible index={isSelected ? 0 : 1}>
            <AccordionItem>
              <AccordionButton
                className='text-overline c-newForest font-size-16px flex w-full items-center justify-center gap-2 border-none bg-transparent tracking-[1px]'
                onClick={() => handleRoomSelection(roomIndex, false)}
              >
                <Icon name={isSelected ? 'arrowDropUp' : 'arrowDropDown'} />
                <span>{isSelected ? 'Hide Rates' : 'View Rates'}</span>
              </AccordionButton>
              <AccordionPanel>
                <HotelRoomRates
                  hotelName={hotelDetails?.name}
                  rates={rates}
                  roomName={name}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>
  )
}

const roomCardCss = isSelected =>
  classNames(
    'relative flex flex-col lg:flex-row w-auto lg:w-full gap-0 lg:gap-2 rd-3 border-2',
    {
      'shadow-[0px_2px_12px_0px_#0000001A] lg:bg-#F0F6F7 lg:border-solid lg:border-new-forest':
        isSelected,
    }
  )

const selectRoomButtonCss = classNames(
  'absolute h-full left-0 top-0 w-full rd-3 cursor-pointer',
  'appearance-none border-2 border-transparent outline-none bg-transparent p-0 m-0 text-left',
  'hover:shadow-[0px_2px_12px_0px_#0000001A]',
  'focus:border-solid focus:border-2 focus:shadow-0 focus:border-valley'
)

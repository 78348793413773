import type { GetGuidesForUserQueryInDashboardQueryVariables } from 'src/__generated__/graphql'
import { GuideStatus } from 'src/__generated__/graphql'

enum DashboardSearchParam {
  editProfile = 'editProfile',
  settingsTab = 'settingsTab',
  tab = 'tab',
}

enum DashboardTab {
  bookings = 'bookings',
  collections = 'collections',
  feed = 'feed',
  guides = 'guides',
  trips = 'trips',
}

const dashboardGuidesIncludeStatuses: GetGuidesForUserQueryInDashboardQueryVariables['userGuidesArgs']['includeStatuses'] =
  [GuideStatus.Published, GuideStatus.ViewableWithLink]

export { DashboardSearchParam, DashboardTab, dashboardGuidesIncludeStatuses }

import type { RefObject } from 'react'
import { forwardRef, useState } from 'react'
import { Divider, Icon, Link, Modal } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { LodgingFee } from 'src/__generated__/graphql'
import {
  constructFees as constructHotelFees,
  getTotalNights,
  getTotalRooms,
} from 'src/utils'
import { HotelPriceSummaryRow } from './HotelPriceSummaryRow'
import { HotelPriceSummaryTaxes } from './HotelPriceSummaryTaxes'
import type { HotelPriceSummaryProps } from './hotelPriceSummaryConstants'
import {
  constructDueLater,
  constructDueNow,
  constructFees,
  constructTotal,
} from './hotelPriceSummaryUtils'
import { HotelCancellationCard } from '../HotelPolicies'

export const HotelPriceSummaryV2 = forwardRef(function HotelPriceSummary(
  {
    allInTotal,
    additionalFees,
    arrival,
    departure,
    dueLater,
    dueNow,
    hotelFees,
    provider,
    showInternationalDisclaimer = false,
    showLegal = false,
    subtotal,
    surcharges,
    surchargeTotal,
    rooms,
    rateData,
  }: HotelPriceSummaryProps,
  ref: RefObject<HTMLHeadingElement>
) {
  const { room } = rateData?.validatedRate ?? {}
  const { cancelPolicy } = room?.rates?.[0] ?? {}
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = cancelPolicy ?? {}
  const { policyText, shortText } = constructedCancellationPolicies ?? {}

  const [isBookingOtherFeesModalOpen, setIsBookingOtherFeesModalOpen] =
    useState(false)
  const constructedFees = constructFees(additionalFees)
  const constructedHotelFees = constructHotelFees(
    hotelFees?.filter(
      (fee: LodgingFee | null | undefined): fee is LodgingFee =>
        fee !== null && fee !== undefined
    ) ?? []
  )
  const showOtherFees =
    !isEmpty(hotelFees) && (provider === 'getaroom' || provider === 'web_beds')

  const constructedOccupancyText = () =>
    `${getTotalRooms({
      isLowerCase: true,
      rooms,
    })} x ${getTotalNights({
      arrival,
      departure,
      isLowerCase: true,
    })}:`

  const constructedDueLater = constructDueLater(constructedFees, dueLater)
  const constructedDueNow = constructDueNow(dueNow)
  const constructedTotalPrice = constructTotal(
    constructedDueLater,
    constructedDueNow
  )
  const price = allInTotal ? allInTotal : constructedTotalPrice
  const isDueLater = dueLater !== '0.00' && dueLater
  const displayDueAtHotel = !!isDueLater || !isEmpty(constructedFees)

  return (
    <div className='rebrand space-y-6'>
      {isBookingOtherFeesModalOpen && (
        <Modal
          size='medium'
          title='Additional Fees'
          onDismiss={() => setIsBookingOtherFeesModalOpen(false)}
        >
          <div className='flex flex-col gap-2'>
            {constructedHotelFees.map((fee, index) => (
              <p key={index} className='c-grey-800'>
                {fee}
              </p>
            ))}
          </div>
        </Modal>
      )}
      <div data-testid='HotelPriceSummary'>
        <details className='group'>
          <summary className='block flex list-none gap-2 [&::-webkit-details-marker]:hidden'>
            <div className='w-full'>
              <HotelPriceSummaryRow
                title='Total'
                value={price}
                variant='title'
              />
            </div>
            <div className='group-open:[&_svg]:rotate--180 color-new-forest [&_svg]:duration-250 [&_svg]:transition-all [&_svg]:ease-in'>
              <Icon name='arrowDownIos' />
            </div>
          </summary>
          <div className='mt-6 flex flex-col gap-2'>
            <HotelPriceSummaryRow
              title={constructedOccupancyText()}
              value={subtotal}
            />
            <HotelPriceSummaryTaxes
              surchargeTotal={surchargeTotal}
              surcharges={surcharges}
            />
            {constructedFees?.map(({ amount, type }) => (
              <HotelPriceSummaryRow
                key={type}
                title={`${type ?? ''} (pay at property):`}
                value={amount?.amount ?? ''}
              />
            ))}
            <Divider />
          </div>
        </details>
      </div>
      {showOtherFees && (
        <div className='flex flex-row items-center justify-between gap-2 pb-10'>
          <p className='c-grey-800 type-body-2'>
            *Additional fees may be due later at the hotel
          </p>
          <div className='min-w-fit'>
            <Link
              label='More Info'
              onClick={() => setIsBookingOtherFeesModalOpen(true)}
            />
          </div>
        </div>
      )}
      {showInternationalDisclaimer && (
        <div className='flex flex-row items-center justify-between gap-2'>
          <p className='c-grey-800 type-body-2'>
            *Rates are based off of the current exchange rate and fees charged
            at the time of checkin will be collected in the hotel&apos;s local
            currency.
          </p>
        </div>
      )}
      {isDueLater && showLegal && (
        <p className='c-grey-800 type-body-2'>
          Please note, you are booking a{' '}
          <q>
            <strong>Reserve Now, Pay Later Room.</strong>
          </q>{' '}
          You will be charged by the hotel at or shortly before your designated
          check-in time
        </p>
      )}
      {displayDueAtHotel && (
        <div className='flex flex-col gap-2'>
          <HotelPriceSummaryRow
            title='Pay now'
            value={constructedDueNow}
            variant='subtitle'
          />
          <HotelPriceSummaryRow
            title='Pay at property'
            value={constructedDueLater}
            variant='subtitle'
          />
        </div>
      )}
      <HotelCancellationCard
        fullyRefundableUntil={fullyRefundableUntil}
        nonRefundableAfter={nonRefundableAfter}
        partiallyRefundableUntil={partiallyRefundableUntil}
        policies={policyText}
        refundType={refundType}
        subtitle={shortText}
      />
    </div>
  )
})

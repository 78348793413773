import { useEffect, useState } from 'react'
import { GuideSessionStorage } from './guideConstants'

const notesEventName = 'guideAutoSaveState'

const onGuideAutoSaveStateChange = (
  updatedState: UseGuideAutoSave['autoSaveState']
) => {
  if (updatedState === 'SAVED') {
    setTimeout(() => {
      sessionStorage.setItem(GuideSessionStorage.autoSaveState, updatedState)
      window.dispatchEvent(new Event(notesEventName))
    }, 1000)
    return
  }

  sessionStorage.setItem(GuideSessionStorage.autoSaveState, updatedState)
  window.dispatchEvent(new Event(notesEventName))
}

type UseGuideAutoSave = {
  autoSaveState: 'DEFAULT' | 'SAVING' | 'SAVED'
}

const useGuideAutoSave = (): UseGuideAutoSave => {
  const [autoSaveState, setGuideAutoSaveState] =
    useState<UseGuideAutoSave['autoSaveState']>('DEFAULT')

  useEffect(() => {
    const notesListener = () =>
      setGuideAutoSaveState(
        (sessionStorage.getItem(
          GuideSessionStorage.autoSaveState
        ) as UseGuideAutoSave['autoSaveState']) ?? 'DEFAULT'
      )

    window.addEventListener(notesEventName, notesListener)

    return () => {
      window.removeEventListener(notesEventName, notesListener)
    }
  }, [])

  return { autoSaveState }
}

export type { UseGuideAutoSave }
export { onGuideAutoSaveStateChange, useGuideAutoSave }

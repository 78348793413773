import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { GuideDraftGeocoder } from '../GuideDraftGeocoder'

interface GuideDraftMapSearchProps {
  id: GuideDraftData['id']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  searchValue: string
}

export const GuideDraftMapSearch = ({
  id,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchValueChange,
  searchValue,
}: GuideDraftMapSearchProps) => (
  <div className='p-x-3 p-y-2 shadow-2 lg:p-y-3 of-visible absolute left-0 right-0 top-0 h-16 bg-white/60 lg:hidden'>
    <div className='p-x-3 top-7px'>
      <GuideDraftGeocoder
        id={id}
        location={location}
        searchValue={searchValue}
        willAddEvent={false}
        onMapMarkerCenterChange={onMapMarkerCenterChange}
        onPlacePointsChange={onPlacePointsChange}
        onSearchValueChange={onSearchValueChange}
      />
    </div>
  </div>
)

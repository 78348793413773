import { useState } from 'react'
import {
  Button,
  Popover,
  MenuItem,
  useScreenQuery,
} from '@travelpass/design-system'
import { HotelReviewsSortBy } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import {
  sortItems,
  type HotelReviewsFilterType,
} from './HotelReviewsApollo/hotelReviewsApolloConstants'
import { HotelReviewsTravelerTypeDrawer } from './HotelReviewsTravelerTypeDrawer'
import { HotelReviewsSearch } from './common/HotelReviewsSearch'

interface HotelReviewsNavProps {
  filters: HotelReviewsFilterType
  onChange(updatedFilters: HotelReviewsFilterType): void
}

const generateSortButtonLabel = (
  sort: HotelReviewsSortBy,
  newHotelDetails?: boolean
) => {
  if (newHotelDetails === false) return 'Sort By'

  const { HighToLow, LowToHigh, MostRecent } = HotelReviewsSortBy
  switch (sort) {
    case HighToLow:
      return 'Highest'
    case LowToHigh:
      return 'Lowest'
    case MostRecent:
      return 'Most Recent'
    default:
      return 'Most Recent'
  }
}

export const HotelReviewsNav = ({
  filters,
  onChange,
}: HotelReviewsNavProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { isDesktopScreen } = useScreenQuery()
  const sort = filters?.sort

  return (
    <>
      <HotelReviewsTravelerTypeDrawer
        filters={filters}
        isOpen={isDrawerOpen}
        onChange={onChange}
        onClose={() => setIsDrawerOpen(false)}
      />
      <div className='grid grid-cols-6 items-center gap-4'>
        <div className='col-span-6 lg:col-span-4'>
          <HotelReviewsSearch filters={filters} onChange={onChange} />
        </div>
        {!newHotelDetails && (
          <div className='col-span-3 lg:hidden'>
            <Button
              fullWidth
              label='Filters'
              startIcon='tune'
              variant='outlined'
              onClick={() => setIsDrawerOpen(true)}
            />
          </div>
        )}
        <div className='col-span-4 lg:col-span-2'>
          <Popover
            placement='bottom-end'
            trigger={
              <Button
                fullWidth
                endIcon='expandMore'
                label={generateSortButtonLabel(sort, newHotelDetails)}
                size={isDesktopScreen ? 'large' : 'medium'}
                startIcon={newHotelDetails ? 'sort' : null}
                variant='outlined'
              />
            }
          >
            {sortItems.map(({ title, value }) => (
              <MenuItem
                key={value}
                isHighlighted={sort === value}
                label={title}
                value={value}
                onClick={() => onChange({ sort: value })}
              />
            ))}
          </Popover>
        </div>
      </div>
    </>
  )
}

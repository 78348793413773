import type { NavigateFunction } from 'react-router-dom'
import { profilePath } from 'src/constants'

export const handleProfileRedirection = (
  selection: string,
  navigate: NavigateFunction
) =>
  selection.length
    ? navigate(profilePath + '/' + selection, {
        state: { referrer: window.location.href },
      })
    : null

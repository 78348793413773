import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_COLLECTION_BY_ID = gql(`
  query GetCollectionId($collectionId: ID!, $first: Int, $after: String) {
    node(id: $collectionId) {
      ... on Collection {
        id
        name
        isDefault
        latitude
        longitude
        locationName
        user {
          id
        }
        collectedItems(after: $after, first: $first) {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              collectedHotel {
                id
                hotelId
                notes
                hotel {
                  id
                  address
                  amenities
                  checkinInstructions
                  checkinSpecialInstructions
                  checkout
                  city
                  cityCode
                  country
                  countryCode
                  createdAt
                  descriptions {
                    type
                    text
                  }
                  emailAddresses
                  fax
                  fees {
                    type
                    text
                  }
                  googlePlaceId
                  googlePlaceIds
                  images {
                    caption
                    category
                    links {
                      size
                      href
                      providerHref
                      disabled
                    }
                  }
                  imagesCacheKey
                  lastAssembledAt
                  latitude
                  longitude
                  minAge
                  name
                  phoneNumbers
                  pointsOfInterest {
                    name
                    type
                    distance
                    geocode
                    unit
                  }
                  postalCode
                  policies {
                    title
                    text
                  }
                  rating
                  state
                  stateCode
                  traditionalLodging
                  updatedAt
                  webAddress
                  isCollected
                }
              }
              collectedGuide {
                notes
                guide {
                  id
                  displayId
                  addresses {
                    id
                    city
                    googlePlaceId
                    lat
                    long
                    state
                    country
                  }
                  collectedCount
                  description
                  endDate
                  eventCounts {
                    numberExperienceEvents
                    numberFoodAndDrinkEvents
                  }
                  guideType
                  images {
                    id
                    source
                    type
                    url
                  }
                  imageUrl
                  insertedAt
                  name
                  numberOfDays
                  numberOfDestinations
                  numberOfLikes
                  ownerProfile {
                    id
                    isUserOwner
                    userId
                    accountHandle
                    displayName
                    profileImageUrl
                    profileImageSource
                  }
                  recommendedHotelIds
                  shareCount
                  startDate
                  status
                  updatedAt
                  timeZone
                  viewCount
                  isCollected
                }
              }
              collectedEvent {
                id
                notes
                event {
                  id
                  displayId
                  imageUrl
                  name
                  description
                  startDate
                  endDate
                  productId
                  externalTypeId
                  status
                  type
                  notes
                  archivedAt
                  insertedAt
                  updatedAt
                  publishedEventCategory {
                    id
                    displayId
                    name
                    description
                    insertedAt
                    updatedAt
                  }
                  addresses {
                    id
                    addressLine1
                    addressLine2
                    googlePlaceId
                    lat
                    long
                    city
                    state
                    country
                    zipcode
                  }
                  isCollected
                  guide {
                    id
                    ownerProfile {
                      id
                      userId
                      accountHandle
                      displayName
                      profileImageUrl
                    }
                    name
                  }
                }
              }
              collectedExperience {
                id
                productId
                notes
                product {
                  id
                  productCode
                  description
                  active
                  status
                  timeZone
                  language
                  productUrl
                  title
                  additionalInfo {
                    type
                    description
                  }
                  cancellationPolicy {
                    cancelIfBadWeather
                    cancelIfInsufficientTravelers
                    type
                    description
                    refundEligibility {
                      dayRangeMin
                      percentageRefundable
                      dayRangeMax
                      endTimestamp
                      startTimestamp
                    }
                  }
                  destinations {
                    destinationId
                    parentId
                    sortOrder
                    destinationName
                    destinationType
                    defaultCurrencyCode
                    iataCode
                    latitude
                    longitude
                    lookupId
                    timeZone
                  }
                  images {
                    srcSet
                    size210x118
                    size240x160
                    size360x240
                    size400x400
                    size480x320
                    size540x360
                    size674x446
                    size720x480
                  }
                  inclusions {
                    category
                    categoryDescription
                    description
                    otherDescription
                    type
                    typeDescription
                    quantity
                  }
                  pricingInfo {
                    type
                    unitType
                  }
                  reviews {
                    totalReviews
                    combinedAverageRating
                  }
                  isCollected
                }
              }
            }
            cursor
          }
          totalCount
        }
      }
    }
  }
`)

export const useGetCollectionById = (collectionId: string) =>
  useQuery(GET_COLLECTION_BY_ID, {
    /** @todo remove errorPolicy when BE errors are fixed. */
    variables: { collectionId, first: 10 },
    skip: !collectionId,
    errorPolicy: 'ignore',
  })

import { useEffect, useRef, useState } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import {
  GuideStatus,
  type GetFeedFromCurrentUserQueryInDashboardQueryVariables,
} from 'src/__generated__/graphql'
import { useIsElementOnScreen } from 'src/common/hooks'
import type { UseGetUsersLikeGuideQuery } from '../../useGetUsersLikeGuideQuery'

interface GuideLikesModalLoadMoreProps {
  guideLikesData: UseGetUsersLikeGuideQuery['guideLikesData']
  onGetMoreResults: UseGetUsersLikeGuideQuery['onGetMoreResults']
}

export const GuideLikesModalLoadMore = ({
  guideLikesData,
  onGetMoreResults,
}: GuideLikesModalLoadMoreProps) => {
  const fetchMoreRef = useRef({
    isLoading: false,
    cursor: '',
  })
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { endCursor, hasNextPage } =
    guideLikesData?.paginatedGuidesLikedByUsers?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(
    async ({ after }: GetFeedFromCurrentUserQueryInDashboardQueryVariables) => {
      if (isLoading || after === fetchMoreRef.current.cursor) return

      fetchMoreRef.current.cursor = after
      setIsLoading(true)

      try {
        await onGetMoreResults({ after })
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    300
  )

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) {
      debouncedGetMoreResults({
        after: endCursor,
        userGuidesArgs: {
          includeStatuses: [GuideStatus.Published],
        },
      })
    }
  }, [debouncedGetMoreResults, endCursor, hasNextPage, isLastElementVisible])

  if (!hasNextPage) return

  return (
    <div className='h-2 space-y-4'>
      <div className='invisible m-0 h-0' ref={lastElementRef} />
      {isLoading && <SkeletonDots />}
    </div>
  )
}

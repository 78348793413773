import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { useFlag } from 'src/common/hooks'
import { HotelReviewsAverages } from './HotelReviewsAverages'
import { getReviewRatingDescription } from '../../../utils'

interface HotelReviewsSummarySectionProps {
  customerReviewScore: number
  totalCustomerReviews?: number
  cleanlinessAverage?: number | null
  locationAverage?: number | null
  staffAverage?: number | null
}

export const HotelReviewsSummarySection = ({
  customerReviewScore,
  totalCustomerReviews,
  cleanlinessAverage,
  locationAverage,
  staffAverage,
}: HotelReviewsSummarySectionProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const equalOrHigherThanSeven = customerReviewScore >= 7

  return (
    <div
      className={classNames('flex-justify-center flex', {
        'bg-warmGrey rounded-12px lg:rounded-0px mx-5 gap-10 p-5 lg:mx-0 lg:gap-20 lg:p-0':
          newHotelDetails,
        'gap-25': !newHotelDetails,
      })}
    >
      <div className='flex flex-col gap-1'>
        <div
          className={classNames('rd-1 w-fit px-2 py-1 text-center', {
            'bg-tealMedium': equalOrHigherThanSeven,
            'bg-grey800': !equalOrHigherThanSeven,
          })}
        >
          <h3
            className={classNames('type-h3 c-white', {
              'gap-x-10px flex flex-row': newHotelDetails,
            })}
          >
            {`${customerReviewScore}${newHotelDetails ? '/10' : ''}`}
            {newHotelDetails ? <Icon name='thumbUpAlt' /> : null}
          </h3>
        </div>
        <p
          className={classNames('type-body-1', {
            'c-tealMedium': equalOrHigherThanSeven,
            'c-grey800': !equalOrHigherThanSeven,
            'hidden lg:block': newHotelDetails,
          })}
        >
          {getReviewRatingDescription(customerReviewScore)}
        </p>
        <p className='type-body-1'>{`${totalCustomerReviews} Reviews`}</p>
      </div>
      <div className='md:max-w-1/3 w-full max-w-full'>
        <HotelReviewsAverages
          cleanlinessAverage={cleanlinessAverage}
          locationAverage={locationAverage}
          staffAverage={staffAverage}
        />
      </div>
    </div>
  )
}

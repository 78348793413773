import { Icon, Tag } from '@travelpass/design-system'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { GuideImage } from 'src/pages/guides/details/common'
import { getEventImage } from 'src/pages/trips/utils'
import { getGuideDetailsUrl, getImageResized } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardAction } from './DashboardFeedCardAction'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import type { DashboardFeedItem } from '../../../types'

interface DashboardFeedCardRepublishedGuideCreatedEventProps {
  dashboardFeedItem: DashboardFeedItem
}

/** @todo Replace guide with event feed item (waiting on BE).  */
export const DashboardFeedCardRepublishedGuideCreatedEvent = ({
  dashboardFeedItem,
}: DashboardFeedCardRepublishedGuideCreatedEventProps) => {
  const { id, latestUpdates, name } = dashboardFeedItem?.guide ?? {}
  const event = latestUpdates?.newEvents?.events?.[0]
  const image = getEventImage(event?.images?.[0]?.url ?? event?.imageUrl)
  const url = getGuideDetailsUrl({
    id,
    name,
  })

  const onAddGuideToCollections = () =>
    dispatchToggleAddToCollectionsModal({
      isModalOpen: true,
      item: {
        id,
        name,
        type: CollectionItemIdType.Guide,
      },
    })

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide dashboardFeedItem={dashboardFeedItem}>
          <p className='type-body-2 flex flex-row items-center gap-1'>
            <span className='min-w-fit'>
              A new recommendation has been added to
            </span>
            <a
              className='c-new-forest font-600 transition-opacity-200 relative line-clamp-1 min-w-0 grow no-underline ease-linear hover:opacity-70'
              href={url}
              rel='noreferrer'
              target='_blank'
            >
              <q>{name}</q>
            </a>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div className='space-y-3'>
          <p className='c-orange m-0 flex flex-row items-center gap-1'>
            <Icon name='placeOutline' size='small' />
            <span className='type-body-1-medium'>{event?.name}</span>
          </p>
          <GuideImage
            className='md:h-65 h-53 rounded-1 pointer-events-none'
            src={getImageResized({
              imageUrl: image,
              shape: 'square',
              width: 1000,
            })}
          />
          <div className='relative text-right'>
            <DashboardFeedCardAction
              aria-label='Add Guide to collections'
              onClick={onAddGuideToCollections}
            >
              <Tag color='newForestLight' startIcon='add' text='Add to' />
            </DashboardFeedCardAction>
          </div>
        </div>
      </article>
    </DashboardFeedCard>
  )
}

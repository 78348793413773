import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useLocation } from 'react-router-dom'
import { gql } from 'src/__generated__/'
import type { UpdateUserCreateAccountMutationVariables } from 'src/__generated__/graphql'
import { useFlag, useUpsertCurrentUserProfileMutation } from 'src/common/hooks'
import { CreateAccount } from './CreateAccount'
import type { UpdateUserInfoProps, alternateModal } from './types'
import { COMPETITION_SESSION_STORAGE } from '../competition/competitionConstants'

const GetUserByExternalIdGql = gql(`
  query GetUserByExternalId($externalId: String!) {
    getUserByExternalId(externalId: $externalId) {
      id
    }
  }
`)

const updateUserCreateAccount = gql(`
  mutation UpdateUserCreateAccount($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`)

export const CreateAccountApollo = ({
  createAccountOrSignInMessage,
  errorText,
  setAttemptingCreation,
  onCloseModal,
  setErrorText,
  setIsCreatingAccount,
  onOpenAlternateModal,
}: {
  createAccountOrSignInMessage: ReactNode
  errorText: string
  setAttemptingCreation: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal(): void
  setErrorText: Dispatch<SetStateAction<string>>
  setIsCreatingAccount: React.Dispatch<React.SetStateAction<boolean>>
  onOpenAlternateModal(modal: alternateModal): void
}): JSX.Element => {
  const isNewSignUpEnabled = useFlag('newSignUpFlow')
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const [isCreatedByEmail, setIsCreatedByEmail] = useState(false)
  const { addSuccessSnack } = useSnackbar()

  const onChangeCreatedByEmail = () => setIsCreatedByEmail(true)

  const [formData, setFormData] = useState<UpdateUserInfoProps>({
    email: null,
    externalId: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
  })
  const { pathname } = useLocation()
  const isOnVotingPage =
    pathname.includes('/competition/leaderboard') ||
    pathname.includes('/profile')
  const [updateUser] = useMutation(updateUserCreateAccount)
  const [upsertProfile] = useUpsertCurrentUserProfileMutation()
  const { loading: isLoading } = useQuery(GetUserByExternalIdGql, {
    skip: !formData?.externalId,
    variables: { externalId: formData?.externalId },
    onCompleted: isNewSignUpEnabled
      ? async data => {
          const id = data?.getUserByExternalId?.id ?? ''
          if (!isEmpty(id)) {
            const referrerId = sessionStorage.getItem(
              COMPETITION_SESSION_STORAGE
            )
            const { externalId, displayName, ...inputData } = formData
            const [firstName, lastName] = displayName
              ?.replace(/[^a-zA-Z0-9 ]/g, '')
              ?.split(' ') ?? ['', '']
            const variables: UpdateUserCreateAccountMutationVariables = {
              input: { id, firstName, lastName, ...inputData },
            }

            if (referrerId && enableCompetition2024)
              variables.input.referrerId = referrerId

            await Promise.all([
              updateUser({ variables }),
              upsertProfile({
                variables: {
                  input: {
                    userProfileRequest: {
                      displayName,
                    },
                  },
                },
              }),
            ])

            if (isCreatedByEmail)
              addSuccessSnack({ title: 'Account successfully created' })
            else addSuccessSnack({ title: 'Successfully signed in' })

            if (!isOnVotingPage) onOpenAlternateModal('onboarding')
            else onOpenAlternateModal('sendVerificationEmail')
          }
        }
      : data => {
          const id = data?.getUserByExternalId?.id ?? ''
          if (!isEmpty(id)) {
            const referrerId = sessionStorage.getItem(
              COMPETITION_SESSION_STORAGE
            )
            const { externalId, ...inputData } = formData
            const variables: UpdateUserCreateAccountMutationVariables = {
              input: { id, ...inputData },
            }

            if (referrerId && enableCompetition2024)
              variables.input.referrerId = referrerId

            updateUser({ variables })
            onCloseModal()

            if (isCreatedByEmail)
              addSuccessSnack({ title: 'Account successfully created' })
            else addSuccessSnack({ title: 'Successfully signed in' })
          }
        },
  })

  const updateUserInfo = (data: UpdateUserInfoProps) => {
    setFormData(data)
  }

  return (
    <CreateAccount
      createAccountOrSignInMessage={createAccountOrSignInMessage}
      errorText={errorText}
      isLoading={isLoading}
      setAttemptingCreation={setAttemptingCreation}
      setErrorText={setErrorText}
      setIsCreatingAccount={setIsCreatingAccount}
      updateUserInfo={updateUserInfo}
      onChangeCreatedByEmail={onChangeCreatedByEmail}
      onCloseModal={onCloseModal}
    />
  )
}

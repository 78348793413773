import { Link } from 'react-router-dom'
import type { City } from '../FeaturedCities'

export const CityCard = ({ city, href, img, state }: City) => (
  <Link
    className='decoration-none hover:scale-99 group block space-y-2 transition-all ease-in'
    to={href}
  >
    <img
      alt=''
      className='aspect-ratio-[9/10] h-full w-full rounded-lg object-cover shadow-lg'
      src={img}
    />
    <div>
      <span className='group-hover:text-newForest block text-center text-sm font-medium uppercase text-zinc-500 transition-all ease-in'>
        {state}
      </span>
      <span className='group-hover:text-forestLight block text-center text-2xl font-bold text-black transition-all ease-in'>
        {city}
      </span>
    </div>
  </Link>
)

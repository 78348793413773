import { useRef } from 'react'
import { Icon, StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { getGuideAddress, getGuideDetailsUrl, getImageResized } from 'src/utils'
import { DashboardGuideOldCardMetrics } from './DashboardGuideOldCardMetrics'
import { DashboardGuideOldCardPopoverMenu } from './DashboardGuideOldCardPopoverMenu'
import { GuideBadge, GuideImage } from '../../../guides/details/common'
import type { UseGetDashboardGuideDraftsQueryOld } from '../../useGetDashboardGuideDraftsQueryOld'

interface DashboardGuideOldCardProps {
  guideDraftData: UseGetDashboardGuideDraftsQueryOld['dashboardGuideDraftsData']['edges'][0]['node']
}

export const DashboardGuideOldCard = ({
  guideDraftData,
}: DashboardGuideOldCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const { guide } = guideDraftData ?? {}
  const {
    addresses,
    description,
    imageUrl,
    name,
    paginatedImages,
    status,
    strippedId,
  } = guide ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const address = getGuideAddress({
    city,
    country,
    state,
  })
  const image = paginatedImages?.edges?.[0]?.node?.url
  const href = getGuideDetailsUrl({
    id: strippedId,
    name,
  })

  return (
    <>
      {/** @todo refactor this to be more like DashboardFeedCard */
      /*
      eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(
          'rounded-3 border-grey-300 of-hidden shadow-3 relative border-2 border-solid hover:cursor-pointer',
          /**
           * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
           * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
           */
          'group'
        )}
        onClick={() => anchorRef?.current?.click?.()}
      >
        <div className='relative flex flex-col justify-between gap-4 p-3 md:min-h-52 md:flex-row md:justify-start lg:gap-6'>
          {/* Top (Mobile) Picture */}
          <div className='relative h-44 w-full overflow-hidden rounded-lg md:hidden'>
            <div className='absolute left-3 top-3 w-fit'>
              <GuideBadge>
                <Icon
                  name={
                    status === GuideStatus.Published
                      ? 'visibility'
                      : 'visibilityOff'
                  }
                  size='small'
                />
                <span>
                  {status === GuideStatus.Published ? 'Public' : 'Private'}
                </span>
              </GuideBadge>
            </div>
            <GuideImage
              src={getImageResized({
                imageUrl: image,
                shape: 'landscape',
                width: 1000,
              })}
            />
          </div>
          {/* Lefthand (Desktop) Picture */}
          <div className='rounded-3 max-w-50 min-w-50 hidden h-48 overflow-hidden md:block'>
            <GuideImage
              src={getImageResized({
                imageUrl: image,
                shape: 'square',
                width: 300,
              })}
            />
          </div>
          <div className='grow space-y-3 lg:space-y-4'>
            <div className='space-y-3'>
              <div className='flex min-w-0 flex-col gap-2'>
                {/* important for SEO! Preserving referrer allows search engine bots to crawl internal links */}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <StopPropagation>
                  <div className='flex flex-row items-center justify-between gap-2'>
                    <Link
                      className='outline-offset-5 focus-visible:outline-emerald type-h3 lg:type-h4 c-forest inline-block no-underline'
                      ref={anchorRef}
                      to={href}
                    >
                      {name}
                    </Link>
                    <div className='flex min-w-fit flex-row items-center'>
                      <div className='hidden w-fit md:block'>
                        <GuideBadge>
                          <Icon
                            name={
                              status === GuideStatus.Published
                                ? 'visibility'
                                : 'visibilityOff'
                            }
                            size='small'
                          />
                          <span>
                            {status === GuideStatus.Published
                              ? 'Public'
                              : 'Private'}
                          </span>
                        </GuideBadge>
                      </div>
                      <DashboardGuideOldCardPopoverMenu
                        guideDraftData={guideDraftData}
                      />
                    </div>
                  </div>
                </StopPropagation>
                <div className='[&>i]:c-canyon flex min-h-4 flex-row items-center gap-1'>
                  {address && (
                    <>
                      <Icon name='placeOutline' size='small' />
                      <span className='type-small-text color-grey-900 line-clamp-1'>
                        {address}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='lg:type-body-2 lg:min-h-13.5 type-body-1 line-clamp-2 min-h-9 lg:line-clamp-3 [&>button]:gap-1'>
                {description}
              </div>
            </div>
            <DashboardGuideOldCardMetrics guideData={guide} />
          </div>
        </div>
      </div>
    </>
  )
}

import { useEffect, useRef } from 'react'
import { Button, EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { isMobile, isTablet } from 'react-device-detect'
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { generateHotelResultsUrl, getPrice } from 'src/utils'
import {
  HotelAmenities,
  HotelFooter,
  HotelHero,
  HotelOverview,
  HotelPolicy,
  HotelRooms,
  HotelReviewsApollo,
  HotelStickyMenu,
} from './components'
import { HotelAmenitiesNew } from './components/HotelAmenities/HotelAmenitiesNew'
import {
  HotelHeroHeader,
  HotelHeroHeaderNew,
} from './components/HotelHero/HotelHeroHeader'
import { HotelOverviewNearbyAttractions } from './components/HotelOverview/HotelOverviewNearbyAttractions'
import { HotelRoomsSearchControls } from './components/HotelRooms/HotelRoomsSearchControls'
import { HotelStickyMenuContext } from './components/HotelStickyMenu/HotelStickyMenuContext'
import { hotelTabs, HOTEL_ROOMS_DIV_ID } from './constants'
import { useHotelReviewsSummaryListHotelReviewsQuery } from './hooks'
import { useHotelDetailsStandardHotel } from './hooks/useHotelDetailsStandardQuery'
import { useHotelHeroStandardHotelQuery } from './hooks/useHotelHeroStandardHotelQuery'

export const Hotel = () => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [searchParams] = useSearchParams()
  const params = useParams()
  const { data: hotelDetails, error, loading } = useHotelDetailsStandardHotel()
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const navigate = useNavigate()
  const { totalReviews } = hotelReviewsSummaryData ?? {}

  const hotelOverviewRef = useRef<HTMLDivElement>(null)
  const hotelRoomsRef = useRef<HTMLDivElement>(null)
  const hotelNearbyRef = useRef<HTMLDivElement>(null)
  const hotelAmenitiesRef = useRef<HTMLDivElement>(null)
  const hotelReviewsRef = useRef<HTMLDivElement>(null)
  const hotelPolicyRef = useRef<HTMLDivElement>(null)

  const isHotelOverviewOnScreen = useIsElementOnScreen(hotelOverviewRef)
  const isHotelRoomsOnScreen = useIsElementOnScreen(hotelRoomsRef)
  const isHotelNearbyOnScreen = useIsElementOnScreen(hotelNearbyRef)
  const isHotelAmenitiesOnScreen = useIsElementOnScreen(hotelAmenitiesRef)
  const isHotelReviewsOnScreen = useIsElementOnScreen(hotelReviewsRef)
  const isHotelPolicyOnScreen = useIsElementOnScreen(hotelPolicyRef)

  const { latitude, longitude } = hotelDetails ?? {}
  const adults = searchParams.get('adults')
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const kids = searchParams.get('kids')
  const location = searchParams.get('location')
  const rateSP = searchParams.get('lowestRate')

  useEffect(() => {
    if (!isEmpty(hotelDetails)) {
      pushDataToDataLayer('hotelPageVisited', {
        currency: 'USD',
        hotel: { arrival, departure, ...hotelDetails },
        cityPageSearchUrl: `https://travelpass.com${generateHotelResultsUrl({
          adults,
          arrival,
          departure,
          kids,
          latitude,
          location,
          longitude,
        })}`,
        cityPageSearchUrlNoDates: `https://travelpass.com${generateHotelResultsUrl(
          {
            adults,
            kids,
            latitude,
            location,
            longitude,
          }
        )}`,
      })
    }
  }, [
    adults,
    arrival,
    departure,
    kids,
    latitude,
    location,
    longitude,
    hotelDetails,
  ])

  const elementsOnScreen = [
    isHotelOverviewOnScreen,
    isHotelRoomsOnScreen,
    isHotelNearbyOnScreen,
    isHotelAmenitiesOnScreen,
    isHotelReviewsOnScreen,
    isHotelPolicyOnScreen,
  ]
  const sectionRefs = [
    hotelOverviewRef,
    hotelRoomsRef,
    hotelNearbyRef,
    hotelAmenitiesRef,
    hotelReviewsRef,
    hotelPolicyRef,
  ]
  const activeIndex = elementsOnScreen.findIndex(refOnScreen => !!refOnScreen)

  const tabs = sectionRefs
    .map((ref, index) => {
      if (hotelTabs?.[index] === 'Nearby' && !newHotelDetails) return null
      if (hotelTabs?.[index] === 'Overview' && newHotelDetails) return null
      if (hotelTabs?.[index] === 'Reviews' && totalReviews === 0) return null
      return {
        label: hotelTabs?.[index] ?? '',
        onClick: () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
      }
    })
    .filter(tab => tab !== null)

  const dataIsMissing = !loading && !hotelDetails

  const { standardHotel } = useHotelHeroStandardHotelQuery()

  const lowestRate = getPrice(rateSP)

  const scrollToRooms = () =>
    hotelRoomsRef.current?.scrollIntoView({
      behavior: 'smooth',
    })

  const handleCTAButtonClick = () => {
    scrollToRooms()
    pushDataToDataLayer('book_now_click_sticky', {
      rate: rateSP,
      arrival,
      departure,
      adults,
      kids,
      hotel: params?.hotelName,
      hotelId: params?.hotelId,
      city: params?.city,
      state: params?.state,
    })
  }

  if (error || dataIsMissing) {
    return (
      <div className='mx-8 mt-20 flex flex-col items-center justify-center gap-4'>
        <div className='type-h3'>Unable to locate hotel</div>
        <div className='type-h4'>
          Oops! We weren&apos;t able to locate the hotel you&apos;re looking
          for.
        </div>
        <div className='w-250px'>
          <EmptyState name='error' />
        </div>{' '}
        <Button
          label='Back to Hotel Search'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </div>
    )
  }

  return (
    <HotelStickyMenuContext.Provider value={{ activeIndex, tabs }}>
      <div>
        {newHotelDetails && (
          <div className='bg-warm-grey of-hidden z-2 max-h-84px h-84px md:max-h-100px md:h-100px sticky top-0 flex items-center'>
            <div className='max-w-340 mx-auto flex w-full items-center px-4 max-md:gap-2 md:px-8'>
              <div className='md:w-4/7 w-full max-md:flex-[6]'>
                <div className='grid w-full grid-cols-7 gap-2 max-md:items-center md:grid-cols-10 md:gap-3'>
                  <HotelRoomsSearchControls />
                </div>
              </div>
              <div className='md:w-3/7 [&_button]:max-md:px-3! [&_button]:max-md:py-5! [&_button]:md:max-lg:px-2! [&_button]:max-md:rounded-full! flex items-center justify-end gap-4 max-md:mt-5 max-md:flex-1'>
                {rateSP && (
                  <>
                    <span className='c-grey-800 text-right text-sm max-md:hidden'>
                      from{' '}
                      <span className='c-forest text-xl font-semibold'>
                        {lowestRate}
                      </span>{' '}
                      /night
                    </span>
                    <Button
                      endIcon={isMobile ? 'modeEdit' : undefined}
                      label={!isMobile ? 'Choose your room' : undefined}
                      size={isTablet || isMobile ? 'small' : 'medium'}
                      onClick={handleCTAButtonClick}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <HotelHero hotelRoomsRef={hotelRoomsRef} />
        {newHotelDetails && (
          <HotelStickyMenu activeIndex={activeIndex} tabs={tabs} />
        )}
        {newHotelDetails ? (
          <HotelHeroHeaderNew
            hotelData={standardHotel}
            hotelReviewData={hotelReviewsSummaryData}
          />
        ) : (
          <HotelHeroHeader
            hotelData={standardHotel}
            hotelRoomsRef={hotelRoomsRef}
          />
        )}
        {!newHotelDetails && (
          <HotelStickyMenu activeIndex={activeIndex} tabs={tabs} />
        )}
        <div
          className='bg-warm-grey scroll-m-46px md:scroll-m-30px'
          ref={hotelOverviewRef}
        >
          <HotelOverview hotelDetailsData={hotelDetails} />
        </div>
        <div
          className='md:scroll-m-29 scroll-m-24'
          id={HOTEL_ROOMS_DIV_ID}
          ref={hotelRoomsRef}
        >
          <HotelRooms />
        </div>
        {newHotelDetails && (
          <div
            className='grid-items-center lg:py-15 bg-warm-grey flex flex-col gap-6 py-10 lg:gap-12'
            ref={hotelNearbyRef}
          >
            <HotelOverviewNearbyAttractions
              hotelDetailsData={hotelDetails}
              showMap={true}
            />
          </div>
        )}
        <div className='md:scroll-m-55px scroll-m-9' ref={hotelAmenitiesRef}>
          {newHotelDetails ? (
            <HotelAmenitiesNew amenities={hotelDetails?.amenities} />
          ) : (
            <HotelAmenities amenities={hotelDetails?.amenities} />
          )}
        </div>
        <div
          className={classNames('md:scroll-m-55px scroll-m-9', {
            'lg:bg-warmGrey': newHotelDetails,
          })}
          ref={hotelReviewsRef}
        >
          <HotelReviewsApollo />
        </div>
        <div className='md:scroll-m-55px scroll-m-9' ref={hotelPolicyRef}>
          <HotelPolicy />
        </div>
        <HotelFooter />
      </div>
    </HotelStickyMenuContext.Provider>
  )
}

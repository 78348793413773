import { useState } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { GuideDraftEventImagesUploadModal } from 'src/pages/guides/lists/common'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideDrawerContentAbout } from './GuideDrawerContentAbout'
import { GuideDrawerContentFooter } from './GuideDrawerContentFooter'
import { GuideDrawerContentLoading } from './GuideDrawerContentLoading'
import { GuideDrawerContentNotes } from './GuideDrawerContentNotes'
import { GuideDrawerHero } from './GuideDrawerHero'
import { useGetGuideDrawerQuery } from '../../useGetGuideDrawerQuery'

interface GuideDrawerContentProps {
  guideId: string
  isEdit: boolean
  notes: string
  onNotesChange: (updatedNotes: string) => void
  owner: ProfileOwner
  selectedEvent: PublishedEvent
}

/** @todo tests (separate tests for GuideDraft/Guide drawer contents) */
export const GuideDrawerContent = ({
  guideId,
  isEdit,
  notes,
  onNotesChange,
  owner,
  selectedEvent,
}: GuideDrawerContentProps) => {
  const {
    guideDrawerData,
    guideDrawerEventData,
    guideDrawerImages,
    isLoading,
  } = useGetGuideDrawerQuery(selectedEvent)
  const [isModalImageUploadOpen, setIsModalImageUploadOpen] = useState(false)

  if (isLoading || isEmpty(selectedEvent))
    return <GuideDrawerContentLoading isEdit={isEdit} />

  return (
    <>
      <div className='p-b-8 grow space-y-4 overflow-y-auto'>
        <GuideDrawerHero
          imageLinks={guideDrawerImages}
          isEdit={isEdit}
          ownerName={owner?.name}
          selectedEvent={selectedEvent}
        />
        {isEdit && (
          <div className='flex flex-col items-center'>
            <Button
              size='small'
              startIcon='camera'
              variant='outlined'
              onClick={() => setIsModalImageUploadOpen(true)}
            >
              {selectedEvent?.images?.length ? 'Edit photos' : 'Add photos'}
            </Button>
          </div>
        )}
        <div className='p-x-9 space-y-4'>
          <GuideDrawerContentAbout
            guideDrawerData={guideDrawerData}
            selectedEvent={selectedEvent}
          />
          <GuideDrawerContentNotes
            isEdit={isEdit}
            notes={notes}
            owner={owner}
            onNotesChange={onNotesChange}
          />
          {!isEdit && guideDrawerEventData?.appearsInGuides?.totalCount > 1 && (
            <div className='m-x-a w-fit'>
              <div className='rounded-2 bg-mint p-x-2 p-y-0.5 [&>*]:c-new-forest flex flex-row items-center gap-1'>
                <Icon name='listAlt' size='small' />
                <span className='type-body-1-medium'>{`Appears in ${guideDrawerEventData?.appearsInGuides?.totalCount} Guides`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isEdit && <GuideDrawerContentFooter selectedEvent={selectedEvent} />}
      {isModalImageUploadOpen && (
        <GuideDraftEventImagesUploadModal
          guideId={guideId}
          selectedEvent={selectedEvent}
          onDismiss={() => setIsModalImageUploadOpen(false)}
        />
      )}
    </>
  )
}

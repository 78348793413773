import { useEffect, useRef, useState } from 'react'
import { Button, SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import type { Scalars } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useIsElementOnScreen } from 'src/common/hooks'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { DashboardGuideOldCard } from './DashboardGuideOldCard'
import { DashboardGuidesOldEmpty } from './DashboardGuidesOldEmpty'
import { DashboardGuidesOldLoading } from './DashboardGuidesOldLoading'
import { useGetDashboardGuideDraftsQueryOld } from '../../useGetDashboardGuideDraftsQueryOld'

export const DashboardGuidesOld = () => {
  const { dashboardGuideDraftsData, hasError, isLoading, onGetMoreResults } =
    useGetDashboardGuideDraftsQueryOld()
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const fetchMoreCursorRef = useRef('')
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const { endCursor, hasNextPage } = dashboardGuideDraftsData?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(
    async ({ after }: { after: Scalars['String']['input'] }) => {
      if (isFetchingMore || after === fetchMoreCursorRef.current) return

      setIsFetchingMore(true)
      fetchMoreCursorRef.current = after

      try {
        await onGetMoreResults({
          after,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetchingMore(false)
      }
    },
    300
  )

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) {
      debouncedGetMoreResults({
        after: endCursor,
      })
    }
  }, [debouncedGetMoreResults, endCursor, hasNextPage, isLastElementVisible])

  const onModalOpen = () => setIsModalOpen(true)

  const onModalClose = () => setIsModalOpen(false)

  if (isLoading) return <DashboardGuidesOldLoading />

  if (hasError || isEmpty(dashboardGuideDraftsData?.edges))
    return <DashboardGuidesOldEmpty />

  return (
    <>
      <section className='space-y-6'>
        <header>
          <Button
            aria-label='Create a guide'
            size='small'
            startIcon='add'
            variant='text'
            onClick={onModalOpen}
          >
            New Guide
          </Button>
        </header>
        <div className='space-y-6'>
          {dashboardGuideDraftsData?.edges?.map(({ node }) => (
            <DashboardGuideOldCard key={node?.id} guideDraftData={node} />
          ))}
          {hasNextPage && (
            <div className='invisible m-0 h-0' ref={lastElementRef} />
          )}
          {isFetchingMore && <SkeletonDots />}
        </div>
      </section>
      {isModalOpen && (
        <GuideDraftCreateModalOld
          triggerButton='dashboardMiddleNewGuide'
          onDismiss={onModalClose}
        />
      )}
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </>
  )
}

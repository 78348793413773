import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetAllGuideDraftsOldQueryVariables } from 'src/__generated__/graphql'

const GET_ALL_GUIDE_DRAFTS = gql(`
  query GetAllGuideDraftsOld($userGuideDraftsArgs: UserGuideDraftsArgs!, $first: Int = 100, $includeExperience: Boolean = false, $includeHotel: Boolean = false, $includeEvent: Boolean = false, $productId: ID = null, $hotelId: ID = null, $publishedEventId: ID = null) {
    currentUser {
      userGuideDrafts(userGuideDraftsArgs: $userGuideDraftsArgs, first: $first) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            existingExperience: existingGuideDraftItem (productId: $productId) @include(if: $includeExperience) {
              isExistingItem
              publishedEvents {
                id
              }
            }
            existingHotel: existingGuideDraftItem (hotelId: $hotelId) @include(if: $includeHotel) {
              isExistingItem
              publishedEvents {
                id
              }
            }
            existingEvent: existingGuideDraftItem (publishedEventId: $publishedEventId) @include(if: $includeEvent) {
              isExistingItem
              publishedEvents {
                id
              }
            }
            name
            publishedEvents {
              id
              externalTypeId
              place {
                id
                googlePlaceId
              }
              productId
            }
          }
        }
      }
    }
  }
`)

export const useGetAllGuidesOld = (
  variables: GetAllGuideDraftsOldQueryVariables
) => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_GUIDE_DRAFTS, {
    variables,
  })

  const guideDraftsData = data?.currentUser?.userGuideDrafts?.edges?.map(
    ({ node }) => node
  )

  return {
    data: guideDraftsData,
    loading,
    error,
    refetch,
  }
}

import { useEffect } from 'react'
import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type { LatLong } from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { ProfileOwner } from 'src/pages/profile/types'
import {
  decodeGuideDetailsName,
  decodeGuideId,
  encodeGuideId,
  getGuideDetailsUrl,
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfileOwnerUrl,
} from 'src/utils'
import { GuideSearchParam } from './guideConstants'
import { getGuideMetaData } from './guideUtils'
import type { GuideData, GuideMetaData } from './types'
import { useIncrementGuideViewCountMutation } from '../useIncrementGuideViewCountMutation'

export const getGuideQuery = gql(`
  query GetGuideQueryInGuideDetails($id: ID!) {
    currentUser {
      id
    }
    node(id: $id) {
      ... on Guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        collectedCount
        description
        eventCounts {
          numberEvents
        }
        firstPublishedAt
        galleryImages {
          id
          url
        }
        guideDraft {
          id
          strippedId
        }
        imageUrl # remove when images are added
        insertedAt
        isCollected
        likedByCurrentUser
        name
        numberOfLikes
        ownerProfile {
          id
          accountHandle
          displayName
          isFollowed
          isUserOwner
          profileImageUrl
          userId
        }
        paginatedImages(first: 3) {
          edges {
            node {
              id
              source
              url
            }
          }
        }
        shareCount
        strippedId
        status
        tags {
          id
          name
        }
        updatedAt
        timeZone
        viewCount
      }
    }
  }
`)

type UseGetGuideQuery = {
  guideData: GuideData
  guideMetaData: GuideMetaData
  hasError: ApolloError
  isLoading: boolean
  location: LatLong
  owner: ProfileOwner
}

export const useGetGuideQuery = (): UseGetGuideQuery => {
  const [incrementGuideViewCount] = useIncrementGuideViewCountMutation()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const guideIdDecoded = decodeGuideId(params?.guideId)
  const id = encodeGuideId({
    guideId: guideIdDecoded,
    isGuideDraft: false,
  })
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getGuideQuery, {
    onCompleted: data => {
      const { guideDraft, id, name } = (data?.node as GuideData) ?? {}
      const userId = data?.currentUser?.id

      if (!id || !userId) return

      /** @todo ask Data if they want guide_draft_id or guide_id here */
      pushDataToDataLayer('guide_view', {
        guide_address: addresses?.[0]?.addressLine1,
        guide_city: addresses?.[0]?.city,
        guide_country: addresses?.[0]?.country,
        guide_draft_id: guideDraft?.id,
        guide_id: id,
        guide_owner_id: ownerProfile?.userId,
        guide_name: name,
        guide_state: addresses?.[0]?.state,
        trigger_url: window.location.href,
        user_id: userId,
      })

      incrementGuideViewCount({
        variables: {
          guideId: id,
        },
      })
    },
    skip: !id,
    variables: {
      id,
    },
  })
  const guideData = data?.node as GuideData
  const {
    addresses,
    eventCounts,
    imageUrl,
    name,
    ownerProfile,
    paginatedImages,
  } = guideData ?? {}
  const [address] = addresses ?? []
  const { lat, lng } = constructMapCenter(address?.lat, address?.long)
  const owner: ProfileOwner = {
    id: ownerProfile?.id,
    image: getProfileOwnerImage(ownerProfile?.profileImageUrl),
    isUserOwner: ownerProfile?.isUserOwner,
    name: getProfileOwnerName({
      accountHandle: ownerProfile?.accountHandle,
      displayName: ownerProfile?.displayName,
    }),
    url: getProfileOwnerUrl(ownerProfile?.accountHandle),
  }
  const guideNameFromSearchParam = searchParams.get(GuideSearchParam.name)
  const guideMetaData = getGuideMetaData({
    address: addresses?.[0],
    canonicalUrl: `${window.location.origin}${getGuideDetailsUrl({
      id: guideIdDecoded,
    })}`,
    image: paginatedImages?.edges?.[0]?.node?.url ?? imageUrl,
    name: name ?? decodeGuideDetailsName(guideNameFromSearchParam),
    numberEvents: eventCounts?.numberEvents,
    ownerName: owner?.name,
  })

  useEffect(() => {
    if (params?.guideId !== guideIdDecoded) {
      navigate(
        getGuideDetailsUrl({
          id: guideIdDecoded,
          name,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideIdDecoded, name, params?.guideId])

  return {
    guideData,
    guideMetaData,
    hasError,
    isLoading,
    location: {
      latitude: lat,
      longitude: lng,
    },
    owner,
  }
}

import { useEffect, useState } from 'react'
import { Button, useScreenQuery } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { HotelRoomCard } from '../HotelRoomCard'
import { HotelRoomDetails } from '../HotelRoomDetails'
import type { HotelRooms as HotelRoomsType } from '../hotelRoomsTypes'

interface HotelRoomsListProps {
  hotelRoomsData: HotelRoomsType
  hasCugRates: boolean
}

export const HotelRoomsList = ({
  hotelRoomsData,
  hasCugRates,
}: HotelRoomsListProps) => {
  const { isAnonymous } = useFirebaseUser()
  const newHotelDetails = useFlag('newHotelDetails')
  const { isDesktopScreen } = useScreenQuery()
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(0)

  useEffect(() => {
    if (selectedRoomIndex === -1) setSelectedRoomIndex(0)
  }, [isDesktopScreen])

  const handleRoomSelection = (index: number, selectOnly: boolean) => {
    if (selectOnly && selectedRoomIndex === index) return
    if (!selectOnly && selectedRoomIndex === index)
      return setSelectedRoomIndex(-1)
    setSelectedRoomIndex(index)
  }

  const onButtonClick = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
      disableAlternateModal: true,
    })
  }

  return (
    <div className='grid grid-cols-1 gap-10 lg:max-w-[1200px] lg:grid-cols-2'>
      <div className='rd-2 lg:b-solid border-1 border-color-grey-200 flex flex-col gap-6 lg:max-h-[840px] lg:overflow-auto lg:px-6 lg:py-3'>
        {newHotelDetails && isAnonymous && hasCugRates && (
          <div>
            <Button
              fullWidth
              label='Log In For Travelpass Member Prices'
              onClick={onButtonClick}
            />
          </div>
        )}
        {hotelRoomsData.map((room, index) => (
          <HotelRoomCard
            key={index}
            handleRoomSelection={handleRoomSelection}
            isSelected={selectedRoomIndex === index}
            room={room}
            roomIndex={index}
          />
        ))}
      </div>
      {hotelRoomsData[selectedRoomIndex] && (
        <div className='hidden lg:block'>
          <HotelRoomDetails room={hotelRoomsData[selectedRoomIndex]} />
        </div>
      )}
    </div>
  )
}

import { useRef } from 'react'
import { SkeletonDots, useScreenQuery } from '@travelpass/design-system'
import {
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageIds,
} from 'src/pages/guides/details'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideDraftEvent } from './GuideDraftEvent'
import { GuideDraftEventCard } from './GuideDraftEventCard'
import type { GuideDraftData, GuideDraftDragItem } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'

interface GuideDraftEventsProps {
  isSortingContainer: boolean
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onSelectedIdChange(updatedSelectedId: string): void
  owner: ProfileOwner
  paginatedEventCategory: GuideDraftDragItem
  paginatedEventCategories?: GuideDraftData['paginatedEventCategories']
  selectedId: string
}

export const GuideDraftEvents = ({
  isSortingContainer = false,
  onMapMarkerCenterChange,
  onSelectedIdChange,
  owner,
  paginatedEventCategories,
  paginatedEventCategory,
  selectedId,
}: GuideDraftEventsProps) => {
  const { hoverId } = useGuideSessionStorageIds()
  const { isMobileOrTablet } = useScreenQuery()
  const fetchMoreRef = useRef({
    isLoading: false,
    cursor: '',
  })
  const { id, publishedEvents } = paginatedEventCategory ?? {}

  const onMouseEnter = (updatedHoverId: string) => {
    if (isMobileOrTablet) return

    onGuideSessionStorageHoverIdChange(updatedHoverId)
  }

  const onMouseLeave = () => {
    if (isMobileOrTablet) return

    onGuideSessionStorageHoverIdChange('')
  }

  return (
    <div className='space-y-4'>
      {publishedEvents?.map(publishedEvent => (
        <GuideDraftEvent
          key={publishedEvent?.id}
          isSortingContainer={isSortingContainer}
          publishedEvent={publishedEvent}
          onSelectedIdChange={onSelectedIdChange}
        >
          <GuideDraftEventCard
            isHovered={hoverId === publishedEvent?.id}
            isSelected={selectedId === publishedEvent?.id}
            owner={owner}
            paginatedEventCategories={paginatedEventCategories}
            paginatedEventCategoryId={id}
            publishedEvent={publishedEvent}
            onMapMarkerCenterChange={onMapMarkerCenterChange}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onSelectedIdChange={onSelectedIdChange}
          />
        </GuideDraftEvent>
      ))}
      {fetchMoreRef.current.isLoading && <SkeletonDots />}
    </div>
  )
}

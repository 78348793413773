import { useLocation } from 'react-router-dom'
import { Helmet } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  UtahJazzBook,
  UtahJazzGuides,
  UtahJazzHeader,
  UtahJazzSignUp,
} from './components'

export const UtahJazz = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  return (
    <>
      <Helmet canonicalUrl={canonicalUrl} pageName={pageName} />
      <UtahJazzHeader />
      <UtahJazzGuides />
      <UtahJazzBook />
      <UtahJazzSignUp />
    </>
  )
}

const pageName = 'Travelpass & The Utah Jazz | Welcome, Jazz Fans!'

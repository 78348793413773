import { Button } from '@travelpass/design-system'
import { ProfileImageSource } from 'src/__generated__/graphql'

interface UploadAvatarModalButtonsProps {
  profileImageSource: ProfileImageSource
  fileInputRef
  onDelete(): void
}

export const UploadAvatarModalButtons = ({
  profileImageSource,
  fileInputRef,
  onDelete,
}: UploadAvatarModalButtonsProps) => {
  return (
    <div>
      <div className='hidden gap-6 md:flex'>
        <Button
          isDisabled={
            profileImageSource === ProfileImageSource.DefaultImage ||
            profileImageSource === ProfileImageSource.ClaimsImage
          }
          label='Delete photo'
          startIcon='deleteOutline'
          variant='outlined'
          onClick={onDelete}
        />
        <Button
          label='Upload photo'
          startIcon='upload'
          variant='outlined'
          onClick={() => fileInputRef.current.click()}
        />
      </div>
      <div className='flex flex-col flex-col-reverse gap-6 md:hidden'>
        <Button
          isDisabled={
            profileImageSource === ProfileImageSource.DefaultImage ||
            profileImageSource === ProfileImageSource.ClaimsImage
          }
          label='Delete photo'
          size='small'
          startIcon='deleteOutline'
          variant='outlined'
          onClick={onDelete}
        />
        <Button
          label='Upload photo'
          size='small'
          startIcon='upload'
          variant='outlined'
          onClick={() => fileInputRef.current.click()}
        />
      </div>
    </div>
  )
}

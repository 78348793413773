import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'
import type { TripMapPoint } from 'src/pages/trips/types'

export const TripDetailsMapPointsStore = createContext<{
  points: TripMapPoint[]
  setPoints: Dispatch<SetStateAction<TripMapPoint[]>>
}>({
  points: [],
  setPoints: () => {},
})

TripDetailsMapPointsStore.displayName = 'TripDetailsMapPointsStore'

import { useState } from 'react'
import { Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { getGuideAddress } from 'src/utils'
import {
  getGuideDrawerWebsite,
  getGuideDrawerWebsiteText,
} from '../../guideUtils'
import type { UseGetGuideDrawerQuery } from '../../useGetGuideDrawerQuery'

interface GuideDrawerContentAboutProps {
  guideDrawerData: UseGetGuideDrawerQuery['guideDrawerData']
  selectedEvent: PublishedEvent
}

export const GuideDrawerContentAbout = ({
  guideDrawerData,
  selectedEvent,
}: GuideDrawerContentAboutProps) => {
  const [isHoursExpanded, setIsHoursExpanded] = useState(false)
  const { city, country, openingHours, phoneNumber, state } =
    guideDrawerData ?? {}
  const { weekdayText } = openingHours ?? {}
  const address = getGuideAddress({
    addressLine1: guideDrawerData?.address,
    city,
    country,
    state,
  })
  const website = getGuideDrawerWebsite({
    externalWebsite: guideDrawerData?.website,
    selectedEvent,
  })

  return (
    <div className='space-y-3'>
      <h2 className='type-subtitle-1 line-clamp-1'>{selectedEvent?.name}</h2>
      {address && (
        <p className='[&>i]:c-orange c-grey-800 type-body-2 flex flex-row gap-1'>
          <Icon name='placeOutline' size='small' />
          <span>{address}</span>
        </p>
      )}
      {phoneNumber && (
        <p className='c-grey-800 type-body-2 flex flex-row gap-1'>
          <Icon name='phone' size='small' />
          <span>{phoneNumber}</span>
        </p>
      )}
      {weekdayText && (
        <div className='c-grey-800 type-body-2 flex flex-row items-start gap-1'>
          <Icon name='accessTime' size='small' />
          <div
            className={classNames('space-y-1', {
              'max-h-4.125 md:max-h-4.375 overflow-hidden': !isHoursExpanded,
            })}
          >
            {weekdayText.map((availableString, index) => (
              <p key={index} className='type-body-2 c-grey-800 m-0'>
                {availableString}
              </p>
            ))}
          </div>
          <div className='[&>button]:m-b--2 [&>button]:justify-start [&>button]:p-0'>
            <IconButton
              aria-expanded={isHoursExpanded}
              aria-label='See more hours'
              icon={isHoursExpanded ? 'expandLess' : 'expandMore'}
              size='small'
              onClick={() => setIsHoursExpanded(!isHoursExpanded)}
            />
          </div>
        </div>
      )}
      {website && (
        <div className='[&>a>span]:type-body-2 flex cursor-pointer flex-row gap-1 [&>a>span]:line-clamp-1'>
          <Icon name='language' size='small' />
          <a
            className='type-body-2 c-grey-800 decoration-none line-clamp-1'
            href={website}
            target='blank'
          >
            {getGuideDrawerWebsiteText(website)}
          </a>
        </div>
      )}
    </div>
  )
}

import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type { BookingValidateRateQuery } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { constructImageLinks } from 'src/utils'

interface BookingSidebarRoomImageProps {
  bookingRateLoading: boolean
  city: string
  images: BookingValidateRateQuery['validatedRate']['room']['images'] | string[]
  name: string
  state: string
}

export const BookingSidebarRoomImage = ({
  bookingRateLoading,
  city,
  images,
  name,
  state,
}: BookingSidebarRoomImageProps) => {
  const showImage = bookingRateLoading || !isEmpty(images)
  const checkoutV2 = useFlag('checkoutV2')
  const constructedImage = checkoutV2
    ? constructImageLinks(images as string[])[0]
    : //@ts-ignore
      images?.[0]?.href

  const bookingSidebarRoomImage = () => {
    if (bookingRateLoading) return <Skeleton variant='rounded' />

    return (
      <img
        alt={`${name} ${city} ${state} Hotel Room}`}
        className='h-full w-full object-cover'
        src={constructedImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
        }}
      />
    )
  }

  if (!showImage) return null

  return (
    <div
      className={classNames('rounded-14px md:w-140px overflow-hidden', {
        'h-150px lg:h-181px lg:w-219px': checkoutV2,
        'w-121px lg:h-118px lg:w-181px': !checkoutV2,
      })}
    >
      {bookingSidebarRoomImage()}
    </div>
  )
}

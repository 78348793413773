import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ParticipantStatus } from 'src/__generated__/graphql'
import type { FollowersModalType } from 'src/common/components'
import { ButtonLink, Helmet } from 'src/common/components'
import { CopyReferralLinkButton } from 'src/common/components/CopyReferralLinkButton/CopyReferralLinkButton'
import { FollowersModal } from 'src/common/components/FollowersModal/FollowersModal'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { competitionLeaderboardPath } from 'src/constants'
import {
  DashboardAccountSettings,
  DashboardAchievements,
  DashboardBookings,
  DashboardCollections,
  DashboardCtas,
  DashboardFeed,
  DashboardGuidesOld,
  DashboardHeader,
  DashboardLoading,
  DashboardNav,
  DashboardProfile,
  DashboardProfileAvatarModal,
  DashboardProfileBannerModal,
  DashboardProfileInfoModal,
  DashboardTrips,
  CompetitionDashboardHeader,
  DashboardGuides,
} from './common'
import { GetTheApp } from './common/GetTheAppCta/GetTheApp'
import { DashboardSearchParam, DashboardTab } from './dashboardConstants'
import { useGetDashboardQuery } from './useGetDashboardQuery'
import { useGetUserCompetitionPhase } from './useGetUserCompetitionPhase'
import { hasUserEnteredPhaseTwo } from '../competition/utils/hasUserEnteredPhaseTwo'
import './dashboardCustomStyles.css'

export const Dashboard = () => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const isAppCtaEnabled = useFlag('appCtaEnabled')
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const isTripsHideEnabled = useFlag('tripsHide')
  const { isAnonymous } = useFirebaseUser()
  const { data: userCompetitionPhase } = useGetUserCompetitionPhase()

  const { dashboardData, isLoading, refetch } = useGetDashboardQuery(
    userCompetitionPhase?.currentUser?.userProfile?.competitionInfo
      ?.currentPhase
  )
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false)
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [scrollId, setScrollId] = useState('')
  const [showModalFollowers, setShowModalFollowers] =
    useState<FollowersModalType>(null)
  const { currentUser } = dashboardData ?? {}
  const {
    collections,
    experienceBookings,
    firstName,
    hotelBookings,
    id,
    trips,
    userGuides,
    userGuideDrafts,
    userProfile,
  } = currentUser ?? {}
  /** @todo see if we can get a 'bookingsCount' on the BE instead of adding the two types together on the FE */
  const bookingsCount =
    experienceBookings?.totalCount + hotelBookings?.totalCount
  const { competitionInfo } = userProfile ?? {}

  const { competitions, currentPhase } = {
    ...competitionInfo,
  }
  const phase2Flag = useFlag('contestPhase2')
  const disableVoting = useFlag('disableVoting')
  const hasEnteredPhaseTwo = hasUserEnteredPhaseTwo(competitions, currentPhase)

  const { status } = competitionInfo ?? {}
  const editProfile = !!searchParams.get(DashboardSearchParam.editProfile)
  const tab = searchParams.get(DashboardSearchParam.tab) ?? DashboardTab.feed

  useEffect(() => {
    if (editProfile) onModalInfoOpen()
  }, [editProfile])

  useEffect(() => {
    if (isAnonymous) navigate('/', { replace: true })
  }, [isAnonymous])

  const onModalAvatarOpen = () => {
    setIsModalAvatarOpen(true)
    setIsModalInfoOpen(false)
  }

  const onModalAvatarDismiss = () => {
    setIsModalAvatarOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalBannerOpen = () => {
    setIsModalInfoOpen(false)
    setIsModalBannerOpen(true)
  }

  const onModalBannerDismiss = () => {
    setIsModalBannerOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalInfoDismiss = () => {
    setIsModalInfoOpen(false)
    searchParams.delete(DashboardSearchParam.editProfile)
    setSearchParams(searchParams)
  }

  const onModalInfoOpen = () => setIsModalInfoOpen(true)

  const onScrollIdChange = (updatedScrollId: string) =>
    setScrollId(updatedScrollId)

  const toggleFollowersModal = (viewType: FollowersModalType) =>
    setShowModalFollowers(viewType)

  if (isLoading)
    return (
      <DashboardLoading isTripsHideEnabled={isTripsHideEnabled} tab={tab} />
    )

  const showCompetitionHeader =
    disableVoting && phase2Flag
      ? hasEnteredPhaseTwo
      : enableCompetition2024 &&
        (status == ParticipantStatus.Entered ||
          status == ParticipantStatus.Ready)

  return (
    <>
      <Helmet pageName={`My Home${firstName ? ` | ${firstName}` : ''}`} />
      <section className='md:bg-warm-grey md:p-b-8'>
        {showCompetitionHeader ? (
          <CompetitionDashboardHeader
            currentUser={currentUser}
            isGuidePublishingV3Enabled={isGuidePublishingV3Enabled}
            toggleFollowersModal={toggleFollowersModal}
            onModalInfoOpen={onModalInfoOpen}
            onScrollIdChange={onScrollIdChange}
          />
        ) : (
          <DashboardHeader
            currentUser={currentUser}
            toggleFollowersModal={toggleFollowersModal}
            onModalInfoOpen={onModalInfoOpen}
            onScrollIdChange={onScrollIdChange}
          />
        )}
        <div className='p-x-10 bg-white pt-3 lg:hidden'>
          <CopyReferralLinkButton
            id={currentUser?.id}
            userRoles={currentUser?.userRoles}
          />
        </div>
        {/** @todo created a one-off page layout container for now */}
        <div className='max-w-452 md:p-x-6 lg:p-x-12 m-y-0 m-x-auto w-full'>
          <div className='lg:mt--43 lg:p-x-0 relative flex flex-col lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-4'>
            <aside className='min-w-84 w-84 rounded-3 hidden space-y-10 lg:block'>
              <DashboardProfile
                dashboardData={dashboardData}
                toggleFollowersModal={toggleFollowersModal}
                onModalInfoOpen={onModalInfoOpen}
                onScrollIdChange={onScrollIdChange}
              />
              <DashboardAchievements dashboardData={dashboardData} />
              <DashboardAccountSettings userId={id} />
            </aside>
            <section className='dashboard-center-column shadow-3 rounded-3 p-y-6 p-x-3 md:p-x-3.5 flex min-w-0 grow flex-col md:bg-white'>
              <DashboardNav
                bookingsCount={bookingsCount}
                collectionsCount={collections?.totalCount ?? 0}
                guideDraftsCount={
                  (isGuidePublishingV3Enabled
                    ? userGuides?.totalCount
                    : userGuideDrafts?.totalCount) ?? 0
                }
                tripsCount={trips?.totalCount ?? 0}
              />
              <div className='md:bg-warm-grey md:b-1 md:b-grey-200 md:b-t-none rounded-b-3 p-y-6 md:p-y-12 b-none md:b-solid p-x-3 md:p-x-6 grow md:bg-opacity-50'>
                {tab === DashboardTab.feed && <DashboardFeed />}
                {isGuidePublishingV3Enabled && tab === DashboardTab.guides && (
                  <DashboardGuides />
                )}
                {!isGuidePublishingV3Enabled && tab === DashboardTab.guides && (
                  <DashboardGuidesOld />
                )}
                {tab === DashboardTab.trips && !isTripsHideEnabled && (
                  <DashboardTrips />
                )}
                {tab === DashboardTab.bookings && <DashboardBookings />}
                {tab === DashboardTab.collections && <DashboardCollections />}
              </div>
            </section>
            <aside className='min-w-89 w-89 shadow-3 rounded-3 dashboard-right-column hidden h-fit space-y-6 bg-white'>
              {!disableVoting &&
                enableCompetition2024 &&
                (!currentUser?.userProfile?.competitionInfo ||
                  status === ParticipantStatus.NotEntered) && (
                  <div className='min-h-35 bg-top-center p-y-8 p-x-4 m-x-3 mt-5 bg-[url(https://static.travelpass.com/assets/job-campaign-2024/enter-competition-banner-bg.webp)] bg-contain bg-no-repeat pt-72 text-center'>
                    <h3 className='type-title-3 text-2xl capitalize'>
                      Vote For The Winner!
                    </h3>
                    <p className='c-zinc-5 font-medium'>
                      We&apos;re giving away $20,000 for a dream vacation! Check
                      out the 20 travelers who are still in the running and vote
                      for your favorites.
                    </p>
                    <div className='m-x-auto mt-3 w-fit'>
                      <ButtonLink
                        className='capitalize'
                        to={competitionLeaderboardPath}
                      >
                        Cast Your Vote
                      </ButtonLink>
                    </div>
                  </div>
                )}
              <DashboardCtas
                isGuidePublishingV3Enabled={isGuidePublishingV3Enabled}
              />
            </aside>
          </div>
        </div>
      </section>
      <MobileBottomNav />
      {isModalAvatarOpen && (
        <DashboardProfileAvatarModal
          userProfile={userProfile}
          onDismiss={onModalAvatarDismiss}
        />
      )}
      {isModalBannerOpen && (
        <DashboardProfileBannerModal
          userProfile={userProfile}
          onDismiss={onModalBannerDismiss}
        />
      )}
      {isModalInfoOpen && (
        <DashboardProfileInfoModal
          scrollId={scrollId}
          userProfile={userProfile}
          onDismiss={onModalInfoDismiss}
          onModalAvatarOpen={onModalAvatarOpen}
          onModalBannerOpen={onModalBannerOpen}
          onSubmitCompleted={() => refetch()}
        />
      )}
      {showModalFollowers && (
        <FollowersModal
          accountHandle={userProfile?.accountHandle}
          toggleModal={toggleFollowersModal}
          userId={userProfile?.userId}
          viewType={showModalFollowers}
          onClose={() => setShowModalFollowers(null)}
        />
      )}
      {isAppCtaEnabled && (
        <div className='fixed bottom-5 right-10'>
          <GetTheApp />
        </div>
      )}
    </>
  )
}

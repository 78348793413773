import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { GetDashboardAccountSettingsFormQueryInDashboardQuery } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  firebaseUpdatePassword,
  firebaseVerifiedCurrentPassword,
} from 'src/config/firebase/firebaseUtils'
import { useUpdateDashboardAccountSettingsFormInfoMutation } from './useUpdateDashboardAccountSettingsFormInfoMutation'
import { useUpdateDashboardAccountSettingsFormNotificationsMutation } from './useUpdateDashboardAccountSettingsFormNotificationsMutation'

const dashboardAccountSettingsFormFields: DashboardAccountSettingsFields = {
  addressFirstLine: '',
  addressSecondLine: '',
  city: '',
  country: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  passwordCurrent: '',
  passwordNew: '',
  passwordNewConfirm: '',
  phoneNumber: '',
  sendFeatureUpdatesEmail: false,
  sendFeatureUpdatesSms: false,
  sendInspirationEmail: false,
  sendInspirationSms: false,
  sendPromotionEmail: false,
  sendPromotionSms: false,
  sendRecentReviewsEmail: false,
  sendRecentReviewsSms: false,
  state: '',
  zip: '',
}

const getDashboardAccountSettingsActiveIndex = (settingsTab: string) => {
  if (settingsTab === 'notifications') return 2

  if (settingsTab === 'security') return 1

  return 0
}

const getDashboardAccountSettingsFormFields = (
  dashboardAccountSettingsFormData: GetDashboardAccountSettingsFormQueryInDashboardQuery['getCommunicationPreferencesForUser']
) => {
  const fields = {
    ...dashboardAccountSettingsFormFields,
  }

  for (const key in fields) {
    if (!dashboardAccountSettingsFormData?.id) return

    if (dashboardAccountSettingsFormData?.[key]) {
      fields[key] = dashboardAccountSettingsFormData[key]
    }

    if (dashboardAccountSettingsFormData?.user?.[key]) {
      fields[key] = dashboardAccountSettingsFormData?.user?.[key]
    }
  }

  return fields
}

type InfoFields =
  GetDashboardAccountSettingsFormQueryInDashboardQuery['getCommunicationPreferencesForUser']['user']

type NotificationFields = Omit<
  GetDashboardAccountSettingsFormQueryInDashboardQuery['getCommunicationPreferencesForUser'],
  'id'
>

type DashboardAccountSettingsFields = InfoFields &
  NotificationFields & {
    passwordCurrent: string
    passwordNew: string
    passwordNewConfirm: string
  }

type UseDashboardAccountSettingsForm = {
  activeIndex: number
  methods: UseFormReturn<DashboardAccountSettingsFields>
  onActiveIndexChange: (updatedActiveIndex: number) => void
  onSubmit: SubmitHandler<DashboardAccountSettingsFields>
}

const useDashboardAccountSettingsForm = ({
  dashboardAccountSettingsFormData,
  onDismiss,
  settingsTab,
}: {
  dashboardAccountSettingsFormData: GetDashboardAccountSettingsFormQueryInDashboardQuery['getCommunicationPreferencesForUser']
  onDismiss: VoidFunction
  settingsTab: string
}): UseDashboardAccountSettingsForm => {
  const methods = useForm<DashboardAccountSettingsFields>({
    mode: 'onSubmit',
  })
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [activeIndex, setActiveIndex] = useState(
    getDashboardAccountSettingsActiveIndex(settingsTab)
  )
  const [updateUser] = useUpdateDashboardAccountSettingsFormInfoMutation()
  const [updateCommunicationPreferences] =
    useUpdateDashboardAccountSettingsFormNotificationsMutation()
  const { user } = dashboardAccountSettingsFormData ?? {}
  const {
    email,
    id,
    addressFirstLine,
    addressSecondLine,
    city,
    country,
    state,
    zip,
  } = user ?? {}
  const existingAddressInfo = {
    addressFirstLine,
    addressSecondLine,
    city,
    country,
    state,
    zip,
  }

  useEffect(() => {
    if (!dashboardAccountSettingsFormData?.id) return

    methods.reset(
      getDashboardAccountSettingsFormFields(dashboardAccountSettingsFormData)
    )
  }, [activeIndex, dashboardAccountSettingsFormData])

  const onActiveIndexChange = (updatedActiveIndex: number) => {
    if (updatedActiveIndex === activeIndex) return

    setActiveIndex(updatedActiveIndex)
  }

  const onSubmit = async (
    dashboardAccountSettingsFormFields: DashboardAccountSettingsFields
  ) => {
    if (activeIndex === 0) {
      try {
        const addressInfoForBloomreach = {}
        const {
          addressFirstLine,
          addressSecondLine,
          city,
          country,
          email,
          firstName,
          lastName,
          phoneNumber,
          state,
          zip,
        } = dashboardAccountSettingsFormFields ?? {}
        for (const key in existingAddressInfo) {
          // if the user has already saved a piece of data and it is different from the form data, add it to the bloomreach data
          if (
            existingAddressInfo[key] &&
            existingAddressInfo[key] !== dashboardAccountSettingsFormFields[key]
          ) {
            addressInfoForBloomreach[key] =
              dashboardAccountSettingsFormFields[key]
            // if the user has not saved a piece of data and it is in the form data, add it to the bloomreach data
          } else if (
            !existingAddressInfo[key] &&
            dashboardAccountSettingsFormFields[key]
          ) {
            addressInfoForBloomreach[key] =
              dashboardAccountSettingsFormFields[key]
          }
        }

        const response = await updateUser({
          variables: {
            input: {
              id,
              addressFirstLine,
              addressSecondLine,
              city,
              country,
              email,
              firstName,
              lastName,
              phoneNumber: phoneNumber || '',
              state,
              zip,
            },
          },
        })

        if (!response?.errors && !isEmpty(addressInfoForBloomreach)) {
          pushDataToDataLayer('account_update', addressInfoForBloomreach)
        }
        addSuccessSnack({
          title: 'Personal Information updated!',
        })
        onDismiss()
      } catch (error) {
        console.error('An error occurred, try again later.')
        addErrorSnack({
          title: 'An unexpected error was found.',
        })
      }
    } else if (activeIndex === 1) {
      try {
        const { passwordCurrent, passwordNew } =
          dashboardAccountSettingsFormFields ?? {}
        await verifyCurrentPassword(passwordCurrent)
        await firebaseUpdatePassword(passwordNew)
        addSuccessSnack({
          title: 'Password updated!',
        })
        onDismiss()
      } catch (error) {
        console.error('An error occurred, try again later.')
        methods.setError('passwordCurrent', {
          message: error.message
            ? // (?<=Firebase:\s) is positive lookbehind to match only the portion after the 'Firebase: ' prefix
              error.message.match(/(?<=Firebase:\s)[\w+\s+]+/)
            : error || 'There was an error updating your password',
        })
      }
    } else if (activeIndex === 2) {
      try {
        const {
          sendFeatureUpdatesEmail,
          sendFeatureUpdatesSms,
          sendInspirationEmail,
          sendInspirationSms,
          sendPromotionEmail,
          sendPromotionSms,
          sendRecentReviewsEmail,
          sendRecentReviewsSms,
        } = dashboardAccountSettingsFormFields ?? {}
        await updateCommunicationPreferences({
          variables: {
            communicationPreferencesInput: {
              sendFeatureUpdatesEmail,
              sendFeatureUpdatesSms,
              sendInspirationEmail,
              sendInspirationSms,
              sendPromotionEmail,
              sendPromotionSms,
              sendRecentReviewsEmail,
              sendRecentReviewsSms,
            },
          },
        })
        addSuccessSnack({
          title: 'Personal Information updated!',
        })
        onDismiss()
      } catch (error) {
        console.error('An error occurred, try again later.')
        addErrorSnack({
          title: 'An unexpected error was found.',
        })
      }
    }
  }

  // List of all firebase auth errors: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
  const verifyCurrentPassword = async (password: string) => {
    const res = await firebaseVerifiedCurrentPassword(email, password)
    if (res === 'success') return Promise.resolve()
    if (res === 'auth/wrong-password' || res === 'auth/user-mismatch')
      return Promise.reject('Wrong Email or password')
    if (res === 'auth/weak-password')
      return Promise.reject('Password should be at least 6 characters')
    if (res === 'auth/too-many-requests')
      return Promise.reject('Too many requests. Please come back later')
  }

  return {
    activeIndex,
    methods,
    onActiveIndexChange,
    onSubmit,
  }
}

export type { DashboardAccountSettingsFields }
export { useDashboardAccountSettingsForm }

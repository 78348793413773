import { useEffect } from 'react'
import { Carousel, Illustration } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { ButtonLink, PageLayoutContainer } from 'src/common/components'
import {
  TravelerCard,
  TravelerCardLoading,
} from 'src/common/components/TravelerCard'
import { useGetPredefinedProfileTagsQuery } from 'src/common/hooks'
import { TravelStyleTags } from './TravelStyleTags'
import { useSearchTravelers } from '../hooks/useSearchTravelers'

export const FilteredTravelers = () => {
  const { availableTags, availableTagsLoading } =
    useGetPredefinedProfileTagsQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialTagArray = availableTags && Array(1).fill(availableTags[0].id)
  const spTag = searchParams.get('tags')
  const tagArray = spTag && Array(1).fill(spTag)

  const { data, loading, error } = useSearchTravelers({
    first: 3,
    searchString: null,
    tagIds: tagArray || initialTagArray || null,
  })

  const profiles = data?.userProfileSearch?.edges?.map(({ node }) => node) ?? []
  const profilesAvailable = !!profiles?.length && !loading
  const empty = error || (!profilesAvailable && !loading)

  // Default to having the first available tag selected
  useEffect(() => {
    if (availableTagsLoading || !availableTags) return
    if (!spTag) {
      setSearchParams(sp => {
        sp.set('tags', initialTagArray?.join())
        return sp
      })
    }
  }, [availableTags, availableTagsLoading, spTag])

  const onTagClick = (tagId: string) => {
    if (!tagId || tagId == spTag) return
    setSearchParams(sp => {
      sp.set('tags', tagId)
      return sp
    })
  }

  return (
    <PageLayoutContainer>
      <section className='mt-10 space-y-10'>
        <TravelStyleTags onClick={onTagClick} />
        <div className='md:max-w-300 block min-h-10 md:mx-auto'>
          {loading && (
            <div className='min-h-451px grid h-full w-full gap-12 px-6 md:grid-cols-2 md:gap-6 md:px-12 lg:grid lg:grid-cols-3'>
              <TravelerCardLoading />
              <TravelerCardLoading className='hidden md:flex' />
              <TravelerCardLoading className='hidden lg:flex' />
            </div>
          )}
          {empty && (
            <section className='mx-auto w-fit space-y-10 px-6 text-center'>
              <div className='max-w-186px max-h-137px mx-auto'>
                <Illustration name='resting' />
              </div>
              <p className='type-body-1 c-grey-800'>
                Sorry, it looks like we weren&apos;t able to find any travelers
                matching your search.{' '}
              </p>
            </section>
          )}
          {profilesAvailable && (
            <>
              <div className='grid hidden h-full w-full gap-12 px-6 md:grid-cols-2 md:gap-6 md:px-12 lg:grid lg:grid-cols-3'>
                {profiles.slice(0, 3).map(profile => (
                  <TravelerCard key={profile.id} traveler={profile} />
                ))}
              </div>
              <div className='lg:hidden'>
                <Carousel
                  isLight
                  borderRadius={12}
                  hideArrows={true}
                  keyBoardControl={true}
                  size='small'
                  slidesPerViewOnDesktop={2}
                  slidesPerViewOnMobile={1}
                  slidesPerViewOnTablet={2}
                >
                  {profiles.map(profile => (
                    <div
                      key={profile.id}
                      className='mx-auto mb-8 max-w-[calc(100%-24px)]'
                    >
                      <TravelerCard key={profile.id} traveler={profile} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </>
          )}
        </div>
        <div className='mx-auto w-fit'>
          <ButtonLink
            aria-label='See all travelers'
            to='results?q=&dataLayerPush=true'
          >
            See All Travelers
          </ButtonLink>
        </div>
      </section>
    </PageLayoutContainer>
  )
}

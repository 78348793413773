import {
  Avatar,
  Button,
  Icon,
  IconButton,
  MenuItem,
  Popover,
} from '@travelpass/design-system'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { ResultsCard } from 'src/common/components'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  useGuideMetricLike,
  useGuideMetricShare,
  GuideLikesModal,
} from 'src/pages/guides/details'
import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideMetricString } from 'src/pages/guides/details/guideUtils'
import {
  getGuideAddress,
  getGuideDetailsUrl,
  getGuidePublishedDate,
  getImageResized,
} from 'src/utils'
import type { ProfileOwner } from '../../types'
import type { UseGetProfileGuidesQuery } from '../../useGetProfileGuidesQuery'

export const ProfileGuideCard = ({
  guide,
  owner,
}: {
  guide: UseGetProfileGuidesQuery['profileGuidesData']['edges'][0]['node']
  owner: ProfileOwner
}) => {
  const {
    addresses,
    description,
    id,
    insertedAt,
    likedByCurrentUser,
    name,
    numberOfLikes,
    paginatedImages,
    strippedId,
    timeZone,
    updatedAt,
    viewCount,
  } = guide ?? {}
  const { isModalOpen, onGuideLike, onIsModalOpenChange } =
    useGuideMetricLike(id)
  const onGuideShare = useGuideMetricShare(id)
  const { city, country, state } = addresses?.[0] ?? {}
  const image = paginatedImages?.edges?.[0]?.node?.url
  const addressFormatted = getGuideAddress({
    city,
    country,
    state,
  })
  const guidePublishedDate = getGuidePublishedDate({
    insertedAt,
    timeZone,
    updatedAt,
  })
  const url = getGuideDetailsUrl({
    id: strippedId,
    name,
  })

  const onCardClick = () => {
    pushDataToDataLayer('guide_card_click', {
      guide_id: id,
      guide_name: name,
      ownerProfile_id: owner?.id,
      trigger_url: window.location.href,
    })
  }

  if (!guide) return

  return (
    <>
      <ResultsCard href={url} onClick={onCardClick}>
        <section className='rounded-3 border-grey-300 of-hidden p-x-6 p-y-4 space-y-4 border-2 border-solid md:flex md:flex-row-reverse md:justify-between md:gap-1 md:space-y-0'>
          <div className='md:w-67 md:min-w-67 relative h-60 w-full overflow-hidden md:h-auto'>
            <GuideImage
              className='rounded-3 absolute inset-0 h-full w-full object-cover'
              draggable={false}
              src={getImageResized({
                imageUrl: image,
                shape: 'landscape',
                width: 1000,
              })}
            />
          </div>
          <div className='grow space-y-4 md:space-y-6'>
            <div className='space-y-2'>
              <div className='flex flex-row items-center'>
                <h3 className='type-h3 grow'>{name}</h3>
                <Popover
                  className='b-grey-300 b-solid b-1 w-50 text-body-1 c-grey900 rd-3 children-[:nth-child(1)]:rd-t-3 children-[:nth-child(2)]:rd-b-3 relative'
                  placement='bottom-end'
                  trigger={
                    <div className='relative'>
                      <IconButton icon='moreHoriz' />
                    </div>
                  }
                >
                  <MenuItem
                    label='Add to'
                    startIcon='addCircleOutline'
                    value='Add to'
                    onClick={() => {
                      dispatchToggleAddToCollectionsModal({
                        isModalOpen: true,
                        item: {
                          id: guide?.id,
                          name: guide?.name,
                          type: CollectionItemIdType.Guide,
                        },
                      })
                    }}
                  />
                  <MenuItem
                    label='Share'
                    startIcon='iosShare'
                    value='Share'
                    onClick={onGuideShare}
                  />
                </Popover>
              </div>
              <div className='c-orange flex flex-row items-center gap-1'>
                <Icon name='placeOutline' size='small' />
                <p className='c-black type-small-text line-clamp-1 max-h-3.5 grow lg:max-h-3.5'>
                  {addressFormatted}
                </p>
              </div>
            </div>
            <div className='flex flex-row items-center gap-3'>
              <a
                aria-label={`View profile of ${owner?.name}`}
                className='transition-opacity-200 relative h-8 w-8 ease-linear hover:opacity-50 active:opacity-50'
                href={owner?.url}
                rel='noreferrer'
                target='_BLANK'
              >
                <Avatar border='none' size='xs' src={owner?.image} />
              </a>
              <div className='flex flex-col gap-0'>
                <a
                  aria-label={`View profile of ${owner?.name}`}
                  className='transition-opacity-200 decoration-none text-body-2 c-forest-dark relative block w-fit ease-linear hover:opacity-50 active:opacity-50'
                  href={owner?.url}
                  rel='noreferrer'
                  target='_BLANK'
                >
                  {owner?.name}
                </a>
                <time className='text-small-text c-grey-700'>
                  {guidePublishedDate}
                </time>
              </div>
            </div>
            <div className='space-y-2 md:space-y-4'>
              <p className='text-body-1 p-r-1.5 md:min-h-15 line-clamp-2 text-ellipsis md:line-clamp-3'>
                {description}
              </p>
              <div className='[&>button]:p-x-0 flex flex-row items-center gap-3.5 [&>button:first-child]:relative'>
                <Button
                  aria-label='Like this Guide'
                  aria-pressed={likedByCurrentUser}
                  className='relative'
                  color='forestLight'
                  size='small'
                  startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
                  variant='text'
                  onClick={onGuideLike}
                >
                  {getGuideMetricString(numberOfLikes, 'like')}
                </Button>
                <Button
                  color='forestLight'
                  name='visibility'
                  size='small'
                  startIcon='visibility'
                  variant='text'
                >
                  {getGuideMetricString(viewCount, 'view')}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </ResultsCard>
      {isModalOpen && (
        <GuideLikesModal
          guideId={id}
          onClose={() => onIsModalOpenChange(false)}
        />
      )}
    </>
  )
}

import { useCallback, useEffect, useRef } from 'react'
import { Button, Divider, Icon, SkeletonDots } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { travelersPath } from 'src/constants'
import { TravelerResultsContent } from './TravelerResultsContent'
import { TravelStyleTags } from './components/TravelStyleTags'
import { useSearchTravelers } from './hooks/useSearchTravelers'

export const TravelerResults = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchString = searchParams.get('q')
  const to = useNavigate()
  const spTags = searchParams.get('tags')
  const tagArray = searchParams.get('tags')?.split(',')

  const { data, loading, error, fetchMore } = useSearchTravelers({
    first: 21,
    searchString: searchString || null,
    tagIds: tagArray || null,
  })

  const tagsScrollRef = useRef<HTMLDivElement>(null)

  const cursor =
    data?.userProfileSearch?.pageInfo?.hasNextPage &&
    data?.userProfileSearch?.pageInfo?.endCursor
  const count = data?.userProfileSearch?.totalCount
  const empty = !count && !loading && !error
  const results = data?.userProfileSearch?.edges

  useEffect(() => {
    // Delete tags from search params if no tags are selected
    if (spTags?.length === 0) {
      setSearchParams(sp => {
        sp.delete('tags')
        return sp
      })
    }
    // Navigate to /travelers if no search params are present
    if (searchParams.size === 0) to(travelersPath)
  }, [searchParams])

  useEffect(() => {
    if (tagsScrollRef.current)
      tagsScrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [searchString])

  const onClick = useCallback(
    (tagId: string, tags: string[]) => {
      if (tags.includes(tagId)) {
        setSearchParams(sp => {
          const filteredTags = tags.filter(tag => tag !== tagId)
          const spToSet = filteredTags?.length ? filteredTags.join(',') : ''
          sp.set('tags', spToSet)
          return sp
        })
      } else {
        setSearchParams(sp => {
          sp.set('tags', [...tags, tagId].join(','))
          return sp
        })
      }
    },
    [searchParams, tagArray]
  )

  const generateResultsString = useCallback(() => {
    if (loading)
      return (
        <div className='min-h-4.5 lg:min-h-8.5 flex self-start md:min-h-8'>
          <SkeletonDots />
        </div>
      )

    const plural = count === 1 ? '' : 's'
    return searchString
      ? `${count} Result${plural} For "${searchString}"`
      : `${count} Result${plural}`
  }, [count, searchString, loading])

  return (
    <PageLayoutContainer>
      <div className='space-y-6 px-5 pt-10 md:space-y-9'>
        <h3 className='type-h6 max-md:px-4 md:hidden'>
          {generateResultsString()}
        </h3>
        <div
          className='mt-0! scroll-mt-9'
          id='tags-scroll-ref'
          ref={tagsScrollRef}
        >
          <TravelStyleTags hideSubtitle onClick={onClick} />
        </div>
        <Divider className='hidden md:block' />
        <header className='c-valley hidden flex-row items-center gap-3 md:flex'>
          <Icon name='navigationCompass' size='small' />
          <h3 className='type-h3'>{generateResultsString()}</h3>
        </header>
        <TravelerResultsContent
          empty={empty}
          loading={loading}
          results={results}
        />
        <section className='mx-auto w-fit pb-6 pt-1.5 md:pb-11 md:pt-5'>
          {cursor && (
            <Button
              aria-label='Show More Results'
              isDisabled={loading}
              onClick={async () => {
                await fetchMore({ variables: { after: cursor } })
              }}
            >
              Show More Results
            </Button>
          )}
        </section>
      </div>
    </PageLayoutContainer>
  )
}

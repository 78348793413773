import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const removeGuideDraftImagesMutation = gql(`
  mutation RemoveGuideDraftImagesOld($input: RemoveGuideDraftImagesInput!) {
    removeGuideDraftImages(input: $input) {
      guideDraft {
        id
        guide {
          id
          paginatedImages(first: 3) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`)

export const useRemoveGuideDraftImagesMutation = () =>
  useMutation(removeGuideDraftImagesMutation)

import { useState } from 'react'
import { Divider } from '@travelpass/design-system'
import { Fragment } from 'react/jsx-runtime'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useFlag } from 'src/common/hooks'
import { DashboardFeedCardRepublishedGuideCreatedNote } from './DashboardFeedCard'
import { DashboardFeedDropdown } from './DashboardFeedDropdown'
import { DashboardFeedEmptyState } from './DashboardFeedEmptyState'
import { DashboardFeedEndCta } from './DashboardFeedEndCta'
import { DashboardFeedLoadMore } from './DashboardFeedLoadMore'
import { DashboardFeedLoading } from './DashboardFeedLoading'
import { DashboardFeedSuggestedFollowers } from './DashboardFeedSuggestedFollowers'
import { DashboardFeedSuggestedGuidesWrapper } from './DashboardFeedSuggestedGuides'
import { DashboardFeedWrapper } from './DashboardFeedWrapper'
import { useGetDashboardFeedQuery } from '../../useGetDashboardFeedQuery'

export const DashboardFeed = () => {
  const isDashboardV3FeedNewNoteEnabled = useFlag('dashboardV3FeedNewNote')
  const [includeAll, setIncludeAll] = useState(true)
  const {
    dashboardFeedData,
    dashboardFeedSuggestedFollowersData,
    dashboardFeedSuggestedGuidesData,
    dashboardFeedSuggestedTravelpassGuidesData,
    data,
    onGetMoreResults,
    isLoading,
  } = useGetDashboardFeedQuery(includeAll)
  const dashboardFeedConnection = data?.currentUser?.paginatedActivityFeed

  const onDropdownChange = (updatedValue: string) =>
    setIncludeAll(updatedValue === 'All')

  if (isLoading) return <DashboardFeedLoading />

  if (!dashboardFeedData?.length)
    return (
      <div className='space-y-12'>
        <div className='space-y-4'>
          <DashboardFeedDropdown
            includeAll={includeAll}
            onDropdownChange={onDropdownChange}
          />
          <DashboardFeedSuggestedFollowers
            suggestedFollowersData={dashboardFeedSuggestedFollowersData}
          />
        </div>
        <DashboardFeedEmptyState />
      </div>
    )

  return (
    <>
      <div className='space-y-4'>
        <DashboardFeedDropdown
          includeAll={includeAll}
          onDropdownChange={onDropdownChange}
        />
        <DashboardFeedSuggestedFollowers
          suggestedFollowersData={dashboardFeedSuggestedFollowersData}
        />
        <div className='space-y-4'>
          {dashboardFeedData?.map((dashboardFeedItem, index) => {
            return (
              <Fragment key={index}>
                <DashboardFeedWrapper dashboardFeedItem={dashboardFeedItem} />
                <DashboardFeedSuggestedGuidesWrapper
                  dashboardFeedSuggestedGuidesData={
                    dashboardFeedSuggestedGuidesData
                  }
                  dashboardFeedSuggestedTravelpassGuidesData={
                    dashboardFeedSuggestedTravelpassGuidesData
                  }
                  feedIndex={index}
                />
              </Fragment>
            )
          })}
        </div>
        {/** @todo move to DashboardFeedWrapper when BE adds feed type */}
        {isDashboardV3FeedNewNoteEnabled && (
          <div>
            <h3 className='m-t-10 type-h1'>New Note</h3>
            <Divider className='m-b-6 m-t-3' />
            <div className='space-y-4'>
              <DashboardFeedCardRepublishedGuideCreatedNote
                dashboardFeedItem={dashboardFeedData?.[0]}
              />
            </div>
          </div>
        )}
        {!dashboardFeedConnection?.pageInfo?.hasNextPage && (
          <DashboardFeedEndCta />
        )}
        <DashboardFeedLoadMore
          dashboardFeedConnection={dashboardFeedConnection}
          onGetMoreResults={onGetMoreResults}
        />
      </div>
      <AddToCollectionsModalListener />
    </>
  )
}

import type { Bed } from 'src/__generated__/graphql'
import type { HotelRoom } from './hotelRoomsTypes'

export const getHotelRoomBedCountWithType = (beds: Bed[]): string =>
  beds
    .map(({ count, type }) => {
      if (!count) {
        if (type) return type
        if (!type) return ''
      }

      if (!type) {
        if (count === 1) return '1 Bed'
        if (count > 1) return `${count} Beds`
      }

      return `${count} ${type}`
    })
    .join(', ')

export const getRefundTypes = (rates: HotelRoom['rates']) => {
  return [...new Set(rates.map(rate => rate.cancelationPolicy.refundType))]
}

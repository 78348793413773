import { today } from '@travelpass/design-system'
import type { UserImage } from 'src/__generated__/graphql'
import { getDateFromUTC } from 'src/utils'
import type { DashboardFeedItem } from './types'
import type { UseGetDashboardFeedQuery } from './useGetDashboardFeedQuery'

const getDashboardFeedGuideImages = ({
  images,
  imageUrl,
}: {
  images: Partial<UserImage>[]
  imageUrl: string
}) => {
  if (images?.length) return images?.slice(0, 3)

  return [
    {
      url: imageUrl,
    },
  ]
}

let suggestedGuidesIndex = 0
let travelpassGuidesIndex = 0

const getDashboardFeedSuggestedGuidesSlicedData = ({
  dashboardFeedSuggestedGuidesData,
  dashboardFeedSuggestedTravelpassGuidesData,
  isTravelpassGuide,
  guidesCount,
  resetIndices = false,
}: {
  dashboardFeedSuggestedGuidesData?: UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData']
  dashboardFeedSuggestedTravelpassGuidesData?: UseGetDashboardFeedQuery['dashboardFeedSuggestedTravelpassGuidesData']
  isTravelpassGuide: boolean
  guidesCount: number
  resetIndices?: boolean
}):
  | UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData']
  | UseGetDashboardFeedQuery['dashboardFeedSuggestedTravelpassGuidesData'] => {
  if (resetIndices) {
    suggestedGuidesIndex = 0
    travelpassGuidesIndex = 0
  }

  if (!resetIndices && isTravelpassGuide) {
    const startIndex = travelpassGuidesIndex * guidesCount
    travelpassGuidesIndex += 1
    const endIndex = startIndex + guidesCount

    if (startIndex > dashboardFeedSuggestedTravelpassGuidesData.length)
      return []

    return dashboardFeedSuggestedTravelpassGuidesData.slice(
      startIndex,
      endIndex
    )
  }

  if (!resetIndices && !isTravelpassGuide) {
    const startIndex = suggestedGuidesIndex * guidesCount
    suggestedGuidesIndex += 1
    const endIndex = startIndex + guidesCount

    if (startIndex > dashboardFeedSuggestedGuidesData.length) return []
    return dashboardFeedSuggestedGuidesData.slice(startIndex, endIndex)
  }
}

const getDashboardFeedTimeSincePublished = ({
  relevantDatetime,
  timeZone,
}: {
  relevantDatetime: DashboardFeedItem['relevantDatetime']
  timeZone: DashboardFeedItem['guide']['timeZone']
}) => {
  const feedDate = getDateFromUTC(relevantDatetime, timeZone)
  const minutesSincePublished = Math.abs(today.diff(feedDate, 'minutes'))

  if (minutesSincePublished < 60) {
    return minutesSincePublished === 1
      ? '1 minute ago'
      : `${minutesSincePublished} minutes ago`
  }

  const hoursSincePublished = Math.abs(today.diff(feedDate, 'hours'))
  if (hoursSincePublished < 24) {
    return hoursSincePublished === 1
      ? '1 hour ago'
      : `${hoursSincePublished} hours ago`
  }

  const daysSincePublished = Math.abs(today.diff(feedDate, 'days'))
  if (daysSincePublished < 7) {
    return daysSincePublished === 1
      ? '1 day ago'
      : `${daysSincePublished} days ago`
  }

  const weeksSincePublished = Math.abs(today.diff(feedDate, 'weeks'))
  return weeksSincePublished === 1
    ? '1 week ago'
    : `${weeksSincePublished} weeks ago`
}

const getDashboardHeaderGreeting = ({
  firstName,
  isMobile,
}: {
  firstName: string
  isMobile: boolean
}) => {
  if (isMobile) return `Hi${firstName ? ` ${firstName}` : ''}!`

  return `Hello${firstName ? `, ${firstName}` : ''}`
}

const validateYoutubeLink = (value: string) => {
  // it validates the following youtube video links.
  /**
   * Normal Url: https://www.youtube.com/watch?v=12345678901
   * Share Url: https://youtu.be/12345678901
   * Share Url with start time: https://youtu.be/12345678901?t=6
   * Mobile browser url: https://m.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1
   * Long url: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=smKgVuS
   * Long url with start time: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=12345678901&t=38
   * Youtube Shorts: https://youtube.com/shorts/12345678901
   * youtue embed iframe link: <iframe width="560" height="315" src="https://www.youtube.com/embed/sJplQSfniMw?si=-KgcqOCag8JOxVq7" title=...... </iframe>
   */
  return /(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?/.test(
    value.trim()
  )
}

export {
  getDashboardFeedGuideImages,
  getDashboardFeedSuggestedGuidesSlicedData,
  getDashboardFeedTimeSincePublished,
  getDashboardHeaderGreeting,
  validateYoutubeLink,
}

import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetCurrentUserQueryInMyBookingsQuery } from 'src/__generated__/graphql'

export const currentUserQuery = gql(`
  query GetCurrentUserQueryInMyBookings { 
    currentUser {
      id
      userProfile {
        id
        displayName
      }
    }
  }
`)

type UseGetMyBookingsQuery = {
  hasError: ApolloError
  isLoading: boolean
  myBookingsData: GetCurrentUserQueryInMyBookingsQuery['currentUser']
}

export const useGetMyBookingsQuery = (): UseGetMyBookingsQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(currentUserQuery)

  return {
    hasError,
    isLoading,
    myBookingsData: data?.currentUser ?? null,
  }
}

import type { ComponentPropsWithoutRef } from 'react'
import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import {
  ResultsCard,
  ResultsCardContent,
  ResultsCardImage,
} from 'src/common/components'
import { GuideImage } from 'src/pages/guides/details/common'
import type { ProfileOwner } from 'src/pages/profile/types'
import { getEventImage } from 'src/pages/trips/utils'
import { constructAddress, getImageResized } from 'src/utils'
import type { GuideDraftPublishedEvent } from '../../types'

interface GuideDraftMapMarkerPopupProps
  extends ComponentPropsWithoutRef<'button'> {
  owner: ProfileOwner
  point: GuideDraftPublishedEvent
}

export const GuideDraftMapMarkerPopup = ({
  owner,
  point,
  ...props
}: GuideDraftMapMarkerPopupProps) => {
  const { addresses, images, imageUrl, name, notes } = point ?? {}
  const { addressLine1: addressFirstLine, city, state } = addresses?.[0] ?? {}
  const address = constructAddress({
    addressFirstLine,
    city,
    state,
  })
  const src = getEventImage(images?.[0]?.url ?? imageUrl)

  return (
    !isEmpty(point) &&
    !!name && (
      <ResultsCard {...props} className='shadow-1 b-1 b-solid b-grey-400'>
        <div className='w-75 md:w-128 grid grid-cols-3'>
          <div className='rounded-3 w-25 md:h-49 md:w-42.5 relative col-span-1 overflow-hidden object-cover'>
            <ResultsCardImage
              alt=''
              imageDimensions=''
              src={getImageResized({
                imageUrl: src,
                shape: 'square',
                width: 500,
              })}
            />
          </div>
          <div className='col-span-2'>
            <ResultsCardContent>
              <section className='space-y-6'>
                <header>
                  <h4 className='c-grey-900 type-body-1-medium'>{name}</h4>
                  <div className='c-grey-800 m-l--.5 flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='type-small-text line-clamp-1'>
                      {address}
                    </span>
                  </div>
                </header>
                {!!notes && (
                  <div className='p-x-1 flex flex-row gap-2'>
                    <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-5 min-h-5 w-5 min-w-5 cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
                      <GuideImage
                        alt={`Profile image for ${owner?.name}`}
                        className='shadow-1'
                        src={owner?.image}
                      />
                    </div>
                    <p
                      className='color-forest type-body-2-desktop line-clamp-4 w-full'
                      style={{ wordBreak: 'break-word' }}
                    >
                      {notes}
                    </p>
                  </div>
                )}
                {!notes && (
                  <p className='color-new-forest type-body-1-mobile flex flex-row items-center gap-2'>
                    <Icon name='stickyNote2' size='small' />
                    <span className='line-clamp-1'>0 notes available</span>
                  </p>
                )}
              </section>
            </ResultsCardContent>
          </div>
        </div>
      </ResultsCard>
    )
  )
}

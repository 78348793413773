import { Button, EmptyState } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useBookingForm } from '../hooks'

interface BookingErrorProps {
  failed: boolean
}

export const BookingError = ({ failed }: BookingErrorProps) => {
  const navigate = useNavigate()
  const { reset } = useBookingForm()

  return (
    <div className='mx-8 flex flex-col items-center justify-center gap-4'>
      <div className='type-h3'>Booking {failed ? 'Failed' : 'Error'}!</div>
      <div className='type-h4'>
        {failed
          ? 'The payment information may be incorrect or the room may no longer be available.'
          : 'Unfortunately the room you are booking is no longer available or we are having difficulties with our platform.'}
      </div>
      <div className='type-h4'>Please try the following options:</div>
      <div className='w-91'>
        <EmptyState name='error' />
      </div>
      <Button
        label='Try Again'
        onClick={() => {
          reset()
          navigate(0)
        }}
      />
      <Button
        label='Back to Hotel Details'
        variant='outlined'
        onClick={() => navigate(-1)}
      />
    </div>
  )
}

import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import { ButtonLink } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { competitionAboutPath, competitionLeaderboardPath } from 'src/constants'

export const HomeCompetitionBanner = () => {
  const phase2Flag = useFlag('contestPhase2')
  const disableVoting = useFlag('disableVoting')
  const [showCompetitionBanner, setShowCompetitionBanner] = useState(true)

  return (
    showCompetitionBanner && (
      <div className='z-3 fixed bottom-0 flex w-full bg-transparent sm:h-auto md:h-80'>
        <div className='md:h-47.5 lg:h-54.5 lg:pl-165 md:pl-15 absolute bottom-0 z-0 flex w-full bg-white sm:h-auto'>
          <div className='sm:h-50 md:h-47.5 lg:h-54.5 absolute left-0 w-full overflow-hidden'>
            <img
              alt=''
              className='sm:max-h-35 md:max-h-45 lg:max-h-72.5 absolute sm:left--7 sm:top--3 md:left-0 md:top--10'
              src='https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-top.webp'
            />
            <img
              alt=''
              className='sm:h-25 md:h-50 absolute right-0 z-0 sm:bottom-0 sm:top-auto md:bottom-auto md:top-8'
              src='https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-bottom-mobile.png'
            />
          </div>
          <div className='job-promotion z-1 flex flex-col justify-center gap-4 sm:mb-5 sm:mt-8 sm:px-8 md:mb-4 md:mt-auto md:px-0'>
            <h3 className='type-h3 c-forestDark'>
              {phase2Flag
                ? 'Vote To Choose The Winner'
                : 'Win The Trip Of A Lifetime'}
            </h3>
            <span className='type-body-1 c-grey800'>
              {phase2Flag
                ? "We're giving away $20,000 for a dream vacation! Check out the 20 travelers who are still in the running and vote for your favorites."
                : 'Enter before November 1st to win your $20,000 dream vacation! Just set up a public profile on Travelpass.com, create three travel Guides, and get votes.'}
            </span>
            <div className='flex'>
              {!disableVoting && (
                <ButtonLink
                  to={
                    phase2Flag
                      ? competitionLeaderboardPath
                      : competitionAboutPath
                  }
                  variant='forest'
                >
                  {phase2Flag ? 'Cast Your Vote' : 'Learn More'}
                </ButtonLink>
              )}
            </div>
          </div>
          <div className='z-3 absolute right-3 top-3 md:flex'>
            <IconButton
              icon='clear'
              onClick={() => setShowCompetitionBanner(false)}
            />
          </div>
        </div>
        <img
          alt=''
          className='z-2 sm:bottom-50 md:bottom-unset sm:max-w-90% md:max-w-unset lg:left-30 absolute hidden object-contain sm:mx-5 md:mx-0 lg:block'
          loading='lazy'
          src='https://static.travelpass.com/assets/job-campaign-2024/home-competition-banner.webp'
        />
      </div>
    )
  )
}

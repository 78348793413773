import { useState } from 'react'
import { Divider } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { travelersPath } from 'src/constants'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { DashboardCta } from './DashboardCta'

interface DashboardCtasProps {
  isGuidePublishingV3Enabled: boolean
}

export const DashboardCtas = ({
  isGuidePublishingV3Enabled,
}: DashboardCtasProps) => {
  const enablePlanPage = useFlag('enablePlanPage')
  const navigate = useNavigate()
  const [showModalCreateGuideDraft, setShowModalCreateGuideDraft] =
    useState(false)
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <>
      <section className='space-y-12 px-4 py-4 pt-8'>
        <div className='pb-21 space-y-8'>
          <DashboardCta
            buttonText='Create A Guide'
            description='Share recommendations about your favorite destinations.'
            illustration='world'
            title='Share Your Tips'
            onClick={() => setShowModalCreateGuideDraft(true)}
          />
          <Divider />
          <DashboardCta
            buttonText='Find Travelers'
            description='Connect with friends and other travelers for endless trip inspo.'
            illustration='scooter'
            title='Follow Travelers'
            onClick={() => navigate(travelersPath)}
          />
          <Divider />
          <DashboardCta
            buttonText='Search & Book'
            description='Book recommended hotels and experiences for your upcoming adventure.'
            illustration='suitcase'
            title='Book Your Trip'
            onClick={() => navigate(`/${pathSegment}`)}
          />
        </div>
      </section>
      {isGuidePublishingV3Enabled && showModalCreateGuideDraft && (
        <GuideDraftCreateModal
          triggerButton='dashboardRight'
          onDismiss={() => setShowModalCreateGuideDraft(false)}
        />
      )}
      {!isGuidePublishingV3Enabled && showModalCreateGuideDraft && (
        <GuideDraftCreateModalOld
          triggerButton='dashboardRight'
          onDismiss={() => setShowModalCreateGuideDraft(false)}
        />
      )}
    </>
  )
}

import type { ApolloError, QueryResult } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  type GetProfileGuidesQueryInProfileOldQuery,
  GuideStatus,
} from 'src/__generated__/graphql'

const viewUserProfileQuery = gql(`
  query GetProfileGuidesQueryInProfileOld($accountHandle: String!, $after: String, $first: Int = 10, $userGuidesArgs: UserGuidesArgs!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      user {
        userGuides(first: $first, after: $after, userGuidesArgs: $userGuidesArgs) {
          edges {
            node {
              id
              addresses {
                id
                city
                country
                state
              }
              description
              insertedAt
              imageUrl
              likedByCurrentUser
              name
              numberOfLikes
              paginatedImages(first: 1) {
                edges {
                  node {
                    id
                    source
                    url
                  }
                }
              }
              ownerProfile {
                id
                accountHandle
                displayName
                profileImageUrl
              }
              strippedId
              timeZone
              updatedAt
              viewCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount,
        }
      }
    }
  }
`)

export type UseGetProfileGuidesQueryOld = {
  profileGuidesData: GetProfileGuidesQueryInProfileOldQuery['viewUserProfile']['user']['userGuides']
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: QueryResult<GetProfileGuidesQueryInProfileOldQuery>['refetch']
}

export const useGetProfileGuidesQueryOld = (
  accountHandle: string
): UseGetProfileGuidesQueryOld => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResults,
    loading: isLoading,
  } = useQuery(viewUserProfileQuery, {
    skip: !accountHandle,
    variables: {
      accountHandle,
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
    },
  })

  return {
    profileGuidesData: data?.viewUserProfile?.user?.userGuides ?? null,
    hasError,
    isLoading,
    onGetMoreResults,
  }
}

import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_USER_PROFILE_BY_ACCOUNT_HANDLE = gql(`
  query ProfileByAccountHandle($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      accountHandle
      activeBannerImage {
        id
        extraSmallUrl
        smallUrl
        mediumUrl
        largeUrl
      }
      bio
      bragContinents
      bragCountries
      bragStates
      displayName
      followCount {
        countOfFollowing
        countOfFollowed
      }
      followingUsers {
        id
        accountHandle
      }
      introVideoEmbed
      isFollowed
      isUserOwner
      links {
        title
        url
      }
      profileImage {
        id
        source
        type
        url
      }
      profileImageSource
      profileImageUrl
      socialLinks {
        identifier
        network
      }
      tags {
        id
        category
        name
      }
      userId
    }
  } 
`)

export const useProfileByAccountHandle = (accountHandle: string, options?) =>
  useQuery(GET_USER_PROFILE_BY_ACCOUNT_HANDLE, {
    variables: {
      accountHandle: accountHandle?.toLowerCase(),
    },
    skip: !accountHandle,
    errorPolicy: 'all',
    ...options,
  })

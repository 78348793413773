import type { ApolloError, QueryResult } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  type GetProfileGuidesQueryInProfileQuery,
  GuideStatus,
} from 'src/__generated__/graphql'

const viewUserProfileQuery = gql(`
  query GetProfileGuidesQueryInProfile($accountHandle: String!, $after: String, $first: Int = 10, $userGuidesArgs: UserGuidesArgs!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      user {
        userGuides(first: $first, after: $after, userGuidesArgs: $userGuidesArgs) {
          edges {
            node {
              id
              addresses {
                id
                city
                country
                state
              }
              description
              insertedAt
              likedByCurrentUser
              name
              numberOfLikes
              paginatedImages(first: 1) {
                edges {
                  node {
                    id
                    url
                  }
                }
              }
              strippedId
              timeZone
              updatedAt
              viewCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount,
        }
      }
    }
  }
`)

type UseGetProfileGuidesQuery = {
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: QueryResult<GetProfileGuidesQueryInProfileQuery>['refetch']
  profileGuidesData: GetProfileGuidesQueryInProfileQuery['viewUserProfile']['user']['userGuides']
}

const useGetProfileGuidesQuery = (
  accountHandle: string
): UseGetProfileGuidesQuery => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResults,
    loading: isLoading,
  } = useQuery(viewUserProfileQuery, {
    skip: !accountHandle,
    variables: {
      accountHandle,
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
    },
  })

  return {
    hasError,
    isLoading,
    onGetMoreResults,
    profileGuidesData: data?.viewUserProfile?.user?.userGuides,
  }
}

export type { UseGetProfileGuidesQuery }
export { useGetProfileGuidesQuery }

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const removeItemFromGuideDraftMutation = gql(`
  mutation RemoveItemFromGuide($archivePublishedEventInput: ArchivePublishedEventInput!) {
    archivePublishedEvent(archivePublishedEventInput: $archivePublishedEventInput) {
      guide {
        id
        name
        publishedEvents {
          id
          externalTypeId
          productId
          place {
            id
            googlePlaceId
          }
        }
      }
    }
  }
`)

export const useRemoveItemFromGuide = (options?) => {
  return useMutation(removeItemFromGuideDraftMutation, options)
}

import { Skeleton } from '@travelpass/design-system'

export const ProfileGuideCardLoading = () => (
  <div className='rounded-3 border-grey-300 of-hidden p-x-6 p-y-4 space-y-4 border-2 border-solid md:flex md:flex-row-reverse md:justify-between md:gap-2 md:space-y-0'>
    <div className='md:w-67 md:min-w-67 h-60 w-full overflow-hidden md:h-auto'>
      <Skeleton variant='rounded' />
    </div>
    <div className='grow space-y-4 md:space-y-6'>
      <div className='space-y-2'>
        <div className='flex flex-row items-center'>
          <div className='grow'>
            <div className='w-30 h-6 md:h-8 md:w-40 lg:h-9'>
              <Skeleton />
            </div>
          </div>
          <div className='m-x-1.5 h-6 w-6'>
            <Skeleton variant='rounded' />
          </div>
        </div>
        <div className='h-4 w-20'>
          <Skeleton />
        </div>
      </div>
      <div className='flex flex-row items-center gap-3'>
        <div className='h-9 w-9'>
          <Skeleton variant='circular' />
        </div>
        <div className='space-y-1'>
          <div className='w-25 h-3 md:h-3.5'>
            <Skeleton />
          </div>
          <div className='w-25 h-2.5 md:h-4'>
            <Skeleton />
          </div>
        </div>
      </div>
      <div className='space-y-2 md:space-y-4'>
        <p className='text-body-1 p-r-1.5 md:h-15 line-clamp-2 w-full text-ellipsis md:line-clamp-3'>
          <Skeleton />
        </p>
        <div className='flex flex-row items-center gap-3.5'>
          <div className='w-25 h-6.5'>
            <Skeleton variant='rounded' />
          </div>
          <div className='w-23 h-6.5'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
    </div>
  </div>
)

import { Chip, Divider } from '@travelpass/design-system'
import type { UserProfile } from 'src/__generated__/graphql'
import { ProfileSearchBarOld } from 'src/pages/profile-old/components/ProfileSearchBarOld/ProfileSearchBarOld'
import type { ExploreFilterUpdateFunction } from '../../exploreTypes'

type Traveler = { accountHandle: string; displayName: string }

interface CreatedByFilterProps {
  travelers: Traveler[]
  onSelection: ExploreFilterUpdateFunction
}

export const CreatedByFilter = ({
  travelers,
  onSelection,
}: CreatedByFilterProps) => {
  const onTravelerSelection = ({ displayName, accountHandle }: UserProfile) => {
    if (!travelers.find(traveler => traveler.accountHandle == accountHandle)) {
      const newTravelers = [...travelers, { displayName, accountHandle }]
      onSelection('createdBy', newTravelers)
    }
  }

  const onClick = (accountHandle: string) => {
    onSelection(
      'createdBy',
      travelers.filter(traveler => traveler.accountHandle !== accountHandle)
    )
  }

  return (
    <>
      <h4 className='type-h5'>Created By</h4>
      <ProfileSearchBarOld
        label='Search Travelers'
        onSelection={onTravelerSelection}
      />
      <div className='flex flex-wrap justify-center gap-x-3 gap-y-2'>
        {travelers?.map(({ displayName, accountHandle }) => (
          <Chip
            key={accountHandle}
            includeCloseIcon
            isSelected
            label={displayName}
            onClick={() => onClick(accountHandle)}
          />
        ))}
        <Divider />
      </div>
    </>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { guideDraftPublishedEventFragment } from './useGetGuideDraftQuery'

const createPublishedEventMutation = gql(`
  mutation CreatePublishedEventMutationInGuideDraft($first: Int = 100, $publishedEventInput: CreatePublishedEventInput!) {
    createPublishedEvent(createPublishedEventInput: $publishedEventInput) {
      guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
        }
        eventCounts {
          numberEvents
        }
        name
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        status
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useCreateGuideDraftEventMutation = () =>
  useMutation(createPublishedEventMutation)

import { Button, StopPropagation } from '@travelpass/design-system'
import {
  GuideLikesModal,
  useGuideMetricLike,
  useGuideMetricSave,
  useGuideMetricShare,
} from 'src/pages/guides/details'
import { getGuideMetricString } from 'src/pages/guides/details/guideUtils'
import type { GuideData } from 'src/pages/guides/details/types'

interface DashboardGuideOldCardMetricsProps {
  guideData: Pick<
    GuideData,
    | 'collectedCount'
    | 'id'
    | 'isCollected'
    | 'likedByCurrentUser'
    | 'name'
    | 'numberOfLikes'
    | 'ownerProfile'
    | 'shareCount'
    | 'viewCount'
  >
}

export const DashboardGuideOldCardMetrics = ({
  guideData,
}: DashboardGuideOldCardMetricsProps) => {
  const {
    collectedCount,
    id,
    isCollected,
    likedByCurrentUser,
    name,
    numberOfLikes,
    shareCount,
    viewCount,
  } = guideData ?? {}
  const { isModalOpen, onGuideLike, onIsModalOpenChange } =
    useGuideMetricLike(id)
  const onGuideSave = useGuideMetricSave({
    id,
    name,
  })
  const onGuideShare = useGuideMetricShare(id)

  return (
    <>
      <div className='w-fit cursor-default'>
        <StopPropagation>
          <div className='[&>div>button]:p-x-0 [&>div>button>i]:color-grey-900 [&>div>button>div]:color-grey-900 [&>div>button>div]:normal-case [&>div>button]:gap-1'>
            <div className='inline-flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
              <Button
                aria-pressed={likedByCurrentUser}
                size='small'
                startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
                variant='text'
                onClick={onGuideLike}
              >
                {getGuideMetricString(numberOfLikes, '')}
              </Button>
              <Button
                aria-disabled={true}
                isDisabled={true}
                name='visibility'
                size='small'
                startIcon='visibility'
                variant='text'
              >
                {getGuideMetricString(viewCount, '')}
              </Button>
              <Button
                aria-pressed={isCollected}
                size='small'
                startIcon='addCircleOutline'
                variant='text'
                onClick={onGuideSave}
              >
                {getGuideMetricString(collectedCount, '')}
              </Button>
              <Button
                aria-pressed={isCollected}
                size='small'
                startIcon='iosShare'
                variant='text'
                onClick={onGuideShare}
              >
                {getGuideMetricString(shareCount, '')}
              </Button>
            </div>
          </div>
        </StopPropagation>
      </div>
      {isModalOpen && (
        <GuideLikesModal
          guideId={id}
          onClose={() => onIsModalOpenChange(false)}
        />
      )}
    </>
  )
}

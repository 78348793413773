import { useEffect, useState } from 'react'
import { useQuery, type ApolloError } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams } from 'react-router-dom'
import type { LatLng } from 'use-places-autocomplete'
import { gql } from 'src/__generated__'
import {
  type LatLong,
  type GetGuideDraftQueryInGuideDraftQuery,
  type PublishedEventCategory,
  type Scalars,
} from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import type { ProfileOwner } from 'src/pages/profile/types'
import {
  decodeGuideId,
  encodeGuideId,
  getGuideDetailsUrl,
  getGuideDraftDetailsUrl,
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfileOwnerUrl,
} from 'src/utils'
import type { GuideDraftData } from './types'
import { GuideSessionStorage } from '../details/guideConstants'
import { getGuideMetaData } from '../details/guideUtils'
import type { GuideMetaData } from '../details/types'
import { onGuideAutoSaveStateChange } from '../details/useGuideAutoSave'

const guideDraftPublishedEventFragment = gql(`
  fragment GuideDraftPublishedEventFields on PublishedEvent {
    id
    addresses {
      id
      addressLine1
      city
      country
      googlePlaceId
      lat
      long
      state
      zipcode
    }
    description
    externalTypeId
    images {
      id
      source
      type
      url
    }
    imageUrl
    isCollected
    likedByCurrentUser
    name
    notes
    productId
    publishedEventLikedByUsers(first: 1) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
    standardHotelId
    status
    type
  }
`)

const getGuideDraftQuery = gql(`
  query GetGuideDraftQueryInGuideDraft($first: Int = 100, $id: ID!) {
    currentUser {
      id
      needsGuideOnboarding
      userRoles
    }
    node(id: $id) {
      ... on Guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        collectedCount
        description
        eventCounts {
          numberEvents
        }
        firstPublishedAt
        imageUrl
        insertedAt
        isCollected
        likedByCurrentUser
        name
        numberOfLikes
        owner {
          id
        }
        ownerProfile {
          id
          accountHandle
          displayName
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        paginatedImages(first: 3) {
          edges {
            node {
              id
              source
              url
            }
          }
        }
        shareCount
        status
        strippedId
        tags {
          id
          name
        }
        timeZone
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        updatedAt
        viewCount
      }
    }
  }
`)

type UseGetGuideDraftQuery = {
  data: GetGuideDraftQueryInGuideDraftQuery
  hasError: ApolloError
  isLoading: boolean
  guideDraftData: GuideDraftData
  guideDraftMetaData: GuideMetaData
  location: LatLong
  mapMarkerCenter: google.maps.LatLngLiteral
  onGetMoreResults: ({
    after,
    id,
  }: {
    after: Scalars['String']['input']
    id: PublishedEventCategory['id']
  }) => Promise<void>
  onMapMarkerCenterChange: (updatedMapMarkerCenter: LatLng) => void
  owner: ProfileOwner
}

const useGetGuideDraftQuery = (): UseGetGuideDraftQuery => {
  const params = useParams()
  const guideDraftIdDecoded = decodeGuideId(params.guideDraftId)
  const guideDraftId = encodeGuideId({
    guideId: guideDraftIdDecoded,
  })
  const navigate = useNavigate()
  const [mapMarkerCenter, setMapMarkerCenter] =
    useState<UseGetGuideDraftQuery['mapMarkerCenter']>(null)
  const {
    data,
    error,
    fetchMore: onGetMoreResultsQuery,
    loading,
  } = useQuery(getGuideDraftQuery, {
    errorPolicy: 'all',
    skip: !guideDraftId,
    variables: {
      id: guideDraftId,
    },
  })
  const guideDraftData = data?.node as GuideDraftData
  const {
    addresses,
    eventCounts,
    imageUrl,
    name,
    ownerProfile,
    paginatedImages,
  } = guideDraftData ?? {}
  const [address] = addresses ?? []
  const owner: ProfileOwner = {
    id: ownerProfile?.id,
    image: getProfileOwnerImage(ownerProfile?.profileImageUrl),
    isUserOwner: ownerProfile?.isUserOwner,
    name: getProfileOwnerName({
      accountHandle: ownerProfile?.accountHandle,
      displayName: ownerProfile?.displayName,
    }),
    url: getProfileOwnerUrl(ownerProfile?.accountHandle),
  }
  const { lat, lng } = constructMapCenter(address?.lat, address?.long)
  const guideDraftMetaData = getGuideMetaData({
    address,
    image: paginatedImages?.edges?.[0]?.node?.url ?? imageUrl,
    name,
    numberEvents: eventCounts?.numberEvents,
    ownerName: owner?.name,
  })

  // When the user is not the owner of the guide draft, redirect to the guide or set the map center
  useEffect(() => {
    if (isEmpty(data)) return

    if (sessionStorage.getItem(GuideSessionStorage.autoSaveState) === 'SAVED')
      onGuideAutoSaveStateChange('DEFAULT')

    const currentUserId = data?.currentUser?.id
    const guideDraftOwnerId = (data?.node as GuideDraftData)?.owner?.id

    if (currentUserId === guideDraftOwnerId) return

    return navigate(
      getGuideDetailsUrl({
        id: guideDraftData?.id,
        name,
      })
    )
  }, [data])

  useEffect(() => {
    if (params?.guideDraftId !== guideDraftIdDecoded) {
      navigate(
        getGuideDraftDetailsUrl({
          id: guideDraftIdDecoded,
          isUserOwner: true,
          name,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideDraftIdDecoded, name, params?.guideDraftId])

  const onMapMarkerCenterChange = (
    updatedMapMarkerCenter: UseGetGuideDraftQuery['mapMarkerCenter']
  ) => setMapMarkerCenter(updatedMapMarkerCenter)

  // Fetch more events for a specific category
  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        /** @todo updateQuery */
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  return {
    data,
    hasError: error,
    isLoading: loading,
    guideDraftData,
    guideDraftMetaData,
    location: {
      latitude: lat,
      longitude: lng,
    },
    mapMarkerCenter,
    onMapMarkerCenterChange,
    onGetMoreResults,
    owner,
  }
}

export type { UseGetGuideDraftQuery }
export {
  getGuideDraftQuery,
  guideDraftPublishedEventFragment,
  useGetGuideDraftQuery,
}

import { useEffect, useMemo, useRef, useState } from 'react'
import { IconButton, useSnackbar } from '@travelpass/design-system'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { createPortal } from 'react-dom'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useUpdateGuideDraftEventMutation } from 'src/pages/guides/lists/useUpdateGuideDraftEventMutation'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideDrawerContent } from './GuideDrawerContent'
import { onGuideAutoSaveStateChange } from '../../useGuideAutoSave'

interface GuideDrawerProps {
  className?: string
  guideId: string
  isEdit: boolean
  onSelectedIdChange: (updatedSelectedId: string) => void
  owner: ProfileOwner
  points?: PublishedEvent[]
  selectedEvent: PublishedEvent
}

export const GuideDrawer = ({
  className,
  guideId,
  isEdit,
  onSelectedIdChange,
  owner,
  points = [],
  selectedEvent,
}: GuideDrawerProps) => {
  const debouncedAutoSave = useMemo(
    () =>
      debounce((updatedNotes: string) => {
        if (selectedEvent?.id && updatedNotes !== selectedEvent?.notes) {
          onSubmit({
            updatedNotes,
            updatedPublishedEventId: selectedEvent?.id,
          })
        }
      }, 1500),
    [selectedEvent?.id, selectedEvent?.notes]
  )
  const previousSelectedEventIdRef = useRef('')
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [notes, setNotes] = useState('')
  const [updatePublishedEvent] = useUpdateGuideDraftEventMutation()
  const isOpen = !!selectedEvent?.id

  useEffect(() => {
    const onBeforeUnload = () =>
      onSubmit({
        forceSave: true,
        updatedNotes: notes,
        updatedPublishedEventId: previousSelectedEventIdRef.current,
      })

    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    return () => {
      debouncedAutoSave.cancel()
    }
  }, [debouncedAutoSave])

  useEffect(() => {
    if (!isEdit || !previousSelectedEventIdRef.current) return

    const previousSelectedEvent = points?.find(
      ({ id }) => id === previousSelectedEventIdRef.current
    )

    if (
      previousSelectedEvent?.id &&
      previousSelectedEvent?.id !== selectedEvent?.id &&
      (previousSelectedEvent?.notes ?? '') !== notes
    ) {
      onSubmit({
        forceSave: true,
        updatedNotes: notes,
        updatedPublishedEventId: previousSelectedEventIdRef.current,
      })
    }
  }, [isEdit, notes, selectedEvent?.id])

  useEffect(() => {
    if (!selectedEvent?.id) return

    previousSelectedEventIdRef.current = selectedEvent?.id

    setNotes(selectedEvent?.notes ?? '')
  }, [selectedEvent?.id, selectedEvent?.notes])

  const onClose = async () => {
    if (isEdit && notes !== selectedEvent?.notes)
      onSubmit({
        updatedNotes: notes,
        updatedPublishedEventId: selectedEvent?.id,
      })

    onSelectedIdChange('')
  }

  const onNotesChange = (updatedNotes: string) => {
    setNotes(updatedNotes)
    debouncedAutoSave(updatedNotes)
  }

  const onSubmit = async ({
    forceSave,
    updatedNotes,
    updatedPublishedEventId,
  }: {
    forceSave?: boolean
    updatedNotes: string
    updatedPublishedEventId: string
  }) => {
    if (!forceSave && (!updatedPublishedEventId || notes === updatedNotes))
      return

    onGuideAutoSaveStateChange('SAVING')

    try {
      await updatePublishedEvent({
        variables: {
          publishedEventInput: {
            guideId,
            notes: updatedNotes,
            publishedEventId: updatedPublishedEventId,
          },
        },
      })
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
      onGuideAutoSaveStateChange('SAVED')
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
      onGuideAutoSaveStateChange('DEFAULT')
    }
  }

  return (
    <>
      {createPortal(
        <div
          className={classNames(
            'h-100dvh z-5 fixed bottom-0 left-0 right-0 top-0 w-full flex-col bg-white lg:hidden',
            {
              hidden: !isOpen,
              'visible flex': isOpen,
            }
          )}
        >
          <div className='flex justify-end'>
            <IconButton icon='clear' size='large' onClick={onClose} />
          </div>
          <GuideDrawerContent
            guideId={guideId}
            isEdit={isEdit}
            notes={notes}
            owner={owner}
            selectedEvent={selectedEvent}
            onNotesChange={onNotesChange}
          />
        </div>,
        document.body
      )}
      <div
        className={classNames(
          className,
          'transition-transform-175 w-130 z-2 lg:b-r-grey-200 lg:b-r-solid lg:b-r-1 fixed relative hidden bg-white ease-[cubic-bezier(0,0,0.2,1)] lg:sticky lg:flex lg:flex-col',
          {
            '-translate-x-150': !isOpen,
          }
        )}
      >
        <div className='flex justify-end'>
          <IconButton
            aria-label='Close the modal'
            icon='clear'
            size='large'
            onClick={onClose}
          />
        </div>
        <GuideDrawerContent
          guideId={guideId}
          isEdit={isEdit}
          notes={notes}
          owner={owner}
          selectedEvent={selectedEvent}
          onNotesChange={onNotesChange}
        />
      </div>
    </>
  )
}

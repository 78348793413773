import classNames from 'classnames'
import type { FollowersModalType } from 'src/common/components'
import { DashboardMobileHeader } from './DashboardMobileHeader'
import { DashboardPlaneSVG } from './DashboardPlaneSVG'
import { getDashboardHeaderGreeting } from '../../dashboardUtils'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardHeaderProps {
  currentUser: UseGetDashboardQuery['dashboardData']['currentUser']
  onModalInfoOpen: VoidFunction
  onScrollIdChange: (updatedScrollId: string) => void
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const DashboardHeader = ({
  currentUser,
  onModalInfoOpen,
  onScrollIdChange,
  toggleFollowersModal,
}: DashboardHeaderProps) => (
  <header className='lg:h-87.5 lg:pl-34.5 lg:content-initial content-center space-y-3 bg-[radial-gradient(61.11%_100%_at_50.03%_100%,_#125E5C_26%,_#042E30_100%)] pb-8 pl-9 pr-5 pt-9 lg:pt-12'>
    <div className='mx-a max-w-452 relative'>
      <div className='w-42 max-w-42 lg:left--40.5 absolute hidden lg:top--8 lg:block'>
        <DashboardPlaneSVG />
      </div>
      <div className='space-y-3'>
        <h1 className='type-h1-desktop c-white hidden lg:block'>
          {getDashboardHeaderGreeting({
            firstName: currentUser?.firstName,
            isMobile: false,
          })}
        </h1>
        <h2 className='type-h6-desktop c-white hidden lg:block'>
          Ready to discover your next adventure?
        </h2>
      </div>
      <DashboardMobileHeader
        currentUser={currentUser}
        toggleFollowersModal={toggleFollowersModal}
        onModalInfoOpen={onModalInfoOpen}
        onScrollIdChange={onScrollIdChange}
      />
    </div>
  </header>
)

import type {
  FlattenedRateRequest,
  HotelRoomsQueryVariables,
  Options,
  RoomFilters,
} from 'src/__generated__/graphql'
import {
  getAdultGuests,
  getArrivalDate,
  getDepartureDate,
  getKidGuestsAges,
  getTracker,
} from 'src/utils'

interface createRateRequestParams {
  filters: RoomFilters | null
  searchParams: URLSearchParams
  standardHotelId: FlattenedRateRequest['standardHotelId']
  options: Options | null
}

const createRateRequest = ({
  filters,
  searchParams,
  standardHotelId,
  options,
}: createRateRequestParams): HotelRoomsQueryVariables => {
  const { adults, arrival, departure, kids } = Object.fromEntries([
    ...searchParams,
  ])

  return {
    rateRequest: {
      arrival: getArrivalDate({ date: arrival }),
      departure: getDepartureDate({ date: departure }),
      customerUserAgent: navigator.userAgent,
      filters,
      occupancy: [
        { adults: getAdultGuests(adults), childAges: getKidGuestsAges(kids) },
      ],
      standardHotelId,
      tracker: getTracker(standardHotelId?.toString() ?? ''),
      options,
    },
  }
}

export { createRateRequest }

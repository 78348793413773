import { Modal, ModalScrollContents } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { FollowersList } from 'src/common/components/FollowersModal/FollowersList'
import { GuideLikesModalLoadMore } from './GuideLikesModalLoadMore'
import { useGetUsersLikeGuideQuery } from '../../useGetUsersLikeGuideQuery'

interface GuideLikesModalProps {
  guideId: string
  onClose: VoidFunction
}

export const GuideLikesModal = ({ guideId, onClose }: GuideLikesModalProps) => {
  const { guideLikesData, hasError, isLoading, onGetMoreResults } =
    useGetUsersLikeGuideQuery(guideId)

  if (!guideId || isEmpty(guideLikesData) || hasError) return

  return (
    <Modal size='medium' title='Likes' onDismiss={onClose}>
      <ModalScrollContents>
        <FollowersList
          emptyListMessage='No travelers have liked this guide yet.'
          isLoading={isLoading}
          list={guideLikesData?.paginatedGuidesLikedByUsers?.edges?.map(
            guideLike => guideLike?.node?.userProfile
          )}
        />
        <GuideLikesModalLoadMore
          guideLikesData={guideLikesData}
          onGetMoreResults={onGetMoreResults}
        />
      </ModalScrollContents>
    </Modal>
  )
}

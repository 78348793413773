import { useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { getGuideDetailsUrl } from 'src/utils'
import type { GuideData } from './types'
import { useIncrementGuideShareCountMutation } from '../useIncrementGuideShareCountMutation'
import { useShareGuide } from '../useShareGuide'

export const useGuideMetricShare = (guideId: GuideData['id']) => {
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id: guideId,
  })}`

  const onGuideShare = debounce(async () => {
    await shareGuide({
      shareUrl: url,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
      },
    })
    if (!hasShared) {
      incrementShareCount({
        variables: {
          guideId,
        },
      })
      setHasShared(true)
    }
  }, 100)

  return onGuideShare
}

export function isWebView() {
  //@ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Detect common webview patterns
  const webViewPatterns = [
    /FBAN|FBAV/, // Facebook
    /Instagram/, // Instagram
    /Twitter/, // Twitter
    /Line|WebView/, // Line and generic WebView
    /wv/, // Generic Android WebView
  ]
  return webViewPatterns.some(pattern => pattern.test(userAgent))
}

import { useContext } from 'react'
import { HotelStickyMenuContext } from './HotelStickyMenuContext'

export const useHotelStickyMenuContext = () => {
  const context = useContext(HotelStickyMenuContext)
  if (!context) {
    throw new Error(
      'useHotelStickyMenuContext must be used within a HotelStickyMenuProvider'
    )
  }
  return context
}

import { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { useIsElementOnScreen } from 'src/common/hooks'
import { stringToBoolean } from 'src/utils'
import {
  GuideDraftEmpty,
  GuideDraftGeocoder,
  GuideDraftHeroEmpty,
  GuideDraftLoading,
  GuideDraftMap,
  GuideDraftMapEvents,
  GuideDraftMapMarkers,
  GuideDraftMapSearch,
  GuideDraftOnboarding,
  GuideDraftSections,
} from './common'
import { getGuideDraftEventMarkers } from './guideDraftUtils'
import { useGetGuideDraftQuery } from './useGetGuideDraftQuery'
import { GuideSearchParam } from '../details'
import {
  GuideDrawer,
  GuideHeader,
  GuideHero,
  GuideMapWrapper,
  GuideSectionsWrapper,
  GuideStickyTopNav,
} from '../details/common'

export const GuideDraft = () => {
  const guideHeaderRef = useRef(null)
  const [, hasMounted] = useState(false)
  /** @todo Remove the need for callback here if possible */
  const setGuideHeaderRef = useCallback(node => {
    guideHeaderRef.current = node
    hasMounted(!!guideHeaderRef.current)
  }, [])
  const isGuideHeaderOnScreen = useIsElementOnScreen(guideHeaderRef, {
    threshold: 0.7,
  })
  const [searchValue, setSearchValue] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const {
    data,
    guideDraftData,
    guideDraftMetaData,
    hasError,
    isLoading,
    location,
    mapMarkerCenter,
    onMapMarkerCenterChange,
    owner,
  } = useGetGuideDraftQuery()
  const [placePoints, setPlacePoints] = useState<
    google.maps.places.PlaceResult[]
  >([])
  const [searchParams] = useSearchParams()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )
  const {
    id,
    paginatedEventCategories,
    paginatedImages,
    uncategorizedPublishedEvents,
  } = guideDraftData ?? {}
  const points = getGuideDraftEventMarkers({
    paginatedEventCategories,
    uncategorizedPublishedEvents,
  })
  const selectedEvent = points?.find(({ id }) => id === selectedId)

  const onPlacePointsChange = (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => setPlacePoints(updatedPlacePoints)

  const onSearchValueChange = (updatedSearchValue: string) =>
    setSearchValue(updatedSearchValue)

  const onSelectedIdChange = (updatedSelectedId: string) =>
    setSelectedId(updatedSelectedId)

  if (isLoading) return <GuideDraftLoading selectedId={selectedId} />

  if (hasError || isEmpty(guideDraftData)) return <GuideDraftEmpty />

  /** @todo use only 1 PageLayoutContainer instead of 2 if possible (bg color discrepancy caused this initially) */
  return (
    <>
      <Helmet {...guideDraftMetaData} />
      <GuideStickyTopNav
        guideData={guideDraftData}
        isEdit={true}
        isVisible={!isGuideHeaderOnScreen && !!guideHeaderRef?.current}
        selectedEvent={selectedEvent}
      />
      {!!paginatedImages?.edges?.length && (
        <div
          className={classNames({
            'hidden lg:block': mapFullView,
          })}
        >
          <GuideHero
            guideData={guideDraftData}
            isEdit={true}
            isGuidePublishingV3Enabled={true}
          />
        </div>
      )}
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapFullView={mapFullView} selectedId={selectedId}>
          <div className='bg-white' ref={setGuideHeaderRef}>
            <PageLayoutContainer size='none'>
              {!paginatedImages?.edges?.length && (
                <div className='p-t-6'>
                  <GuideDraftHeroEmpty guideDraftData={guideDraftData} />
                </div>
              )}
              <GuideHeader
                guideData={guideDraftData}
                isEdit={true}
                owner={owner}
              />
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <div className='p-t-6 p-b-5 lg:p-b-30 space-y-8 lg:space-y-10'>
              <div className='space-y-4'>
                <h3 className='color-forest-dark type-body-1-medium-desktop'>
                  Add places, experiences, and more to your Guide
                </h3>
                <GuideDraftGeocoder
                  id={guideDraftData?.id}
                  location={location}
                  paginatedEventCategories={
                    guideDraftData?.paginatedEventCategories
                  }
                  searchValue={searchValue}
                  onMapMarkerCenterChange={onMapMarkerCenterChange}
                  onPlacePointsChange={onPlacePointsChange}
                  onSearchValueChange={onSearchValueChange}
                />
              </div>
              <GuideDraftSections
                data={data}
                location={location}
                owner={owner}
                selectedId={selectedId}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onPlacePointsChange={onPlacePointsChange}
                onSelectedIdChange={onSelectedIdChange}
              />
            </div>
          </PageLayoutContainer>
          <GuideDraftOnboarding currentUser={data?.currentUser} />
        </GuideSectionsWrapper>
        <GuideDrawer
          className='h-100svh lg:top-0'
          guideId={id}
          isEdit={true}
          owner={owner}
          points={points}
          selectedEvent={selectedEvent}
          onSelectedIdChange={onSelectedIdChange}
        />
        {!isbot(navigator.userAgent) && (
          <GuideMapWrapper
            className='lg:h-100svh h-[calc(100svh-166px)] md:h-[calc(100svh-175px)] lg:top-0'
            mapFullView={mapFullView}
            selectedId={selectedId}
          >
            <GuideDraftMap data={data} onSelectedIdChange={onSelectedIdChange}>
              <GuideDraftMapEvents
                data={data}
                mapMarkerCenter={mapMarkerCenter}
              />
              <GuideDraftMapMarkers
                id={guideDraftData?.id}
                owner={owner}
                placePoints={placePoints}
                points={points}
                selectedId={selectedId}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onSelectedIdChange={onSelectedIdChange}
              />
              <GuideDraftMapSearch
                id={guideDraftData?.id}
                location={location}
                searchValue={searchValue}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onPlacePointsChange={onPlacePointsChange}
                onSearchValueChange={onSearchValueChange}
              />
            </GuideDraftMap>
          </GuideMapWrapper>
        )}
      </div>
    </>
  )
}

import { Carousel } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import {
  TravelerCard,
  TravelerCardLoading,
} from 'src/common/components/TravelerCard'
import { useRecommendedTravelers } from '../hooks/useRecommendedTravelers'

export const RecommendedTravelers = () => {
  const { data, loading, refetch } = useRecommendedTravelers()
  const profiles = data?.currentUser?.userProfile?.userRecommendations.slice(
    0,
    15
  )
  const showResults = !loading && !!profiles?.length

  if (!showResults && !loading) return null

  return (
    <PageLayoutContainer>
      <section className='flex flex-col gap-y-2 py-16 pb-8'>
        <p className='type-overline c-grey700 text-center'>
          RECOMMENDED FOR YOU
        </p>
        <h2 className='type-h2 c-black mt-2 text-center capitalize'>
          Find Friends And Similar Travelers
        </h2>
        <div className='bg-valley w-1/8 mx-auto h-1.5 rounded-full max-md:hidden' />
        <div className='flex justify-center'>
          <div className='w-100% pt-10'>
            <Carousel
              showArrowsOutside
              borderRadius={12}
              keyBoardControl={true}
              size='small'
              slidesPerViewOnDesktop={3}
              slidesPerViewOnMobile={1}
              slidesPerViewOnTablet={2}
            >
              {loading &&
                Array(3)
                  .fill(null)
                  .map(card => (
                    <div
                      key={`loading-recommended-traveler-card-${card}`}
                      className='mx-auto mb-8 max-w-[calc(100%-24px)]'
                    >
                      <TravelerCardLoading />
                    </div>
                  ))}
              {showResults &&
                profiles?.map(profile => (
                  <div
                    key={profile.recommendedUserProfile.id}
                    className='h-90% mx-auto mb-8 max-w-[calc(100%-24px)]'
                  >
                    <TravelerCard
                      key={profile.recommendedUserProfile.id}
                      hideStats
                      reason={profile.reason}
                      traveler={profile.recommendedUserProfile}
                      onFollow={refetch}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </section>
    </PageLayoutContainer>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const removeGuideImagesMutation = gql(`
  mutation RemoveGuideImagesMutationInGuideDraft($input: RemoveGuideImagesInput!) {
    removeGuideImages(input: $input) {
      guide {
        id
        paginatedImages(first: 3) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`)

export const useRemoveGuideDraftImagesMutation = () =>
  useMutation(removeGuideImagesMutation)

import { useEffect, useState } from 'react'
import { DateRangePicker } from '@travelpass/design-system'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import { GuestConfigurationPopover } from 'src/common/components/Guests/GuestConfigurationPopover'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { DatesType, GuestsType } from 'src/constants/user'
import { constructDates, constructGuests, formatDate } from 'src/utils'

export const HotelRoomsSearchControls = () => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [searchParams, setSearchParams] = useSearchParams()
  const { adults, arrival, departure, kids } = Object.fromEntries([
    ...searchParams,
  ])
  const constructedDates = constructDates({ arrival, departure })
  const constructedGuests = constructGuests({ adults, kids })
  const [guests, setGuests] = useState(constructedGuests)
  const [dates, setDates] = useState<DatesType>(constructedDates)
  const onGuestsChange = (updatedGuests: GuestsType) => setGuests(updatedGuests)
  const onSelect = (date: Dayjs) =>
    date?.isBefore(dates[1])
      ? setDates(prev => [date, prev[1]])
      : setDates(prev => [prev[1], date])

  useEffect(() => {
    const updatedAdults = guests?.[0]?.toString()
    const updatedArrival = dates[0] ? formatDate(dates?.[0]) : null
    const updatedDeparture = dates[1] ? formatDate(dates?.[1]) : null
    const updatedKids = guests?.[1]?.toString()
    const willSubmit =
      adults !== updatedAdults ||
      arrival !== updatedArrival ||
      departure !== updatedDeparture ||
      kids !== updatedKids

    if (willSubmit) {
      if (updatedArrival && updatedDeparture) {
        pushDataToDataLayer('hotelDatesUpdated', {
          arrival: updatedArrival,
          departure: updatedDeparture,
        })
        searchParams.set('departure', updatedDeparture)
        searchParams.set('arrival', updatedArrival)
      }
      searchParams.set('adults', updatedAdults)
      searchParams.set('kids', updatedKids)
      setSearchParams(searchParams, { replace: true })
    }
  }, [guests, dates])

  return (
    <>
      <div
        className={classNames(
          newHotelDetails &&
            'max-md:col-span-5 md:col-span-6 max-md:[&>div>label~div]:gap-1 [&_input]:max-md:text-sm'
        )}
      >
        <DateRangePicker
          label={isMobile && newHotelDetails ? 'Dates' : 'Check-in – Check-out'}
          numberOfMonths={1}
          selected={{ from: dates?.[0], to: dates?.[1] }}
          onSelect={range => setDates([range.from, range?.to])}
        />
      </div>
      <div
        className={classNames(
          newHotelDetails && 'max-md:order--1 max-md:col-span-2 md:col-span-4',
          'max-md:w-full'
        )}
      >
        <GuestConfigurationPopover guests={guests} onChange={onGuestsChange} />
      </div>
    </>
  )
}

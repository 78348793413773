import { Icon } from '@travelpass/design-system'
import { guideEventsMock } from 'src/pages/guides/details/__tests__/__mocks__'
import { GuideImage } from 'src/pages/guides/details/common'
import { getEventImage } from 'src/pages/trips/utils'
import { getGuideDetailsUrl, getImageResized } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import type { DashboardFeedItem } from '../../../types'

/** @todo replace with event feed item (waiting on BE).  */
const event =
  guideEventsMock.paginatedEventCategories.edges[0].node.publishedEvents
    .edges[0].node

interface DashboardFeedCardRepublishedGuideCreatedNoteProps {
  dashboardFeedItem: DashboardFeedItem
}

/** @todo Replace guide with event feed item (waiting on BE).  */
export const DashboardFeedCardRepublishedGuideCreatedNote = ({
  dashboardFeedItem,
}: DashboardFeedCardRepublishedGuideCreatedNoteProps) => {
  const { id, name } = dashboardFeedItem?.guide ?? {}
  const image = getEventImage(event?.imageUrl)
  const url = getGuideDetailsUrl({
    id,
    name,
  })

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide dashboardFeedItem={dashboardFeedItem}>
          <p className='type-body-2 flex flex-row items-center gap-1'>
            <span className='min-w-fit'>Added a new note to an event in</span>
            <a
              className='c-new-forest font-600 transition-opacity-200 relative line-clamp-1 min-w-0 grow no-underline ease-linear hover:opacity-70'
              href={url}
              rel='noreferrer'
              target='_blank'
            >
              <q>{name}</q>
            </a>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div className='space-y-3'>
          <p className='c-orange relative m-0 flex flex-row items-center gap-1'>
            <Icon name='placeOutline' size='small' />
            <span className='type-body-1-medium'>{event?.name}</span>
          </p>
          <div className='flex flex-col gap-4 md:flex-row-reverse'>
            <div className='h-53 pointer-events-none md:h-60 md:w-52 md:min-w-52'>
              <GuideImage
                className='rounded-1'
                src={getImageResized({
                  imageUrl: image,
                  shape: 'square',
                  width: 1000,
                })}
              />
            </div>
            <div className='grow'>
              <p className='bg-warm-grey rounded-1 m-0 flex flex-row gap-1 p-3'>
                <span className='c-grey-800 w-4 min-w-4'>
                  <Icon name='stickyNote2' size='small' />
                </span>
                <q className='type-body-2-desktop md:line-clamp-12 line-clamp-3'>
                  {event?.notes}
                </q>
              </p>
            </div>
          </div>
        </div>
      </article>
    </DashboardFeedCard>
  )
}

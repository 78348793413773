import { useRef, useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { GuideStatus, ParticipantStatus } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useLazyUserCompetitionStatus } from 'src/pages/dashboard/useUserCompetitionStatus'
import type { GuideData } from 'src/pages/guides/details/types'
import { useUpdateGuideMutation } from 'src/pages/guides/useUpdateGuideMutation'
import {
  copyToClipboard,
  getGuideDetailsUrl,
  getProfileOwnerUrl,
} from 'src/utils'
import { GuideDraftShareIntro } from './GuideDraftShareIntro'
import { GuideDraftShareReview } from './GuideDraftShareReview'
import { GuideDraftShareSuccess } from './GuideDraftShareSuccess'
import { getGuideDraftInvalidFields } from '../../guideDraftUtils'
import { GuideDraftEditModal } from '../GuideDraftEditModal'
import { GuideDraftImagesEditModal } from '../GuideDraftImagesEdit'

const getGuideDraftShareTitle = ({
  displayName,
  step,
}: {
  displayName: string
  step: number
}) => {
  if (step === 1) return 'Share Your Guide'

  if (step === 2) return "You're Almost There"

  return displayName ? `Congrats, ${displayName}!` : 'Congrats!'
}

interface GuideDraftShareProps {
  guideData: GuideData
  onDismiss: VoidFunction
}

export const GuideDraftShare = ({
  guideData,
  onDismiss,
}: GuideDraftShareProps) => {
  const [updateGuide, { loading: isLoading }] = useUpdateGuideMutation()
  const navigate = useNavigate()
  const scrollId = useRef<string>('')
  const { addSuccessSnack } = useSnackbar()
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalImagesEditOpen, setIsModalImagesEditOpen] = useState(false)
  const [step, setStep] = useState(1)
  const { ownerProfile, paginatedImages } = guideData ?? {}
  const images = paginatedImages?.edges?.map(({ node }) => node)
  const modalTitle = getGuideDraftShareTitle({
    displayName: ownerProfile?.displayName ?? '',
    step,
  })
  const isPublished = guideData?.status === GuideStatus.Published

  const enableCompetition2024 = useFlag('enableCompetition2024')
  const [getCompetitionStatus] = useLazyUserCompetitionStatus()

  const checkCompetitionReady = async () => {
    const { data } = await getCompetitionStatus()
    const isReady =
      data?.currentUser?.userProfile?.competitionInfo?.status ===
      ParticipantStatus.Ready
    return { isReady, data }
  }

  const handleCompetitionStatusChange = async () => {
    const { isReady, data: competitionData } = await checkCompetitionReady()

    if (isReady)
      pushDataToDataLayer('competition_ready', {
        user_id: competitionData.currentUser.userProfile.userId,
        status: competitionData.currentUser.userProfile.competitionInfo.status,
        first_name: competitionData.currentUser.firstName,
        last_name: competitionData.currentUser.lastName,
        profile_url: `${window.location.origin}${getProfileOwnerUrl(competitionData?.currentUser?.userProfile?.accountHandle)}`,
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
  }

  const onChange = async () => {
    try {
      const { isReady: isAlreadyCompetitionReady } =
        enableCompetition2024 && (await checkCompetitionReady())

      await updateGuide({
        variables: {
          updateGuideInput: {
            guideId: guideData?.id,
            status: isPublished
              ? GuideStatus.ViewableWithLink
              : GuideStatus.Published,
          },
        },
      })

      if (enableCompetition2024 && !isAlreadyCompetitionReady)
        await handleCompetitionStatusChange()

      pushDataToDataLayer('guide_status_update', {
        guide_id: guideData?.id,
        guide_name: guideData?.name,
        guide_status: isPublished
          ? GuideStatus.Published
          : GuideStatus.ViewableWithLink,
        trigger_url: window.location.href,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onCopy = () => {
    copyToClipboard(
      `${window.location.origin}${getGuideDetailsUrl({
        id: guideData?.id,
        name: guideData?.name,
      })}`
    )
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  const onModalEditOpen = (updatedScrollId: string) => {
    scrollId.current = updatedScrollId
    setIsModalEditOpen(true)
  }

  const onShare = async () => {
    const invalidFields = getGuideDraftInvalidFields(guideData)

    if (!invalidFields?.length) {
      try {
        const { isReady: isAlreadyCompetitionReady } =
          enableCompetition2024 && (await checkCompetitionReady())

        onStepChange(3)
        await updateGuide({
          variables: {
            updateGuideInput: {
              guideId: guideData?.id,
              status: GuideStatus.Published,
            },
          },
        })

        if (enableCompetition2024 && !isAlreadyCompetitionReady)
          await handleCompetitionStatusChange()
      } catch (error) {
        onStepChange(1)
        console.error(error)
      }
    } else {
      onStepChange(2)
    }
  }

  const onStepChange = (updatedStep: number) => setStep(updatedStep)

  const onView = () =>
    navigate(
      getGuideDetailsUrl({
        id: guideData?.id,
        name: guideData?.name,
      })
    )

  if (!guideData?.id) return

  return (
    <>
      <Modal
        className={classNames({
          '[&>h2]:text-center': step === 3,
        })}
        title={modalTitle}
        onDismiss={onDismiss}
      >
        <ModalScrollContents>
          <div className='p-x-2 md:p-x-0 md:p-b-3'>
            {step === 1 && (
              <GuideDraftShareIntro
                guideData={guideData}
                isLoading={isLoading}
                onChange={onChange}
                onCopy={onCopy}
                onShare={onShare}
              />
            )}
            {step === 2 && (
              <GuideDraftShareReview
                guideData={guideData}
                isLoading={isLoading}
                onModalEditOpen={onModalEditOpen}
                onModalImagesEditOpen={() => setIsModalImagesEditOpen(true)}
                onShare={onShare}
              />
            )}
            {step === 3 && <GuideDraftShareSuccess guideData={guideData} />}
          </div>
        </ModalScrollContents>
        {step === 3 && (
          <ModalActions>
            <Button autoFocus={true} onClick={onView}>
              View Guide
            </Button>
            <Button startIcon='contentCopy' variant='outlined' onClick={onCopy}>
              Copy Link
            </Button>
          </ModalActions>
        )}
      </Modal>
      {isModalEditOpen && (
        <GuideDraftEditModal
          guideData={guideData}
          scrollId={scrollId?.current}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
      {isModalImagesEditOpen && (
        <GuideDraftImagesEditModal
          guideDraftImagesEditData={{
            addresses: guideData?.addresses,
            images,
            id: guideData?.id,
          }}
          onDismiss={() => setIsModalImagesEditOpen(false)}
        />
      )}
    </>
  )
}

import type { KeyboardEvent } from 'react'
import { useState } from 'react'
import type { FetchResult } from '@apollo/client'
import {
  Button,
  Input,
  KeyCode,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import type { CreatePublishedEventCategoryMutationInGuideDraftMutation } from 'src/__generated__/graphql'
import { onGuideAutoSaveStateChange } from 'src/pages/guides/details/useGuideAutoSave'
import {
  guideDraftSectionDescriptionMaxLength,
  guideDraftSectionNameMaxLength,
} from '../../guideDraftConstants'
import { useCreateGuideDraftEventCategoryMutation } from '../../useCreateGuideDraftEventCategoryMutation'

interface GuideDraftSectionAddModalProps {
  callback?: (
    result: FetchResult<CreatePublishedEventCategoryMutationInGuideDraftMutation>
  ) => void
  guideId: string
  onDismiss: VoidFunction
}

export const GuideDraftSectionAddModal = ({
  callback,
  guideId,
  onDismiss,
}: GuideDraftSectionAddModalProps) => {
  const [createPublishedEventCategory] =
    useCreateGuideDraftEventCategoryMutation()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [description, setDescription] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)
  const [name, setName] = useState('')

  const onSubmit = async () => {
    onGuideAutoSaveStateChange('SAVING')
    const validatedName = name?.trim()
    const validatedDescription = description?.trim()
    setIsInvalid(!validatedName)

    if (validatedName) {
      try {
        const result = await createPublishedEventCategory({
          variables: {
            createCategoryInput: {
              description: validatedDescription,
              name: validatedName,
              guideId: guideId,
            },
          },
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Section created',
        })
        onGuideAutoSaveStateChange('SAVED')
        callback?.(result)
        onDismiss()
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
        onGuideAutoSaveStateChange('DEFAULT')
      }
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event ?? {}

    if (key === KeyCode.ENTER) {
      event.preventDefault()
      onSubmit()
      onDismiss()
    }
  }

  return (
    <Modal size='small' title='Add New Section' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='space-y-4'>
          <Input
            aria-label='Add a name for this section'
            errorText={isInvalid && 'Section name is required'}
            fullWidth={true}
            helperText={`${name.length}/${guideDraftSectionNameMaxLength} characters`}
            isInvalid={isInvalid}
            label='Section Name'
            maxLength={guideDraftSectionNameMaxLength}
            placeholder='e.g. Places to Eat'
            required={true}
            value={name}
            onChange={event => setName(event.target.value)}
            onKeyDown={onKeyDown}
          />
          <TextArea
            aria-label='Add a description for this section'
            helperText={`${description.length}/${guideDraftSectionDescriptionMaxLength} characters`}
            label='Section Description'
            maxLength={guideDraftSectionDescriptionMaxLength}
            value={description}
            onInput={event => setDescription(event.currentTarget.value)}
          />
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Create new section'
          autoFocus={true}
          size='large'
          onClick={onSubmit}
        >
          Save
        </Button>
        <Button
          aria-label='Cancel new section creation'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}

import { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import { Icon } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { ParticipantStatus } from 'src/__generated__/graphql'
import type { FollowersModalType } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { hasUserEnteredPhaseTwo } from 'src/pages/competition/utils/hasUserEnteredPhaseTwo'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { CompetitionProfileDisplay } from './CompetitionProfileDisplay'
import { CompetitionStepsChecker } from './CompetitionStepsChecker'
import { ParticipantReadyHeader } from './ParticipantReadyHeader'
import { StepsCompleted } from './StepsCompleted'
import { AddABioModal } from './modals/AddABioModal'
import { PublishGuidesModal } from './modals/PublishGuidesModal'
import { UploadProfilePictureModal } from './modals/UploadProfilePictureModal'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'
import { DashboardPlaneSVG } from '../DashboardHeader/DashboardPlaneSVG'

interface CompetitionDashboardHeaderProps {
  currentUser: UseGetDashboardQuery['dashboardData']['currentUser']
  isGuidePublishingV3Enabled: boolean
  onModalInfoOpen: VoidFunction
  onScrollIdChange: (updatedScrollId: string) => void
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const CompetitionDashboardHeader = ({
  currentUser,
  isGuidePublishingV3Enabled,
  onModalInfoOpen,
  onScrollIdChange,
  toggleFollowersModal,
}: CompetitionDashboardHeaderProps) => {
  const phase2Flag = useFlag('contestPhase2')
  const disableVoting = useFlag('disableVoting')
  const [searchParams] = useSearchParams()
  const collapsed = searchParams.get('collapsed')
  const { firstName, userProfile } = currentUser ?? {}
  const { competitionCheck, status } = userProfile?.competitionInfo ?? {}
  const isReady = status === ParticipantStatus.Ready
  const { highQualityGuideCount, hasBio, hasProfileImage } =
    competitionCheck ?? {}
  const [isExpanded, setIsExpanded] = useState(!(collapsed == 'false'))
  const [showModal, setShowModal] = useState<
    null | 'picture' | 'guides' | 'bio' | 'video' | 'createGuide'
  >(null)

  const { competitionInfo } = { ...userProfile }
  const { competitions, currentPhase } = {
    ...competitionInfo,
  }
  const hasEnteredPhaseTwo = hasUserEnteredPhaseTwo(competitions, currentPhase)

  const closeModal = () => setShowModal(null)

  const handleOnClick = (id: string) => {
    onScrollIdChange(id)
    onModalInfoOpen()
  }

  const CompetitionBanner = () => {
    if (disableVoting && hasEnteredPhaseTwo) {
      return (
        <ParticipantReadyHeader
          currentUser={currentUser}
          firstName={firstName}
        />
      )
    } else if (disableVoting && !hasEnteredPhaseTwo) {
      return null
    } else if (isReady || phase2Flag) {
      return (
        <ParticipantReadyHeader
          currentUser={currentUser}
          firstName={firstName}
        />
      )
    } else {
      return (
        <>
          <div className='w-42 max-w-42 lg:left--26 absolute hidden lg:top--8 lg:block'>
            <DashboardPlaneSVG />
          </div>
          <StepsCompleted
            competitionCheck={competitionCheck}
            name={firstName}
          />
          {/**MOBILE */}
          <div className='mt-18 space-y-2 lg:hidden'>
            <AlmostDone />
          </div>
          <Accordion
            className='my-5 lg:hidden'
            index={Number(isExpanded)}
            onChange={() => setIsExpanded(prevIsExpanded => !prevIsExpanded)}
          >
            <AccordionItem className='space-y-3 md:space-y-4'>
              <AccordionButton className='b-none w-full cursor-pointer space-y-3 bg-transparent p-0 text-left'>
                <p className='c-white type-button flex flex-row items-center justify-center gap-x-1'>
                  {isExpanded
                    ? 'See Remaining Requirements'
                    : 'Hide Remaining Requirements'}
                  <Icon
                    name={isExpanded ? 'arrowDownIos' : 'arrowUpIos'}
                    size='small'
                  />
                </p>
              </AccordionButton>
              <AccordionPanel>
                <div className='relative space-y-6 pt-5 md:space-y-5'>
                  <CompetitionCheckers
                    handleOnClick={handleOnClick}
                    hasBio={hasBio}
                    hasProfileImage={hasProfileImage}
                    highQualityGuideCount={highQualityGuideCount}
                    setShowModal={setShowModal}
                  />
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {/**DESKTOP */}
          <section className='max-w-250 lg:pt-25 md:pt-15 hidden w-full flex-col gap-y-5 pt-20 lg:ml-20 lg:flex lg:pt-8'>
            <AlmostDone />
            <div className='mt-5 grid grid-cols-1 gap-x-10 gap-y-5 lg:grid-cols-2 last:lg:[&>*]:col-span-2 last:lg:[&>*]:w-1/2 last:lg:[&>*]:justify-self-center'>
              <CompetitionCheckers
                handleOnClick={handleOnClick}
                hasBio={hasBio}
                hasProfileImage={hasProfileImage}
                highQualityGuideCount={highQualityGuideCount}
                setShowModal={setShowModal}
              />
            </div>
          </section>
        </>
      )
    }
  }

  return (
    <header className='lg:h-150 lg:pl-34.5 h-auto space-y-3 bg-[radial-gradient(61.11%_100%_at_50.03%_100%,_#125E5C_26%,_#042E30_100%)] pt-9 lg:pl-9 lg:pr-5 lg:pt-12'>
      <div className='max-w-452 relative mx-5 flex flex-col justify-around lg:mx-auto lg:flex-row'>
        <CompetitionBanner />
      </div>
      <CompetitionProfileDisplay
        currentUser={currentUser}
        toggleFollowersModal={toggleFollowersModal}
        onModalInfoOpen={onModalInfoOpen}
      />
      {showModal == 'bio' && (
        <AddABioModal
          onClick={() => handleOnClick('dashboard-profile-info-about')}
          onClose={closeModal}
        />
      )}
      {showModal == 'guides' && (
        <PublishGuidesModal
          onClick={() => setShowModal('createGuide')}
          onClose={closeModal}
        />
      )}
      {showModal == 'picture' && (
        <UploadProfilePictureModal
          onClick={() => handleOnClick('dashboard-profile-info-header')}
          onClose={closeModal}
        />
      )}
      {isGuidePublishingV3Enabled && showModal == 'createGuide' && (
        <GuideDraftCreateModal
          triggerButton='competition'
          onDismiss={closeModal}
        />
      )}
      {!isGuidePublishingV3Enabled && showModal == 'createGuide' && (
        <GuideDraftCreateModalOld
          triggerButton='competition'
          onDismiss={closeModal}
        />
      )}
    </header>
  )
}

const AlmostDone = () => (
  <>
    <p className='type-h5 c-white'>
      Your chance at $20,000 is just a few steps away!
    </p>
    <p className='type-body-1 c-white'>
      Complete each of the steps below to start collecting votes.
    </p>
  </>
)

const CompetitionCheckers = ({
  hasProfileImage,
  highQualityGuideCount,
  hasBio,
  setShowModal,
  handleOnClick,
}) => (
  <>
    <CompetitionStepsChecker
      helperText='What makes a great profile photo?'
      icon='personOutline'
      isCompleted={hasProfileImage}
      title='Upload A Profile Picture'
      onClick={() => handleOnClick('dashboard-profile-info-header')}
      onHelperTextClick={() => {
        setShowModal('picture')
        pushDataToDataLayer('contest_profile_photo_details_modal', {
          timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
        })
      }}
    />
    <CompetitionStepsChecker
      helperText='What counts as a high-quality Guide?'
      icon='mapOutlined'
      isCompleted={highQualityGuideCount >= 3}
      title={`(${highQualityGuideCount > 3 ? '3' : highQualityGuideCount}/3) Quality Guides Shared Publicly`}
      onClick={() => setShowModal('createGuide')}
      onHelperTextClick={() => {
        pushDataToDataLayer('contest_quality_guide_details_modal', {
          timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
        })
        setShowModal('guides')
      }}
    />
    <CompetitionStepsChecker
      helperText='What should be included?'
      icon='personOutline'
      isCompleted={hasBio}
      title='Add A Bio'
      onClick={() => handleOnClick('dashboard-profile-info-about')}
      onHelperTextClick={() => {
        setShowModal('bio')
        pushDataToDataLayer('contest_bio_details_modal', {
          timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
        })
      }}
    />
  </>
)

import type { MouseEvent } from 'react'
import { useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import type { GuideData } from './types'
import { useLikeGuideMutation } from '../useLikeGuideMutation'

export const useGuideMetricLike = (guideId: GuideData['id']) => {
  const { isAnonymous } = useFirebaseUser()
  const [likeGuide] = useLikeGuideMutation()
  const { addErrorSnack } = useSnackbar()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onGuideLike = debounce(async (event: MouseEvent<HTMLButtonElement>) => {
    if (isAnonymous) {
      dispatch('openSignin', { isCreateAccount: false })
    }

    if (event.target instanceof HTMLDivElement) {
      setIsModalOpen(true)
    } else {
      try {
        likeGuide({
          variables: {
            guideId,
          },
        })
      } catch (error) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }, 100)

  const onIsModalOpenChange = (updatedIsModalOpen: boolean) => {
    setIsModalOpen(updatedIsModalOpen)
  }

  return { isModalOpen, onGuideLike, onIsModalOpenChange }
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const addGuideDraftImagesMutation = gql(`
  mutation AddGuideDraftImagesOld($input: AddGuideDraftImagesInput!) {
    addGuideDraftImages(input: $input) {
      addedImages {
        id
        isFlagged
        url
      }
      errorImages {
        reason
        url
      }
      guideDraft {
        id
        guide {
          id
          paginatedImages(first: 3) {
            edges {
              node {
                id
                source
                url
              }
            }
          }
        }
      }
    }
  }
`)

export const useAddGuideDraftImagesMutation = () =>
  useMutation(addGuideDraftImagesMutation)

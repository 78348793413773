import { useEffect, useState } from 'react'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { AddToGuideModal } from './AddToGuideModal'
import { AddToGuideModalOld } from './AddToGuideModalOld'
import {
  TOGGLE_ADD_TO_GUIDE_MODAL,
  dispatchToggleAddToGuideModal,
} from './dispatchToggleAddToGuideModal'

const AddToGuideModalListener = () => {
  const { isAnonymous } = useFirebaseUser()
  const isPublishingV3Enabled = useFlag('guidePublishingV3')
  const [item, setItem] = useState<AddToItem>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const listen = (event: CustomEvent) => {
      if (isAnonymous) {
        dispatch('openSignin', {
          isCreateAccount: true,
        })
      } else {
        setIsModalOpen(event?.detail?.isModalOpen)
        setItem(event?.detail?.item)
      }
    }

    window?.addEventListener(TOGGLE_ADD_TO_GUIDE_MODAL, listen)
    window?.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        dispatchToggleAddToGuideModal({
          isModalOpen: false,
          item: null,
        })
      }
    })
    return () => {
      window?.removeEventListener(TOGGLE_ADD_TO_GUIDE_MODAL, listen)
      window?.removeEventListener('keydown', e => {
        if (e.key === 'Escape') {
          dispatchToggleAddToGuideModal({
            isModalOpen: false,
            item: null,
          })
        }
      })
    }
  }, [])

  if (!isModalOpen) return null

  return isPublishingV3Enabled ? (
    <AddToGuideModal
      item={item}
      onClose={() =>
        dispatchToggleAddToGuideModal({ isModalOpen: false, item: null })
      }
    />
  ) : (
    <AddToGuideModalOld
      item={item}
      onClose={() =>
        dispatchToggleAddToGuideModal({ isModalOpen: false, item: null })
      }
    />
  )
}

export { AddToGuideModalListener }

import { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type {
  Options,
  RefundType,
  RoomFilters,
} from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import { getRateOptions, checkIsNativeAppUser } from 'src/utils'
import { HotelRoomsApolloLoading } from './HotelRoomsApolloLoading'
import { useHasCugRates } from './useHasCugRates'
import { useHotelRoomsQuery } from './useHotelRoomsQuery'
import { HotelEmpty } from '../../HotelEmpty'
import { HotelRoomsList } from '../HotelRoomsList'
import { getRefundTypes } from '../hotelRoomsUtils'

interface HotelRoomsApolloProps {
  filters: RoomFilters
  setAvailableRefundTypes(refunds: RefundType[]): void
  onSetdisabledOnLoading?(isDisabled: boolean): void
}

export const HotelRoomsApollo = ({
  filters,
  setAvailableRefundTypes,
  onSetdisabledOnLoading,
}: HotelRoomsApolloProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isAnonymous } = useFirebaseUser()
  const [options, setOptions] = useState<Options>(null)
  const [isValid, setIsValid] = useState(true)
  const isNativeAppUser = checkIsNativeAppUser()
  const willSkip = !options
  const { updateLucency } = useLucencyNumber()
  const { hasCugRates } = useHasCugRates(filters, willSkip)
  const { rooms, loading, error } = useHotelRoomsQuery(
    filters,
    options,
    willSkip
  )

  useEffect(() => {
    setOptions(getRateOptions(isAnonymous, isNativeAppUser))
  }, [isAnonymous, isNativeAppUser])

  useEffect(() => onSetdisabledOnLoading(loading), [loading])

  useEffect(() => {
    if (isEmpty(rooms)) setIsValid(false)
    else if (!loading) {
      handlesLucency()
      getAllRefundTypes()
    }
  }, [rooms])

  /**
   * It gets the refund types from all rates
   */
  const getAllRefundTypes = () => {
    const refundTypes = rooms.flatMap(({ rates }) => rates.map(rate => rate))
    //It unifies uniquely the diffrent types. e.g. ['NONE', 'NONE'] becomes ['NONE']
    setAvailableRefundTypes(getRefundTypes(refundTypes))
  }

  const handlesLucency = () => {
    const updatedHotelRoomsData = rooms?.filter(
      ({ images }) => !!images?.length
    )
    setIsValid(!!updatedHotelRoomsData?.length)

    if (updatedHotelRoomsData?.length) {
      // set/update lucency - since it is ordered by price, we are getting the first room/rate
      const lowestRate = parseFloat(rooms[0]?.rates[0]?.nightlyAverage)
      // set it on url to be used on the modal
      searchParams.set('lowestRate', lowestRate.toString())
      setSearchParams(searchParams, { replace: true })

      updateLucency({
        averageOrderValue: lowestRate || 0,
        ratesAvailability: 'available',
        cugAvailability: hasCugRates ? 'available' : 'unavailable',
      })
    } else {
      updateLucency({
        averageOrderValue: 0,
        ratesAvailability: 'unavailable',
        cugAvailability: 'unavailable',
      })
    }
  }

  if (loading || willSkip) return <HotelRoomsApolloLoading />
  if (error || !isValid) {
    if (!!filters?.bedType || !!filters?.refundable) {
      return (
        <HotelEmpty
          subtitle='Try removing some filters.'
          title="We're sorry, there are no rooms with all of those options."
        />
      )
    }

    return (
      <HotelEmpty
        subtitle='Try searching for other dates.'
        title="We're sorry, it looks like there are no rooms available for this hotel."
      />
    )
  }

  return <HotelRoomsList hasCugRates={hasCugRates} hotelRoomsData={rooms} />
}

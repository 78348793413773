import { Button, Icon } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { dispatch } from 'src/common/hooks/useListen'
import { baseUrl } from 'src/constants'
import { GuideLandingPageCard } from '../Guides/GuideLandingPageCard'

const guides = [
  {
    id: 1,
    owner: {
      name: 'Matt McConnell',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HCMKBA99XF0WGBP6HT15APCT/profile_images/9ee307ed-dd09-48c9-89a2-a22bf62f5339',
    },
    title: 'San Diego Restaurants with Amazing Ocean Views',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HCMKBA99XF0WGBP6HT15APCT/guide_images/7bc43b4d-165b-4593-af1f-844ec583565f',
    city: 'San Diego',
    state: 'California',
    href: 'https://www.travelpass.com/guides/01J1DSESM57JZ7226GQWH4W33S?name=San-Diego-Restaurants-with-Amazing-Ocean-Views',
  },
  {
    id: 2,
    owner: {
      name: 'Sam Loveland',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01H52T0NV19K4EHSK27T8AJS70/profile_images/5f5d0465-37f6-4a83-a37e-3cdc240fdbe7',
    },
    title: 'A Weekend in Washington',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01H52T0NV19K4EHSK27T8AJS70/guide_images/2f8988a9-993f-471e-814c-0fa6888f2dbd',
    city: 'Washington',
    state: 'District of Columbia',
    href: 'https://www.travelpass.com/guides/01HZYZN8H4ZXJQNHH2379TPWEV?name=A-Weekend-in-Washington',
  },
  {
    id: 3,
    owner: {
      name: 'Kedrick Morris',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB/profile_images/fe976bf7-c05c-4ac3-80bc-73b042811835',
    },
    title: 'Years of Yosemite Experience',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB/guide_images/c41747f5-d02e-4fc6-91b9-0c4b2d6e81ac',
    city: 'California',
    state: 'United States',
    href: 'https://www.travelpass.com/guides/01J1FTY21WG968HWXNRX1A234X?name=Years-of-Yosemite-Experience',
  },
]

export const JoinTravelpass = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const openSignUpModal = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
    })
  }
  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <div className='bg-forest lg:pb-180px pb-35 w-full pt-10 lg:pt-20'>
        <PageLayoutContainer>
          <div className='job-promotion space-y-6 lg:space-y-8'>
            <h1 className='type-h1 text-warmGrey text-10 md:text-16 lg:text-22 text-balance text-center capitalize'>
              Share your stories with the world.
            </h1>
            <p className='type-subtitle-2 font-400 text-warmGrey mx-a max-w-250 block text-balance text-center'>
              Join a community of travelers like you. Become a trusted voice in
              travel, and get the inside scoop on exploring like a local.
              You&apos;re going to love what you can share and discover.
            </p>
            <button
              className='of-hidden c-forest font-700 text-4 bg-valley/90 border-valley-dark/20 mx-a hover:bg-valley block w-auto appearance-none rounded-full border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-all hover:cursor-pointer hover:shadow-lg'
              onClick={openSignUpModal}
            >
              Create Your Free Account
            </button>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='bg-beach rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px lg:pb-120px pb-25 h-auto w-full pt-3 lg:pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion my-6 space-y-4 md:space-y-6'>
            <h2 className='type-h2 text-center capitalize'>
              Why Join Travelpass?
            </h2>
            <div className='flex w-full flex-col flex-wrap justify-center gap-2 md:flex-row md:justify-center md:gap-6 lg:flex-nowrap'>
              <div className='c-forest-light flex w-full flex-row items-center gap-2 text-balance text-center md:w-1/3 md:flex-col lg:w-1/4 lg:gap-4'>
                <Icon name='postAddIcon' size={isMobile ? 'small' : 'large'} />
                <h3 className='type-h6 text-balance'>
                  Create and share your own travel Guides
                </h3>
              </div>
              <div className='c-forest-light flex w-full flex-row items-center gap-2 text-balance text-center md:w-1/3 md:flex-col lg:w-1/4 lg:gap-4'>
                <Icon
                  name='bookmarkAddedIcon'
                  size={isMobile ? 'small' : 'large'}
                />
                <h3 className='type-h6 text-balance'>
                  Save your favorite travel tips and destinations
                </h3>
              </div>
              <div className='c-forest-light flex w-full flex-row items-center gap-2 text-balance text-center md:w-1/3 md:flex-col lg:w-1/4 lg:gap-4'>
                <Icon name='favorite' size={isMobile ? 'small' : 'large'} />
                <h3 className='type-h6 text-balance'>
                  Inspire other travelers with your travel stories
                </h3>
              </div>
              <div className='c-forest-light flex w-full flex-row items-center gap-2 text-balance text-center md:w-1/3 md:flex-col lg:w-1/4 lg:gap-4'>
                <Icon name='personAdd' size={isMobile ? 'small' : 'large'} />
                <h3 className='type-h6 text-balance'>
                  Connect with a community of travelers like you
                </h3>
              </div>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='bg-beachDark rounded-10 lg:rounded-20 mt--100px z-2 relative h-auto w-full pb-10 pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion flex w-full flex-col items-center justify-between gap-4 lg:flex-row'>
            <div className='my-6 space-y-3 text-center md:space-y-4 lg:text-left'>
              <p className='type-overline text-grey-800 uppercase tracking-widest'>
                Find Some Inspiration
              </p>
              <h2 className='type-h2 capitalize'>
                Explore a few featured guides
              </h2>
              <div className='bg-valley w-30 mx-auto h-1 rounded-full md:h-1.5 lg:mx-0'></div>
            </div>
            <div className='hidden lg:block'>
              <Button
                endIcon='search'
                label='Search Guides'
                size='large'
                variant='filledDark'
                onClick={() => (window.location.href = '/explore')}
              />
            </div>
          </div>
          <div className='flex w-full flex-col items-center gap-4 lg:flex-row'>
            {guides.map(({ id, owner, title, image, city, state, href }) => (
              <div
                key={id}
                className='lg:w-33% w-full px-3 transition-all md:px-10 lg:px-0 lg:hover:-translate-y-2'
              >
                <GuideLandingPageCard
                  city={city}
                  href={href}
                  image={image}
                  owner={owner}
                  state={state}
                  title={title}
                />
              </div>
            ))}
          </div>
          <div className='mt-10 flex justify-center lg:hidden'>
            <Button
              endIcon='search'
              label='Search Guides'
              size='large'
              variant='filledDark'
              onClick={() => (window.location.href = '/explore')}
            />
          </div>
        </PageLayoutContainer>
      </div>

      <div className='bg-beach pt-150px mt--150px z-1 job-promotion relative pb-14'>
        <h2 className='type-h1 text-6 md:text-12 max-w-150 md:max-w-250 mx-a my-10 text-balance text-center capitalize'>
          Always know where to eat, where to stay and what to do.
        </h2>
        <button
          className='of-hidden c-forest font-700 text-4 bg-valley/90 border-valley-dark/20 mx-a hover:bg-valley block w-auto appearance-none rounded-full border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-all hover:cursor-pointer hover:shadow-lg'
          onClick={openSignUpModal}
        >
          Create Your Free Account
        </button>
      </div>
    </>
  )
}

const metaDescription =
  'Join Travelpass. Share your ideas with the world, and join a community of travelers like you.'
const pageName = 'Join Travelpass | Create a Free Account'

import { SkeletonDots } from '@travelpass/design-system'
import type { UserProfile } from 'src/__generated__/graphql'
import { EmptyList } from './EmptyList'
import { FollowerListItem } from './FollowerListItem'

interface FollowersListProps {
  emptyListMessage: string
  isLoading: boolean
  list: Pick<
    UserProfile,
    | 'id'
    | 'accountHandle'
    | 'displayName'
    | 'isFollowed'
    | 'isUserOwner'
    | 'profileImageUrl'
    | 'userId'
  >[]
}

export const FollowersList = ({
  emptyListMessage,
  isLoading,
  list,
}: FollowersListProps) => {
  if (isLoading)
    return (
      <div className='flex flex-col content-center justify-center'>
        <SkeletonDots numberOfDots={3} />
      </div>
    )

  if (!list?.length) return <EmptyList text={emptyListMessage} />

  return (
    <div className='space-y-6'>
      {list?.map((follower, index) => (
        <FollowerListItem key={index} follower={follower} />
      ))}
    </div>
  )
}

import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { ResultsCard } from 'src/common/components'
import { GuideImage } from 'src/pages/guides/details/common'
import type { ProfileOwner } from 'src/pages/profile/types'
import { getEventImage } from 'src/pages/trips/utils'
import {
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGuideAddress,
  getImageResized,
} from 'src/utils'
import { GuideDraftEventCardMenu } from './GuideDraftEventCardMenu'
import type { GuideDraftData, GuideDraftPublishedEvent } from '../../../types'
import type { UseGetGuideDraftQuery } from '../../../useGetGuideDraftQuery'

interface GuideDraftEventCardProps {
  isHovered: boolean
  isSelected: boolean
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onMouseEnter(updatedHoverId: string): void
  onMouseLeave: VoidFunction
  onSelectedIdChange(updatedSelectedId: string): void
  owner: ProfileOwner
  publishedEvent: GuideDraftPublishedEvent
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  paginatedEventCategoryId: string
}

export const GuideDraftEventCard = ({
  isHovered,
  isSelected,
  onMapMarkerCenterChange,
  onMouseEnter,
  onMouseLeave,
  onSelectedIdChange,
  owner,
  publishedEvent,
  paginatedEventCategories,
  paginatedEventCategoryId,
}: GuideDraftEventCardProps) => {
  const { id, images, imageUrl, name, notes, addresses } = publishedEvent ?? {}
  const [address] = addresses ?? []
  const { addressLine1, city, country, state } = address ?? {}
  const addressFormatted = getGuideAddress({
    addressLine1,
    city,
    country,
    state,
  })
  const src = getEventImage(images?.[0]?.url ?? imageUrl)

  const onClick = () => {
    onSelectedIdChange(isSelected ? '' : id)
    onMapMarkerCenterChange({
      lat: getGeocoderLatitude(address?.lat),
      lng: getGeocoderLongitude(address?.long),
    })
  }

  return (
    <div onMouseEnter={() => onMouseEnter(id)} onMouseLeave={onMouseLeave}>
      <ResultsCard
        className={classNames({
          'shadow-1': isSelected || isHovered,
        })}
        onClick={onClick}
      >
        <section
          className={classNames(
            'b-2 b-solid rounded-3 flex w-full flex-row gap-2',
            {
              'b-forestLight bg-mintLight': isSelected,
              'b-grey-300': !isSelected,
            }
          )}
        >
          <div className='min-w-25 w-25 md:min-w-35 md:w-35 pointer-events-none relative'>
            <GuideImage
              alt=''
              className='rounded-3 absolute inset-0 h-full w-full object-cover'
              loading='lazy'
              src={getImageResized({
                imageUrl: src,
                shape: 'square',
                width: 500,
              })}
            />
          </div>
          <div className='p-x-3.5 p-y-3.5 max-w-[calc(100%-100px)] grow space-y-2 md:max-w-[calc(100%-140px)]'>
            <div className='space-y-1'>
              <div className='flex w-full flex-row items-center'>
                <h3 className='type-body-1-medium-desktop line-clamp-1 grow'>
                  {name}
                </h3>
                <GuideDraftEventCardMenu
                  paginatedEventCategories={paginatedEventCategories}
                  paginatedEventCategoryId={paginatedEventCategoryId}
                  publishedEventId={id}
                  onSelectedIdChange={onSelectedIdChange}
                />
              </div>
              <div className='c-orange flex flex-row items-center gap-1'>
                <Icon name='placeOutline' size='small' />
                <p className='c-black type-subtext line-clamp-1 max-h-3.5 grow lg:max-h-3.5'>
                  {addressFormatted}
                </p>
              </div>
            </div>
            <div className='min-h-7.5'>
              {!!notes && (
                <div className='p-x-1 flex flex-row gap-2'>
                  <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-5 min-h-5 w-5 min-w-5 cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
                    <GuideImage
                      alt={`Profile image for ${owner?.name}`}
                      className='shadow-1'
                      src={owner?.image}
                    />
                  </div>
                  <p
                    className='color-forest type-small-text line-clamp-2 w-full'
                    style={{ wordBreak: 'break-word' }}
                  >
                    {notes}
                  </p>
                </div>
              )}
              {!notes && (
                <p className='color-new-forest type-button-mobile flex flex-row items-center gap-1'>
                  <Icon name='stickyNote2' size='small' />
                  <span className='line-clamp-1'>Add note</span>
                </p>
              )}
            </div>
          </div>
        </section>
      </ResultsCard>
    </div>
  )
}

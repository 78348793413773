import { useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  SkeletonDots,
  Tile,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import {
  ParticipantStatus,
  type GetCurrentUserQueryInDashboardQuery,
} from 'src/__generated__/graphql'
import { useFlag, useUpsertCurrentUserProfileMutation } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getProfileOwnerUrl } from 'src/utils'
import { useGetDashboardBannerImagesQuery } from '../../useGetDashboardBannerImagesQuery'
import { useLazyUserCompetitionStatus } from '../../useUserCompetitionStatus'

interface DashboardProfileBannerModalProps {
  onDismiss: VoidFunction
  userProfile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
}

export const DashboardProfileBannerModal = ({
  onDismiss,
  userProfile,
}: DashboardProfileBannerModalProps) => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const [getCompetitionStatus] = useLazyUserCompetitionStatus()
  const { bannerImagesData, isLoading, hasError } =
    useGetDashboardBannerImagesQuery()
  const [updateProfileInfo, updateProfileInfoResult] =
    useUpsertCurrentUserProfileMutation()
  const { addSuccessSnack } = useSnackbar()
  const [selectedId, setSelectedId] = useState(
    userProfile?.activeBannerImage?.id
  )
  const { profileImageSource, profileImageUrl, tags } = userProfile ?? {}

  const handleCompetitionStatusChange = async () => {
    const { data: { currentUser } = {} } = await getCompetitionStatus()
    const { userProfile, firstName, lastName } = currentUser
    const isReady =
      userProfile?.competitionInfo?.status === ParticipantStatus.Ready

    if (isReady) {
      pushDataToDataLayer('competition_ready', {
        user_id: userProfile.userId,
        status: userProfile.competitionInfo.status,
        first_name: firstName,
        last_name: lastName,
        profile_url: `${window.location.origin}${getProfileOwnerUrl(userProfile?.accountHandle)}`,
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
    }
  }

  const onSubmit = async () => {
    if (selectedId) {
      try {
        await updateProfileInfo({
          variables: {
            input: {
              userProfileRequest: {
                bannerImageId: selectedId,
                profileImageSource,
                profileImageUrl,
                profileTagIds: tags?.map(tag => tag?.id) || [],
              },
            },
          },
        })
        addSuccessSnack({
          title: 'Profile updated!',
        })
        if (enableCompetition2024) handleCompetitionStatusChange()
        onDismiss()
      } catch (error) {
        console.error(error)
      }
    }
  }

  if (!bannerImagesData?.length || hasError || isLoading || !selectedId) return

  return (
    <Modal title='Edit Banner Image' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 flex flex-col space-y-6 lg:space-y-10'>
          {bannerImagesData?.map(bannerImage => (
            <Tile
              key={bannerImage?.id}
              pressed={selectedId === bannerImage?.id}
              onClick={() =>
                selectedId !== bannerImage?.id && setSelectedId(bannerImage?.id)
              }
            >
              <img
                alt=''
                className='rounded-2 max-h-48 w-full object-cover'
                draggable={false}
                src={bannerImage?.extraSmallUrl}
              />
            </Tile>
          ))}
        </div>
      </ModalScrollContents>
      <ModalActions>
        {updateProfileInfoResult?.loading && (
          <div className='flex h-10 flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        )}
        {!updateProfileInfoResult?.loading && (
          <Button autoFocus={true} onClick={onSubmit}>
            Save Image
          </Button>
        )}
      </ModalActions>
    </Modal>
  )
}

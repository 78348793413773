import { Skeleton } from '@travelpass/design-system'

export const DashboardGuideCardLoading = () => (
  <div className='rounded-3 b-grey-300 of-hidden shadow-3 b-2 b-solid md:p-b-3 p-b-4 space-y-4 p-3 md:flex md:flex-row md:gap-6 md:space-y-0'>
    <div className='md:w-50 md:min-w-50 rounded-3 relative h-44 w-full md:h-auto'>
      <Skeleton variant='rounded' />
      <div className='absolute left-3 top-3 w-fit'>
        <div className='rounded-2 h-7 w-16'>
          <Skeleton />
        </div>
      </div>
    </div>
    <div className='p-x-1 md:p-x-0 w-full space-y-3'>
      <div className='space-y-2'>
        <div className='flex min-h-8 flex-row items-center gap-2 md:min-h-12'>
          <div className='grow'>
            <div className='h-5.25 inline-block w-40 md:h-8'>
              <Skeleton />
            </div>
          </div>
          <div className='flex min-w-fit flex-row items-center gap-4'>
            <div className='w-20.5 hidden h-7 md:block'>
              <Skeleton variant='rounded' />
            </div>
            <div className='h-4 w-4 md:h-6 md:w-6'>
              <Skeleton variant='rounded' />
            </div>
          </div>
        </div>
        <div className='w-29 h-4'>
          <Skeleton />
        </div>
      </div>
      <div className='md:h-13.5 h-9 w-full'>
        <Skeleton />
      </div>
      <div className='h-6.5 md:h-7.5 flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
        <div className='h-4 w-6'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-4 w-6'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-4 w-6'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-4 w-6'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    </div>
  </div>
)

import { Divider, useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type { FollowersModalType } from 'src/common/components'
import { VideoPreview } from 'src/common/components'
import { CopyReferralLinkButton } from 'src/common/components/CopyReferralLinkButton/CopyReferralLinkButton'
import { useFlag } from 'src/common/hooks'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import { ProfileCompetitionVoteCta } from 'src/pages/profile/common'
import { BioSection } from './BioSection'
import { FollowersSection } from './FollowersSection'
import { GeneralLinks } from './GeneralLinks'
import { ProfileActionButtons } from './ProfileActionButtons'
import { ProfileAvatarAndHandle } from './ProfileAvatarAndHandle'
import { SocialLinks } from './SocialLinks'
import { Tags } from './Tags'
import { BragMetricsOld } from '../content-section/BragMetricsOld'
import { ProfileStatistics } from '../content-section/ProfileStatistics'
import type { GeneralLinksType, ProfileFields, SocialLinkType } from '../types'

interface ProfileInformationSectionProps {
  guidesCount: number
  profile: ProfileFields
  toggleModal?: (viewType: FollowersModalType) => void
}

export const ProfileInformationSection = ({
  guidesCount,
  profile,
  toggleModal,
}: ProfileInformationSectionProps) => {
  const { data: userData } = useUserProfileQuery()
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const { isMobileOrTablet } = useScreenQuery()
  //Others
  const {
    bio,
    links,
    socialLinks,
    displayName,
    accountHandle,
    isUserOwner,
    profileImage: { url: profileImageUrl } = { url: undefined },
    bragStates,
    bragCountries,
    bragContinents,
    tags,
    introVideoEmbed,
  } = profile || {}
  const dividerBaseRule = 'w-21 m-x-auto'
  const isBragMetricsOnlyChild =
    !bio &&
    (!socialLinks || isEmpty(socialLinks)) &&
    (!links || isEmpty(links)) &&
    (!tags || isEmpty(tags))

  const showVoteForCta = enableCompetition2024

  return (
    <div
      className={classNames({
        'my-5 h-fit w-full': isMobileOrTablet,
        'w-60 min-w-60': !isMobileOrTablet,
      })}
    >
      <ProfileAvatarAndHandle
        accountHandle={accountHandle}
        displayName={displayName}
        profileImageUrl={profileImageUrl}
      />
      <section className='flex flex-col space-y-6 pt-4'>
        {showVoteForCta && isMobileOrTablet && <ProfileCompetitionVoteCta />}
        {isMobileOrTablet && (
          <ProfileStatistics
            guidesCount={guidesCount}
            profile={profile}
            toggleModal={toggleModal}
          />
        )}
        <ProfileActionButtons isUserOwner={isUserOwner} profile={profile} />
        {isUserOwner && (
          <CopyReferralLinkButton
            id={userData?.currentUser?.id}
            userRoles={userData?.currentUser?.userRoles}
          />
        )}
      </section>
      <section className='rounded-b-5 flex flex-col gap-y-6 pt-6'>
        {isMobileOrTablet && (
          <div className='space-y-6'>
            <VideoPreview embedVideoLink={introVideoEmbed} />
            <BioSection bio={bio} textCenter={isMobileOrTablet} />
          </div>
        )}
        <FollowersSection profile={profile} toggleModal={toggleModal} />
        {socialLinks?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: isEmpty(socialLinks) && !isUserOwner,
              })}
            />
            <SocialLinks
              isUserOwner={isUserOwner}
              socialLinks={socialLinks as SocialLinkType}
            />
          </>
        )}
        {links?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: isEmpty(links) && !isUserOwner,
              })}
            />
            <GeneralLinks
              isUserOwner={isUserOwner}
              links={links as GeneralLinksType}
            />
          </>
        )}
        {tags?.length > 0 && (
          <>
            <Divider
              className={classNames(dividerBaseRule, {
                hidden: !tags || isEmpty(tags),
              })}
            />
            <Tags tags={tags} />
          </>
        )}
        {isMobileOrTablet && (
          <>
            <Divider
              className={`${dividerBaseRule} ${isBragMetricsOnlyChild ? 'hidden' : ''}`}
            />
            <div className='space-y-6'>
              <p className='type-h4 text-center'>I&apos;ve visited</p>
              <BragMetricsOld
                justifyCenter
                metrics={[
                  { label: 'Continents', value: bragContinents },
                  { label: 'Countries', value: bragCountries },
                  { label: 'States', value: bragStates },
                ]}
              />
            </div>
          </>
        )}
      </section>
    </div>
  )
}

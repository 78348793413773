import { useState } from 'react'
import { Divider, Link } from '@travelpass/design-system'
import type { GuideDraftDataOld } from 'src/pages/guides/lists-old'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideHeaderOldContent } from './GuideHeaderOldContent'
import { GuideHeaderOldSummary } from './GuideHeaderOldSummary'
import { GuideHeaderOldTags } from './GuideHeaderOldTags'
import type { GuideData } from '../../types'

interface GuideHeaderOldProps {
  guideData?: GuideData
  guideDraftData?: GuideDraftDataOld
  owner?: ProfileOwner
}

/** @todo tests */
export const GuideHeaderOld = ({
  guideData,
  guideDraftData,
  owner,
}: GuideHeaderOldProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onIsExpandedChange = () => {
    setIsExpanded(!isExpanded)
  }

  if (!guideData && !guideDraftData) return

  if (guideData) {
    return (
      <header className='p-y-6 space-y-5 md:space-y-6'>
        <GuideHeaderOldContent
          description={guideData?.description}
          guideDraftData={guideData?.guideDraft}
          insertedAt={guideData?.insertedAt}
          isEdit={false}
          name={guideData?.name}
          owner={owner}
          timeZone={guideData?.timeZone}
          updatedAt={guideData?.updatedAt}
        />
        <GuideHeaderOldTags tags={guideData?.tags} />
        <div>
          <Divider />
          <GuideHeaderOldSummary
            addresses={guideData?.addresses}
            collectedCount={guideData?.collectedCount}
            eventCounts={guideData?.eventCounts}
            id={guideData?.id}
            isCollected={guideData?.isCollected}
            isEdit={false}
            likedByCurrentUser={guideData?.likedByCurrentUser}
            name={guideData?.name}
            numberOfLikes={guideData?.numberOfLikes}
            shareCount={guideData?.shareCount}
            viewCount={guideData?.viewCount}
          />
          <Divider />
        </div>
      </header>
    )
  }

  if (isExpanded)
    return (
      <header className='p-y-6 space-y-5 md:space-y-6'>
        <GuideHeaderOldContent
          description={guideDraftData?.description}
          guideDraftData={guideDraftData}
          insertedAt={guideDraftData?.insertedAt}
          isEdit={true}
          isExpanded={isExpanded}
          name={guideDraftData?.name}
          owner={owner}
          timeZone={guideDraftData?.timeZone}
          updatedAt={guideDraftData?.updatedAt}
          onIsExpandedChange={onIsExpandedChange}
        />
        <div className='space-y-5 md:space-y-6'>
          <GuideHeaderOldTags tags={guideDraftData?.tags} />
          <div>
            <Divider />
            <GuideHeaderOldSummary
              addresses={guideDraftData?.addresses}
              collectedCount={guideDraftData?.guide?.collectedCount}
              eventCounts={guideDraftData?.eventCounts}
              id={guideDraftData?.id}
              isCollected={guideDraftData?.guide?.isCollected}
              isEdit={true}
              likedByCurrentUser={guideDraftData?.guide?.likedByCurrentUser}
              name={guideDraftData?.name}
              numberOfLikes={guideDraftData?.guide?.numberOfLikes}
              shareCount={guideDraftData?.guide?.shareCount}
              viewCount={guideDraftData?.guide?.viewCount}
            />
            <Divider />
          </div>
        </div>
        <div className='flex flex-row justify-center lg:hidden'>
          <Link
            endIcon={isExpanded ? 'arrowUpIos' : 'arrowDownIos'}
            onClick={onIsExpandedChange}
          >
            Guide Details
          </Link>
        </div>
      </header>
    )

  return (
    <div className='p-y-6'>
      <GuideHeaderOldContent
        description={guideDraftData?.description}
        guideDraftData={guideDraftData}
        insertedAt={guideDraftData?.insertedAt}
        isEdit={true}
        isExpanded={isExpanded}
        name={guideDraftData?.name}
        owner={owner}
        timeZone={guideDraftData?.timeZone}
        updatedAt={guideDraftData?.updatedAt}
        onIsExpandedChange={onIsExpandedChange}
      />
      <div className='flex flex-row justify-center lg:hidden'>
        <Link
          endIcon={isExpanded ? 'arrowUpIos' : 'arrowDownIos'}
          onClick={onIsExpandedChange}
        >
          Guide Details
        </Link>
      </div>
    </div>
  )
}

import type { FunctionComponent, ReactElement } from 'react'
import { Button, useScreenQuery, useSnackbar } from '@travelpass/design-system'
import { type ExperienceProduct } from 'src/__generated__/graphql'
import { copyToClipboard } from 'src/utils'
import { AddToCollectionsModalListener } from '../Collections/AddToCollectionsModalListener'
import { AddToMenu } from '../Collections/AddToMenu'
import { AddToGuideModalListener } from '../Guides/AddToGuideModalListener'

interface ShareAndSaveButtonsProps {
  className?: string
  customShareButton?: FunctionComponent<{ onShare: VoidFunction }>
  /** will be passed to the AddTo menu as the Popover's trigger */
  customTrigger?: ReactElement
  product?: ExperienceProduct
  /** ID and Name from a hotel, experience, guide or event. */
  item?: AddToItem
  shareAction?: VoidFunction
}

export const ShareAndSaveButtons = ({
  className,
  customShareButton,
  customTrigger,
  item,
  shareAction,
}: ShareAndSaveButtonsProps) => {
  const { isMobileScreen } = useScreenQuery()
  const { addSuccessSnack } = useSnackbar()

  const onShare = () => {
    copyToClipboard(document.location.href)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
    shareAction?.()
  }

  return (
    <div className={`flex gap-4 pb-7 md:pb-8 ${className || ''}`}>
      <AddToMenu customTrigger={customTrigger} item={item} variant='outlined' />
      {customShareButton ? (
        customShareButton({ onShare })
      ) : (
        <Button
          aria-label='Share'
          label='Share'
          size='small'
          startIcon='iosShare'
          variant={isMobileScreen ? 'text' : 'outlined'}
          onClick={() => onShare()}
        />
      )}
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </div>
  )
}

import type { ContextType } from 'react'
import { useRef, useState } from 'react'
import { Divider, IconButton } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller } from 'src/common/components'
import type { UseGetDashboardFeedQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { DashboardFeedSuggestedFollower } from './DashboardFeedSuggestedFollower'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface DashboardFeedSuggestedFollowersProps {
  suggestedFollowersData: UseGetDashboardFeedQuery['dashboardFeedSuggestedFollowersData']
}

export const DashboardFeedSuggestedFollowers = ({
  suggestedFollowersData,
}: DashboardFeedSuggestedFollowersProps) => {
  const apiRef = useRef<ScrollVisibilityApiType>(null)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(false)

  const onUpdate = (api: ScrollVisibilityApiType) => {
    const { isFirstItemVisible, isLastItemVisible } = api
    setIsPrevDisabled(isFirstItemVisible)
    setIsNextDisabled(isLastItemVisible)
  }

  if (!suggestedFollowersData?.length) return

  return (
    <section className='of-hidden rounded-2 b-1 b-solid b-grey-200 relative bg-white p-4 md:p-6'>
      <header className='gap-1'>
        <div className='flex flex-row items-center justify-between'>
          <h4 className='type-h5-desktop type-body-1-medium-mobile c-forest-dark'>
            Suggested Travelers
          </h4>
          {/** @todo remove when IconButton is updated in DES-109 */}
          <div className='[&>button:disabled]:c-grey-500 flex flex-row items-center gap-3 [&>button:disabled]:bg-transparent'>
            <IconButton
              icon='arrowBackIos'
              isDisabled={isPrevDisabled}
              onClick={() => apiRef.current?.scrollPrev()}
            />
            <IconButton
              icon='arrowForwardIos'
              isDisabled={isNextDisabled}
              onClick={() => apiRef.current?.scrollNext()}
            />
          </div>
        </div>
        <p className='type-small-text-desktop type-body-2-mobile c-grey-900 line-clamp-2'>
          Unlock endless trip inspo by connecting with travelers who share your
          travel style.
        </p>
      </header>
      <Divider className='m-b-5 m-t-4' />
      <div className='m-r--4 lg:m-r--6'>
        <Scroller
          allowTouch={true}
          apiRef={apiRef}
          hideScrollBar={true}
          scrollContainerClassName='gap-1.25 md:gap-5'
          onUpdate={onUpdate}
        >
          {suggestedFollowersData?.map((follower, index) => (
            <div key={index}>
              <DashboardFeedSuggestedFollower
                follower={follower?.recommendedUserProfile}
                index={index}
              />
            </div>
          ))}
        </Scroller>
      </div>
    </section>
  )
}

import type { PublishedEvent } from 'src/__generated__/graphql'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideImage } from '../GuideImage'

interface GuideEventCardDescriptionProps {
  notes: PublishedEvent['notes']
  owner: ProfileOwner
}

export const GuideEventCardDescription = ({
  notes,
  owner,
}: GuideEventCardDescriptionProps) => {
  const { image, name } = owner ?? {}

  return (
    !!notes && (
      <div className='p-x-1 flex flex-row gap-2'>
        <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-5 min-h-5 w-5 min-w-5 cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
          <GuideImage
            alt={`Profile image for ${name}`}
            className='shadow-1'
            src={image}
          />
        </div>
        <p
          className='color-forest type-body-2 line-clamp-4 w-full'
          style={{
            wordBreak: 'break-word',
          }}
        >
          {notes}
        </p>
      </div>
    )
  )
}

import { useRef } from 'react'
import { Icon } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { ResultsCard } from 'src/common/components'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getGuideAddress, getGuideDetailsUrl, getImageResized } from 'src/utils'
import { DashboardGuideCardMetrics } from './DashboardGuideCardMetrics'
import { DashboardGuideCardPopoverMenu } from './DashboardGuideCardPopoverMenu'
import { GuideBadge, GuideImage } from '../../../guides/details/common'
import type { DashboardGuideData } from '../../types'

interface DashboardGuideCardProps {
  guideData: DashboardGuideData
}

export const DashboardGuideCard = ({ guideData }: DashboardGuideCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const {
    addresses,
    description,
    id,
    name,
    ownerProfile,
    paginatedImages,
    status,
    strippedId,
  } = guideData ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const address = getGuideAddress({
    city,
    country,
    state,
  })
  const href = getGuideDetailsUrl({
    id: strippedId,
    name,
  })
  const image = paginatedImages?.edges?.[0]?.node?.url
  const url = getGuideDetailsUrl({
    id: strippedId,
    name,
  })

  const onCardClick = () => {
    pushDataToDataLayer('guide_card_click', {
      guide_id: id,
      guide_name: name,
      ownerProfile_id: ownerProfile?.id,
      trigger_url: window.location.href,
    })
  }

  return (
    <ResultsCard href={url} onClick={onCardClick}>
      <section className='rounded-3 b-grey-300 of-hidden shadow-3 b-2 b-solid md:p-b-3 p-b-4 space-y-4 p-3 md:flex md:flex-row md:gap-6 md:space-y-0'>
        <div className='md:w-50 md:min-w-50 pointer-events-none relative h-44 w-full md:h-auto'>
          <GuideImage
            className='rounded-3 absolute inset-0 h-full w-full object-cover'
            draggable={false}
            src={getImageResized({
              imageUrl: image,
              shape: 'landscape',
              width: 1000,
            })}
          />
          <div className='absolute left-3 top-3 w-fit md:hidden'>
            <GuideBadge>
              <Icon
                name={
                  status === GuideStatus.Published
                    ? 'visibility'
                    : 'visibilityOff'
                }
                size='small'
              />
              <span>
                {status === GuideStatus.Published ? 'Public' : 'Private'}
              </span>
            </GuideBadge>
          </div>
        </div>
        <div className='p-x-1 md:p-x-0 w-full space-y-3'>
          <div className='space-y-2'>
            <div className='flex flex-row items-center gap-2'>
              <Link
                className='outline-offset-5 focus-visible:outline-emerald type-h3 lg:type-h4 c-forest inline-block grow no-underline'
                ref={anchorRef}
                to={href}
              >
                {name}
              </Link>
              <div className='flex min-w-fit flex-row items-center'>
                <div className='hidden w-fit md:block'>
                  <GuideBadge>
                    <Icon
                      name={
                        status === GuideStatus.Published
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      size='small'
                    />
                    <span>
                      {status === GuideStatus.Published ? 'Public' : 'Private'}
                    </span>
                  </GuideBadge>
                </div>
                <DashboardGuideCardPopoverMenu guideData={guideData} />
              </div>
            </div>
            <div className='[&>i]:c-canyon flex min-h-4 flex-row items-center gap-1'>
              {address && (
                <>
                  <Icon name='placeOutline' size='small' />
                  <span className='type-small-text color-grey-900 line-clamp-1'>
                    {address}
                  </span>
                </>
              )}
            </div>
          </div>
          <p className='md:type-body-2 md:min-h-13.5 type-body-1 line-clamp-2 min-h-9 md:line-clamp-3 [&>button]:gap-1'>
            {description}
          </p>
          <DashboardGuideCardMetrics guideData={guideData} />
        </div>
      </section>
    </ResultsCard>
  )
}

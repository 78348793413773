import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  GuideStatus,
  type GetFeedFromCurrentUserQueryInDashboardQuery,
  type Scalars,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import type { DashboardFeedItem } from './types'

/** @todo replace insertedAt/updatedAt with relevantDate when BE is ready */
export const getFeedFromCurrentUserQuery = gql(`
  query GetFeedFromCurrentUserQueryInDashboard($after: String, $ffGuideOnly: Boolean = false, $first: Int = 10, $includeAll: Boolean = false, $userGuidesArgs: UserGuidesArgs!) {
    currentUser {
      id
      paginatedActivityFeed(after: $after, ffGuideOnly: $ffGuideOnly, first: $first, includeAll: $includeAll) {
        edges {
          node {
            guideItem {
              groupedUpdate
              guide(ffGuideOnly: $ffGuideOnly) {
                id
                addresses {
                  id
                  city
                  country
                  state
                }
                description
                eventCounts {
                  numberEvents
                }
                insertedAt
                imageUrl
                latestUpdates {
                  newEvents {
                    events {
                      id
                      images {
                        id
                        source
                        url
                      }
                      imageUrl
                      name
                    }
                    eventsCount
                  }
                  newImages {
                    images {
                      id
                      source
                      url
                    }
                    imagesCount
                  }
                }
                name
                ownerProfile {
                  id
                  accountHandle
                  displayName
                  isFollowed
                  profileImageUrl
                  userId
                }
                paginatedImages(first: 3) {
                  edges {
                    node {
                      id
                      source
                      url
                    }
                  }
                }
                updatedAt
                timeZone
              }
              relevantDatetime
              type
            }
            title
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      userProfile {
        id
        userRecommendations {
          recommendedUserProfile {
            id
            accountHandle
            displayName
            followersConnection(first: 1, timeRange: 0) {
              totalCount
            }
            user {
              userGuides(first: 1, userGuidesArgs: $userGuidesArgs) {
                totalCount
              }
            }
            isFollowed
            profileImageUrl
            userId
          }
        }
        guideRecommendations(numberOfGuides: 20) {
          id
          addresses {
            id
            city
            country
            state
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
          paginatedImages(first: 1) {
            edges {
              node {
                id
                url
              }
            }
          }
        }
      }
    }
    officialTravelpassGuides(first: 10) {
      edges {
        node {
          id
          addresses {
            id
            city
            country
            state
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
          paginatedImages(first: 1) {
            edges {
              node {
                id
                url
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`)

type UseGetDashboardFeedQuery = {
  dashboardFeedData: DashboardFeedItem[]
  dashboardFeedSuggestedFollowersData: GetFeedFromCurrentUserQueryInDashboardQuery['currentUser']['userProfile']['userRecommendations']
  dashboardFeedSuggestedGuidesData: GetFeedFromCurrentUserQueryInDashboardQuery['currentUser']['userProfile']['guideRecommendations']
  dashboardFeedSuggestedTravelpassGuidesData: GetFeedFromCurrentUserQueryInDashboardQuery['officialTravelpassGuides']['edges']
  data: GetFeedFromCurrentUserQueryInDashboardQuery
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

const useGetDashboardFeedQuery = (
  includeAll = false
): UseGetDashboardFeedQuery => {
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
  } = useQuery(getFeedFromCurrentUserQuery, {
    skip: isGuidePublishingV3Enabled === undefined,
    variables: {
      ffGuideOnly: isGuidePublishingV3Enabled && !includeAll,
      includeAll,
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
    },
  })

  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          currentUser: {
            ...previousResult.currentUser,
            paginatedActivityFeed: {
              ...fetchMoreResult?.currentUser?.paginatedActivityFeed,
              edges:
                previousResult?.currentUser?.paginatedActivityFeed?.edges?.concat(
                  fetchMoreResult?.currentUser?.paginatedActivityFeed?.edges
                ),
            },
          },
        }),
      })
    } catch (error) {
      console.error('Server error')
    }
  }
  const dashboardFeedData =
    data?.currentUser?.paginatedActivityFeed?.edges?.reduce(
      (total, current) => {
        if (current?.node?.guideItem?.guide?.id)
          total.push(current.node.guideItem)

        return total
      },
      [] as DashboardFeedItem[]
    ) ?? []
  const dashboardFeedSuggestedFollowersData =
    data?.currentUser?.userProfile?.userRecommendations
  const dashboardFeedSuggestedGuidesData =
    data?.currentUser?.userProfile?.guideRecommendations
  const dashboardFeedSuggestedTravelpassGuidesData =
    data?.officialTravelpassGuides?.edges

  return {
    data,
    dashboardFeedData,
    dashboardFeedSuggestedFollowersData,
    dashboardFeedSuggestedGuidesData,
    dashboardFeedSuggestedTravelpassGuidesData,
    hasError,
    isLoading,
    onGetMoreResults,
  }
}

export type { UseGetDashboardFeedQuery }
export { useGetDashboardFeedQuery }

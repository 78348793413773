import ReactConfetti from 'react-confetti'
import { useWindowSize } from 'src/common/hooks/useWindowSize'
import { GuideImage } from 'src/pages/guides/details/common'
import { constructAddress } from 'src/utils'
import type { GuideDraftDataOld } from '../../types'

interface GuideDraftShareSuccessProps {
  guideDraftData: GuideDraftDataOld
}

export const GuideDraftShareSuccess = ({
  guideDraftData,
}: GuideDraftShareSuccessProps) => {
  const { height, width } = useWindowSize()
  const { addresses } = guideDraftData ?? {}
  const address = constructAddress({
    addressFirstLine: addresses?.[0]?.addressLine1,
    city: addresses?.[0]?.city,
    state: addresses?.[0]?.state,
  })
  const image = guideDraftData?.paginatedImages?.edges?.[0]?.node?.url ?? ''

  return (
    <>
      <section className='md:p-t-4 md:rounded-3 space-y-4 md:space-y-11'>
        <div className='flex flex-row items-center justify-center gap-1'>
          <img
            alt=''
            className='hidden md:inline-block'
            src='https://static.travelpass.com/assets/job-campaign-2024/confetti-left.svg'
          />
          <div className='h-43 p-x-6 p-y-4 of-hidden rounded-2 w-82 min-w-82 relative flex max-w-full flex-col justify-end gap-1'>
            <GuideImage className='absolute inset-0' src={image} />
            <div className='from-black/58 absolute inset-0 bg-gradient-to-l via-black/30 via-40% to-black/65 to-100%' />
            <h3 className='c-white type-h3-mobile relative'>
              {guideDraftData?.name}
            </h3>
            {address && (
              <p className='c-white type-body-1-medium-mobile relative'>
                {address}
              </p>
            )}
          </div>
          <img
            alt=''
            className='hidden md:inline-block'
            src='https://static.travelpass.com/assets/job-campaign-2024/confetti-right.svg'
          />
        </div>
        <div className='space-y-1 text-center md:space-y-5'>
          <h3 className='c-black type-h4'>Your Guide Is Now Public</h3>
          <p className='c-grey-900 type-body-2-mobile lg:type-subtitle-2-desktop'>
            Thanks for sharing your Guide with the Travelpass community.
          </p>
        </div>
      </section>
      <ReactConfetti
        className='hidden md:block'
        height={height}
        recycle={false}
        width={width}
      />
    </>
  )
}

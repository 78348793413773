import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { useGuideEventMetricLike, useGuideEventMetricShare } from '../..'
import {
  checkIsGuideEventAvailableOnTravelpass,
  getGuideDrawerWebsite,
} from '../../guideUtils'
import { GuideEventSavePopover } from '../GuideEventSavePopover'

interface GuideDrawerFooterProps {
  selectedEvent: PublishedEvent
}

export const GuideDrawerContentFooter = ({
  selectedEvent,
}: GuideDrawerFooterProps) => {
  const isAvailableOnTravelpassEnabled = useFlag('availableOnTravelpass')
  const { id, likedByCurrentUser, name } = selectedEvent ?? {}
  const onGuideEventLike = useGuideEventMetricLike(id)
  const onGuideEventShare = useGuideEventMetricShare(id)
  const isEventAvailable = checkIsGuideEventAvailableOnTravelpass(selectedEvent)

  return (
    <div
      className={classNames(
        'b-grey-400 b-t-1 b-b-1 h-20.5 shadow-1 p-x-9 p-y-5 sticky bottom-0 flex flex-row items-center border-0 border-solid bg-white',
        {
          'justify-between': isEventAvailable && isAvailableOnTravelpassEnabled,
          'justify-end': !isEventAvailable || !isAvailableOnTravelpassEnabled,
        }
      )}
    >
      {isAvailableOnTravelpassEnabled && isEventAvailable && (
        <Button
          size='small'
          onClick={() =>
            window.open(
              getGuideDrawerWebsite({ externalWebsite: '', selectedEvent }),
              '_blank'
            )
          }
        >
          Book Now
        </Button>
      )}
      <div className='flex flex-row items-center gap-3'>
        <GuideEventSavePopover
          id={id}
          name={name}
          trigger={
            <IconButton
              aria-label='Save this Guide event'
              color='white'
              icon='add'
              outline='round'
              size='medium'
            />
          }
        />
        <IconButton
          aria-label='Share this Guide event'
          color='white'
          icon='iosShare'
          outline='round'
          size='medium'
          onClick={onGuideEventShare}
        />
        <IconButton
          aria-label='Like this Guide event'
          aria-pressed={likedByCurrentUser}
          color='white'
          icon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
          outline='round'
          size='medium'
          onClick={onGuideEventLike}
        />
      </div>
    </div>
  )
}

import { ProfileGuideCardLoading } from './ProfileGuideCardLoading'

export const ProfileGuidesLoading = () => (
  <div className='space-y-4' data-testid='ProfileGuidesLoading'>
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
    <ProfileGuideCardLoading />
  </div>
)

import { Button, StopPropagation } from '@travelpass/design-system'
import {
  GuideLikesModal,
  useGuideMetricLike,
  useGuideMetricSave,
  useGuideMetricShare,
} from 'src/pages/guides/details'
import { getGuideMetricString } from 'src/pages/guides/details/guideUtils'
import type { DashboardGuideData } from '../../types'

interface DashboardGuideCardMetricsProps {
  guideData: DashboardGuideData
}

export const DashboardGuideCardMetrics = ({
  guideData,
}: DashboardGuideCardMetricsProps) => {
  const {
    collectedCount,
    id,
    isCollected,
    likedByCurrentUser,
    name,
    numberOfLikes,
    shareCount,
    viewCount,
  } = guideData ?? {}
  const { isModalOpen, onGuideLike, onIsModalOpenChange } =
    useGuideMetricLike(id)
  const onGuideSave = useGuideMetricSave({
    id,
    name,
  })
  const onGuideShare = useGuideMetricShare(id)

  if (!guideData) return

  return (
    <>
      <div className='md:p-t-1 relative w-fit cursor-default'>
        <StopPropagation>
          <div className='[&>div>button]:p-x-0 [&>div>button>i]:color-grey-900 [&>div>button>div]:color-grey-900 [&>div>button>div]:normal-case [&>div>button]:gap-1'>
            <div className='inline-flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
              <Button
                aria-label='Like this Guide'
                aria-pressed={likedByCurrentUser}
                size='small'
                startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
                variant='text'
                onClick={onGuideLike}
              >
                {getGuideMetricString(numberOfLikes, '')}
              </Button>
              <Button
                aria-disabled={true}
                isDisabled={true}
                name='visibility'
                size='small'
                startIcon='visibility'
                variant='text'
              >
                {getGuideMetricString(viewCount, '')}
              </Button>
              <Button
                aria-label='Add this Guide to your collections'
                aria-pressed={isCollected}
                size='small'
                startIcon='addCircleOutline'
                variant='text'
                onClick={onGuideSave}
              >
                {getGuideMetricString(collectedCount, '')}
              </Button>
              <Button
                aria-label='Share this Guide'
                aria-pressed={isCollected}
                size='small'
                startIcon='iosShare'
                variant='text'
                onClick={onGuideShare}
              >
                {getGuideMetricString(shareCount, '')}
              </Button>
            </div>
          </div>
        </StopPropagation>
      </div>
      {isModalOpen && (
        <GuideLikesModal
          guideId={id}
          onClose={() => onIsModalOpenChange(false)}
        />
      )}
    </>
  )
}

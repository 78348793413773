import { useEffect, useRef, useState } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type {
  ProfileByAccountHandleQuery,
  Scalars,
} from 'src/__generated__/graphql'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { getGuideDetailsUrl } from 'src/utils'
import { ProfileGuideCard } from './new-guides/ProfileGuideCard'
import { ProfileGuidesZeroState } from './new-guides/ProfileGuidesZeroState'
import type { UseGetProfileGuidesQueryOld } from '../useGetProfileGuidesQueryOld'

interface ProfileGuidesProps {
  onGetMoreResults: UseGetProfileGuidesQueryOld['onGetMoreResults']
  profile: ProfileByAccountHandleQuery['viewUserProfile']
  profileGuidesData: UseGetProfileGuidesQueryOld['profileGuidesData']
}

export const ProfileGuides = ({
  onGetMoreResults,
  profile,
  profileGuidesData,
}: ProfileGuidesProps) => {
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const fetchMoreCursorRef = useRef('')
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const { endCursor, hasNextPage } = profileGuidesData?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(
    async ({ after }: { after: Scalars['String']['input'] }) => {
      if (isFetchingMore || after === fetchMoreCursorRef.current) return

      setIsFetchingMore(true)
      fetchMoreCursorRef.current = after

      try {
        await onGetMoreResults({
          variables: {
            after,
          },
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetchingMore(false)
      }
    },
    300
  )

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) {
      debouncedGetMoreResults({
        after: endCursor,
      })
    }
  }, [debouncedGetMoreResults, endCursor, hasNextPage, isLastElementVisible])

  if (!profileGuidesData?.edges?.length)
    return (
      <>
        <ProfileGuidesZeroState
          displayName={profile?.displayName}
          isUserOwner={profile?.isUserOwner}
          onClick={() => setIsModalOpen(true)}
        />
        {isGuidePublishingV3Enabled && isModalOpen && (
          <GuideDraftCreateModal
            triggerButton='profile'
            onDismiss={() => setIsModalOpen(false)}
          />
        )}
        {!isGuidePublishingV3Enabled && isModalOpen && (
          <GuideDraftCreateModalOld
            triggerButton='profile'
            onDismiss={() => setIsModalOpen(false)}
          />
        )}
      </>
    )

  return (
    <div className='flex flex-col gap-y-4'>
      {profileGuidesData?.edges?.map(guide => (
        <ProfileGuideCard
          key={guide?.node.id}
          guide={guide?.node}
          href={getGuideDetailsUrl({
            id: guide?.node?.id,
            name: guide?.node?.name,
          })}
        />
      ))}
      {hasNextPage && (
        <div className='invisible m-0 h-0' ref={lastElementRef} />
      )}
      {isFetchingMore && <SkeletonDots />}
    </div>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const updateGuideMutation = gql(`
  mutation UpdateGuideMutationInGuide($updateGuideInput: UpdateGuideInput!) {
    updateGuide(updateGuideInput: $updateGuideInput) {
      guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        firstPublishedAt
        guideDraft {
          id
          hasUnpublishedChanges
        }
        imageUrl
        name
        owner {
          id
        }
        ownerProfile {
          id
          guides {
            id
          }
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        paginatedImages(first: 3) {
          edges {
            node {
              id
              source
              url
            }
          }
        }
        status
        tags {
          id
          name
        }
        updatedAt
      }
    }
  }
`)

export const useUpdateGuideMutation = (willUpdateDataLayer = true) =>
  useMutation(updateGuideMutation, {
    onCompleted: data => {
      if (!willUpdateDataLayer) return

      const {
        addresses,
        description,
        id,
        name,
        owner,
        status,
        tags,
        updatedAt,
      } = data?.updateGuide?.guide ?? {}
      pushDataToDataLayer('guide_update', {
        guide_address: addresses?.[0]?.addressLine1,
        guide_city: addresses?.[0]?.city,
        guide_country: addresses?.[0]?.country,
        guide_description: description,
        guide_draft_id: id,
        guide_id: id,
        guide_state: addresses?.[0]?.state,
        guide_name: name,
        guide_status: status,
        travel_style: tags ?? [],
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })
    },
  })

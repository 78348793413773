import type { UnderlineTabProps } from '@travelpass/design-system'

/**
 * @description
 * Mapping of banner images (provided by the API) to their alt text.
 * The keys are the names of the banners as provided by the API, and the values are the corresponding alt texts.
 */
const profileBannerAltMapping: Record<string, string> = {
  beach_bum: 'A relaxing beach scene with sand, ocean waves, and a blue sky.',
  city_life:
    'A bustling cityscape with skyscrapers, traffic, and urban energy.',
  nature_escape:
    'A tranquil nature scene with trees, a calm river, and mountains in the background.',
  postcards:
    'A collection of colorful postcards with various travel destinations.',
  travel_banner_one:
    'A scenic travel destination with mountains, a lake, and a clear sky.',
  travel_banner_two:
    'An idyllic outdoor destination with a wide horizon, perfect for travelers.',
  wildlife: 'A beautiful wildlife scene with animals in their natural habitat.',
}

const profileTabs: UnderlineTabProps['tabs'] = [
  {
    label: 'Guides',
    onClick: () => {},
  },
]
export { profileBannerAltMapping, profileTabs }

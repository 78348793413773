import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const likeGuideMutation = gql(`
  mutation LikeGuide($guideId: ID!) {
    likeListGuide(guideId: $guideId) {
      id
      addresses {
        id
        addressLine1
        city
        country
        state
      }
      likedByCurrentUser
      name
      numberOfLikes
    }
  }
`)

export const useLikeGuideMutation = () =>
  useMutation(likeGuideMutation, {
    onCompleted: data => {
      const { likeListGuide } = data ?? {}
      const { addresses, id, name } = likeListGuide

      if (likeListGuide?.likedByCurrentUser) {
        pushDataToDataLayer('guide_like', {
          guide_address: addresses?.[0]?.addressLine1,
          guide_city: addresses?.[0]?.city,
          guide_country: addresses?.[0]?.country,
          guide_id: id,
          guide_name: name,
          guide_state: addresses?.[0]?.state,
          trigger_url: window.location.href,
        })
        return
      }
      pushDataToDataLayer('guide_unlike', {
        guide_address: addresses?.[0]?.addressLine1,
        guide_city: addresses?.[0]?.city,
        guide_country: addresses?.[0]?.country,
        guide_id: id,
        guide_name: name,
        guide_state: addresses?.[0]?.state,
        trigger_url: window.location.href,
      })
    },
  })

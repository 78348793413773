import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetViewUserProfileInProfileQuery } from 'src/__generated__/graphql'
import type { HelmetProps } from 'src/common/components/Helmet/Helmet'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfileOwnerUrl,
} from 'src/utils'
import { getProfileMetaData } from './profileUtils'
import type { ProfileOwner } from './types'

const getProfileQuery = gql(`
  query GetViewUserProfileInProfile($accountHandle: String!) {
    currentUser {
      id
    }
    viewUserProfile(accountHandle: $accountHandle) {
      id
      accountHandle
      activeBannerImage {
        id
        mediumUrl
      }
      bio
      bragContinents
      bragCountries
      bragStates
      displayName
      followCount {
        countOfFollowed
        countOfFollowing
      }
      followingUsers {
        id
        accountHandle
      }
      introVideoEmbed
      isFollowed
      isUserOwner
      links {
        title
        url
      }
      profileImageUrl
      socialLinks {
        identifier
        network
      }
      tags {
        id
        name
      }
      userId
    }
  }
`)

/** @todo Move ProfileOwner/utils to profile types */
type UseGetProfileQuery = {
  owner: ProfileOwner
  profileData: GetViewUserProfileInProfileQuery['viewUserProfile']
  profileMetaData: HelmetProps
  hasError: ApolloError
  isLoading: boolean
}

const useGetProfileQuery = (accountHandle: string): UseGetProfileQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getProfileQuery, {
    onCompleted: data => {
      const { accountHandle, id, tags } = data?.viewUserProfile ?? {}
      pushDataToDataLayer('profile_view', {
        account_handle: accountHandle,
        profile_id: id,
        profile_tags: tags,
        profile_url: window.location.href,
        trigger_url: document?.referrer,
        user_id: data?.currentUser?.id,
      })
    },
    skip: !accountHandle,
    variables: {
      accountHandle,
    },
  })
  const profileData = data?.viewUserProfile
  const profileMetaData = getProfileMetaData(profileData)
  const owner: ProfileOwner = {
    id: profileData?.id,
    image: getProfileOwnerImage(profileData?.profileImageUrl),
    isUserOwner: profileData?.isUserOwner,
    name: getProfileOwnerName({
      accountHandle,
      displayName: profileData?.displayName,
    }),
    url: getProfileOwnerUrl(accountHandle),
  }

  return {
    owner,
    profileData,
    profileMetaData,
    hasError,
    isLoading,
  }
}

export { getProfileQuery, useGetProfileQuery }

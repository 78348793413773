import { useId, useState } from 'react'
import { IconButton, Link, ModalNext } from '@travelpass/design-system'
import {
  type HotelReviewsSummaryListHotelReviewsQueryQuery,
  type HotelHeroHeaderAndImagesQuery,
  CollectionItemIdType,
} from 'src/__generated__/graphql'
import { PageLayoutContainer, ReviewBadge } from 'src/common/components'
import { ShareAndSaveButtons } from 'src/common/components/ShareAndSaveButtons'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

interface HotelHeroHeaderProps {
  hotelData: HotelHeroHeaderAndImagesQuery['lodging']
  hotelReviewData: HotelReviewsSummaryListHotelReviewsQueryQuery['listHotelReviews']
}

const CustomShareButton = ({ onShare }: { onShare: VoidFunction }) => (
  <IconButton
    aria-label='Share'
    color='forestLight'
    icon='iosShare'
    outline='round'
    size='medium'
    onClick={onShare}
  >
    Share
  </IconButton>
)

export const HotelHeroHeaderNew = ({
  hotelData,
  hotelReviewData,
}: HotelHeroHeaderProps) => {
  const { name, address, city, country, state, descriptions, id } =
    hotelData ?? {}
  const { averageOverall, totalReviews } = hotelReviewData ?? {}
  const descriptionParagraphs = descriptions?.map(({ text }) => text)

  const descriptionId = useId()
  const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false)
  const hotelEventData = {
    hotel_address: {
      address,
      city,
      country,
      state,
    },
    hotel_id: id,
    hotel_name: name,
  }

  const onReadMoreClick = () => {
    setIsOverviewModalOpen(true)
    pushDataToDataLayer('read_full_overview', hotelEventData)
  }

  const onShare = () => {
    pushDataToDataLayer('share_hotel_details', hotelEventData)
  }

  return (
    <PageLayoutContainer>
      <div className='flex w-full flex-col space-y-3 py-5 max-md:px-3 md:space-y-4 md:py-10'>
        <section className='flex flex-row justify-between'>
          <h1 className='type-h2'>{name}</h1>
          <div className='hidden md:block'>
            <ShareAndSaveButtons
              customShareButton={CustomShareButton}
              customTrigger={
                <IconButton
                  aria-label='Add to'
                  color='forestLight'
                  icon='add'
                  outline='round'
                  size='medium'
                >
                  Save
                </IconButton>
              }
              item={{
                id,
                name,
                type: CollectionItemIdType.Hotel,
              }}
              shareAction={onShare}
            />
          </div>
        </section>
        <div className='type-overline'>
          {[address, city, state].filter(value => value).join(', ')}
        </div>
        {totalReviews > 0 && (
          <ReviewBadge
            colorMap={{
              7: 'bg-tealMedium',
              default: 'bg-gray',
            }}
            customerReviewScore={averageOverall}
            totalCustomerReviews={totalReviews}
          />
        )}
        {descriptionParagraphs?.length && (
          <section className='last:children-self-end md:max-w-1/2 flex flex-col space-y-3 md:space-y-4'>
            <p
              className='color-grey-900 type-body-1 line-clamp-2'
              id={descriptionId}
            >
              {descriptionParagraphs}
            </p>
            <Link
              aria-controls={descriptionId}
              aria-label='Read more content'
              onClick={onReadMoreClick}
            >
              Read More
            </Link>
          </section>
        )}
        <div className='md:hidden'>
          <ShareAndSaveButtons
            customShareButton={CustomShareButton}
            customTrigger={
              <IconButton
                aria-label='Add to'
                color='forestLight'
                icon='add'
                outline='round'
                size='medium'
              >
                Save
              </IconButton>
            }
            item={{
              id,
              name,
              type: CollectionItemIdType.Hotel,
            }}
          />
        </div>
      </div>
      {isOverviewModalOpen && (
        <ModalNext size='medium' onClose={() => setIsOverviewModalOpen(false)}>
          <div className='space-y-8 px-8'>
            <h2 className='type-h2'>{name}</h2>
            {descriptionParagraphs?.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </ModalNext>
      )}
    </PageLayoutContainer>
  )
}

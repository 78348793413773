import { Avatar } from '@travelpass/design-system'
import type { UseGetDashboardFeedQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { FollowButtonOld } from 'src/pages/profile-old/components/FollowButtonOld/FollowButtonOld'
import { getImageResized, getProfilePageUrl } from 'src/utils'

interface DashboardFeedSuggestedFollowerProps {
  follower: UseGetDashboardFeedQuery['dashboardFeedSuggestedFollowersData'][0]['recommendedUserProfile']
  index: number
}

export const DashboardFeedSuggestedFollower = ({
  follower,
  index,
}: DashboardFeedSuggestedFollowerProps) => {
  const {
    accountHandle,
    displayName,
    followersConnection,
    isFollowed,
    profileImageUrl,
    user,
    userId,
  } = follower ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(follower?.accountHandle)}`

  if (!accountHandle) return

  return (
    <div
      className='min-w-35 w-35 flex select-none flex-col items-center gap-3'
      data-testid='DashboardFeedSuggestedFollower'
    >
      <a
        className='no-underline'
        href={ownerUrl}
        rel='noreferrer'
        target='_blank'
      >
        <div className='hidden md:block'>
          <Avatar
            border='sm'
            size='lg'
            src={getImageResized({
              imageUrl: profileImageUrl,
              shape: 'square',
              width: 500,
            })}
          />
        </div>
        <div className='md:hidden'>
          <Avatar
            border='sm'
            size='md'
            src={getImageResized({
              imageUrl: profileImageUrl,
              shape: 'square',
              width: 300,
            })}
          />
        </div>
      </a>
      <div className='flex flex-col items-center space-y-1'>
        <h5 className='type-h6-mobile md:type-h5-desktop text-center'>
          <a
            className='c-forest-light line-clamp-1 no-underline'
            href={ownerUrl}
            rel='noreferrer'
            target='_blank'
          >
            {displayName ?? accountHandle}
          </a>
        </h5>
        <div className='flex flex-row items-center gap-2'>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {followersConnection?.totalCount}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Followers
            </span>
          </p>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {user?.userGuides?.totalCount}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Guides
            </span>
          </p>
        </div>
      </div>
      <FollowButtonOld
        accountHandle={accountHandle}
        displayName={displayName}
        fullWidth={true}
        isFollowed={isFollowed}
        isRecommendedUser={true}
        label={isFollowed ? 'Following' : 'Follow'}
        recommendedUserIndex={index}
        userId={userId}
        variant={isFollowed ? 'outlined' : 'filled'}
      />
    </div>
  )
}

import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import type { HotelReviewsListHotelReviewsQuery } from 'src/__generated__/graphql'
import { formatDate } from 'src/utils'
import { HotelReviewHighlightedText } from '../HotelReviewHighlightedText'

interface HotelReviewComment {
  isHidden: boolean
  title?: string
}

interface HotelReviewV2Props {
  hotelReviewData: HotelReviewsListHotelReviewsQuery['listHotelReviews']['reviews'][0]
  keyword?: string
  fullWidth?: boolean
}

export const HotelReviewV2 = ({
  hotelReviewData,
  keyword,
  fullWidth,
}: HotelReviewV2Props) => {
  const { addedDate, averageRate, name, negativeComments, positiveComments } =
    hotelReviewData ?? {}
  const formatedAddedDate = formatDate(dayjs(addedDate), 'MMM DD, YYYY')
  const comments: HotelReviewComment[] = [
    {
      isHidden: !positiveComments,
      title: positiveComments,
    },
    {
      isHidden: !negativeComments,
      title: negativeComments,
    },
  ]

  const commentsSection = comments.map(
    ({ isHidden, title }, index) =>
      !isHidden && (
        <p key={index} className='color-grey-800 type-body-2 flex gap-1.5'>
          <span className='pt-3px'>
            <HotelReviewHighlightedText keyword={keyword} text={title} />
          </span>
        </p>
      )
  )

  return (
    <div
      className={classNames(
        'mx-5 mb-2 justify-between lg:mx-0 lg:mx-10 lg:mb-0 lg:flex',
        { 'lg:w-90%': fullWidth, 'lg:w-50%': !fullWidth }
      )}
    >
      <p className='type-body-1 c-black lg:max-w-15% mr-1 font-semibold'>
        {name}
      </p>
      <div className='w-80%'>
        <p className='type-body-1 c-black mb-2 flex flex-row items-center gap-x-1 font-semibold'>
          {averageRate}/10{' '}
          <span className='c-canyon'>
            <Icon name='thumbUpAlt' size='small' />
          </span>
          <span className='type-small-text c-grey700'>{formatedAddedDate}</span>
        </p>
        <p className='type-body-2'>{commentsSection}</p>
      </div>
    </div>
  )
}

import { useState } from 'react'
import { Divider, Link } from '@travelpass/design-system'
import classNames from 'classnames'
import type { ProfileOwner } from 'src/pages/profile/types'
import { GuideHeaderContent } from './GuideHeaderContent'
import { GuideHeaderSummary } from './GuideHeaderSummary'
import { GuideHeaderTags } from './GuideHeaderTags'
import type { GuideData } from '../../types'

interface GuideHeaderProps {
  guideData: GuideData
  isEdit: boolean
  owner?: ProfileOwner
}

export const GuideHeader = ({
  guideData,
  isEdit = false,
  owner,
}: GuideHeaderProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onIsExpandedChange = () => {
    setIsExpanded(!isExpanded)
  }

  if (!guideData) return

  if (!isEdit) {
    return (
      <header className='p-y-6 space-y-5 md:space-y-6'>
        <GuideHeaderContent
          guideData={guideData}
          isEdit={false}
          isExpanded={isExpanded}
          owner={owner}
          onIsExpandedChange={onIsExpandedChange}
        />
        <GuideHeaderTags tags={guideData?.tags} />
        <div>
          <Divider />
          <GuideHeaderSummary guideData={guideData} isEdit={false} />
          <Divider />
        </div>
      </header>
    )
  }

  return (
    <header
      className={classNames('p-y-6', {
        'space-y-5 md:space-y-6': isExpanded,
      })}
    >
      <GuideHeaderContent
        guideData={guideData}
        isEdit={true}
        isExpanded={isExpanded}
        owner={owner}
        onIsExpandedChange={onIsExpandedChange}
      />
      {isExpanded && (
        <div className='space-y-5 md:space-y-6'>
          <GuideHeaderTags tags={guideData?.tags} />
          <div>
            <Divider />
            <GuideHeaderSummary guideData={guideData} isEdit={true} />
            <Divider />
          </div>
        </div>
      )}
      <div className='flex flex-row justify-center lg:hidden'>
        <Link
          endIcon={isExpanded ? 'arrowUpIos' : 'arrowDownIos'}
          onClick={onIsExpandedChange}
        >
          Guide Details
        </Link>
      </div>
    </header>
  )
}

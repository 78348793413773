import { Avatar } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import type { UserProfile } from 'src/__generated__/graphql'
import { defaultProfileImage } from 'src/constants'
import { getProfilePageUrl } from 'src/utils'
import { FollowButtonOld } from '../../../pages/profile-old/components/FollowButtonOld/FollowButtonOld'

interface FollowerListItemProps {
  follower: Pick<
    UserProfile,
    | 'id'
    | 'accountHandle'
    | 'displayName'
    | 'isFollowed'
    | 'isUserOwner'
    | 'profileImageUrl'
    | 'userId'
  >
}

export const FollowerListItem = ({ follower }: FollowerListItemProps) => {
  const {
    accountHandle,
    profileImageUrl,
    displayName,
    userId,
    isFollowed,
    isUserOwner,
  } = follower ?? {}
  // for the profile_view GTM event
  const referrer = window.location.href
  const url = getProfilePageUrl(accountHandle)

  if (!follower) return

  return (
    <div className='flex flex-row items-center gap-4'>
      <Link
        className='hidden no-underline md:block'
        state={{ referrer }}
        to={url}
      >
        <Avatar
          border='none'
          size='sm'
          src={profileImageUrl ? profileImageUrl : defaultProfileImage}
        />
      </Link>
      <Link className='no-underline md:hidden' state={{ referrer }} to={url}>
        <Avatar
          border='none'
          size='sm'
          src={profileImageUrl ? profileImageUrl : defaultProfileImage}
        />
      </Link>
      <div className='flex grow flex-row items-center justify-between gap-8'>
        <Link
          className='shrink-1 flex flex-col gap-1 no-underline'
          state={{ referrer }}
          to={url}
        >
          <p className='type-h6-mobile type-h5-desktop line-clamp-1 break-all'>
            {displayName}
          </p>
          <p className='type-body-2 c-gray font-100 line-clamp-1 break-all'>
            {`@${accountHandle}`}
          </p>
        </Link>
        <div className='w-28'>
          {!isUserOwner && (
            <FollowButtonOld
              accountHandle={accountHandle}
              displayName={displayName}
              fullWidth={true}
              isFollowed={isFollowed}
              label={isFollowed ? 'unfollow' : 'follow'}
              userId={userId}
              variant={isFollowed ? 'outlined' : 'filled'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

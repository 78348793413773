import { useState } from 'react'
import {
  IconButton,
  MenuItem,
  Popover,
  useSnackbar,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import {
  useIncrementGuideShareCountMutation,
  useShareGuide,
} from 'src/pages/guides'
import {
  GuideDraftDeleteModalOld,
  GuideDraftEditModalOld,
} from 'src/pages/guides/lists-old/common'
import { getGuideDetailsUrl, getGuideDraftDetailsUrl } from 'src/utils'
import type { UseGetDashboardGuideDraftsQueryOld } from '../../useGetDashboardGuideDraftsQueryOld'

/** @todo replace GuideDraft with GuideDraftData (when pagination is implemented) */
interface DashboardGuideOldCardPopoverMenuProps {
  guideDraftData: Pick<
    UseGetDashboardGuideDraftsQueryOld['dashboardGuideDraftsData']['edges'][0]['node'],
    'guide' | 'id' | 'name' | 'strippedId'
  >
}

export const DashboardGuideOldCardPopoverMenu = ({
  guideDraftData,
}: DashboardGuideOldCardPopoverMenuProps) => {
  const navigate = useNavigate()
  const shareGuide = useShareGuide()
  const [hasShared, setHasShared] = useState(false)
  const [isGuideDraftEditModalOpen, setIsGuideDraftEditModalOpen] =
    useState(false)
  const [isGuideDraftDeleteModalOpen, setIsGuideDraftDeleteModalOpen] =
    useState(false)
  const { id, name, guide, strippedId } = guideDraftData ?? {}
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()

  const onShareGuide = async () => {
    await shareGuide({
      shareUrl: `${window.location.origin}${getGuideDetailsUrl({ id: guide?.id, name })}`,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
      },
    })

    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    <>
      <Popover
        placement='bottom-end'
        trigger={
          <div className='[&>button]:color-grey-800'>
            <IconButton aria-label='Guide Settings' icon='moreHoriz' />
          </div>
        }
      >
        <div className='w-50 p-2'>
          {guideDraftData?.guide?.status === GuideStatus.Published && (
            <MenuItem
              aria-label='Share Guide'
              label='Share'
              startIcon='iosShare'
              value='Share'
              onClick={onShareGuide}
            />
          )}
          <MenuItem
            aria-label='Edit Guide name/description'
            label='Edit'
            startIcon='modeEditOutline'
            value='Edit'
            onClick={() =>
              navigate(
                getGuideDraftDetailsUrl({
                  id: strippedId,
                  isUserOwner: true,
                  name,
                })
              )
            }
          />
          <MenuItem
            aria-label='Delete Guide'
            label='Delete'
            startIcon='deleteOutline'
            value='Delete'
            onClick={() => setIsGuideDraftDeleteModalOpen(true)}
          />
        </div>
      </Popover>
      {/** @todo move modals to DashboardGuidesOld */}
      {isGuideDraftEditModalOpen && (
        <GuideDraftEditModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftEditModalOpen(false)}
        />
      )}
      {isGuideDraftDeleteModalOpen && (
        <GuideDraftDeleteModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftDeleteModalOpen(false)}
        />
      )}
    </>
  )
}

import classNames from 'classnames'
import type { HotelDetailsStandardHotelQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { HotelOverviewDescription } from './HotelOverviewDescription'
import { HotelOverviewNearbyAttractions } from './HotelOverviewNearbyAttractions'
import { HotelOverviewTopAmenities } from './HotelOverviewTopAmenities'

interface HotelOverviewProps {
  hotelDetailsData: HotelDetailsStandardHotelQuery['lodging']
}

export const HotelOverview = ({ hotelDetailsData }: HotelOverviewProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { descriptions, amenities, id, name } = hotelDetailsData ?? {}
  const constructedDescriptions = descriptions?.map(({ text }) => text)

  return (
    <PageLayoutContainer>
      <section
        className={classNames(
          'lg:gap-18 py-10px lg:py-60px grid-items-center grid w-full grid-cols-1 items-baseline gap-8',
          { 'lg:grid-cols-2': !newHotelDetails }
        )}
      >
        {hotelDetailsData && (
          <div className='flex flex-col items-center justify-center'>
            {!newHotelDetails && (
              <HotelOverviewDescription
                descriptions={constructedDescriptions}
              />
            )}
            <HotelOverviewTopAmenities
              amenities={amenities}
              hotelId={id}
              name={name}
            />
          </div>
        )}
        {!newHotelDetails && (
          <HotelOverviewNearbyAttractions
            hotelDetailsData={hotelDetailsData}
            showMap={true}
          />
        )}
      </section>
    </PageLayoutContainer>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const incrementGuideShareCountMutation = gql(`
  mutation IncrementGuideShareCount($guideId: ID!) {
    incrementGuideShareCount(guideId: $guideId) {
      id
      addresses {
        id
        addressLine1
        city
        country
        state
      }
      name
      shareCount
    }
  }
`)

export const useIncrementGuideShareCountMutation = () =>
  useMutation(incrementGuideShareCountMutation, {
    onCompleted: data => {
      const { incrementGuideShareCount } = data ?? {}
      const { addresses, id, name } = incrementGuideShareCount

      pushDataToDataLayer('guide_share', {
        guide_address: addresses?.[0]?.addressLine1,
        guide_city: addresses?.[0]?.city,
        guide_country: addresses?.[0]?.country,
        guide_id: id,
        guide_name: name,
        guide_state: addresses?.[0]?.state,
        trigger_url: window.location.href,
      })
    },
  })

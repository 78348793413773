import { useState } from 'react'
import {
  IconButton,
  MenuItem,
  Popover,
  useSnackbar,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  useIncrementGuideShareCountMutation,
  useShareGuide,
} from 'src/pages/guides'
import {
  GuideDraftDeleteModal,
  GuideDraftEditModal,
} from 'src/pages/guides/lists/common'
import { getGuideDetailsUrl, getGuideDraftDetailsUrl } from 'src/utils'
import type { DashboardGuideData } from '../../types'

interface DashboardGuideCardPopoverMenuProps {
  guideData: DashboardGuideData
}

export const DashboardGuideCardPopoverMenu = ({
  guideData,
}: DashboardGuideCardPopoverMenuProps) => {
  const navigate = useNavigate()
  const shareGuide = useShareGuide()
  const [hasShared, setHasShared] = useState(false)
  const [isGuideDraftEditModalOpen, setIsGuideDraftEditModalOpen] =
    useState(false)
  const [isGuideDraftDeleteModalOpen, setIsGuideDraftDeleteModalOpen] =
    useState(false)
  const { id, name, strippedId } = guideData ?? {}
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()

  const onEditGuide = () => {
    pushDataToDataLayer('guide_edit_click', {
      guide_id: id,
      guide_name: name,
      trigger_url: window.location.href,
    })
    navigate(
      getGuideDraftDetailsUrl({
        id: strippedId,
        isUserOwner: true,
        name,
      })
    )
  }

  const onShareGuide = async () => {
    await shareGuide({
      shareUrl: `${window.location.origin}${getGuideDetailsUrl({
        id,
        name,
      })}`,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
      },
    })

    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    <>
      <Popover
        placement='bottom-end'
        trigger={
          <div className='relative'>
            <div className='[&>button]:color-grey-800 hidden md:block'>
              <IconButton
                aria-label='Guide Settings'
                data-testid='DashboardGuidePopoverMenu-guide-settings-button'
                icon='moreHoriz'
                size='large'
              />
            </div>
            <div className='[&>button]:color-grey-800 md:hidden'>
              <IconButton
                aria-label='Guide Settings'
                icon='moreHoriz'
                size='medium'
              />
            </div>
          </div>
        }
      >
        <div className='w-50 p-2'>
          {guideData?.status === GuideStatus.Published && (
            <MenuItem
              aria-label='Share Guide'
              label='Share'
              startIcon='iosShare'
              value='Share'
              onClick={onShareGuide}
            />
          )}
          <MenuItem
            aria-label='Edit Guide Details'
            label='Edit'
            startIcon='modeEditOutline'
            value='Edit'
            onClick={onEditGuide}
          />
          <MenuItem
            aria-label='Delete Guide'
            label='Delete'
            startIcon='deleteOutline'
            value='Delete'
            onClick={() => setIsGuideDraftDeleteModalOpen(true)}
          />
        </div>
      </Popover>
      {/** @todo move modals to DashboardGuides */}
      {isGuideDraftDeleteModalOpen && (
        <GuideDraftDeleteModal
          guideData={guideData}
          onDismiss={() => setIsGuideDraftDeleteModalOpen(false)}
        />
      )}
      {isGuideDraftEditModalOpen && (
        <GuideDraftEditModal
          guideData={guideData}
          onDismiss={() => setIsGuideDraftEditModalOpen(false)}
        />
      )}
    </>
  )
}

import { useState, useRef, useEffect } from 'react'
import { Carousel, useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import { useFlag } from 'src/common/hooks'
import {
  IMAGE_SIZE_MOBILE,
  IMAGE_SIZE_DESKTOP,
  MINI_IMAGE_SIZE_MOBILE,
  MINI_IMAGE_SIZE_DESKTOP,
} from 'src/pages/hotels/details/constants'
import { HotelModalImagesScroll } from './HotelModalImagesScroll'
import { constructImageLinks } from './hotelModalUtils'

interface HotelModalImagesProps {
  hotelName: string
  hotelImages: string[]
  initialSlideIndex?: number
}

export const HotelModalImages = ({
  hotelName,
  hotelImages,
  initialSlideIndex = 0,
}: HotelModalImagesProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { isMobileScreen } = useScreenQuery()
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [slideIndex, setSlideIndex] = useState(initialSlideIndex)
  const [imageLinks, setImageLinks] = useState(hotelImages)
  const scrollContainerRef = useRef(null)
  const hasSingleImage = imageLinks.length === 1
  const carouselImages = constructImageLinks(
    imageLinks,
    isMobileScreen ? IMAGE_SIZE_MOBILE : IMAGE_SIZE_DESKTOP
  )
  const minifiedImagesList = constructImageLinks(
    imageLinks,
    isMobileScreen ? MINI_IMAGE_SIZE_MOBILE : MINI_IMAGE_SIZE_DESKTOP
  )

  useEffect(() => {
    document
      ?.getElementById(`wheel-images-${slideIndex}`)
      ?.scrollIntoView({ behavior: 'smooth' })
  }, [slideIndex])

  const onSetSlideIndex = (index: number) => setSlideIndex(index)

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }

  const onError = (errorImage: string) =>
    setImageLinks(images => images.filter(image => errorImage !== image))

  const getImagesList = () => (
    <>
      {carouselImages.map((image, index) => (
        <div key={index}>
          <img
            alt={`${hotelName} hero modal carousel ${index + 1}`}
            className='h-[calc(100vh-460px)] w-full object-cover md:h-[calc(100vh-360px)]'
            src={image}
            onError={() => onError}
            onLoad={onLoad}
          />
        </div>
      ))}
    </>
  )

  const getCarousel = () => (
    <div
      className={classNames({
        'md:w-244 grow md:max-w-[calc(100%-180px)]': !newHotelDetails,
      })}
    >
      <Carousel
        disableInfinite
        hideDots
        isLight
        keyBoardControl
        showArrowsOnHover
        beforeChange={nextSlide => setSlideIndex(nextSlide)}
        borderRadius={12}
        hideArrows={hasSingleImage}
        isDisabled={!hasFirstImageLoaded}
        size='small'
        slideIndex={slideIndex}
      >
        {carouselImages.map((image, index) => (
          <img
            key={index}
            alt={`${hotelName} hero modal carousel ${index + 1}`}
            className={classNames('h-[calc(100vh-460px)] w-full object-cover', {
              'md:h-[calc(100vh-360px)]': !newHotelDetails,
              'md:h-[calc(100vh-160px)]': newHotelDetails,
            })}
            src={image}
            onError={() => onError}
            onLoad={onLoad}
          />
        ))}
      </Carousel>
    </div>
  )

  return (
    <div
      className={classNames('', {
        'flex h-auto flex-col gap-4 md:h-[calc(100vh-360px)] md:flex-row md:gap-3':
          !newHotelDetails,
        'overflow-scroll': newHotelDetails,
      })}
    >
      {getCarousel()}
      {newHotelDetails && (
        <div className='flex flex-col md:hidden'>{getImagesList()}</div>
      )}
      <HotelModalImagesScroll
        hotelName={hotelName}
        images={minifiedImagesList}
        scrollContainerRef={scrollContainerRef}
        slideIndex={slideIndex}
        onClick={onSetSlideIndex}
      />
    </div>
  )
}

import { ProfileSearchBarOld } from './ProfileSearchBarOld'

export const ProfileSearchBarOldContainer = () => {
  return (
    <div className='max-w-330 mxa flex w-full justify-end px-5 pt-5'>
      <div className='md:w-33% lg:w-25% w-full'>
        <ProfileSearchBarOld />
      </div>
    </div>
  )
}

import { useRef, useState } from 'react'
import classNames from 'classnames'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { stringToBoolean } from 'src/utils'
import {
  GuideCreateGuideAction,
  GuideEmpty,
  GuideHeaderOld,
  GuideHero,
  GuideLoading,
  GuideMap,
  GuideMapWrapper,
  GuideSections,
  GuideSectionsWrapper,
  GuideStickyTopNavOld,
} from './common'
import { GuideDrawer } from './common/GuideDrawer'
import { GuideHeader } from './common/GuideHeader'
import { GuideStickyTopNav } from './common/GuideStickyTopNav'
import { GuideSearchParam } from './guideConstants'
import { useGetGuideQuery } from './useGetGuideQuery'

export const Guide = () => {
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const guideHeaderRef = useRef(null)
  const isGuideHeaderOnScreen = useIsElementOnScreen(guideHeaderRef)
  const [searchParams] = useSearchParams()
  const [points, setPoints] = useState<PublishedEvent[]>()
  const [selectedId, setSelectedId] = useState('')
  const { guideData, guideMetaData, hasError, isLoading, location, owner } =
    useGetGuideQuery()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView)
  )
  const { eventCounts, id } = guideData ?? {}
  const selectedEvent = points?.find(({ id }) => id === selectedId)

  const onPointsChange = (updatedPoints: PublishedEvent[]) =>
    setPoints(updatedPoints)

  const onSelectedIdChange = (updatedSelectedId: string) =>
    setSelectedId(updatedSelectedId)

  if (isLoading)
    return (
      <GuideLoading
        isGuidePublishingV3Enabled={isGuidePublishingV3Enabled}
        selectedId={selectedId}
      />
    )

  if (hasError || isEmpty(guideData)) return <GuideEmpty />

  /** @todo use only 1 PageLayoutContainer instead of 2 if possible (bg color discrepancy caused this initially) */
  return (
    <>
      {!isEmpty(guideData) && (
        <Helmet
          canonicalUrl={guideMetaData?.canonicalUrl}
          metaDescription={guideMetaData?.metaDescription}
          metaImage={guideMetaData?.metaImage}
          pageName={guideMetaData?.pageName}
        />
      )}
      <article>
        {isGuidePublishingV3Enabled && (
          <GuideStickyTopNav
            guideData={guideData}
            isEdit={false}
            isVisible={!isGuideHeaderOnScreen && !!guideHeaderRef?.current}
            selectedEvent={selectedEvent}
          />
        )}
        {!isGuidePublishingV3Enabled && (
          <GuideStickyTopNavOld
            guideData={guideData}
            guideDraftData={null}
            isVisible={!isGuideHeaderOnScreen && !!guideHeaderRef?.current}
            selectedId={selectedId}
          />
        )}
        <div
          className={classNames({
            'invisible absolute lg:visible lg:static': mapFullView,
          })}
        >
          <GuideHero
            guideData={guideData}
            isEdit={false}
            isGuidePublishingV3Enabled={isGuidePublishingV3Enabled}
          />
        </div>
        <div className='lg:flex lg:flex-row'>
          <GuideSectionsWrapper
            mapFullView={mapFullView}
            selectedId={selectedId}
          >
            <div className='bg-white' ref={guideHeaderRef}>
              <PageLayoutContainer size='none'>
                {isGuidePublishingV3Enabled && (
                  <GuideHeader
                    guideData={guideData}
                    isEdit={false}
                    owner={owner}
                  />
                )}
                {!isGuidePublishingV3Enabled && (
                  <GuideHeaderOld guideData={guideData} owner={owner} />
                )}
              </PageLayoutContainer>
            </div>
            <PageLayoutContainer size='none'>
              <GuideSections
                eventCount={eventCounts?.numberEvents}
                guideId={id}
                owner={owner}
                selectedId={selectedId}
                onPointsChange={onPointsChange}
                onSelectedIdChange={onSelectedIdChange}
              />
              {owner?.isUserOwner && <div className='p-b-5 lg:p-bv-4' />}
            </PageLayoutContainer>
          </GuideSectionsWrapper>
          <GuideDrawer
            className='h-100svh lg:top-0'
            guideId={id}
            isEdit={false}
            owner={owner}
            selectedEvent={selectedEvent}
            onSelectedIdChange={onSelectedIdChange}
          />
          {!isbot(navigator.userAgent) && (
            <GuideMapWrapper
              className='lg:h-100svh h-[calc(100svh-143px)] lg:top-0'
              mapFullView={mapFullView}
              selectedId={selectedId}
            >
              <GuideMap
                isLoading={isLoading}
                location={location}
                owner={owner}
                points={points}
                selectedId={selectedId}
                onSelectedIdChange={onSelectedIdChange}
              />
            </GuideMapWrapper>
          )}
        </div>
        {!owner?.isUserOwner && (
          <div
            className={classNames({
              'invisible absolute lg:visible lg:static': mapFullView,
            })}
          >
            <GuideCreateGuideAction />
          </div>
        )}
      </article>
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </>
  )
}

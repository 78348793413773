import { Avatar } from '@travelpass/design-system'
import { getImageResized } from 'src/utils'
interface ProfileAvatarAndHandleProps {
  accountHandle?: string
  displayName?: string
  profileImageUrl?: string
}

export const ProfileAvatarAndHandle = ({
  accountHandle,
  displayName,
  profileImageUrl,
}: ProfileAvatarAndHandleProps) => (
  <div className='flex flex-col items-center justify-center'>
    <Avatar
      size='xl'
      src={getImageResized({
        imageUrl: profileImageUrl,
        shape: 'square',
        width: 300,
      })}
    />
    <div>
      {(accountHandle || displayName) && (
        <>
          <p className='type-h3 max-w-80 text-wrap break-words text-center'>
            {`@${accountHandle}`}
          </p>
          <p className='type-body-1 text-caption-1 break-all text-center'>
            {displayName}
          </p>
        </>
      )}
    </div>
  </div>
)

import { Skeleton } from '@travelpass/design-system'
import { DashboardFeedCardLoading } from './DashboardFeedCard/DashboardFeedCardLoading'

/** @todo add dropdown */
export const DashboardFeedLoading = () => (
  <div className='space-y-4'>
    <div className='max-w-50 h-12'>
      <Skeleton variant='rounded' />
    </div>
    <DashboardFeedCardLoading />
    <DashboardFeedCardLoading />
    <DashboardFeedCardLoading />
  </div>
)

import dayjs from 'dayjs'
import {
  useForm,
  useController as _useController,
  useWatch as _useWatch,
  useFormContext as _useFormContext,
} from 'react-hook-form'
import type { UseControllerProps } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'

/**
 * Default values for the booking form.
 *
 * This object is also useful for ensuring type correctness with react-hook-form.
 *
 * @examples
 * useController<BookingFormFields>({ name: 'firstName' })
 * useWatch<BookingFormFields>('lastName')
 */
const BOOKING_FORM_VALUES = {
  // Guest information
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  phoneCountry: 'US',
  sendSms: false,
  // Billing information
  // - Credit card fields
  address1: '',
  address2: '',
  cardHolder: '',
  city: '',
  country: 'US',
  cvc: '',
  expiration: '',
  number: '',
  postalCode: '',
  stateProvince: '',
  // - Alternative payments fields
  braintreePayment: null,
}

export type BookingFormFields = typeof BOOKING_FORM_VALUES

type UseBookingControllerProps = UseControllerProps<BookingFormFields>

/** BookingForm type-aware useController */
export const useController = _useController<BookingFormFields>
/** BookingForm type-aware useWatch */
export const useWatch = _useWatch<BookingFormFields>
export const useFormContext = _useFormContext<BookingFormFields>

export const useBookingForm = () => {
  const methods = useForm({
    defaultValues: BOOKING_FORM_VALUES,
    mode: 'onTouched',
  })

  useFormPersist('booking-details-form', {
    watch: methods.watch,
    setValue: methods.setValue,
    storage: window.localStorage,
    exclude: ['cvc', 'expiration', 'number', 'sendSms', 'braintreePayment'],
    dirty: true,
  })

  return methods
}

export const rules = {
  cvc: {
    pattern: {
      message: 'CVV must contain 3 digits.',
      value: /^[0-9]{3}$/,
    },
  },
  cvcAmex: {
    pattern: {
      message: 'CVV must contain 4 digits.',
      value: /^[0-9]{4}$/,
    },
  },
  email: {
    pattern: {
      message:
        'This field must be a valid email address (e.g. fmagellan@example.com).',
      value:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    },
  },
  expiration: {
    pattern: {
      message: 'This field must be a valid expiration date (e.g. 02/24).',
      value: /^1[0-2]|0[1-9]\/?[0-9]{2}$/,
    },
    validate: (value: string) => {
      return (
        dayjs(value, 'MMYY').isAfter(dayjs()) ||
        'The expiration date should be a future date.'
      )
    },
  },
  number: {
    pattern: {
      message: 'This field must have a valid debit/credit card number.',
      value: /^[1-9][0-9]{15}$/,
    },
  },
  numberAmex: {
    pattern: {
      message: 'This field must have a valid debit/credit card number.',
      // https://www.americanexpress.com/content/dam/amex/hk/en/staticassets/merchant/pdf/support-and-services/useful-information-and-downloads/GuidetoCheckingCardFaces.pdf
      value: /^3[74][0-9]{13}$/,
    },
  },
  phone: {
    pattern: {
      message: 'This field must be a valid phone number.',
      value: /^[+0-9]{10,14}$/,
    },
  },
  postalCode: {
    pattern: {
      message: 'This field must have a valid billing ZIP code.',
      value: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/,
    },
  },
  required: 'This field is required.',
}

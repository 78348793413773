import { useEffect, useState, type CSSProperties, type ReactNode } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { Icon, IconButton, useSnackbar } from '@travelpass/design-system'
import classNames from 'classnames'
import { onGuideAutoSaveStateChange } from 'src/pages/guides/details/useGuideAutoSave'
import type { GuideDraftPublishedEvent } from '../../types'
import { useArchiveGuideDraftEventMutation } from '../../useArchiveGuideDraftEventMutation'
import { GuideDraftEventDeleteModal } from '../GuideDraftEventDeleteModal'

interface GuideDraftEventProps {
  children: ReactNode
  isOverlay?: boolean
  isSortingContainer: boolean
  onSelectedIdChange?: (updatedSelectedId: string) => void
  publishedEvent: GuideDraftPublishedEvent
}

export const GuideDraftEvent = ({
  children,
  isOverlay = false,
  isSortingContainer,
  onSelectedIdChange,
  publishedEvent,
}: GuideDraftEventProps) => {
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const { setNodeRef, listeners, isDragging, transform, transition } =
    useSortable({
      id: publishedEvent?.id,
    })
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  useEffect(() => {
    if (!isOverlay) return

    document.body.style.cursor = 'grabbing'

    return () => {
      document.body.style.cursor = ''
    }
  }, [isOverlay])

  const onDelete = async () => {
    try {
      if (!!publishedEvent?.notes && !isModalDeleteOpen) {
        setIsModalDeleteOpen(true)
        return
      }

      onGuideAutoSaveStateChange('SAVING')
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId: publishedEvent?.id,
          },
        },
      })
      setIsModalDeleteOpen(false)
      onSelectedIdChange?.('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
      onGuideAutoSaveStateChange('SAVED')
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
      onGuideAutoSaveStateChange('DEFAULT')
    }
  }

  return (
    <>
      <div
        className={classNames('box-border flex', {
          'animate-[guideDraftPop_500ms_ease]': isDragging,
        })}
        ref={isSortingContainer ? undefined : setNodeRef}
        style={
          {
            animation: isDragging ? 'guideDraftPop 500ms ease' : undefined,
            opacity: isDragging && !isOverlay ? 0.5 : undefined,
            touchAction: 'manipulation',
            transform:
              'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
            transformOrigin: '0 0',
            transition,
            zIndex: isOverlay ? 999 : undefined,
            '--scale': isOverlay ? 1.0 : undefined,
            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
            '--translate-x': transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            '--translate-y': transform
              ? `${Math.round(transform.y)}px`
              : undefined,
          } as React.CSSProperties
        }
      >
        <div
          className={classNames(
            'c-grey-800 rounded-1 focus-visible:shadow-2 group relative box-border flex w-full origin-[50%_50%] list-none flex-row items-center outline-none'
          )}
          style={
            {
              animation: isOverlay
                ? 'guideDraftPop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)'
                : undefined,
              cursor: isOverlay ? 'inherit' : undefined,
              opacity: isOverlay ? 1 : undefined,
              transform: isOverlay
                ? 'scale(var(--scale))'
                : 'scale(var(--scale, 1))',
            } as CSSProperties
          }
        >
          <div
            className='c-forest-dark w-6 min-w-6 cursor-pointer'
            {...listeners}
          >
            <Icon name='draggable' />
          </div>
          <div className='p-r-1 p-l-2 grow'>{children}</div>
          <div className='[&>button]:c-forest-dark'>
            <IconButton
              aria-label='Delete Guide event'
              color='transparent'
              icon='deleteOutline'
              outline='round'
              size='medium'
              onClick={onDelete}
            />
          </div>
        </div>
      </div>
      {isModalDeleteOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsModalDeleteOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}

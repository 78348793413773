import { useEffect, useMemo, useState } from 'react'
import { Carousel, Icon, IconButton, Link } from '@travelpass/design-system'
import type { MappedImage } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelRoomDetailsImage } from './HotelRoomDetailsImage'
import { useHotelDetailsStandardHotel } from '../../../hooks/useHotelDetailsStandardQuery'
import { HotelRoomModal } from '../../HotelRooms-old/HotelRoom/HotelRoomModal'
import { HotelRoomModalNew } from '../HotelRoomModalNew'
import { HotelRoomRates } from '../HotelRoomRates'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'
import { getHotelRoomBedCountWithType } from '../hotelRoomsUtils'

interface HotelRoomDetailsProps {
  room: HotelRoomType
}

export const HotelRoomDetails = ({ room }: HotelRoomDetailsProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { data: hotelDetails } = useHotelDetailsStandardHotel()
  const {
    name,
    images: initialImages,
    beds,
    occupancyType: sleeps,
    rates,
  } = { ...room }

  const [isHotelRoomModalOpen, setIsHotelRoomModalOpen] = useState(false)
  const [images, setImages] = useState([])
  const bedCountWithType = getHotelRoomBedCountWithType(beds)

  useEffect(() => {
    setImages(initialImages.length > 0 ? initialImages : [])
  }, [initialImages])

  const hasBreakfastFree = useMemo(() => {
    const amenities = [...room?.amenities, ...hotelDetails?.amenities]
    return amenities?.some(
      amenity =>
        amenity === 'Breakfast (free)' ||
        amenity === 'Continental Breakfast (free)'
    )
  }, [room, hotelDetails])

  const isNonSmoking = useMemo(() => {
    const amenities = [...room?.amenities, ...hotelDetails?.amenities]
    return amenities?.some(amenity => amenity === 'Non-smoking')
  }, [room, hotelDetails])

  const onHotelRoomModalClose = () => setIsHotelRoomModalOpen(false)

  const onHotelRoomModalOpen = () => setIsHotelRoomModalOpen(true)

  const onHotelRoomModalImagesError = (errorImage: MappedImage) =>
    setImages(images => images.filter(({ href }) => errorImage?.href !== href))

  return (
    <>
      {isHotelRoomModalOpen &&
        (newHotelDetails ? (
          <HotelRoomModalNew
            hotelRoomData={room}
            images={images}
            onClose={onHotelRoomModalClose}
            onImageError={onHotelRoomModalImagesError}
          />
        ) : (
          <HotelRoomModal
            hotelRoomData={room}
            images={images}
            onClose={onHotelRoomModalClose}
            onImageError={onHotelRoomModalImagesError}
          />
        ))}
      <div className='flex flex-col gap-3'>
        <div className='relative'>
          {/* carousel dots are hidden until the design system is updated to allow controlling the number of dots */}
          <Carousel isLight hideArrows={images.length <= 1} hideDots={true}>
            {images.map((image, index) => (
              <HotelRoomDetailsImage
                key={`room-image-${index}`}
                image={image}
                name={name}
              />
            ))}
          </Carousel>
          <div className='shadow-2 rounded-10 absolute bottom-3 right-3 hidden bg-white lg:block'>
            <IconButton
              color='transparent'
              icon='mapExpand'
              onClick={onHotelRoomModalOpen}
            />
          </div>
        </div>
        <h3 className='type-h3 py-1'>{name}</h3>
        <div className='flex gap-5'>
          {isNonSmoking && (
            <div className='flex gap-2 align-middle'>
              <Icon name='smokeFree' />
              Non-smoking
            </div>
          )}
          <div className='flex gap-2 align-middle'>
            <Icon name='personOutline' />
            <div>Sleeps {sleeps || 1}</div>
          </div>
          {bedCountWithType && (
            <div className='flex gap-2 align-middle'>
              <Icon name='bed' />
              {bedCountWithType}
            </div>
          )}
          {hasBreakfastFree && (
            <div className='flex gap-2 align-middle'>
              <Icon name='freeBreakfastOutlined' />
              Breakfast included
            </div>
          )}
        </div>
        <Link label='Room details' onClick={onHotelRoomModalOpen} />
        <HotelRoomRates
          hotelName={hotelDetails?.name}
          rates={rates}
          roomName={name}
        />
      </div>
    </>
  )
}

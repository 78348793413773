import type { ReactNode } from 'react'
import { useState } from 'react'
import type { TripMapPoint } from 'src/pages/trips/types'
import { TripDetailsMapPointsStore } from './TripDetailsMapPointsStore'

export const TripDetailsMapPointsStoreProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [points, setPoints] = useState<TripMapPoint[]>([])

  return (
    <TripDetailsMapPointsStore.Provider
      value={{
        points,
        setPoints,
      }}
    >
      {children}
    </TripDetailsMapPointsStore.Provider>
  )
}

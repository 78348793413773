import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const addGuideImagesMutation = gql(`
  mutation AddGuideImagesMutationInGuideDraft($input: AddGuideImagesInput!) {
    addGuideImages(input: $input) {
      addedImages {
        id
        isFlagged
        url
      }
      errorImages {
        reason
        url
      }
      guide {
        id
        paginatedImages(first: 3) {
          edges {
            node {
              id
              source
              url
            }
          }
        }
      }
    }
  }
`)

export const useAddGuideDraftImagesMutation = () =>
  useMutation(addGuideImagesMutation)

import { Button } from '@travelpass/design-system'
import type { UseGuideAutoSave } from '../useGuideAutoSave'

interface GuideAutoSaveProps {
  state: UseGuideAutoSave['autoSaveState']
}

export const GuideAutoSave = ({ state }: GuideAutoSaveProps) => {
  if (state === 'DEFAULT') return

  return (
    <div className='min-w-19 [&>button]:p-0 [&>button]:normal-case'>
      <Button
        color='transparent'
        size='small'
        startIcon={state === 'SAVING' ? 'save' : 'check'}
        variant='text'
      >
        {state === 'SAVING' ? 'Saving' : 'Saved'}
      </Button>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Icon, Modal, ModalScrollContents } from '@travelpass/design-system'
import classNames from 'classnames'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { HotelReviewsModalCardsApollo } from './HotelReviewsModalCards'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../../hooks/useHotelReviewsSummaryListHotelReviewsQuery'
import { getReviewRatingDescription } from '../../../utils'
import type { HotelReviewsFilterType } from '../HotelReviewsApollo/hotelReviewsApolloConstants'
import { HotelReviewsNav } from '../HotelReviewsNav'
import { HotelReviewsAverages } from '../common/HotelReviewsAverages'
import { HotelReviewsTravelerType } from '../common/HotelReviewsTravelerType'

interface HotelReviewsModalProps {
  onClose(): void
}

export const HotelReviewsModal = ({ onClose }: HotelReviewsModalProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [filters, setFilters] = useState<HotelReviewsFilterType>()
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const {
    averageOverall,
    cleanlinessAverage,
    locationAverage,
    staffAverage,
    totalReviews,
  } = hotelReviewsSummaryData ?? {}
  const greaterThanOrEqualToSeven = Number(averageOverall) >= 7

  const onChange = (updatedFilters: HotelReviewsFilterType) =>
    setFilters(previousFilters => ({
      ...previousFilters,
      ...updatedFilters,
    }))

  useEffect(() => {
    if (filters)
      pushDataToDataLayer('filter_reviews', { filter_option: filters })
  }, [filters])

  return (
    <Modal
      size={newHotelDetails ? 'xl' : 'large'}
      title={newHotelDetails ? 'Reviews' : ''}
      onDismiss={onClose}
    >
      <ModalScrollContents>
        <div
          className={classNames(
            'flex flex-col gap-8 py-8 md:flex-row md:gap-5',
            { 'h-175': !newHotelDetails }
          )}
        >
          <div
            className={classNames(
              'md:min-w-65 flex flex-col gap-8 px-2 md:gap-12 lg:overflow-auto',
              { 'bg-warmGrey rounded-12px py-10 lg:bg-white': newHotelDetails }
            )}
          >
            <div
              className={classNames('flex gap-1', {
                'flex-col': !newHotelDetails,
              })}
            >
              <div
                className={classNames('rd-1 w-fit px-2 py-1 text-center', {
                  'bg-tealMedium': greaterThanOrEqualToSeven,
                  'bg-grey800': !greaterThanOrEqualToSeven,
                })}
              >
                <h3
                  className={classNames('type-h3 c-white', {
                    'gap3 flex': newHotelDetails,
                  })}
                >
                  {`${averageOverall}${newHotelDetails ? '/10' : ''}`}
                  {newHotelDetails ? <Icon name='thumbUpAlt' /> : null}
                </h3>
              </div>
              <div
                className={classNames('flex gap-1', {
                  'items-center': newHotelDetails,
                })}
              >
                <p
                  className={classNames('type-body-1', {
                    'c-tealMedium': greaterThanOrEqualToSeven,
                    'c-grey800': !greaterThanOrEqualToSeven,
                    hidden: newHotelDetails,
                  })}
                >
                  {getReviewRatingDescription(averageOverall)}
                </p>
                <p className={classNames('type-body-1 c-grey800')}>
                  {newHotelDetails
                    ? `${totalReviews} reviews`
                    : `(${totalReviews})`}
                </p>
              </div>
            </div>
            <HotelReviewsAverages
              cleanlinessAverage={cleanlinessAverage}
              locationAverage={locationAverage}
              staffAverage={staffAverage}
            />
            <div
              className={classNames({
                hidden: newHotelDetails,
                'sm:hidden lg:flex': !newHotelDetails,
              })}
            >
              <HotelReviewsTravelerType filters={filters} onChange={onChange} />
            </div>
          </div>
          <div className='bg-grey-300 min-w-1px w-1px hidden md:flex' />
          <div className='flex flex-col gap-12 overflow-auto'>
            <HotelReviewsNav filters={filters} onChange={onChange} />
            <HotelReviewsModalCardsApollo filters={filters} />
          </div>
        </div>
      </ModalScrollContents>
    </Modal>
  )
}

import { Button, Divider, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { ResultsCard, TravelpassLogo } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import type { ProfileOwner } from 'src/pages/profile/types'
import { getEventImage } from 'src/pages/trips/utils'
import { getGuideAddress, getImageResized } from 'src/utils'
import { GuideEventCardDescription } from './GuideEventCardDescription'
import { GuideBadge, GuideEventSavePopover, GuideImage } from '..'
import { useGuideEventMetricLike, useGuideEventMetricShare } from '../..'
import {
  checkIsGuideEventAvailableOnTravelpass,
  getGuideDrawerWebsite,
} from '../../guideUtils'
import type { GuideEvent } from '../../types'
import { onGuideSessionStorageHoverIdChange } from '../../useGuideSessionStorageIds'

interface GuideEventCardProps {
  guideEventCardData: GuideEvent
  isHovered: boolean
  isSelected: boolean
  onSelectedIdChange(updatedSelectedId: string): void
  owner: ProfileOwner
}

export const GuideEventCard = ({
  guideEventCardData,
  isHovered,
  isSelected,
  onSelectedIdChange,
  owner,
}: GuideEventCardProps) => {
  const isAvailableOnTravelpassEnabled = useFlag('availableOnTravelpass')
  const {
    addresses,
    id,
    images,
    imageUrl,
    isCollected,
    likedByCurrentUser,
    name,
    notes,
    publishedEventLikedByUsers,
  } = guideEventCardData ?? {}
  const onGuideEventLike = useGuideEventMetricLike(id)
  const onGuideEventShare = useGuideEventMetricShare(id)
  const { addressLine1, city, country, state } = addresses?.[0] ?? {}
  const addressFormatted = getGuideAddress({
    addressLine1,
    city,
    country,
    state,
  })
  const isEventAvailable =
    checkIsGuideEventAvailableOnTravelpass(guideEventCardData)
  const src = getEventImage(images?.[0]?.url ?? imageUrl)

  const onMouseEnter = () => onGuideSessionStorageHoverIdChange(id)

  const onMouseLeave = () => onGuideSessionStorageHoverIdChange('')

  return (
    <>
      {name && (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <ResultsCard
            className={classNames({
              'shadow-1': isSelected || isHovered,
            })}
            isCollected={isCollected}
            onClick={() => onSelectedIdChange(isSelected ? '' : id)}
          >
            <div
              className={classNames(
                'b-2px b-solid rounded-3 flex w-full flex-col gap-2 md:flex-row',
                {
                  'b-forestLight bg-mintLight': isSelected,
                  'b-grey-300': !isSelected,
                }
              )}
            >
              <div className='min-h-35 md:min-w-45.5 md:w-45.5 pointer-events-none relative w-full md:min-h-0'>
                <GuideImage
                  alt=''
                  className='rounded-3 absolute inset-0 h-full w-full object-cover'
                  loading='lazy'
                  src={getImageResized({
                    imageUrl: src,
                    shape: 'square',
                    width: 500,
                  })}
                />
                {isAvailableOnTravelpassEnabled && isEventAvailable && (
                  <div className='pointer-events-auto absolute right-2 top-3 md:hidden'>
                    <GuideBadge
                      size='small'
                      onClick={() =>
                        window.open(
                          getGuideDrawerWebsite({
                            externalWebsite: '',
                            selectedEvent: guideEventCardData,
                          }),
                          '_blank'
                        )
                      }
                    >
                      <div className='flex flex-row items-center gap-2'>
                        <TravelpassLogo
                          className='w-4'
                          variant='symbol-valley'
                        />
                        <span className='type-body-1-medium'>
                          Available on Travelpass
                        </span>
                      </div>
                    </GuideBadge>
                  </div>
                )}
              </div>
              <div className='p-x-4 p-y-3 pointer-events-none relative grow space-y-3 md:max-w-[calc(100%-198px)]'>
                <div className='space-y-1'>
                  <div className='flex flex-row items-center justify-between'>
                    <h3 className='type-body-1-medium-desktop type-h5-mobile color-forest-dark line-clamp-1'>
                      {name}
                    </h3>
                    <div className='pointer-events-auto'>
                      <Button
                        aria-label='Like this Guide event'
                        aria-pressed={likedByCurrentUser}
                        color='transparent'
                        size='small'
                        startIcon={
                          likedByCurrentUser ? 'favorite' : 'favoriteBlank'
                        }
                        variant='text'
                        onClick={onGuideEventLike}
                      >
                        {publishedEventLikedByUsers?.totalCount?.toString()}
                      </Button>
                    </div>
                  </div>
                  <p className='c-orange flex min-h-4 flex-row items-center gap-1'>
                    {addressFormatted && (
                      <>
                        <Icon name='placeOutline' size='small' />
                        <span className='c-black type-subtext line-clamp-1 max-h-3.5 grow lg:max-h-3.5'>
                          {addressFormatted}
                        </span>
                      </>
                    )}
                  </p>
                </div>
                <div className='md:min-h-18 flex min-h-16 flex-col justify-between'>
                  <GuideEventCardDescription notes={notes} owner={owner} />
                </div>
                <div className='md:hidden'>
                  <Divider />
                </div>
                <div className='flex flex-row items-center justify-between'>
                  <div className='z-3 pointer-events-auto flex gap-2 self-start md:gap-3'>
                    <GuideEventSavePopover
                      id={id}
                      name={name}
                      trigger={
                        <Button
                          aria-label='Save this Guide event'
                          size='small'
                          startIcon='addCircleOutline'
                          variant='text'
                        >
                          Add To
                        </Button>
                      }
                    />
                    <Button
                      aria-label='Share this Guide event'
                      size='small'
                      startIcon='iosShare'
                      variant='text'
                      onClick={onGuideEventShare}
                    >
                      Share
                    </Button>
                  </div>
                  {isAvailableOnTravelpassEnabled && isEventAvailable && (
                    <div className='pointer-events-auto hidden md:block'>
                      <GuideBadge
                        size='small'
                        onClick={() =>
                          window.open(
                            getGuideDrawerWebsite({
                              externalWebsite: '',
                              selectedEvent: guideEventCardData,
                            }),
                            '_blank'
                          )
                        }
                      >
                        <div className='flex flex-row items-center gap-2'>
                          <TravelpassLogo
                            className='w-4'
                            variant='symbol-valley'
                          />
                          <span className='type-body-1-medium'>
                            Available on Travelpass
                          </span>
                        </div>
                      </GuideBadge>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ResultsCard>
        </div>
      )}
    </>
  )
}

import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetGuideDrawerQueryInGuideQuery,
  PublishedEvent,
} from 'src/__generated__/graphql'
import { checkGuideEventIsHotelSearch } from 'src/utils'
import { getGuideDrawerImages } from './guideUtils'

const getGuideDrawerQuery = gql(`
  query GetGuideDrawerQueryInGuide($includePlaceDetails: Boolean = false, $includePublishedEvent: Boolean = false, $placeDetailsRequest: PlaceDetailsRequest!, $placeId: ID!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) @include (if: $includePlaceDetails) {
      address
      city
      country
      countryCode
      description
      googlePlaceId
      imageLinks
      latitude
      longitude
      name
      phoneNumber
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      standardHotelId
      state
      type
      website
    }
    node (id: $placeId) @include(if: $includePublishedEvent) {
      ... on PublishedEvent {
        id
        appearsInGuides(first: 1) {
          totalCount
        }
        experienceProduct {
          images {
            size720x480(limit: 10)
          }
        }
      }
    }
  }
`)

type UseGetGuideDrawerQuery = {
  guideDrawerData: GetGuideDrawerQueryInGuideQuery['getPlaceDetails']
  guideDrawerEventData: Extract<
    GetGuideDrawerQueryInGuideQuery['node'],
    {
      __typename?: 'PublishedEvent'
    }
  >
  guideDrawerImages: string[]
  hasError: ApolloError
  isLoading: boolean
}

const useGetGuideDrawerQuery = (
  selectedEvent: PublishedEvent
): UseGetGuideDrawerQuery => {
  const googlePlaceId = selectedEvent?.addresses?.[0]?.googlePlaceId
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getGuideDrawerQuery, {
    variables: {
      includePlaceDetails: !!googlePlaceId,
      includePublishedEvent: !!selectedEvent?.id,
      placeDetailsRequest: {
        isHotelSearch: checkGuideEventIsHotelSearch(selectedEvent),
        placeId: googlePlaceId ?? '',
      },
      placeId: selectedEvent?.id,
    },
  })
  const guideDrawerData = data?.getPlaceDetails ?? {}
  const guideDrawerEventData =
    data?.node as UseGetGuideDrawerQuery['guideDrawerEventData']
  const guideDrawerImages = getGuideDrawerImages({
    internalImages: selectedEvent?.images,
    externalImages: guideDrawerData?.imageLinks
      ? guideDrawerData?.imageLinks
      : guideDrawerEventData?.experienceProduct?.images?.size720x480,
  })

  return {
    guideDrawerData,
    guideDrawerEventData,
    guideDrawerImages,
    hasError,
    isLoading,
  }
}

export type { UseGetGuideDrawerQuery }
export { useGetGuideDrawerQuery }

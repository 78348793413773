import { forwardRef } from 'react'
import {
  Input as DesignSystemInput,
  Dropdown,
  DropdownOption,
} from '@travelpass/design-system'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { FormCheckbox } from 'src/common/components/FormCheckbox'
import { FormInput } from 'src/common/components/FormInput'
import { useFlag } from 'src/common/hooks'
import { type BookingFormFields, rules } from '../../hooks'
import { useFormContext } from '../../hooks'
import { BookingSendSmsLabel } from '../BookingGuest/BookingSendSmsLabel'

const Input = FormInput<BookingFormFields>

const CustomNumberInput = forwardRef<HTMLInputElement>((props, ref) => {
  // @ts-ignore
  const { className, errorText, loading, defaultValue, ...rest } = props
  return (
    <DesignSystemInput
      fullWidth
      required
      errorText={errorText}
      isDisabled={loading}
      label='Mobile Number'
      ref={ref}
      {...rest}
    />
  )
})
CustomNumberInput.displayName = 'CustomNumberInput'

const CustomCountryInput = props => {
  const { options, loading, iconComponent, ...config } = props
  return (
    <div className='md:max-w-1/3 md:w-full'>
      {/* @ts-ignore */}
      <Dropdown isDisabled={loading} label='Country' {...config}>
        {options.map(option => (
          <DropdownOption key={option?.value} value={option?.value}>
            {option?.label}
          </DropdownOption>
        ))}
      </Dropdown>
    </div>
  )
}

export const GuestInformationFields = ({ loading }: { loading: boolean }) => {
  const checkoutV2 = useFlag('checkoutV2')
  const isBookingSendSmsEnabled = useFlag('isBookingSendSmsEnabled')

  const { formState, setValue, control, register } = useFormContext()
  const { errors } = formState
  const { name } = register('phone')

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-4'>
        <Input
          fullWidth
          isDisabled={loading}
          label='First Name'
          name='firstName'
          placeholder='eg. John'
          required={rules.required}
        />
        <Input
          fullWidth
          isDisabled={loading}
          label='Last Name'
          name='lastName'
          placeholder='eg. Smith'
          required={rules.required}
        />
      </div>
      {checkoutV2 ? (
        <div>
          <PhoneInputWithCountry
            international
            className='flex flex-col md:flex-row'
            control={control}
            countryCallingCodeEditable={false}
            countryOptionsOrder={['US', 'BR']}
            countrySelectComponent={CustomCountryInput}
            countrySelectProps={{
              loading,
            }}
            defaultCountry='US'
            disabled={loading}
            inputComponent={CustomNumberInput}
            name={name}
            numberInputProps={{
              loading,
              errorText: errors?.phone?.message || '',
            }}
            rules={{ required: rules.required }}
            onChange={phoneNumber => setValue('phone', phoneNumber)}
            onCountryChange={countryCode =>
              setValue('phoneCountry', countryCode)
            }
          />
        </div>
      ) : (
        <Input
          isNumericString
          autoComplete='tel'
          format='(###) ###-####'
          isDisabled={loading}
          label='Phone Number'
          name='phone'
          placeholder='(000) 000-0000'
          required={rules.required}
          rules={rules.phone}
          type='tel'
        />
      )}
      <Input
        autoComplete='email'
        isDisabled={loading}
        label='Email Address'
        name='email'
        placeholder='Johnsmith@email.com'
        required={rules.required}
        rules={rules.email}
      />
      {isBookingSendSmsEnabled && (
        <FormCheckbox label={<BookingSendSmsLabel />} name='sendSms' />
      )}
    </div>
  )
}

import { Button, useScreenQuery } from '@travelpass/design-system'
import type { HotelReviewsModalCardsListHotelReviewsQuery } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelLoading } from '../../../HotelLoading'
import { HotelReview } from '../../common/HotelReview'
import { HotelReviewV2 } from '../../common/HotelReview/HotelReviewV2'

interface HotelReviewsModalCardsProps {
  hasMoreResults: boolean
  hotelReviewsModalCardsData: HotelReviewsModalCardsListHotelReviewsQuery['listHotelReviews']
  isMoreResultsLoading: boolean
  onGetMoreResults(): void
  keyword?: string
}

export const HotelReviewsModalCards = ({
  hasMoreResults,
  hotelReviewsModalCardsData,
  isMoreResultsLoading,
  onGetMoreResults,
  keyword,
}: HotelReviewsModalCardsProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { reviews } = hotelReviewsModalCardsData ?? {}
  const { isMobileOrTablet } = useScreenQuery()

  return (
    <div className='flex flex-col gap-10'>
      {reviews.map(hotelReviewData => (
        <>
          {newHotelDetails ? (
            <HotelReviewV2 fullWidth hotelReviewData={hotelReviewData} />
          ) : (
            <HotelReview
              key={hotelReviewData?.id}
              hotelReviewData={hotelReviewData}
              keyword={keyword}
            />
          )}
        </>
      ))}
      {isMoreResultsLoading && <HotelLoading />}
      {hasMoreResults && !newHotelDetails && (
        <div className='flex flex-col items-center'>
          <Button
            fullWidth={isMobileOrTablet}
            label='View More'
            variant='outlined'
            onClick={onGetMoreResults}
          />
        </div>
      )}
    </div>
  )
}

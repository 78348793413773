import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  FollowType,
  GetSearchFollowUserProfilesQueryInFollowersModalQuery,
  GetViewUserProfileQueryInFollowersModalQuery,
} from 'src/__generated__/graphql'

const viewUserProfileQuery = gql(`
  query GetViewUserProfileQueryInFollowersModal($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      displayName
      followingUsers {
        ...FollowUserProfileFields
      }
      followedUsers {
        ...FollowUserProfileFields
      }
      followCount {
        countOfFollowing
        countOfFollowed
      }
    }
  }
`)

const searchFollowUserProfilesQuery = gql(`
  query GetSearchFollowUserProfilesQueryInFollowersModal($searchInput: SearchInput!) {
    searchFollowUserProfiles(searchInput: $searchInput) {
      ...FollowUserProfileFields
    }
  }
`)

interface UseFollowersModalQuery {
  followersData: GetViewUserProfileQueryInFollowersModalQuery['viewUserProfile']
  followersSearchData: GetSearchFollowUserProfilesQueryInFollowersModalQuery['searchFollowUserProfiles']
  isLoading: boolean
  refetch: Function
}

export const useFollowersModalQuery = ({
  searchString,
  followTypeSearch,
  userId,
  accountHandle,
}: {
  searchString: string
  followTypeSearch: FollowType
  userId: string
  accountHandle: string
}): UseFollowersModalQuery => {
  const viewUserProfileResults = useQuery(viewUserProfileQuery, {
    variables: {
      accountHandle,
    },
    skip: !accountHandle,
  })
  const searchFollowUserProfilesResults = useQuery(
    searchFollowUserProfilesQuery,
    {
      variables: {
        searchInput: {
          searchString,
          followTypeSearch,
          userId,
        },
      },
      skip: !searchString || searchString.length < 3,
    }
  )

  return {
    followersData: viewUserProfileResults?.data?.viewUserProfile,
    followersSearchData:
      searchFollowUserProfilesResults?.data?.searchFollowUserProfiles,
    isLoading:
      searchFollowUserProfilesResults?.loading ||
      viewUserProfileResults?.loading,
    refetch: searchFollowUserProfilesResults?.refetch,
  }
}

import { useEffect, useState } from 'react'
import {
  Icon,
  Input,
  Modal,
  ModalScrollContents,
  UnderlineTab,
  useScreenQuery,
} from '@travelpass/design-system'
import { FollowType } from 'src/__generated__/graphql'
import { FollowersList } from './FollowersList'
import type { FollowersModalType } from './types'
import { useFollowersModalQuery } from './useFollowersModalQuery'

const getViewTypeIndex = (viewType: FollowersModalType) => {
  if (viewType === 'followers') return 0

  if (viewType === 'following') return 1

  return 0
}

interface FollowersModalProps {
  accountHandle: string
  onClose(): void
  toggleModal?: (viewType: FollowersModalType) => void
  userId: string
  viewType: FollowersModalType
}

export const FollowersModal = ({
  accountHandle,
  onClose,
  toggleModal,
  userId,
  viewType,
}: FollowersModalProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [searchString, setSearchString] = useState('')
  const [tabIndex, setTabindex] = useState(getViewTypeIndex(viewType))
  const followTypeSearch =
    tabIndex == 0 ? FollowType.Following : FollowType.Followed
  const { followersData, followersSearchData, isLoading, refetch } =
    useFollowersModalQuery({
      searchString,
      followTypeSearch,
      userId,
      accountHandle,
    })
  const tabs = [
    {
      label: `Followers ${followersData?.followCount?.countOfFollowing || 0}`,
      onClick: () => onTabClick('followers', 0),
    },
    {
      label: `Following ${followersData?.followCount?.countOfFollowed || 0}`,
      onClick: () => onTabClick('following', 1),
    },
  ]

  useEffect(() => {
    if (searchString) {
      refetch({
        searchInput: {
          searchString,
          followTypeSearch,
          userId,
        },
      })
    }
  }, [searchString, refetch, tabIndex])

  const getListToDisplay = () => {
    if (searchString.length >= 3) {
      return followersSearchData
    } else if (tabIndex === 0) {
      return followersData?.followingUsers ?? []
    } else {
      return followersData?.followedUsers ?? []
    }
  }

  const onTabClick = (viewType: FollowersModalType, index) => {
    toggleModal(viewType)
    setTabindex(index)
    setSearchString('')
  }

  /** @todo refactor Modal to allow for dynamic ScrollContents like this */
  return (
    <Modal
      data-testid='followers-modal'
      size='medium'
      title={followersData?.displayName}
      onDismiss={onClose}
    >
      <div className='p-b-6 md:p-b-8 min-h-fit space-y-6 overflow-y-hidden md:space-y-8'>
        <div className='lg:w-82.5 m-x-a w-full'>
          <UnderlineTab
            activeIndex={tabIndex}
            fullWidth={true}
            tabs={tabs}
            willScroll={isMobileScreen}
          />
        </div>
        <Input
          aria-label='Search'
          autoFocus={true}
          fullWidth={true}
          placeholder='Search'
          slotBefore={<Icon name='search' />}
          value={searchString}
          onChange={event => setSearchString(event?.target?.value)}
        />
      </div>
      <ModalScrollContents>
        <FollowersList
          emptyListMessage={
            tabIndex == 0
              ? "You don't have followers yet"
              : "You're not following anybody yet"
          }
          isLoading={isLoading}
          list={getListToDisplay()}
        />
      </ModalScrollContents>
    </Modal>
  )
}

import { Skeleton } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import { ProfileGuidesLoading } from './ProfileGuides'
import { ProfileHeaderLoading } from './ProfileHeader'
import { ProfileStatsLoading } from './ProfileStats'

export const ProfileLoading = () => (
  <div className='md:p-b-24 p-b-8' data-testid='ProfileLoading'>
    <div className='bg-grey-900 h-78 relative'>
      <Skeleton />
    </div>
    <PageLayoutContainer>
      <div className='m-t--20 md:m-t--25 relative flex flex-col gap-4 md:flex-row md:gap-24'>
        <aside className='flex flex-col items-center md:w-60 md:min-w-60'>
          {/** @todo might add background-color */}
          <div className='h-50 w-50' />
          <ProfileStatsLoading />
        </aside>
        <div className='md:p-t-8 p-t-20 grow space-y-4'>
          <ProfileHeaderLoading />
          <div className='m-x--2 lg:m-x-0'>
            <div className='h-9.5 md:h-15.75 flex flex-row items-center gap-4'>
              <div className='w-21 h-4'>
                <Skeleton />
              </div>
            </div>
          </div>
          <ProfileGuidesLoading />
        </div>
      </div>
    </PageLayoutContainer>
  </div>
)

import { useEffect, useRef, useState } from 'react'
import {
  DropdownOption,
  Autocomplete,
  KeyCode,
  Icon,
  IconButton,
} from '@travelpass/design-system'
import type { Suggestion } from 'use-places-autocomplete'
import { getDetails } from 'use-places-autocomplete'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { countries } from 'src/constants'
import { states } from 'src/utils'
import { usesAlternateCityDesignation } from 'src/utils/addressUtils'
import type { BookingFormFields } from '../../hooks'
import { rules, useFormContext, useWatch } from '../../hooks'

const Input = FormInput<BookingFormFields>

const countriesSortedByCode = countries.sort((a, b) =>
  a.value.localeCompare(b.value)
)

const getStateInputElement = (country: string, loading: boolean) => {
  if (country.match(/US/i)) {
    return (
      <FormDropdown
        fullWidth
        isDisabled={loading}
        label='State'
        name='stateProvince'
        placeholder='State'
        required={rules.required}
      >
        {states.map(({ value, label }) => (
          <DropdownOption key={value} value={value}>
            {label}
          </DropdownOption>
        ))}
      </FormDropdown>
    )
  } else
    return (
      <Input
        fullWidth
        autoComplete='address-level1'
        isDisabled={loading}
        label='State/Province'
        name='stateProvince'
        placeholder='Enter State/Province'
        required={rules.required}
      />
    )
}

export const ConditionalAddressFields = ({ loading }: { loading: boolean }) => {
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const [addressFields, setAddressFields] = useState<{
    [key: string]: string | boolean
  }>({
    address2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
  })

  const {
    formState,
    getValues,
    register,
    resetField,
    setValue: setFormFieldValue,
  } = useFormContext()
  const [address1, address2, city, stateProvince, postalCode] = getValues([
    'address1',
    'address2',
    'city',
    'stateProvince',
    'postalCode',
  ])
  const country = useWatch({ name: 'country' })
  const {
    value: geocoderValue,
    setValue: setGeocoderValue,
    suggestions,
    clearSuggestions,
    ready,
    clearCache,
  } = useGeocoderSearch({
    requestOptions: {
      types: ['address'],
      componentRestrictions: {
        country: [country],
      },
    },
    cacheKey: CACHE_KEYS.withoutRestriction,
  })

  const { errors } = formState
  const options = suggestions?.data

  const handleClearAndFocus = ({
    newValue,
    focusesInput = false,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput?: boolean
    shouldFetchData?: boolean
  }) => {
    setGeocoderValue(newValue, shouldFetchData)
    clearSuggestions()
    resetField('address1')
    resetField('address2')
    resetField('city')
    resetField('postalCode')
    resetField('stateProvince')
    focusesInput && autocompleteRef?.current?.focus()
  }

  const onCountryClick = () => {
    address1 && resetField('address1')
    address2 && resetField('address2')
    city && resetField('city')
    postalCode && resetField('postalCode')
    stateProvince && resetField('stateProvince')
    handleClearAndFocus({ newValue: '', focusesInput: true })
    clearCache()
    setAddressFields({
      address2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
    })
  }

  const onOptionSelect = async (option: Suggestion) => {
    const result = await getDetails({
      placeId: option.place_id,
      fields: ['address_components'],
    })
    if (!result || typeof result === 'string') return

    const addressLine1 = option.structured_formatting?.main_text || ''
    const addressComponents = result?.address_components

    setGeocoderValue(addressLine1)
    setFormFieldValue('address1', addressLine1, { shouldValidate: true })

    const usesAlternativeCityDesignation =
      usesAlternateCityDesignation(addressComponents)

    addressComponents.forEach(({ types, short_name }) => {
      if (types.includes('postal_code')) {
        setAddressFields(prev => ({ ...prev, postalCode: short_name }))
        setFormFieldValue('postalCode', short_name, { shouldValidate: true })
      }

      if (types.includes('administrative_area_level_1')) {
        setAddressFields(prev => ({ ...prev, stateProvince: short_name }))
        setFormFieldValue('stateProvince', short_name, { shouldValidate: true })
      }
      if (usesAlternativeCityDesignation) {
        if (types.includes('administrative_area_level_2')) {
          setAddressFields(prev => ({ ...prev, city: short_name }))
          setFormFieldValue('city', short_name, { shouldValidate: true })
        }
      } else {
        if (types.includes('locality') || types.includes('postal_town')) {
          setAddressFields(prev => ({ ...prev, city: short_name }))
          setFormFieldValue('city', short_name, { shouldValidate: true })
        }
      }
    })

    // after autofilling and showing fields based on geocoder result, we need to make sure all the required fields are shown
    for (const field in addressFields) {
      if (!addressFields[field]) {
        setAddressFields(prev => ({ ...prev, [field]: true }))
      }
    }

    clearSuggestions()
  }

  useEffect(() => {
    if (address1) {
      setGeocoderValue(address1)
      // show all other fields so that the form cannot be submitted without required fields
      for (const field in addressFields) {
        if (!addressFields[field]) {
          setAddressFields(prev => ({ ...prev, [field]: true }))
        }
      }
    }
  }, [address1])

  return (
    <div className='grid grid-cols-1 gap-x-4 md:grid-cols-4'>
      <section className='md:col-span-1'>
        <FormDropdown
          required
          isDisabled={loading}
          label='Country'
          name='country'
        >
          {countriesSortedByCode.map(({ value, label }) => (
            <DropdownOption
              key={label}
              value={value}
              // @ts-expect-error
              onClick={onCountryClick}
            >
              {value}
            </DropdownOption>
          ))}
        </FormDropdown>
      </section>
      <section className='md:col-span-3'>
        <Autocomplete
          {...register('address1')}
          autoExpand
          fullWidth
          required
          autoComplete='off'
          disabled={loading}
          errorText={errors?.['address1']?.message}
          isDisabled={!ready}
          label='Billing address'
          placeholder='Search Address'
          ref={autocompleteRef}
          slotAfter={
            geocoderValue ? (
              <IconButton
                icon='clear'
                size='large'
                onClick={() => {
                  handleClearAndFocus({ newValue: '', focusesInput: true })
                  for (const field in addressFields) {
                    setAddressFields(prev => ({ ...prev, [field]: true }))
                  }
                }}
              />
            ) : null
          }
          slotBefore={
            <span className='c-grey-700'>
              <Icon name='search' size='medium' />
            </span>
          }
          value={geocoderValue}
          onChange={e => setGeocoderValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === KeyCode.ENTER) e.preventDefault()
          }}
          onOptionSelect={onOptionSelect}
        >
          {options.map(option => (
            <GeocoderOption key={option.place_id} option={option} />
          ))}
        </Autocomplete>
      </section>
      {addressFields.address2 ? (
        <section className='md:col-span-4'>
          <Input
            fullWidth
            autoComplete='address-line2'
            isDisabled={loading}
            label='Address Line 2'
            name='address2'
            placeholder='Suite or Apt #'
          />
        </section>
      ) : null}
      {addressFields.city && (
        <section className='md:col-span-4'>
          <Input
            fullWidth
            autoComplete='address-level2'
            isDisabled={loading}
            label='City'
            name='city'
            placeholder='City'
            required={rules.required}
          />
        </section>
      )}
      <section className='md:children-col-span-2 md:col-span-4 md:grid md:grid-cols-subgrid'>
        {addressFields.postalCode && (
          <Input
            fullWidth
            autoComplete='postal-code'
            isDisabled={loading}
            label='Zip Code'
            name='postalCode'
            placeholder='Enter Zip Code'
            required={rules.required}
            rules={rules.postalCode}
          />
        )}
        {addressFields.stateProvince && getStateInputElement(country, loading)}
      </section>
    </div>
  )
}

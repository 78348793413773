import { ModalNext } from '@travelpass/design-system'
import type { CarouselProps } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { MappedImage } from 'src/__generated__/graphql'
import type { HotelRoom } from './hotelRoomsTypes'
import { HotelRoomCarousel } from '../HotelRooms-old/common'

interface HotelRoomModalNewProps {
  hotelRoomData: HotelRoom
  images: HotelRoom['images']
  slideIndex?: CarouselProps['slideIndex']
  onClose(): void
  onImageError?(errorImage: MappedImage): void
}

export const HotelRoomModalNew = ({
  hotelRoomData,
  images,
  slideIndex,
  onClose,
  onImageError,
}: HotelRoomModalNewProps) => {
  const { amenities, description, name } = hotelRoomData ?? {}
  const constructedAmenities = amenities.map(amenity => (
    <li key={amenity} className='of-hidden text-ellipsis whitespace-nowrap'>
      {amenity}
    </li>
  ))
  const showAmenities = !isEmpty(amenities)

  return (
    <ModalNext size='large' onClose={onClose}>
      <div className='px-8'>
        <h3 className='type-h3 pb-8'>{name}</h3>
        <section className='[&_div]:rd-3'>
          <HotelRoomCarousel
            keyBoardControl
            altSuffix={`${name} - modal`}
            height='h-75'
            hideDots={true}
            images={images}
            slideIndex={slideIndex}
            onImageError={onImageError}
          />
        </section>
        <section className='pb-3 pt-5'>
          <h5 className='type-h5 pb-2'>Know Before You Go</h5>
          <div className='pb-5'>{description}</div>
        </section>
        <section
          className={`flex flex-row gap-8 ${showAmenities ? '' : 'hidden'}`}
        >
          <div
            className={`bg-warm-grey rd-3 h-fit w-full px-4 py-2.5 ${isEmpty(constructedAmenities) ? 'hidden' : ''}`}
          >
            <h5 className='type-h5 c-forest-light'>Amenities</h5>
            <ul className='grid list-inside grid-flow-row grid-cols-4 grid-rows-[repeat(auto-fit,_1fr)] gap-x-5 gap-y-2 pl-6'>
              {constructedAmenities}
            </ul>
          </div>
        </section>
      </div>
    </ModalNext>
  )
}

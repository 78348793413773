import { Skeleton } from '@travelpass/design-system'
import { DashboardGuideCardLoading } from './DashboardGuideCardLoading'

export const DashboardGuidesLoading = () => (
  <div className='space-y-6'>
    <div className='w-31 h-6.5'>
      <Skeleton variant='rounded' />
    </div>
    <div className='space-y-6'>
      <DashboardGuideCardLoading />
      <DashboardGuideCardLoading />
      <DashboardGuideCardLoading />
    </div>
  </div>
)

import { useEffect, useState } from 'react'
import { Button, Modal } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import {
  Helmet,
  PageLayoutContainer,
  SearchBooking,
  SectionHeader,
} from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { MyBookingsContent } from './MyBookingsContent'
import { MyBookingsTabs } from './MyBookingsTabs'
import { useGetMyBookingsQuery } from './useGetMyBookingsQuery'

export const MyBookings = () => {
  const { isAnonymous } = useFirebaseUser()
  const navigate = useNavigate()
  const [isSearchModalOpen, setSearchModalOpen] = useState(false)
  const { myBookingsData } = useGetMyBookingsQuery()
  const displayName = myBookingsData?.userProfile?.displayName

  // Anon users get redirected
  useEffect(() => {
    if (isAnonymous) navigate('/find-my-booking')
  }, [isAnonymous])

  return (
    <>
      <Helmet
        pageName={`Bookings ${displayName ? '|' : ''} ${displayName || ''}`}
      />
      <div className='rebrand'>
        <PageLayoutContainer>
          <div className='m-y-12 flex flex-col items-start gap-6 md:flex-row md:items-end md:justify-between'>
            <SectionHeader subtitle='Reservations' title='My Bookings' />
            <Button
              label='Find your booking'
              size='large'
              startIcon='search'
              onClick={() => setSearchModalOpen(true)}
            />
          </div>
          <MyBookingsTabs />
        </PageLayoutContainer>
        <MyBookingsContent />
      </div>
      {isSearchModalOpen && (
        <Modal
          size='medium'
          title='Find Booking'
          onDismiss={() => setSearchModalOpen(false)}
        >
          <SearchBooking showTitle={false} />
        </Modal>
      )}
    </>
  )
}

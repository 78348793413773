import { useRef, useState } from 'react'
import {
  Chip,
  IconButton,
  MenuItem,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'
import type { PublishedEventCategory } from 'src/__generated__/graphql'
import { onGuideAutoSaveStateChange } from 'src/pages/guides/details/useGuideAutoSave'
import type { GuideDraftData } from '../../../types'
import { useUpdateGuideDraftEventSortOrderMutation } from '../../../useUpdateGuideDraftEventSortOrderMutation'

interface GuideDraftEventCardMenuProps {
  onSelectedIdChange(updatedSelectedId: string): void
  paginatedEventCategories?: GuideDraftData['paginatedEventCategories']
  paginatedEventCategoryId: string
  publishedEventId: string
}

export const GuideDraftEventCardMenu = ({
  onSelectedIdChange,
  paginatedEventCategories,
  paginatedEventCategoryId,
  publishedEventId,
}: GuideDraftEventCardMenuProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isMobileOrTablet } = useScreenQuery()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [updatePublishedEventSortOrder] =
    useUpdateGuideDraftEventSortOrderMutation()

  const onMoveToSectionClick = async (
    paginatedEventCategoryId: PublishedEventCategory['id']
  ) => {
    onGuideAutoSaveStateChange('SAVING')

    try {
      const eventSortOrder =
        paginatedEventCategories?.edges
          ?.find(({ node }) => node?.id === paginatedEventCategoryId)
          ?.node?.publishedEvents?.edges?.map(({ node }) => node?.id) ?? []
      await updatePublishedEventSortOrder({
        variables: {
          input: {
            eventSortOrder,
            publishedEventCategoryId: paginatedEventCategoryId,
            publishedEventId,
          },
        },
      })
      onSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
      onGuideAutoSaveStateChange('SAVED')
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
      onGuideAutoSaveStateChange('DEFAULT')
    } finally {
      setIsPopoverOpen(false)
    }
  }

  return (
    <>
      <Popover
        align={isMobileOrTablet ? 'end' : 'center'}
        containerClassName='z-5'
        content={
          <div className='max-h-45 of-y-auto shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            {paginatedEventCategories?.edges?.map(({ node }) => (
              <MenuItem
                key={node?.id}
                aria-label={`Move event to ${node?.name}`}
                isHighlighted={node?.id === paginatedEventCategoryId}
                label={node?.name}
                value={node?.id}
                onClick={() => onMoveToSectionClick(node?.id)}
              />
            ))}
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div className='relative' ref={ref}>
          <div className='hidden md:block'>
            {paginatedEventCategoryId && (
              <div className='[&>button]:color-forest'>
                <IconButton
                  icon='expandMore'
                  size='large'
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                />
              </div>
            )}
            {!paginatedEventCategoryId && (
              <Chip
                icon='arrowDownIos'
                isSelected={false}
                label='Move to'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            )}
          </div>
          <div className='[&>button]:color-forest md:hidden'>
            <IconButton
              icon='expandMore'
              size='small'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
        </div>
      </Popover>
    </>
  )
}

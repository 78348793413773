import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { guideDraftPublishedEventFragment } from './useGetGuideDraftQuery'

const createPublishedEventMutation = gql(`
  mutation CreatePublishedEventMutationInGuideDraftOld($first: Int = 100, $publishedEventInput: CreatePublishedEventInput!) {
    createPublishedEvent(createPublishedEventInput: $publishedEventInput) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
        }
        eventCounts {
          numberEvents
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
        name
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFieldsOld
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFieldsOld
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useCreateGuideDraftEventMutation = () =>
  useMutation(createPublishedEventMutation)

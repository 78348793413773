import { useState } from 'react'
import { IconButton, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import type { HotelDetailsStandardHotelQuery } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelMap } from 'src/pages/hotels/details/components/HotelMap'
import {
  kilometersToMiles,
  milesToMinutesWalking,
  stringToNumber,
} from 'src/utils'
import { useHotelLocationStandardHotelQuery } from '../../hooks'
import { HotelLoading } from '../HotelLoading'
import { filteredPointsOfInterest } from '../HotelMap/hotelMapUtils'
import { HotelModal } from '../HotelModal'

interface HotelOverviewNearbyAttractionsProps {
  hotelDetailsData: HotelDetailsStandardHotelQuery['lodging']
  showMap: boolean
}

export const HotelOverviewNearbyAttractions = ({
  hotelDetailsData,
  showMap,
}: HotelOverviewNearbyAttractionsProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const { hasError, hotelLocationData, isLoading } =
    useHotelLocationStandardHotelQuery()
  const { staticMap } = hotelDetailsData ?? {}
  const { pointsOfInterest } = hotelLocationData ?? {}
  const filteredPoints = filteredPointsOfInterest(pointsOfInterest)

  if (isLoading || !showMap) return <HotelLoading />
  if (hasError) return // TODO: Error component

  const constructedPointsItems = filteredPoints.map(
    ({ distance, name, unit }) => {
      const distanceToNumber = stringToNumber(distance)
      const updatedDistance =
        unit === 'Km' ? kilometersToMiles(distanceToNumber) : distanceToNumber
      const minutesWalking = milesToMinutesWalking(updatedDistance)
      const maximumMinutesWalking = 100

      return (
        minutesWalking < maximumMinutesWalking && (
          <p
            key={name}
            className='color-grey-900 type-body-1 flex w-full justify-between gap-x-2 py-1'
          >
            <span>
              <Icon name='place' size='small' /> {name}
            </span>
            {minutesWalking && <> {minutesWalking} min walk</>}
          </p>
        )
      )
    }
  )

  return (
    <>
      {isMapModalOpen && (
        <HotelModal
          initialTab='MAP'
          onCloseModal={() => setIsMapModalOpen(false)}
        />
      )}
      <div
        className={classNames({
          'w-full px-10 lg:px-0': newHotelDetails,
        })}
        id='hotel-location'
      >
        <h3
          className={classNames({
            'type-h2 md:type-h3 pb-6 text-center': newHotelDetails,
            hidden: !newHotelDetails,
          })}
        >
          Nearby Attractions
        </h3>
        <div
          className={classNames('sm:pb-8', {
            'justify-center lg:flex': newHotelDetails,
          })}
        >
          <div className={classNames({ 'lg:w-1/3': newHotelDetails })}>
            <div className='pb-6'>
              <h4
                className={classNames('type-h4', { hidden: newHotelDetails })}
              >
                Nearby Attractions
              </h4>
            </div>
            <div className='h-90 rounded-5 of-hidden relative'>
              {staticMap ? (
                <img
                  alt='Google Static Map'
                  className='h-full w-full object-cover'
                  loading='lazy'
                  src={staticMap}
                />
              ) : (
                <HotelMap
                  enableScrollWheel={false}
                  height='h-90'
                  hideZoom={true}
                  points={filteredPoints}
                />
              )}
              <div className='shadow-2 rounded-10 absolute bottom-7 right-5 flex flex-col bg-white'>
                <IconButton
                  color='transparent'
                  icon='mapExpand'
                  onClick={() => setIsMapModalOpen(true)}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames('px-4 pt-8 lg:px-12', {
              'flex flex-col justify-center lg:w-1/4': newHotelDetails,
            })}
          >
            {constructedPointsItems}
          </div>
        </div>
      </div>
    </>
  )
}

import { Avatar, IconButton } from '@travelpass/design-system'
import type { GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import { GuideImage } from 'src/pages/guides/details/common'
import { getImageResized } from 'src/utils'

interface DashboardProfileInfoHeaderProps {
  onModalAvatarOpen: VoidFunction
  onModalBannerOpen: VoidFunction
  userProfile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
}

export const DashboardProfileInfoHeader = ({
  onModalAvatarOpen,
  onModalBannerOpen,
  userProfile,
}: DashboardProfileInfoHeaderProps) => (
  <header id='dashboard-profile-info-header'>
    <div className='rounded-3 h-37.5 lg:rounded-5 of-hidden bg-grey-800 relative'>
      <GuideImage
        className='absolute inset-0'
        src={userProfile?.activeBannerImage?.mediumUrl}
      />
      <div className='absolute bottom-2 right-2 lg:bottom-4 lg:right-4'>
        <IconButton
          color='forestLight'
          icon='camera'
          outline='round'
          size='small'
          onClick={onModalBannerOpen}
        />
      </div>
    </div>
    <div className='w-21 h-21 m-t--10 m-a relative rounded-full'>
      <Avatar
        border='sm'
        size='md'
        src={getImageResized({
          imageUrl: userProfile?.profileImageUrl,
          shape: 'square',
          width: 300,
        })}
      />
      <div className='absolute bottom--1 right-0'>
        <IconButton
          color='forestLight'
          icon='camera'
          outline='round'
          size='small'
          onClick={onModalAvatarOpen}
        />
      </div>
    </div>
  </header>
)

import { EventType, type PublishedEvent } from 'src/__generated__/graphql'
import { MarkerWrapper } from 'src/common/components/Map'
import type { ProfileOwner } from 'src/pages/profile/types'
import { StyledMarker } from 'src/pages/trips/components'
import { stringToNumber } from 'src/utils'
import { GuideMapMarkerPopup } from './GuideMapMarkerPopup'
import { onGuideSessionStorageHoverIdChange } from '../../useGuideSessionStorageIds'

interface GuideMapMarkerProps {
  isHovered: boolean
  isSelected: boolean
  onClick(id: PublishedEvent['id']): void
  onPopupClick(id: PublishedEvent['id']): void
  owner: ProfileOwner
  point: PublishedEvent
}

export const GuideMapMarker = ({
  isHovered,
  isSelected,
  onClick,
  onPopupClick,
  owner,
  point,
}: GuideMapMarkerProps) => {
  const { addresses, id, type } = point ?? {}
  const [address] = addresses ?? []
  const lat = stringToNumber(address?.lat)
  const lng = stringToNumber(address?.long)

  return (
    <>
      <MarkerWrapper
        isHovered={isHovered || isSelected}
        position={{
          lat,
          lng,
        }}
        onClick={() => onClick(id)}
      >
        <div
          className='relative'
          onMouseEnter={() => onGuideSessionStorageHoverIdChange?.(id)}
          onMouseLeave={() => onGuideSessionStorageHoverIdChange?.('')}
        >
          <StyledMarker
            isHovered={isHovered}
            isSelected={isSelected}
            type={type ?? EventType.Miscellaneous}
          />
          {isHovered && (
            <div className='w-95 md:w-115 absolute top-[calc(100%+12px)] hidden -translate-x-1/2 md:block'>
              <GuideMapMarkerPopup
                owner={owner}
                point={point}
                onClick={() => onPopupClick(id)}
              />
            </div>
          )}
        </div>
      </MarkerWrapper>
      {isHovered && (
        <div className='z-2 absolute bottom-0 left-0 right-0 md:hidden'>
          <GuideMapMarkerPopup
            owner={owner}
            point={point}
            onClick={() => onPopupClick(id)}
          />
        </div>
      )}
    </>
  )
}

import type { MouseEvent } from 'react'
import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GuideDraftData, GuideDraftDragItem } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { GuideDraftGeocoder } from '../GuideDraftGeocoder'

interface GuideDraftSectionSearchProps {
  id: GuideDraftData['id']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchIdChange: (updatedSearchId: string) => void
  paginatedEventCategory?: GuideDraftDragItem
  searchId: string
}

export const GuideDraftSectionSearch = ({
  id,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchIdChange,
  paginatedEventCategory,
  searchId,
}: GuideDraftSectionSearchProps) => {
  const [searchValue, setSearchValue] = useState('')

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSearchIdChange(paginatedEventCategory?.id)
    pushDataToDataLayer('guide_section_add_recommendation_click', {
      guide_id: id,
      item_id: paginatedEventCategory?.id,
      item_name: paginatedEventCategory?.name,
    })
  }

  const onSearchValueChange = (updatedSearchValue: string) =>
    setSearchValue(updatedSearchValue)

  if (searchId === paginatedEventCategory?.id)
    return (
      <div
        className='flex flex-row items-center'
        data-geocoder-id={paginatedEventCategory?.id}
      >
        <div className='grow'>
          <GuideDraftGeocoder
            autoFocus={true}
            id={id}
            location={location}
            paginatedEventCategoryId={paginatedEventCategory?.id}
            searchValue={searchValue}
            onMapMarkerCenterChange={onMapMarkerCenterChange}
            onPlacePointsChange={onPlacePointsChange}
            onSearchValueChange={onSearchValueChange}
          />
        </div>
        <IconButton icon='clear' onClick={() => onSearchIdChange('')} />
      </div>
    )

  return (
    /** @todo one-off until we have a button variant without horizontal padding */
    <div className='[&>button]:p-x-0 [&>button]:normal-case'>
      <Button size='medium' startIcon='add' variant='text' onClick={onClick}>
        Add Recommendation
      </Button>
    </div>
  )
}

import { Carousel, Icon, Illustration } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { ButtonLink, Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import paperPlaneIcon from 'src/pages/guides/details/assets/paper-plane.svg'
import { GuideLandingPageCard } from '../GuideLandingPageCard'
import { christmasGuides } from '../guideLandingPageCardConstants'

export const ChristmasGuidesLandingPage = () => {
  const { pathname } = useLocation()
  const canonicalUrl = baseUrl + pathname
  const metaDescription =
    'Explore the top Travel Guides for the best winter vacations and activities, filled with tips and advice from travelers like you! Travel with tips you can trust.'
  const pageName =
    'Winter Travel Guides | The Best Winter Vacations & Activities'

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='job-promotion pb-35 lg:pb-180px from-forest-dark via-forest to-forest-light m-0 w-full bg-gradient-to-b pt-10 lg:pt-20'>
        <PageLayoutContainer>
          <div className='space-y-6 md:space-y-8 lg:space-y-10'>
            <h1 className='type-h1 text-warm-grey text-10 md:text-16 lg:text-22 text-balance text-center'>
              <div className='space-y-4'>
                <span className='text-7 md:text-10 lg:text-14 text-new-forest block'>
                  Top Travel Guides for
                </span>
                <span className='block'>
                  The Best Winter & Holiday Vacations
                </span>
              </div>
            </h1>
            <p className='type-subtitle-2 font-400 text-warm-grey mx-a max-w-800px line-height-135% px-10px text-balance text-center md:text-wrap md:px-0'>
              Looking for a trip to take this winter? Whether you&apos;re
              dreaming of a magical holiday getaway, the chance to explore a
              winter wonderland, or need help planning a tropical escape, we
              have the perfect travel Guide for your trip. Find our top picks
              below, filled with tips and advice from travelers like you.
            </p>
          </div>
        </PageLayoutContainer>
      </div>

      <section className='rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px pb-35 h-auto w-full bg-white pt-3 lg:pb-40 lg:pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion my-6 text-center md:text-left'>
            <div className='space-y-4'>
              <h2 className='type-h2'>🎄 Holiday Magic</h2>
              <div className='mx-a h-5px w-120px bg-new-forest block rounded-full md:mx-0'></div>
            </div>
          </div>
          <Carousel
            isLight
            borderRadius={14}
            size='medium'
            slidesPerViewOnDesktop={3}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {christmasGuides.map(section =>
              section.holidayMagic.map(
                ({ id, owner, title, image, city, state, href }) => (
                  <div key={id} className='px-2'>
                    <GuideLandingPageCard
                      city={city}
                      href={href}
                      image={image}
                      owner={owner}
                      state={state}
                      title={title}
                    />
                  </div>
                )
              )
            )}
          </Carousel>
        </PageLayoutContainer>
      </section>

      <section className='rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px pb-35 bg-forest h-auto w-full pt-3 lg:pb-40 lg:pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion my-6 text-center md:text-left'>
            <div className='space-y-4'>
              <h2 className='type-h2 text-warm-grey'>❄️ Snowy Getaways</h2>
              <div className='mx-a h-5px w-120px bg-new-forest block rounded-full md:mx-0'></div>
            </div>
          </div>
          <Carousel
            isLight
            borderRadius={14}
            size='medium'
            slidesPerViewOnDesktop={3}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {christmasGuides.map(section =>
              section.snowyGetaways.map(
                ({ id, owner, title, image, city, state, href }) => (
                  <div key={id} className='px-2'>
                    <GuideLandingPageCard
                      city={city}
                      href={href}
                      image={image}
                      owner={owner}
                      state={state}
                      title={title}
                    />
                  </div>
                )
              )
            )}
          </Carousel>
        </PageLayoutContainer>
      </section>

      <section className='rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px pb-35 h-auto w-full bg-white pt-3 lg:pb-40 lg:pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion my-6 text-center md:text-left'>
            <div className='space-y-4'>
              <h2 className='type-h2 text-balance'>
                ⛷️ Winter Sports & Icy Adventures
              </h2>
              <div className='mx-a h-5px w-120px bg-new-forest block rounded-full md:mx-0'></div>
            </div>
          </div>
          <Carousel
            isLight
            borderRadius={14}
            size='medium'
            slidesPerViewOnDesktop={3}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {christmasGuides.map(section =>
              section.winterSports.map(
                ({ id, owner, title, image, city, state, href }) => (
                  <div key={id} className='px-2'>
                    <GuideLandingPageCard
                      city={city}
                      href={href}
                      image={image}
                      owner={owner}
                      state={state}
                      title={title}
                    />
                  </div>
                )
              )
            )}
          </Carousel>
        </PageLayoutContainer>
      </section>

      <section className='rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px pb-35 bg-forest border-b-newForest border-b-3 h-auto w-full border-0 border-solid pt-3 lg:pb-40 lg:pt-5'>
        <PageLayoutContainer>
          <div className='job-promotion my-6 text-center md:text-left'>
            <div className='space-y-4'>
              <h2 className='type-h2 text-warm-grey text-balance'>
                🌴 Tropical Winter Escapes
              </h2>
              <div className='mx-a h-5px w-120px bg-new-forest block rounded-full md:mx-0'></div>
            </div>
          </div>
          <Carousel
            isLight
            borderRadius={14}
            size='medium'
            slidesPerViewOnDesktop={3}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {christmasGuides.map(section =>
              section.tropicalEscapes.map(
                ({ id, owner, title, image, city, state, href }) => (
                  <div key={id} className='px-2'>
                    <GuideLandingPageCard
                      city={city}
                      href={href}
                      image={image}
                      owner={owner}
                      state={state}
                      title={title}
                    />
                  </div>
                )
              )
            )}
          </Carousel>
        </PageLayoutContainer>
      </section>

      <section className='rounded-tl-10 rounded-tr-10 lg:rounded-tl-20 lg:rounded-tr-20 mt--100px bg-warm-grey h-auto w-full'>
        <PageLayoutContainer>
          <div className='job-promotion lg:of-initial relative my-6 py-8 text-center md:py-12 md:text-left'>
            <img
              alt=''
              className='m-a w-90 lg:left--51 lg:right-a absolute bottom--6 left-0 right-0 h-auto max-w-full lg:bottom-0 lg:top-0'
              src={paperPlaneIcon}
            />
            <div className='relative flex flex-col gap-8 text-center lg:flex-row lg:items-center lg:gap-12 lg:text-left'>
              <div className='space-y-2 lg:space-y-4'>
                <h3 className='type-h3 text-forest text-balance lg:text-wrap'>
                  Always know where to stay, what to do, and where to eat for
                  every trip.
                </h3>
                <p className='type-body-1 p-t-2 md:p-t-0 text-balance'>
                  Find travel tips and advice about countless destinations with{' '}
                  <b>Travelpass Guides</b>.
                </p>
              </div>
              <div className='m-a min-w-fit'>
                <ButtonLink to='/guides/all' variant='valley'>
                  Explore More Guides
                  <Icon name='arrowForward' size='medium' />
                </ButtonLink>
              </div>
              <div className='w-47 hidden lg:block'>
                <Illustration name='suitcase' />
              </div>
            </div>
          </div>
        </PageLayoutContainer>
      </section>
    </>
  )
}

import { Avatar, UnderlineTab } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useParams } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { getProfileAvatarAlt } from 'src/utils'
import {
  ProfileEmpty,
  ProfileLoading,
  ProfileGuides,
  ProfileHeader,
  ProfileStats,
} from './common'
import { profileTabs } from './profileConstants'
import { useGetProfileQuery } from './useGetProfileQuery'
import { GuideImage } from '../guides/details/common'
import { ProfileSearchBarOld } from '../profile-old/components/ProfileSearchBarOld/ProfileSearchBarOld'

export const Profile = () => {
  const { accountHandle } = useParams()
  const { profileData, profileMetaData, hasError, isLoading, owner } =
    useGetProfileQuery(accountHandle)

  if (isLoading) return <ProfileLoading />

  if (hasError || isEmpty(profileData)) return <ProfileEmpty />

  return (
    <>
      <Helmet {...profileMetaData} />
      <section className='md:p-b-24 p-b-8'>
        <div className='bg-grey-900 h-78 p-y-4 relative'>
          {/** @todo add Banner alt getProfileBannerAlt */}
          <GuideImage
            className='absolute inset-0'
            src={profileData?.activeBannerImage?.mediumUrl}
          />
          <PageLayoutContainer>
            <div className='m-l-a md:w-78 relative'>
              {/** @todo ProfileSearchBar */}
              <ProfileSearchBarOld />
            </div>
          </PageLayoutContainer>
        </div>
        <PageLayoutContainer>
          <div className='m-t--20 md:m-t--25 relative flex flex-col gap-4 md:flex-row md:gap-24'>
            <aside className='flex flex-col items-center md:w-60 md:min-w-60'>
              <Avatar
                alt={getProfileAvatarAlt({
                  accountHandle: profileData?.accountHandle,
                  displayName: profileData?.displayName,
                })}
                border='lg'
                size='xl'
                src={profileData?.profileImageUrl}
              />
              <ProfileStats />
            </aside>
            <div className='md:p-t-18 p-t-20 grow space-y-4'>
              <ProfileHeader />
              <div className='m-x--2 lg:m-x-0'>
                <ScrollMenu scrollContainerClassName='hide-scrollbar'>
                  <UnderlineTab
                    activeIndex={0}
                    fullWidth={true}
                    tabs={profileTabs}
                    willScroll={false}
                  />
                </ScrollMenu>
              </div>
              <ProfileGuides owner={owner} profileData={profileData} />
            </div>
          </div>
        </PageLayoutContainer>
      </section>
    </>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { constructAddress } from 'src/utils'

const createGuideDraftMutation = gql(`
  mutation CreateGuideDraftMutationInGuideDraftOld($createGuideDraftInput: CreateGuideDraftInput!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    createGuideDraft(input: $createGuideDraftInput) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
          zipcode
        }
        description
        guide {
          id
          status
        }
        insertedAt
        images {
          id
          source
          url
        }
        imageUrl
        name
        owner {
          id
          userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
            totalCount
          }
        }
        ownerProfile {
          id
        }
        strippedId
        updatedAt
      }
    }  
  }
`)

export const useCreateGuideDraftMutation = (triggerButton: string) =>
  useMutation(createGuideDraftMutation, {
    onCompleted: data => {
      const {
        addresses,
        description,
        guide,
        id,
        insertedAt,
        name,
        owner,
        updatedAt,
      } = data?.createGuideDraft?.guideDraft ?? {}
      const address = constructAddress({
        addressFirstLine: addresses?.[0]?.addressLine1,
        city: addresses?.[0]?.city,
        state: addresses?.[0]?.state,
      })

      if (!id) return

      pushDataToDataLayer('guide_draft_create', {
        guide_description: description,
        guide_draft_id: id,
        guide_id: guide?.id,
        guide_location: address,
        guide_name: name,
        guide_status: guide?.status,
        inserted_at: insertedAt,
        trigger_button: triggerButton,
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })

      pushDataToDataLayer('guide_three_events', {
        guide_address: addresses?.[0]?.addressLine1,
        guide_city: addresses?.[0]?.city,
        guide_country: addresses?.[0]?.country,
        guide_has_three_events: false,
        guide_id: id,
        guide_name: name,
        guide_state: addresses?.[0]?.state,
        trigger_url: window.location.href,
      })
    },
  })

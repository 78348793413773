import { Skeleton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { stringToBoolean } from 'src/utils'
import { GuideDraftEventsLoading } from './GuideDraftEvents'
import { GuideDraftGeocoderLoading } from './GuideDraftGeocoder'
import { GuideSearchParam } from '../../details'
import {
  GuideMapLoading,
  GuideMapWrapper,
  GuideSectionsWrapper,
  GuideHeaderOldLoading,
  GuideHeroLoading,
  GuideStickyTopNavOldLoading,
} from '../../details/common'

interface GuideDraftLoadingProps {
  selectedId: string
}

export const GuideDraftLoading = ({ selectedId }: GuideDraftLoadingProps) => {
  const [searchParams] = useSearchParams()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )

  return (
    <>
      <GuideStickyTopNavOldLoading />
      <GuideHeroLoading />
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapFullView={mapFullView} selectedId={selectedId}>
          <div className='bg-white'>
            <PageLayoutContainer size='none'>
              <GuideHeaderOldLoading isEdit={true} />
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <div className='p-t-6 p-b-5 lg:p-b-30 space-y-4 lg:space-y-8'>
              <div className='w-30 h-5'>
                <Skeleton />
              </div>
              <GuideDraftGeocoderLoading />
              <GuideDraftEventsLoading />
            </div>
          </PageLayoutContainer>
        </GuideSectionsWrapper>
        <GuideMapWrapper
          className='lg:h-100svh h-[calc(100svh-166px)] lg:top-0'
          mapFullView={mapFullView}
          selectedId={selectedId}
        >
          <GuideMapLoading />
        </GuideMapWrapper>
      </div>
    </>
  )
}

import { GuideStatus, UserImageSource } from 'src/__generated__/graphql'
import { predefinedGuidesTagsMock } from 'src/pages/guides/__tests__/__mocks__'
import type { GuideData } from '../../types'

const description =
  "Dive into the depths of Salt Lake City's experiences. Discover the vibrant culture and thrilling adventures awaiting you."

export const guideMock: GuideData = {
  id: 'R3VpZGU6Z2RlXzAxSFhaMTJTNzFGUUowNEdRVjE3NkgzOEhH',
  addresses: [
    {
      id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFhaMTJTNzNENDBGRUdURE5FSEczNEdW',
      addressLine1: null,
      city: 'Salt Lake City',
      country: 'United States',
      googlePlaceId: 'ChIJ7THRiJQ9UocRyjFNSKC3U1s',
      lat: '40.7607793',
      long: '-111.8910474',
      state: 'Utah',
      __typename: 'GenericAddress',
    },
  ],
  collectedCount: 100,
  description,
  eventCounts: {
    numberEvents: 5,
  },
  guideDraft: {
    id: 'R3VpZGVEcmFmdDpnZGVkXzAxSkIySzZCRVI5RDAw',
    strippedId: '01JB2K6BER9D00',
  },
  imageUrl:
    'https://res.cloudinary.com/simpleview/image/upload/v1607099492/clients/saltlake/VSL_SLC_DroneGrab_01_a8964ffd-f8ec-4c2c-9146-dcb43f23273f.jpg',
  firstPublishedAt: '2024-05-22T13:43:06.414915Z',
  insertedAt: '2024-05-15T21:03:40.257149Z',
  isCollected: false,
  likedByCurrentUser: false,
  name: 'Salt Lake Adventures',
  numberOfLikes: 20,
  ownerProfile: {
    id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
    accountHandle: 'mlane',
    displayName: 'Marcus Lane',
    isFollowed: false,
    isUserOwner: true,
    profileImageUrl:
      'https://lh3.googleusercontent.com/a/ALm5wu2OT9aLdGrKKiNn4IYSsEpQjaHB7dAKZBpOpuLD=s192-c',
    userId: 'VXNlcjp1c3JfMDFIVlBNRDlXMFdRWEg4TjhRUzBWVlNZSDM=',
    __typename: 'UserProfile',
  },
  paginatedImages: {
    edges: [
      {
        node: {
          id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzBBSkNK',
          source: UserImageSource.UserUploadedImage,
          url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1A15PRRNEG8NVMYRJ9R1YJ/guide_images/47329f8c-2598-45ba-a2ce-8cb86db0c59f',
          __typename: 'UserImage',
        },
        __typename: 'UserImageEdge',
      },
      {
        node: {
          id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzABSkNK',
          source: UserImageSource.PixabayImage,
          url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1A15PRRNEG8NVMYRJ9R1YJ/guide_images/c677b0b0-b38b-4b7d-9597-42df3d055f77',
          __typename: 'UserImage',
        },
        __typename: 'UserImageEdge',
      },
      {
        node: {
          id: 'VXNlckltYWdlOnVzaW1nXzAxSkIyQjRXMDkxWlRCS0JHVkEyUTM5NEpa',
          source: UserImageSource.UserUploadedImage,
          url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1A15PRRNEG8NVMYRJ9R1YJ/guide_images/97f9c95a-9ca7-4c5c-8ff8-03547716efe9',
          __typename: 'UserImage',
        },
        __typename: 'UserImageEdge',
      },
    ],
    __typename: 'UserImageConnection',
  },
  shareCount: 10,
  status: GuideStatus.Published,
  strippedId: '01HXZ12S71FQJ04GQV176H38HG',
  timeZone: null,
  tags: predefinedGuidesTagsMock.getPredefinedGuideTags,
  updatedAt: '2024-05-15T21:03:40.257149Z',
  viewCount: 30,
}

import { useEffect, useState } from 'react'
import { Autocomplete, Icon } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'
import type { SearchUserProfilesQueryQuery } from 'src/__generated__/graphql'
import { useSearchProfiles } from 'src/common/hooks'
import { getProfilePageUrl } from 'src/utils'
import { ProfileSearchBarOldOption } from './ProfileSearchBarOldOption'

type SearchedProfile = SearchUserProfilesQueryQuery['searchUserProfiles'][0]

interface ProfileSearchBarOldProps {
  onSelection?: (profile: SearchedProfile) => void
  label?: string
}

export const ProfileSearchBarOld = ({
  onSelection,
  label = 'Search friends and travelers',
}: ProfileSearchBarOldProps) => {
  const navigate = useNavigate()
  const { searchProfiles } = useSearchProfiles()
  const [searchString, setSearchString] = useState('')
  const [profiles, setProfiles] = useState<
    SearchUserProfilesQueryQuery['searchUserProfiles']
  >([])

  useEffect(() => {
    if (searchString.length > 2) debouncedSearchProfiles(searchString)
    else setProfiles([])

    return () => debouncedSearchProfiles.cancel()
  }, [searchString])

  const debouncedSearchProfiles = debounce((searchString: string) => {
    searchProfiles({ variables: { searchString } }).then(({ data }) =>
      setProfiles(data.searchUserProfiles)
    )
  }, 300)

  const onOptionSelect = (profile: SearchedProfile) => {
    setSearchString('')
    if (onSelection) {
      onSelection(profile)
      return
    }
    const URL = getProfilePageUrl(profile.accountHandle)
    navigate(URL, { state: { referrer: window.location.href } })
  }

  return (
    <Autocomplete
      autoExpand
      aria-label={label}
      autoComplete='on'
      placeholder={label}
      slotBefore={
        <div className='color-new-forest flex'>
          <Icon name='search' />
        </div>
      }
      value={searchString}
      onChange={event => setSearchString(event?.target?.value)}
      onOptionSelect={onOptionSelect}
    >
      {!!profiles.length && (
        <div className='max-h-50vh overflow-y-auto'>
          <p className='type-body-1-desktop c-grey700 px-4'>Accounts</p>
          {profiles.map(profile => (
            <ProfileSearchBarOldOption key={profile.id} profile={profile} />
          ))}
        </div>
      )}
    </Autocomplete>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_EXPERIENCE_TO_GUIDE = gql(`
  mutation AddExperienceToGuide($input: AddExperienceToGuideInput!) {
    addExperienceToGuide(input: $input) {
      guide {
        id
        publishedEvents {
          id
          externalTypeId
          productId
        }
      }
    }
  }
`)

export const useAddExperienceToGuide = (options?) =>
  useMutation(ADD_EXPERIENCE_TO_GUIDE, options)

import type { ReactElement } from 'react'
import { Avatar, Divider } from '@travelpass/design-system'
import { FollowButtonOld } from 'src/pages/profile-old/components/FollowButtonOld/FollowButtonOld'
import {
  getImageResized,
  getProfileAvatarAlt,
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfilePageUrl,
} from 'src/utils'
import { getDashboardFeedTimeSincePublished } from '../../../dashboardUtils'
import type { DashboardFeedItem } from '../../../types'

interface DashboardFeedCardHeaderGuiderops {
  dashboardFeedItem: DashboardFeedItem
  children: ReactElement
}

export const DashboardFeedCardHeaderGuide = ({
  children,
  dashboardFeedItem,
}: DashboardFeedCardHeaderGuiderops) => {
  const { insertedAt, ownerProfile, updatedAt, timeZone } =
    dashboardFeedItem?.guide ?? {}
  const { accountHandle, displayName, isFollowed, profileImageUrl, userId } =
    ownerProfile ?? {}
  const ownerImage = getProfileOwnerImage(profileImageUrl)
  const ownerName = getProfileOwnerName({
    accountHandle,
    displayName,
  })
  const ownerUrl = getProfilePageUrl(accountHandle)
  const timeSincePublished = getDashboardFeedTimeSincePublished({
    relevantDatetime: dashboardFeedItem?.relevantDatetime,
    timeZone,
  })

  return (
    <>
      <header className='flex flex-row gap-2'>
        <a
          aria-label={`View profile of ${ownerName}`}
          className='transition-opacity-200 relative block cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'
          href={ownerUrl}
          rel='noreferrer'
          target='_BLANK'
        >
          <Avatar
            alt={getProfileAvatarAlt({
              accountHandle,
              displayName,
            })}
            border='none'
            size='sm'
            src={getImageResized({
              imageUrl: ownerImage,
              shape: 'square',
              width: 300,
            })}
          />
        </a>
        <div className='grow space-y-1'>
          <h4 className='type-h6-desktop font-700 c-forest-dark'>
            <address>
              <a
                aria-label={`View profile of ${ownerName}`}
                className='b-0 decoration-none of-hidden c-forest-dark transition-opacity-200 relative cursor-pointer bg-transparent p-0 not-italic ease-linear hover:opacity-50 active:opacity-50 md:truncate'
                href={ownerUrl}
                rel='noreferrer'
                target='_BLANK'
              >
                {ownerName}
              </a>
            </address>
          </h4>
          {children}
          <time
            className='c-grey-700 type-subtext md:truncate'
            dateTime={updatedAt ?? insertedAt}
          >
            {timeSincePublished}
          </time>
        </div>
        {!isFollowed && (
          <div className='[&>button]:p-0'>
            <FollowButtonOld
              accountHandle={accountHandle}
              displayName={displayName}
              fullWidth={false}
              isFollowed={isFollowed}
              label='Follow'
              startIcon='add'
              userId={userId}
              variant='text'
            />
          </div>
        )}
      </header>
      <Divider className='m-b-4 m-t-2' />
    </>
  )
}

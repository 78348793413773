import { useEffect, useRef, useState } from 'react'
import { Button, SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import type { Scalars } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useIsElementOnScreen } from 'src/common/hooks'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { DashboardGuideCard } from './DashboardGuideCard'
import { DashboardGuidesEmpty } from './DashboardGuidesEmpty'
import { DashboardGuidesLoading } from './DashboardGuidesLoading'
import { useGetDashboardGuidesQuery } from '../../useGetDashboardGuidesQuery'

export const DashboardGuides = () => {
  const { dashboardGuidesData, hasError, isLoading, onGetMoreResults } =
    useGetDashboardGuidesQuery()
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const fetchMoreCursorRef = useRef('')
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const { endCursor, hasNextPage } = dashboardGuidesData?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(
    async ({ after }: { after: Scalars['String']['input'] }) => {
      if (isFetchingMore || after === fetchMoreCursorRef.current) return

      setIsFetchingMore(true)
      fetchMoreCursorRef.current = after

      try {
        await onGetMoreResults({
          after,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetchingMore(false)
      }
    },
    300
  )

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) {
      debouncedGetMoreResults({
        after: endCursor,
      })
    }
  }, [debouncedGetMoreResults, endCursor, hasNextPage, isLastElementVisible])

  if (isLoading) return <DashboardGuidesLoading />

  if (hasError || isEmpty(dashboardGuidesData?.edges))
    return <DashboardGuidesEmpty />

  return (
    <>
      <section className='space-y-6'>
        <header>
          <Button
            aria-label='Create a guide'
            size='small'
            startIcon='add'
            variant='text'
            onClick={() => setIsModalOpen(true)}
          >
            New Guide
          </Button>
        </header>
        <div className='space-y-6'>
          {dashboardGuidesData?.edges?.map(({ node }) => (
            <DashboardGuideCard key={node?.id} guideData={node} />
          ))}
          {hasNextPage && (
            <div className='invisible m-0 h-0' ref={lastElementRef} />
          )}
          {isFetchingMore && <SkeletonDots />}
        </div>
      </section>
      {isModalOpen && (
        <GuideDraftCreateModal
          triggerButton='dashboardMiddleNewGuide'
          onDismiss={() => setIsModalOpen(false)}
        />
      )}
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </>
  )
}

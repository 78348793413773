import { useLocation } from 'react-router-dom'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useProfileByAccountHandle } from './useProfileByAccountHandle'

export const useGetProfile = (accountHandle?: string) => {
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const location = useLocation()
  const referrer = location.state?.referrer

  const {
    data: profileByAccountHandle,
    loading,
    error,
    refetch,
  } = useProfileByAccountHandle(
    accountHandle || data?.currentUser?.userProfile?.accountHandle,
    {
      onCompleted: callbackData => {
        try {
          const { accountHandle, id, tags } =
            callbackData?.viewUserProfile ?? {}
          const { id: currentUserId } = data?.currentUser ?? {}

          pushDataToDataLayer('profile_view', {
            account_handle: accountHandle,
            profile_tags: tags,
            profile_id: id,
            trigger_url: referrer ?? document.referrer,
            profile_url: window.location.href,
            user_id: currentUserId,
          })
        } catch (error) {
          console.error('Error in useGetProfile onCompleted', error)
        }
      },
    }
  )

  return {
    profile: profileByAccountHandle?.viewUserProfile,
    loading,
    error,
    refetch,
  }
}

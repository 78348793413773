import { Icon, IconButton } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { GetGuidesEventMapQueryInGuidesEventMapQuery } from 'src/__generated__/graphql'
import { ResultsCard, ResultsCardContent } from 'src/common/components'
import { getEventImage } from 'src/pages/trips/utils'
import {
  constructAddress,
  getGuideDetailsUrl,
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfileOwnerUrl,
} from 'src/utils'
import { GuideImage } from '../details/common'

interface GuidesEventsMapMarkerPopupProps {
  point: GetGuidesEventMapQueryInGuidesEventMapQuery['getPublishedEvent']
}

export const GuidesEventsMapMarkerPopup = ({
  point,
}: GuidesEventsMapMarkerPopupProps) => {
  const { addresses, imageUrl, description, name, notes, guide } = point ?? {}
  const { id: guideId, name: guideName } = guide ?? {}
  const { accountHandle, displayName, profileImageUrl } =
    guide?.ownerProfile ?? {}
  const ownerName = getProfileOwnerName({
    accountHandle,
    displayName,
  })
  const guideOwnerImage = getProfileOwnerImage(profileImageUrl)
  const { addressLine1: addressFirstLine, city, state } = addresses?.[0] ?? {}
  const address = constructAddress({
    addressFirstLine,
    city,
    state,
  })
  const showCard = !isEmpty(point) && !!name
  const guideUrl = getGuideDetailsUrl({ id: guideId, name: guideName })
  const guideOwnerUrl = getProfileOwnerUrl(accountHandle)

  return (
    showCard && (
      <ResultsCard className='shadow-1 b-1 b-solid b-grey-400 hover:cursor-default'>
        <div className='of-hidden rounded-3 w-25 md:w-42 absolute bottom-0 left-0 top-0'>
          <GuideImage
            alt=''
            className='shadow-1'
            src={getEventImage(imageUrl)}
          />
        </div>
        <div className='p-l-25 md:p-l-42 w-full'>
          <ResultsCardContent>
            <div className='space-y-6'>
              <div>
                <div className='flex flex-row items-center justify-between'>
                  <h4 className='c-grey-900 type-body-1-medium line-clamp-2'>
                    {name}
                  </h4>
                  <IconButton
                    color='forestLight'
                    icon='listAlt'
                    outline='round'
                    size='small'
                    onClick={() => window.open(guideUrl, '_blank')}
                  />
                </div>
                {address && (
                  <p className='c-grey-800 type-small-text flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='line-clamp-1'>{address}</span>
                  </p>
                )}
              </div>
              <div className='min-h-5 space-y-2'>
                <div className='rounded-2 space-y-4'>
                  <div className='space-y-2'>
                    {notes && <div className='type-body-2'>{notes}</div>}
                    <a
                      className='b-0 decoration-none of-hidden transition-opacity-200 block inline-flex cursor-pointer flex-row items-center space-x-2 bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'
                      href={guideOwnerUrl}
                      rel='noreferrer'
                      target='_BLANK'
                    >
                      <GuideImage
                        alt={`Profile image for ${ownerName}`}
                        className='rounded-1/2 shadow-1 h-6 min-h-6 w-6 min-w-6'
                        src={guideOwnerImage}
                      />
                      <span className='c-forest type-subtext'>
                        By {ownerName}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ResultsCardContent>
        </div>
      </ResultsCard>
    )
  )
}

import { useLayoutEffect } from 'react'
import { parsePhoneNumber } from 'react-phone-number-input'
import { useFlag } from 'src/common/hooks'
import { countries } from 'src/constants'
import { useUserGuestInfoQuery } from 'src/pages/experiences/booking/useUserGuestInfoQuery'
import { useFormContext, type BookingFormFields } from './useBookingForm'

const findCountryByCode = (code: string) => {
  return countries.find(country => country.value === code)?.label
}

export const useAutofillGuestInfo = () => {
  const checkoutV2 = useFlag('checkoutV2')
  const { setValue, resetField } = useFormContext()
  const { data, loading } = useUserGuestInfoQuery()

  const currentUser = data?.currentUser
  const currentUserAsArray = Object.entries(currentUser ?? {})
  const currentUserSavedInfo = currentUserAsArray?.filter(([key, value]) => {
    return value && key !== '__typename' && key !== 'id'
  })
  const parsedPhoneNumber = parsePhoneNumber(currentUser?.phoneNumber ?? '')
  const extractedCountryCode = parsedPhoneNumber?.country ?? ''
  const extractedCountryName = findCountryByCode(extractedCountryCode)
  const hasSavedUserData = currentUserSavedInfo?.length > 0

  useLayoutEffect(() => {
    if (!checkoutV2 || !hasSavedUserData || loading) return

    const mappedValues = {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phone: currentUser?.phoneNumber,
      phoneCountry: extractedCountryName ?? extractedCountryCode,
      email: currentUser?.email,
      address1: currentUser?.addressFirstLine,
      address2: currentUser?.addressSecondLine,
      city: currentUser?.city,
      postalCode: currentUser?.zip,
      stateProvince: currentUser?.state,
      country: currentUser?.country,
    }

    Object.keys(mappedValues)?.map((key: keyof BookingFormFields) => {
      if (mappedValues[key]) {
        setValue(key, mappedValues[key])
      } else {
        resetField(key)
      }
    })
  }, [currentUser, hasSavedUserData, loading])

  return { loading }
}

import { useCallback } from 'react'
import type { RoomFilters } from 'src/__generated__/graphql'
import { getRateOptions } from 'src/utils'
import { useHotelRoomsQuery } from './useHotelRoomsQuery'

export const useHasCugRates = (filters: RoomFilters, skip = false) => {
  const { rooms, loading, error } = useHotelRoomsQuery(
    filters,
    getRateOptions(null, null, true),
    skip
  )

  const hasCugRates = useCallback(
    () =>
      rooms?.reduce((accumulatedRates, room) => {
        const rates = room.rates.filter(
          ({ category }) => category?.type == 'CUG'
        )
        return [...accumulatedRates, ...rates]
      }, []).length > 0,
    [rooms]
  )

  return { hasCugRates: hasCugRates() && !loading && !error }
}

import { Chip } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { useGetPredefinedProfileTagsQuery } from 'src/common/hooks'

interface TravelStyleTagsProps {
  onClick: (tagId: string, tags?: string[]) => void
  hideSubtitle?: boolean
}

export const TravelStyleTags = ({
  onClick,
  hideSubtitle = false,
}: TravelStyleTagsProps) => {
  const { availableTags } = useGetPredefinedProfileTagsQuery()
  const [searchParams] = useSearchParams()

  const urlTags = searchParams.get('tags')
    ? searchParams.get('tags').split(',')
    : []
  const selectedTags = availableTags?.filter(tag => urlTags.includes(tag.id))
  const unselectedTags = availableTags?.filter(tag => !urlTags.includes(tag.id))
  const isOnResultsPage = window.location.pathname.includes('results')

  return (
    <div className='pb-2 text-center max-md:px-4 md:pb-0'>
      <p className='type-overline c-grey-700'>EXPLORE BY TRAVEL STYLE</p>
      {!hideSubtitle && (
        <>
          <h2 className='type-h2 my-2 text-center'>
            Find Travelers Who Share Your Sense Of Adventure
          </h2>
          <div className='bg-valley w-1/8 mx-auto h-1.5 rounded-full max-md:hidden' />
        </>
      )}
      <div className='max-md:of-x-scroll [&>button]:rd-md mt-10 flex flex-row gap-1 md:flex-wrap md:justify-center'>
        {isOnResultsPage ? (
          <>
            {selectedTags?.map(tag => (
              <Chip
                key={tag.id}
                isSelected={true}
                label={tag.name}
                size='small'
                onClick={() => onClick(tag.id, urlTags)}
              >
                {tag.name}
              </Chip>
            ))}
            {unselectedTags?.map(({ id, name }) => (
              <Chip
                key={id}
                isSelected={urlTags.includes(id)}
                label={name}
                size='small'
                onClick={() => onClick(id, urlTags)}
              >
                {name}
              </Chip>
            ))}
          </>
        ) : (
          <>
            {availableTags?.map(({ id, name }) => (
              <Chip
                key={id}
                isSelected={urlTags.includes(id)}
                label={name}
                size='small'
                onClick={() => onClick(id, urlTags)}
              >
                {name}
              </Chip>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

import { AutocompleteOption } from '@travelpass/design-system'
import type { SearchUserProfilesQueryQuery } from 'src/__generated__/graphql'
import { defaultProfileImage } from 'src/constants'
import { ImageWithFallback } from '../ImageWithFallback'

interface ProfileSearchBarOldOptionProps {
  profile: SearchUserProfilesQueryQuery['searchUserProfiles'][0]
}

export const ProfileSearchBarOldOption = ({
  profile,
}: ProfileSearchBarOldOptionProps) => {
  const { id, displayName, profileImageUrl, accountHandle, isFollowed } =
    profile

  return (
    <AutocompleteOption
      key={id}
      className='color-black rounded-0 transition-background-color-200 type-body-1 focus:bg-grey-100 hover:bg-grey-100 aria-selected:bg-grey-100 w-full cursor-pointer bg-white px-4 py-4 ease-linear'
      value={profile}
    >
      <div className='my-5 flex'>
        <div className='w-72px h-72px'>
          <ImageWithFallback
            className='rounded-1/2 w-72px h-72px object-cover'
            displayName={displayName}
            fallbackSrc={defaultProfileImage}
            src={profileImageUrl}
          />
        </div>
        <div className='w-full place-content-between items-center pl-5 md:pr-10'>
          <p className='type-h6'>{displayName}</p>
          <p className='type-small-text c-gray font-100 mt-1'>
            {`@${accountHandle}`}
          </p>
          {isFollowed && <p className='type-small-text mt-5'>Following</p>}
        </div>
      </div>
    </AutocompleteOption>
  )
}

import { ButtonLink } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { competitionLeaderboardPath, dashboardPath } from 'src/constants'

export const EnterCompetitionButton = ({
  competitionPhaseTwoStarted,
  hasEnteredCompetition,
  variant = 'forest',
  hasEnteredPhaseTwo,
}: {
  competitionPhaseTwoStarted: boolean
  hasEnteredCompetition: boolean
  variant?: 'forest' | 'valley'
  hasEnteredPhaseTwo: boolean
}) => {
  const phase2Flag = useFlag('contestPhase2')
  const disableVoting = useFlag('disableVoting')
  const buttonLabel =
    phase2Flag && !hasEnteredPhaseTwo ? 'Cast Your Vote' : 'Check My Progress'
  const buttonURL =
    phase2Flag && !hasEnteredPhaseTwo
      ? competitionLeaderboardPath
      : dashboardPath
  if (disableVoting) {
    return (
      <ButtonLink
        className='pointer-events-auto'
        to={competitionLeaderboardPath}
        variant={variant}
      >
        See Leaderboard
      </ButtonLink>
    )
  }
  // If the user has already entered the competition, show a button to check their progress regardless of the competition phase
  else if (hasEnteredCompetition) {
    return (
      <ButtonLink
        className='pointer-events-auto'
        to={buttonURL}
        variant={variant}
      >
        {buttonLabel}
      </ButtonLink>
    )
    // If the user has not entered the competition and phase two has not started, show a button to enter the competition
  } else if (!competitionPhaseTwoStarted) {
    return (
      <ButtonLink
        className='pointer-events-auto'
        to='/competition/apply'
        variant={variant}
      >
        Enter to win
      </ButtonLink>
    )
    // If the user has not entered the competition and phase two has started, do not show a button
  } else return null
}

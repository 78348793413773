import { EmptyState, Carousel, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { getImageResized } from 'src/utils'
import { GuideImage } from '../GuideImage'

interface GuideDrawerOldHeroProps {
  imageLinks: string[]
  ownerName: string
  selectedEvent: PublishedEvent
}

export const GuideDrawerOldHero = ({
  imageLinks = [],
  ownerName,
  selectedEvent,
}: GuideDrawerOldHeroProps) => {
  if (
    isEmpty(imageLinks) &&
    isEmpty(selectedEvent?.images) &&
    isEmpty(selectedEvent?.imageUrl)
  )
    return (
      <div
        className='h-55 p-x-8 rounded-3 flex h-full w-full flex-col items-center justify-center overflow-hidden'
        data-testid='GuideDrawerOldHero-emptyState'
      >
        <EmptyState name='noImages' />
      </div>
    )

  return (
    <div className='h-55 p-x-8 w-full'>
      <div className='bg-warm-grey rounded-3 h-full w-full overflow-hidden'>
        <Carousel
          key={imageLinks?.join('')}
          isLight
          hideArrows={imageLinks?.length <= 1}
          hideDots={true}
          isDisabled={imageLinks?.length <= 1}
          size='small'
        >
          {imageLinks?.map((image, index) => (
            <div key={index} className='h-55 relative'>
              <GuideImage
                className='h-full w-full object-cover'
                draggable={false}
                src={getImageResized({
                  imageUrl: image,
                  shape: 'landscape',
                  width: 1000,
                })}
              />
              {image?.includes('tpg-user-uploaded') && (
                <div
                  className='p-x-2 p-y-1.5 bg-forest-dark/60 [&>i]:c-white transition-width group absolute bottom-4 right-6 flex h-7 w-7 flex-row items-center justify-center gap-1 rounded-full backdrop-blur-sm hover:w-fit'
                  data-testid='GuideDrawerOldHero-badge'
                >
                  <Icon name='person' size='small' />
                  {ownerName && (
                    <span className='c-white type-subtext pointer-events-none hidden group-hover:block'>
                      By {ownerName}
                    </span>
                  )}
                </div>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

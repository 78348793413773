import { useEffect, useRef, useState } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useIsElementOnScreen } from 'src/common/hooks'
import type { UseGetProfileGuidesQuery } from '../../useGetProfileGuidesQuery'

interface ProfileGuidesLoadMoreProps {
  onGetMoreResults: UseGetProfileGuidesQuery['onGetMoreResults']
  profileGuidesData: UseGetProfileGuidesQuery['profileGuidesData']
}

/** @todo create common component for infinite scroll load more components (e.g. DashboardFeedLoadMore)  */
export const ProfileGuidesLoadMore = ({
  onGetMoreResults,
  profileGuidesData,
}: ProfileGuidesLoadMoreProps) => {
  const fetchMoreRef = useRef({
    cursor: '',
    isLoading: false,
  })
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { endCursor, hasNextPage } = profileGuidesData?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(async (after: string) => {
    if (isLoading || after === fetchMoreRef.current.cursor) return

    fetchMoreRef.current.cursor = after
    setIsLoading(true)

    try {
      await onGetMoreResults({
        variables: {
          after,
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, 300)

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) debouncedGetMoreResults(endCursor)
  }, [debouncedGetMoreResults, endCursor, hasNextPage, isLastElementVisible])

  if (!hasNextPage) return

  return (
    <>
      <div className='invisible m-0 h-0' ref={lastElementRef} />
      {isLoading && <SkeletonDots />}
    </>
  )
}

import {
  Button,
  Icon,
  Illustration,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useForm } from 'react-hook-form'
import type { GeocodeResult } from 'use-places-autocomplete'
import { Geocoder } from 'src/common/components'
import { CACHE_KEYS } from 'src/common/components/Geocoder/useGeocoderSearch'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { baseUrl, competitionApplyPath, profilePath } from 'src/constants'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import { accountHandleValidationRegex } from 'src/constants/validation'
import { useGetUserOnboardingMutation } from './useGetUserOnboardingMutation'
import { useGetUserOnboardingQuery } from './useGetUserOnboardingQuery'
import { useUserProfileMutation } from '../trips/hooks'
import { constructAddressInput } from '../trips/utils'

export const OnboardingModal = ({ onClose }: { onClose: VoidFunction }) => {
  const { data } = useGetUserOnboardingQuery()
  const [updateUserProfileMutation] = useGetUserOnboardingMutation()
  const [updateUserAddressMutation] = useUserProfileMutation()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()

  const { currentUser } = data ?? {}
  const { accountHandle: userAccountHandle } = currentUser?.userProfile ?? {}
  const { formState, handleSubmit, register, watch, getValues, setValue } =
    useForm({
      defaultValues: {
        accountHandle: '',
        geocode: initialGeocoder,
      },
    })
  const { errors } = formState
  const { accountHandle: accountHandleError } = errors
  const accountHandle = watch('accountHandle', '')

  const handleError = () => {
    if (accountHandleError?.type === 'validate')
      return `${accountHandle.length}/25. Use only letters, numbers, ".", and "_". No spaces.`
  }

  const onGeocoderResult = ({
    center,
    placeName,
    addressComponents,
  }: GeocoderType) => {
    setValue('geocode', {
      center: [center[0], center[1]],
      placeName: placeName,
      addressComponents,
    })
  }

  const updatesAccountHandle = async (accountHandle: string) => {
    await updateUserProfileMutation({
      variables: {
        input: {
          userProfileRequest: {
            accountHandle: accountHandle || userAccountHandle,
          },
        },
      },
      // ensure that step two in the competition sign-up flow shows the new account handle
      refetchQueries: window.location.pathname.includes(competitionApplyPath)
        ? ['CurrentUserCompetitionInfo']
        : null,
    })
  }

  const updateAddress = async () => {
    if (!isEmpty(getValues('geocode')?.addressComponents)) {
      const { addressComponents, center, placeId } = getValues('geocode') as {
        addressComponents?: GeocodeResult['address_components']
        center: number[]
        placeId?: string
      }
      const { addressLine1, addressLine2, city, state, country, zipcode } =
        constructAddressInput({ addressComponents, center, placeId })

      await updateUserAddressMutation({
        variables: {
          input: {
            id: currentUser?.id,
            addressFirstLine: addressLine1,
            addressSecondLine: addressLine2,
            city,
            country,
            state,
            zip: zipcode,
          },
        },
      })

      pushDataToDataLayer('account_update', {
        accountHandle,
        addressFirstLine: addressLine1,
        addressSecondLine: addressLine2,
        city,
        country,
        profileUrl: `${baseUrl}${profilePath}/${accountHandle}`,
        state,
        zip: zipcode,
      })
    }
  }

  const onSubmit = async ({ accountHandle }) => {
    try {
      await updatesAccountHandle(accountHandle)
      await updateAddress()

      addSuccessSnack({ title: 'Personal Information updated!' })
      onClose()
    } catch (error) {
      if (
        error?.message ===
        '%{account_handle: ["account_handle already exists"]}'
      ) {
        addErrorSnack({ title: 'Account handle already exists.' })
      } else {
        addErrorSnack({ title: 'An unexpected error was found.' })
      }
    }
  }

  return (
    <Modal size='medium' title='Personalize Your Profile' onDismiss={onClose}>
      <div className='[&>div]:of-y-visible' id='onboarding-scroll-wrapper'>
        <ModalScrollContents>
          <form noValidate className='mt-8' onSubmit={handleSubmit(onSubmit)}>
            <div className='max-w-254px max-h-182px md:max-w-216px md:max-h-155px mx-auto hidden md:block'>
              <Illustration name='world' />
            </div>
            <div className='mb-8 space-y-6 md:mb-0 md:mt-5 md:space-y-3'>
              <Input
                fullWidth
                errorText={handleError()}
                helperText={`${accountHandle.length}/25. Use only letters, numbers, ".", and "_". No spaces.`}
                label='Claim your account handle'
                placeholder='e.g. john_doe'
                {...register('accountHandle', {
                  required: false,
                  validate: (value: string) => {
                    const regex = new RegExp(accountHandleValidationRegex)
                    return regex.test(value)
                  },
                })}
              />
              <Geocoder
                config={{
                  requestOptions: { componentRestrictions: { country: [] } },
                  cacheKey: CACHE_KEYS.withoutRestriction,
                }}
                geocoder={getValues('geocode')}
                label='Where’s home or your most central location?'
                placeholder='Search'
                slotBefore={
                  <span className='c-new-forest'>
                    <Icon name='placeOutline' />
                  </span>
                }
                onResult={onGeocoderResult}
              />
            </div>
            <div className='max-w-254px max-h-182px md:max-w-216px md:max-h-155px mx-auto block md:hidden'>
              <Illustration name='world' />
            </div>
          </form>
        </ModalScrollContents>
      </div>
      <ModalActions>
        <Button label='Done' type='submit' onClick={handleSubmit(onSubmit)} />
      </ModalActions>
    </Modal>
  )
}

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_EVENT_TO_GUIDE = gql(` 
    mutation AddEventToGuideOld($input: AddPublishedEventToGuideDraftInput!){
        addPublishedEventToGuideDraft(input: $input) {
             guideDraft {
                id
                name
                publishedEvents {
                    id
                    externalTypeId
                }
            }
            publishedEvent {
                id
            }
        }
    }    
`)

export const useAddEventToGuideOld = (options?) =>
  useMutation(ADD_EVENT_TO_GUIDE, options)

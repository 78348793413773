import { useState } from 'react'
import { Icon, Link } from '@travelpass/design-system'
import type { Lodging } from 'src/__generated__/graphql'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { HotelAmenitiesModal } from 'src/common/components'
import { ShareAndSaveButtons } from 'src/common/components/ShareAndSaveButtons'
import { useFlag } from 'src/common/hooks'
import { constructPopularHotelAmenities } from 'src/utils'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../hooks'
import { getReviewRatingDescription } from '../../utils'

interface HotelOverviewTopAmenitiesProps {
  amenities: Lodging['amenities']
  hotelId: string
  name: string
}

export const HotelOverviewTopAmenities = ({
  amenities,
  hotelId,
  name,
}: HotelOverviewTopAmenitiesProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const { averageOverall, totalReviews } = hotelReviewsSummaryData ?? {}
  const showAverageOverall = totalReviews > 0
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      {isModalOpen && (
        <HotelAmenitiesModal
          amenities={amenities}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div className='w-full space-y-4'>
        <h4 className='type-h4'>What This Hotel Offers</h4>
        <div className='gap-x-3.25 gap-y-3.25 grid grid-cols-2 py-5 lg:grid-cols-3'>
          {constructPopularHotelAmenities(amenities)
            ?.slice(0, 5)
            ?.map(({ icon, label }, index) => (
              <div
                key={`top-amenity-${index}`}
                className='flex-justify-center grid-items-center shadow-2 rd-2 box-border flex w-auto flex-col gap-2 overflow-hidden bg-white px-1 py-4 shadow-[0px_1px_4px_0px_#7C7C7C40]'
              >
                <Icon name={icon} />
                <p className='type-body-1-medium c-forestLight text-center'>
                  {label}
                </p>
              </div>
            ))}
          {showAverageOverall && (
            <div className='flex-justify-center grid-items-center rd-2 flex w-auto flex-col gap-2 bg-white px-1 py-4 shadow-[0px_1px_4px_0px_#7C7C7C40]'>
              <span className='text-6 font-500 color-newForest leading-6'>
                {averageOverall}
              </span>
              <p className='type-body-1-medium'>
                {getReviewRatingDescription(averageOverall)}
              </p>
            </div>
          )}
        </div>
        <div className='text-end'>
          <Link label='See All' onClick={() => setIsModalOpen(true)} />
        </div>
        {!newHotelDetails && (
          <ShareAndSaveButtons
            item={{ id: hotelId, name, type: CollectionItemIdType.Hotel }}
          />
        )}
      </div>
    </>
  )
}

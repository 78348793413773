import { Avatar, Button } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import type { CuratedUserProfilesQuery } from 'src/__generated__/graphql'
import { defaultProfileImage } from 'src/constants'
import { FollowButtonOld } from 'src/pages/profile-old/components/FollowButtonOld/FollowButtonOld'
import { getProfilePageUrl } from 'src/utils'
import { TravelpassLogo } from '../TravelpassLogo'

const pCss = 'flex flex-col gap-y-1 text-center'
const numberCss = 'type-h5'
const labelCss = 'type-small-text'

interface TravelerCardProps {
  reason?: string
  traveler: CuratedUserProfilesQuery['curatedUserProfiles'][0]
  hideStats?: boolean
  onFollow?: () => void
}

const REASON_DICTIONARY = [
  {
    original: 'You have travlers in common',
    match: 'You May Know',
  },
  {
    original: 'Travels the way you do',
    match: 'Similar Travel Style',
  },
  {
    original: 'Trending now',
    match: 'Guides You May Like',
  },
]

export const TravelerCard = ({
  traveler,
  reason,
  hideStats = false,
  onFollow,
}: TravelerCardProps) => {
  const navigate = useNavigate()
  const {
    userId,
    profileImageUrl,
    accountHandle,
    displayName,
    bio,
    guideCount,
    followersConnection,
    followingConnection,
    isUserOwner,
    isFollowed,
  } = { ...traveler }

  const mappedReason =
    reason?.length > 0
      ? REASON_DICTIONARY.find(
          r => r.original.toLowerCase() === reason.toLowerCase()
        )?.match || reason
      : reason

  const TravelerCardButton = ({
    isFollowing = false,
  }: {
    isFollowing: boolean
  }) =>
    isFollowing ? (
      <div className='pb-1.5'>
        <Button
          fullWidth
          endIcon='check'
          label='Following'
          size='small'
          variant='text'
          onClick={() => navigate(getProfilePageUrl(accountHandle))}
        />
      </div>
    ) : (
      <FollowButtonOld
        fullWidth
        accountHandle={accountHandle}
        displayName={displayName}
        endIcon='add'
        isFollowed={isFollowed}
        label='Follow'
        userId={userId}
        variant='filled'
        onFollow={onFollow}
      />
    )

  return (
    <div className='rounded-8px border-width-1px border-grey400 group relative flex h-fit flex-col gap-y-3 border-solid bg-white pt-8 md:gap-y-2 md:pt-4'>
      <Link
        className='flex h-full flex-col justify-between no-underline'
        state={{ referrer: window.location.href }}
        to={getProfilePageUrl(accountHandle)}
      >
        <div className='flex justify-center'>
          <TravelpassLogo className='w-7' variant='symbol-valley' />
        </div>
        <h4 className='type-h4 mt-2 text-center'>{displayName}</h4>
        <p className='type-overline c-grey700 text-center'>{accountHandle}</p>

        <div className='my-5 flex justify-center'>
          <Avatar size='lg' src={profileImageUrl || defaultProfileImage} />
        </div>
        {mappedReason && (
          <div className='flex justify-center'>
            <p className='bg-tealMedium bg-op-5 border-tealMedium border-op-50 text-tealDark text-3.5 font-500 border-style-solid border-width-1px rounded-5px inline-flex items-center justify-center gap-1 px-2 py-1 capitalize'>
              {mappedReason}
            </p>
          </div>
        )}

        <p className='min-h-3rem type-body-2 c-grey900 max-h-50px line-clamp-3 flex-grow px-5 text-center'>
          {bio}
        </p>

        {!hideStats && (
          <div className='lg:mb6 flex justify-center gap-x-5'>
            <p className={pCss}>
              <span className={numberCss}>{guideCount}</span>
              <span className={labelCss}>Guides</span>
            </p>
            <p className={pCss}>
              <span className={numberCss}>
                {followersConnection.totalCount}
              </span>
              <span className={labelCss}>Followers</span>
            </p>
            <p className={pCss}>
              <span className={numberCss}>
                {followingConnection.totalCount}
              </span>
              <span className={labelCss}>Following</span>
            </p>
          </div>
        )}
      </Link>
      {!isUserOwner && (
        <div
          className={classNames(
            'transition-all ease-in lg:group-hover:opacity-100',
            !isFollowed &&
              'lg:translate-y-100% lg:group-hover:translate-y-0% lg:opacity-0'
          )}
        >
          <TravelerCardButton isFollowing={isFollowed} />
        </div>
      )}
    </div>
  )
}

import { useEffect } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import type { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import {
  type GetPredefinedGuideTagsQuery,
  type GetCurrentUserQueryInDashboardQuery,
  type SocialNetwork,
  VisibilityLevel,
  ParticipantStatus,
} from 'src/__generated__/graphql'
import { useFlag, useUpsertCurrentUserProfileMutation } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { baseUrl, profilePath } from 'src/constants'
import { getProfileOwnerUrl, identifySocialLink } from 'src/utils'
import type { DashboardProfileFields } from './common/DashboardProfile/DashboardProfileInfo/types'
import { useLazyUserCompetitionStatus } from './useUserCompetitionStatus'

const getCombinedSocialLinks = (
  socialLinks: DashboardProfileFields['socialLinks'],
  identifier: DashboardProfileFields['socialLink']
): DashboardProfileFields['socialLinks'] => {
  const updatedSocialLinks = [...(socialLinks ?? [])]

  if (!isEmpty(identifier))
    updatedSocialLinks.push({
      network: identifySocialLink(identifier) as SocialNetwork,
      identifier,
    })

  return (
    updatedSocialLinks?.map(({ identifier, network }) => ({
      identifier: identifier?.startsWith('http')
        ? identifier?.replace('http://', 'https://')
        : `https://${identifier}`,
      network,
    })) ?? []
  )
}

const getCombinedLinks = (
  links: DashboardProfileFields['links'],
  url: DashboardProfileFields['linkURL'],
  title: DashboardProfileFields['linkTitle']
) => {
  const updatedLinks = [...(links ?? [])]

  if (!isEmpty(url) && !isEmpty(title))
    updatedLinks.push({
      url,
      title,
    })

  return (
    updatedLinks?.map(({ url, title }) => ({
      url: url?.startsWith('http')
        ? url?.replace('http://', 'https://')
        : `https://${url}`,
      title,
    })) ?? []
  )
}

type UseDashboardProfileInfo = {
  isLoading: boolean
  methods: UseFormReturn<DashboardProfileFields>
  onSubmit: SubmitHandler<DashboardProfileFields>
}

const useDashboardProfileInfo = ({
  onDismiss,
  userProfile,
}: {
  onDismiss: VoidFunction
  userProfile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
}): UseDashboardProfileInfo => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const [getCompetitionStatus] = useLazyUserCompetitionStatus()
  const methods = useForm<DashboardProfileFields>({
    mode: 'onSubmit',
  })
  const [updateProfileInfo, { loading: isLoading }] =
    useUpsertCurrentUserProfileMutation()
  const { addSuccessSnack } = useSnackbar()
  const {
    accountHandle,
    bio,
    displayName,
    bragContinents,
    bragCountries,
    bragStates,
    introVideoEmbed,
    links,
    profileImageSource,
    profileImageUrl,
    socialLinks,
    tags,
  } = userProfile ?? {}
  const formatLinks = links?.map(({ url, title }) => ({ url, title })) ?? []
  const formatSocialLinks =
    socialLinks?.map(({ identifier, network }) => ({
      identifier,
      network,
    })) ?? []

  useEffect(() => {
    methods.reset({
      accountHandle: accountHandle || '',
      bio: bio || '',
      bragContinents: bragContinents || 0,
      bragCountries: bragCountries || 0,
      bragStates: bragStates || 0,
      displayName: displayName || '',
      introVideoEmbed: introVideoEmbed || '',
      links: links ? formatLinks : [],
      linkTitle: '',
      linkURL: '',
      socialLink: '',
      socialLinks: socialLinks ? formatSocialLinks : [],
      tags: tags ?? [],
    })
  }, [userProfile])

  const isLinkValid = (linkURL: string, linkTitle: string) => {
    if (isEmpty(linkURL) && !isEmpty(linkTitle)) {
      methods.setError('linkURL', {
        type: 'manual',
        message: 'A URL is required',
      })
      return false
    }

    if (!isEmpty(linkURL) && isEmpty(linkTitle)) {
      methods.setError('linkTitle', {
        type: 'manual',
        message: 'A title is required',
      })
      return false
    }

    return true
  }

  /**
   * It's meant to track the changes user made on its profile
   * @param profile
   * @param socialLinks
   * @param links
   * @param bio
   * @param bragContinents
   * @param bragCountries
   * @param bragStates
   */
  const pushProfileUpdateDataToDataLayer = ({
    profile,
    socialLinks,
    links,
    bio,
    bragContinents,
    bragCountries,
    bragStates,
    accountHandle,
    tags,
  }: {
    profile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
    socialLinks
    links
    bio: string
    bragContinents: number
    bragCountries: number
    bragStates: number
    accountHandle: string
    tags: GetPredefinedGuideTagsQuery['getPredefinedGuideTags']
  }) => {
    // If profile is null, it means user is creating a new profile
    if (!profile) {
      pushDataToDataLayer('create_profile', { accountHandle })
      return
    }

    const orginalSocialLinks = profile?.socialLinks?.map(
      ({ identifier, network }) => ({ identifier, network })
    )
    const originalLinks = profile?.links?.map(({ title, url }) => ({
      title,
      url,
    }))

    if (!isEqual(orginalSocialLinks, socialLinks)) {
      pushDataToDataLayer('add_social_media', {
        social_medias: socialLinks,
      })
    }

    if (!isEqual(originalLinks, links)) {
      pushDataToDataLayer('add_links', { links })
    }

    if (profile.accountHandle !== accountHandle) {
      pushDataToDataLayer('account_update', {
        account_handle: accountHandle,
        profile_url: `${baseUrl}${profilePath}/${accountHandle}`,
      })
    }

    if (profile.bio !== bio) pushDataToDataLayer('add_bio', { bio })

    if (
      profile.bragContinents !== bragContinents ||
      profile.bragCountries !== bragCountries ||
      profile.bragStates !== bragStates
    ) {
      pushDataToDataLayer('add_travel_tracker', {
        bragContinents,
        bragCountries,
        bragStates,
      })
    }

    if (!isEqual(profile.tags, tags)) {
      pushDataToDataLayer('add_travel_style', {
        travel_style: tags,
      })
    }
  }

  const handleCompetitionStatusChange = async () => {
    const { data: competitionData } = await getCompetitionStatus()
    const isReady =
      competitionData?.currentUser?.userProfile?.competitionInfo?.status ===
      ParticipantStatus.Ready
    if (isReady) {
      pushDataToDataLayer('competition_ready', {
        user_id: competitionData.currentUser.userProfile.userId,
        status: competitionData.currentUser.userProfile.competitionInfo.status,
        first_name: competitionData.currentUser.firstName,
        last_name: competitionData.currentUser.lastName,
        profile_url: `${window.location.origin}${getProfileOwnerUrl(competitionData?.currentUser?.userProfile?.accountHandle)}`,
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
    }
  }

  const onSubmit = async ({
    accountHandle,
    bio,
    bragStates,
    bragContinents,
    bragCountries,
    displayName,
    introVideoEmbed,
    links,
    linkTitle,
    linkURL,
    socialLink,
    socialLinks,
    tags,
  }: DashboardProfileFields) => {
    if (!isLinkValid(linkURL, linkTitle)) return

    const combinedSocialLinks = getCombinedSocialLinks(socialLinks, socialLink)
    const combinedLinks = getCombinedLinks(links, linkURL, linkTitle)

    try {
      await updateProfileInfo({
        variables: {
          input: {
            userProfileRequest: {
              accountHandle,
              bio,
              displayName,
              bragContinents: parseInt(bragContinents.toString()),
              bragCountries: parseInt(bragCountries.toString()),
              bragStates: parseInt(bragStates.toString()),
              links: combinedLinks || [],
              introVideoEmbed: introVideoEmbed || '',
              profileImageSource,
              profileImageUrl,
              profileTagIds: tags?.map(tag => tag?.id) || [],
              socialLinks: combinedSocialLinks || [],
              visibilityLevel: VisibilityLevel.Public,
            },
          },
        },
      })
      pushProfileUpdateDataToDataLayer({
        profile: userProfile,
        socialLinks: combinedSocialLinks,
        links: combinedLinks,
        bio,
        bragContinents,
        bragCountries,
        bragStates,
        accountHandle,
        tags: tags ?? [],
      })

      if (enableCompetition2024) handleCompetitionStatusChange()

      addSuccessSnack({
        title: 'Profile updated!',
      })
      onDismiss()
    } catch (error) {
      if (
        error?.message ===
        '%{account_handle: ["account_handle already exists"]}'
      ) {
        methods.setError('accountHandle', {
          type: 'validate',
          message: 'Account handle already exists.',
        })
      }
    }
  }

  return {
    isLoading,
    methods,
    onSubmit,
  }
}

export { getCombinedLinks, getCombinedSocialLinks, useDashboardProfileInfo }

import { GuideItemType } from 'src/__generated__/graphql'
import {
  DashboardFeedCardCreatedGuide,
  DashboardFeedCardRepublishedGuideCreatedEvent,
  DashboardFeedCardRepublishedGuideGrouped,
  DashboardFeedCardRepublishedGuidePhotos,
} from './DashboardFeedCard'
import type { DashboardFeedItem } from '../../types'

interface DashboardFeedWrapperProps {
  dashboardFeedItem: DashboardFeedItem
}

/** @todo flags will be removed as soon as we release the new feed */
export const DashboardFeedWrapper = ({
  dashboardFeedItem,
}: DashboardFeedWrapperProps) => {
  const { groupedUpdate, guide, type } = dashboardFeedItem ?? {}
  const { newEvents, newImages } = guide?.latestUpdates ?? {}

  if (groupedUpdate || newEvents?.events?.length > 1)
    return (
      <DashboardFeedCardRepublishedGuideGrouped
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  if (type === GuideItemType.Updates && !!newImages?.images?.length)
    return (
      <DashboardFeedCardRepublishedGuidePhotos
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  if (type === GuideItemType.Updates && newEvents?.events?.length === 1)
    return (
      <DashboardFeedCardRepublishedGuideCreatedEvent
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  if (type === GuideItemType.Updates)
    return (
      <DashboardFeedCardRepublishedGuideGrouped
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  return <DashboardFeedCardCreatedGuide dashboardFeedItem={dashboardFeedItem} />
}

import { useEffect, useState } from 'react'
import { Button, Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import type { HotelReviewsListHotelReviewsQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { HotelReviewsModal } from './HotelReviewsModal'
import { HotelReview } from './common/HotelReview'
import { HotelReviewV2 } from './common/HotelReview/HotelReviewV2'
import { HotelReviewsSummarySection } from './common/HotelReviewsSummarySection'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../hooks/useHotelReviewsSummaryListHotelReviewsQuery'

interface HotelReviewsProps {
  hotelReviewsData: HotelReviewsListHotelReviewsQuery['listHotelReviews']
}

export const HotelReviews = ({ hotelReviewsData }: HotelReviewsProps) => {
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    averageOverall,
    cleanlinessAverage,
    locationAverage,
    staffAverage,
    totalReviews,
  } = hotelReviewsSummaryData ?? {}
  const { reviews } = hotelReviewsData ?? {}
  const showReviews = reviews?.length >= 1
  const showViewAllButton = reviews?.length >= 4
  const newHotelDetails = useFlag('newHotelDetails')

  const onModalClose = () => setIsModalOpen(false)

  const onModalOpen = () => setIsModalOpen(true)

  useEffect(() => {
    isModalOpen && pushDataToDataLayer('view_reviews')
  }, [isModalOpen])

  return (
    <>
      {isModalOpen && <HotelReviewsModal onClose={onModalClose} />}
      {showReviews && (
        <PageLayoutContainer>
          <div className='flex-justify-center grid-items-center flex'>
            <div className='grid-items-center lg:py-15 flex w-full flex-col gap-12 py-10'>
              <h3 className='type-h3'>Guest Reviews</h3>
              {!newHotelDetails && <Divider />}
              <div className='flex w-full flex-col gap-5'>
                <HotelReviewsSummarySection
                  cleanlinessAverage={cleanlinessAverage}
                  customerReviewScore={averageOverall}
                  locationAverage={locationAverage}
                  staffAverage={staffAverage}
                  totalCustomerReviews={totalReviews}
                />
                {!newHotelDetails && <Divider className='my-5' />}
                <div className='flex-justify-center flex'>
                  <div
                    className={classNames(
                      'grid-auto-flow-dense grid grid-cols-1 gap-10',
                      {
                        'lg:grid-cols-2': !newHotelDetails,
                        'lg:mt10 w-full': newHotelDetails,
                      }
                    )}
                  >
                    {reviews
                      ?.slice(0, newHotelDetails ? 3 : 4)
                      ?.map((hotelReviewData, index) => (
                        <div
                          key={`${hotelReviewData?.id}-${index}`}
                          className={classNames('flex flex-col', {
                            'lg:items-center': newHotelDetails,
                          })}
                        >
                          {newHotelDetails ? (
                            <>
                              <HotelReviewV2
                                hotelReviewData={hotelReviewData}
                              />
                              <div className='pt-0 lg:mx-10 lg:hidden'>
                                <Divider />
                              </div>
                            </>
                          ) : (
                            <HotelReview
                              showExcerpt
                              hotelReviewData={hotelReviewData}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {showViewAllButton && (
                <Button
                  label='VIEW ALL'
                  variant='outlined'
                  onClick={onModalOpen}
                />
              )}
            </div>
          </div>
        </PageLayoutContainer>
      )}
    </>
  )
}

import { Chip } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { useGetPredefinedProfileTagsQuery } from 'src/common/hooks'
import type { DashboardProfileFields } from './types'

const maxSelectedTags = 5

export const DashboardProfileInfoTags = () => {
  const { setValue, watch } = useFormContext<DashboardProfileFields>()
  const { availableTags } = useGetPredefinedProfileTagsQuery()
  const selectedTags: DashboardProfileFields['tags'] = watch('tags') ?? []

  const getSelectedTag = (tag: DashboardProfileFields['tags'][0]) =>
    selectedTags?.find(selectedTag => selectedTag.id === tag?.id)

  const onTagClick = (tag: DashboardProfileFields['tags'][0]) => {
    if (getSelectedTag(tag)) {
      setValue(
        'tags',
        selectedTags.filter(selectedTag => selectedTag.id !== tag?.id)
      )
    } else if (selectedTags?.length < maxSelectedTags) {
      setValue('tags', [...selectedTags, tag])
    }
  }

  return (
    <section className='space-y-6' id='dashboard-profile-info-tags'>
      <header className='space-y-2'>
        <h6 className='type-h6-desktop c-black'>Describe Your Travel Style</h6>
        <p className='type-body-2-desktop c-grey-800'>
          Select up to five tags.
        </p>
      </header>
      <div className='flex flex-wrap gap-4'>
        {availableTags?.map(tag => (
          <Chip
            key={tag?.id}
            isDisabled={
              selectedTags?.length >= maxSelectedTags && !getSelectedTag(tag)
            }
            isSelected={!!getSelectedTag(tag)}
            label={tag?.name}
            onClick={() => onTagClick(tag)}
          />
        ))}
      </div>
    </section>
  )
}

import isEmpty from 'lodash.isempty'
import { matchPath } from 'react-router-dom'
import type {
  UserImage,
  GetGuideEventsQueryInGuideDetailsQuery,
  GetGuideDraftQueryInGuideDraftQuery,
  GenericAddress,
  PublishedEvent,
} from 'src/__generated__/graphql'
import {
  destinationsSegment,
  experiencesSegment,
  hotelsSegment,
} from 'src/constants'
import { getEventImage } from 'src/pages/trips/utils'
import {
  constructAddress,
  generateExperienceDetailsUrl,
  generateHotelDetailsUrl,
  getStateAbbreviation,
} from 'src/utils'
import type { GuideEventsData, GuideMetaData } from './types'
import type { GuideDraftData } from '../lists'

const checkIsGuideEventAvailableOnTravelpass = (
  selectedEvent: PublishedEvent
) => {
  if (selectedEvent?.standardHotelId || selectedEvent?.productId) return true
  return false
}

const getGuideDrawerImages = ({
  externalImages,
  internalImages,
}: {
  externalImages: string[]
  internalImages: UserImage[]
}) => {
  const externalProcessedImages =
    externalImages?.slice(0, 5)?.map(image => getEventImage(image)) ?? []
  const internalProcessedImages = internalImages?.map(image => image?.url) ?? []

  return internalProcessedImages.concat(externalProcessedImages)
}

const getGuideDrawerWebsite = ({
  externalWebsite,
  selectedEvent,
}: {
  externalWebsite: string
  selectedEvent: PublishedEvent
}): string => {
  const { addresses, name, productId, standardHotelId } = selectedEvent ?? {}
  const { city, state } = addresses?.[0] ?? {}
  const stateAbbreviation = getStateAbbreviation(state, '')

  if (standardHotelId)
    return `${window.origin}${generateHotelDetailsUrl({
      city,
      id: standardHotelId,
      name,
      state,
      stateAbbreviation,
    })}`

  if (productId)
    return `${window.origin}${generateExperienceDetailsUrl({
      city,
      id: encodeURIComponent(btoa(`ExperienceSearchProduct:${productId}`)),
      name,
      state,
      stateAbbreviation,
    })}`

  return externalWebsite ?? ''
}

const getGuideDrawerWebsiteText = (website: string) => {
  const pathname = new URL(website).pathname

  if (
    matchPath(
      `/${destinationsSegment}/:state/:city/${experiencesSegment}/:experienceId/:experienceName`,
      pathname
    )
  )
    return 'See Experience Details'

  if (
    matchPath(
      `${destinationsSegment}/:state/:city/${hotelsSegment}/:hotelId/:hotelName`,
      pathname
    )
  )
    return 'See Hotel Details'

  return website ?? ''
}

const getGuideEventMarkers = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideEventsData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideEventsData['uncategorizedPublishedEvents']
}): PublishedEvent[] => {
  if (
    isEmpty(paginatedEventCategories) &&
    isEmpty(uncategorizedPublishedEvents)
  )
    return []

  const flattenedPublishedEvents = paginatedEventCategories?.edges?.flatMap(
    paginatedEventCategory =>
      paginatedEventCategory?.node?.publishedEvents?.edges ?? []
  )

  return flattenedPublishedEvents
    .concat(uncategorizedPublishedEvents?.edges ?? [])
    ?.reduce((total, publishedEvent) => {
      const { addresses } = publishedEvent?.node ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(publishedEvent?.node)

      return total
    }, [])
}

const getGuideMetaData = ({
  address,
  canonicalUrl,
  image,
  name,
  numberEvents,
  ownerName,
}: {
  address: GenericAddress
  canonicalUrl?: string
  image: string
  name: string
  numberEvents: number
  ownerName: string
}): GuideMetaData => ({
  canonicalUrl: canonicalUrl ?? '',
  metaDescription: getGuideMetaDescription({
    address,
    numberEvents,
    ownerName,
  }),
  metaImage: image ?? '',
  pageName: name ?? '',
})

const getGuideMetaDescription = ({
  address,
  numberEvents,
  ownerName,
}: {
  address: GenericAddress
  numberEvents: number
  ownerName: string
}) => {
  const addressFormatted = constructAddress({
    addressFirstLine: address?.addressLine1,
    city: address?.city,
    state: address?.state,
  })
  let description = ''

  if (addressFormatted)
    description += `Discover what to do in ${addressFormatted}! `

  if (ownerName) description += `${ownerName} recommends `

  if (numberEvents > 1) description += `${numberEvents} `

  return `${description}things to do, places to eat, hidden gems, and more.`
}

const getGuideMetricString = (numberOfMetrics: number, metric: string) => {
  const numberOfMetricsFormatted =
    numberOfMetrics === null || isNaN(numberOfMetrics) ? 0 : numberOfMetrics

  if (metric === '') return numberOfMetricsFormatted.toString()

  const trailingChar = numberOfMetricsFormatted === 1 ? '' : 's'

  return `${numberOfMetricsFormatted} ${metric}${trailingChar}`
}

const getGuideUpdateQuery = ({
  fetchMoreResult,
  id,
  previousResult,
}: {
  fetchMoreResult:
    | GetGuideEventsQueryInGuideDetailsQuery
    | GetGuideDraftQueryInGuideDraftQuery
  id: string
  previousResult:
    | GetGuideEventsQueryInGuideDetailsQuery
    | GetGuideDraftQueryInGuideDraftQuery
}) => {
  const fetchMoreNode = fetchMoreResult?.node as
    | GuideDraftData
    | GuideEventsData
  const previousNode = previousResult?.node as GuideDraftData | GuideEventsData
  const uncategorizedEvents =
    fetchMoreNode?.uncategorizedPublishedEvents?.edges || []
  const fetchMoreCategory =
    fetchMoreNode?.paginatedEventCategories?.edges?.find(
      category => category?.node?.id === id
    )
  const updatedUncategorizedPublishedEvents = {
    ...previousNode.uncategorizedPublishedEvents,
  }

  // Update the uncategorized events
  if (!id && !!uncategorizedEvents.length) {
    updatedUncategorizedPublishedEvents.edges = [
      ...(previousNode?.uncategorizedPublishedEvents?.edges || []),
      ...uncategorizedEvents,
    ]
    updatedUncategorizedPublishedEvents.pageInfo =
      fetchMoreNode?.uncategorizedPublishedEvents?.pageInfo
  }

  // Update the paginated event categories
  const updatedPaginatedEventCategories = {
    ...previousNode.paginatedEventCategories,
    edges: previousNode.paginatedEventCategories.edges.map(category => {
      if (category?.node?.id === id) {
        return {
          ...category,
          node: {
            ...category.node,
            publishedEvents: {
              ...category.node.publishedEvents,
              edges: [
                ...(category.node.publishedEvents.edges || []),
                ...(fetchMoreCategory?.node?.publishedEvents?.edges || []),
              ],
              pageInfo: fetchMoreCategory?.node?.publishedEvents?.pageInfo,
            },
          },
        }
      }

      return category
    }),
  }

  // Return the updated result
  return {
    ...previousResult,
    node: {
      ...previousNode,
      paginatedEventCategories: updatedPaginatedEventCategories,
      uncategorizedPublishedEvents: updatedUncategorizedPublishedEvents,
    },
  }
}

export {
  checkIsGuideEventAvailableOnTravelpass,
  getGuideDrawerImages,
  getGuideDrawerWebsite,
  getGuideDrawerWebsiteText,
  getGuideEventMarkers,
  getGuideMetaData,
  getGuideMetricString,
  getGuideUpdateQuery,
}

import { useState } from 'react'
import { Link, Modal, ModalScrollContents } from '@travelpass/design-system'
import type { CarouselProps } from '@travelpass/design-system'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import type { MappedImage } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelRoomCarousel } from '../common'
import type { HotelRoom } from '../hotelRoomsTypes'

interface HotelRoomModalProps {
  hotelRoomData: HotelRoom
  images: HotelRoom['images']
  slideIndex?: CarouselProps['slideIndex']
  onClose(): void
  onImageError?(errorImage: MappedImage): void
}

export const HotelRoomModal = ({
  hotelRoomData,
  images,
  slideIndex,
  onClose,
  onImageError,
}: HotelRoomModalProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [showAll, setShowAll] = useState(!isMobile)
  const { amenities, description, name } = hotelRoomData ?? {}
  const constructAmenities = [...amenities]
    .slice(0, showAll ? amenities.length : 6)
    .sort()
  const constructedAmenities = constructAmenities.map(amenity => (
    <li key={amenity} className='truncate'>
      {amenity}
    </li>
  ))

  return (
    <Modal
      size={newHotelDetails ? 'xl' : 'medium'}
      title={name}
      onDismiss={onClose}
    >
      <ModalScrollContents>
        <div className={hotelRoomModalCss}>
          {/* carousel dots are hidden until the design system is updated to allow controlling the number of dots */}
          <HotelRoomCarousel
            keyBoardControl
            altSuffix={`${name} - modal`}
            height='h-45 md:h-97'
            hideDots={true}
            images={images}
            slideIndex={slideIndex}
            onImageError={onImageError}
          />
          {newHotelDetails && <h5 className='type-h5'>Know Before You Go</h5>}
          <p className='type-body-1 c-grey900'>{description}</p>
          <div
            className={classNames('flex flex-col gap-6', {
              'bg-warmGrey rounded-12px p-10px': newHotelDetails,
            })}
          >
            <h6 className={hotelRoomModalTitleCss}>Amenities</h6>
            <ul
              className={classNames(
                'grid-auto-flow-dense text-body-1 m-0 grid list-inside grid-cols-1 gap-x-4 p-0',
                {
                  'md:grid-cols-2': !newHotelDetails,
                  'md:grid-cols-3': newHotelDetails,
                }
              )}
            >
              {constructedAmenities}
            </ul>
            {isMobile && (
              <Link
                label={showAll ? 'See less' : 'See all'}
                onClick={() => setShowAll(!showAll)}
              />
            )}
          </div>
        </div>
      </ModalScrollContents>
    </Modal>
  )
}

const hotelRoomModalCss = 'flex flex-col gap-6'

const hotelRoomModalTitleCss = 'text-h6'

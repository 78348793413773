const christmasGuides = [
  {
    holidayMagic: [
      {
        id: 1,
        owner: {
          name: 'Travelpass Official',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSjFSMzAxU0RaNzBOMFJNSzM5U1pLNEhQL3Byb2ZpbGVfaW1hZ2VzL2YxZTJmYzNkLTcxYTAtNDQyMy04NDU2LTI2Zjk1OWU5OTUxZSIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Cozy Hallmark Movie Towns To Visit This Winter',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSjFSMzAxU0RaNzBOMFJNSzM5U1pLNEhQL2d1aWRlX2ltYWdlcy9mOGUxMzJlZi01YzJjLTRiZDUtYjE2OS1hYzdmYzU2YmJjNmEiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: '',
        state: 'United States',
        href: 'https://www.travelpass.com/guides/01JDJ7J8D22YE1?name=Cozy-Hallmark-Movie-Towns-To-Visit-This-Winter',
      },
      {
        id: 2,
        owner: {
          name: 'Angela Jensen',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSERFWEcxUE1SNFdSTUMzRjlGSEY3RUpIL3Byb2ZpbGVfaW1hZ2VzLzk1Nzk0ODc4LTYxMTEtNGUyZS1iZmQ2LTJlNGQ0ZmEyOWY1ZSIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Christmas in New York City',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSERFWEcxUE1SNFdSTUMzRjlGSEY3RUpIL2d1aWRlX2ltYWdlcy85OTRiZGE4ZS00MDAxLTQyOTMtYmVmMy1jZWVjZmNmMmFmZmYiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjg0My43NSwid2lkdGgiOjE1MDB9fX0=',
        city: 'New York',
        state: 'New York',
        href: 'https://www.travelpass.com/guides/01JDN1KQV4TQ51?name=Christmas-in-New-York-City',
      },
      {
        id: 3,
        owner: {
          name: 'Adam Calder',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSEtCTktYMk1IS1hLRUhLTldSOE1GQU01L3Byb2ZpbGVfaW1hZ2VzL2MyYWM3Njc5LTk5YjItNGFmYi05YTgwLWI1Y2ZkYzg5ZDMwNyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: '10 Holiday Trains You Can Ride',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSEtCTktYMk1IS1hLRUhLTldSOE1GQU01L2d1aWRlX2ltYWdlcy9jNTE1MjY4YS03ODU3LTQ1ZWUtOTY1Yy02MjYyZTI3YTlkOTgiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: '',
        state: 'United States',
        href: 'https://www.travelpass.com/guides/01JA33QP2TCSG1?name=10-Holiday-Trains-You-Can-Ride',
      },
      {
        id: 4,
        owner: {
          name: 'Allie Wisniewski',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSEhRUlNNQTFHUFZZSFdaNFJXTVFCRFdUL3Byb2ZpbGVfaW1hZ2VzL2YxNzk4NzI3LWQ5NzctNGVhZC1iYWNjLWQ0YmQwYjRhYjIwYSIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'A Buddy-Approved Elf Guide to NYC',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSEhRUlNNQTFHUFZZSFdaNFJXTVFCRFdUL2d1aWRlX2ltYWdlcy85OTMxZTQ5OC0xNjUzLTRlNzUtOWM5MS01ZjA1ZjM2ZjU3NWQiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: 'New York',
        state: 'New York',
        href: 'https://www.travelpass.com/guides/01JDQ06EW7KVY0?name=A-Buddy-Approved-Elf-Guide-to-NYC',
      },
      {
        id: 5,
        owner: {
          name: 'Matt Joseph',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSFJRNjJXRTlWSEYyUzlWQ0tBUTc5MFc4L3Byb2ZpbGVfaW1hZ2VzLzEyMDFjZTM3LTkxNDctNDJhNS05ZWRmLTU1Zjc3MTEwOGQ1MiIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'The Best Holiday & Winter Attractions in Utah 🎄',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSFJRNjJXRTlWSEYyUzlWQ0tBUTc5MFc4L2d1aWRlX2ltYWdlcy84YzkyNDYxNy0wZWNlLTQ0ZGQtYTZiZi0zYTgwOTFkM2Y1M2YiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjg0My43NSwid2lkdGgiOjE1MDB9fX0=',
        city: 'Utah',
        state: 'United States',
        href: 'https://www.travelpass.com/guides/01JCGSN38HYH71?name=The-Best-Holiday-%26-Winter-Attractions-in-Utah',
      },
    ],
    snowyGetaways: [
      {
        id: 1,
        owner: {
          name: 'Ashley Porter',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1FKWlJNOEdLWVZOOENES1RYMzRKN0FQL3Byb2ZpbGVfaW1hZ2VzLzE2Y2E2MmU1LTMwYzgtNDA0NS04Y2RjLTIyYzc5ZDIxYWFkNSIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Iceland Road Trip 🧊',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1FKWlJNOEdLWVZOOENES1RYMzRKN0FQL2d1aWRlX2ltYWdlcy84MjdjZmZkNS01ODQ4LTQyNGQtYWFkNy1lNWQ0YmUxMmJjOTgiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: '',
        state: 'Iceland',
        href: 'https://www.travelpass.com/guides/01J8QRYKEM8251?name=Iceland-Road-Trip',
      },
      {
        id: 2,
        owner: {
          name: 'Alexis Cortez',
          profileImage:
            'https://lh3.googleusercontent.com/a/ACg8ocKK334EAEXsI5PYs-TKUKjEHaR1tl4D8jS4h2EVOf-IOAaO3KE=s192-c',
        },
        title: 'Three Days In Midway',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR0ZZVk02RFExQjE1WFQ3SlNCWVA2MVNZL2d1aWRlX2ltYWdlcy8xMGZiZDRlMS0xMjYwLTRlYzgtYjQ3OC01MzllNzg2NWU2MmMiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: 'Midway',
        state: 'Utah',
        href: 'https://www.travelpass.com/guides/01JARBD0QAW9F0?name=Three-Days-In-Midway',
      },
      {
        id: 3,
        owner: {
          name: 'Jessica Hancock',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJGQkFZNVdEUkUzUEhHMFNUSDhBQzI4L3Byb2ZpbGVfaW1hZ2VzL2VhOGNlMDAxLTYyMmMtNDgxZi04ZjVjLWEzZTc2MzlmOGM0MSIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'So you have 3 days in Banff',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJGQkFZNVdEUkUzUEhHMFNUSDhBQzI4L2d1aWRlX2ltYWdlcy9mYzZmYWExNC1iZDFlLTQyNDEtOGVmZS03NzYxOWU5NjIwMmUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: 'Banff',
        state: 'Canada',
        href: 'https://www.travelpass.com/guides/01JBFBF91A4A20?name=So-you-have-3-days-in-Banff',
      },
      {
        id: 4,
        owner: {
          name: 'Dallin Lane',
          profileImage:
            'https://s3.us-west-2.amazonaws.com/travelpass-profiles-prd/profile_images/usr_01J01SGMDTEX7XSB4JHCCX430X',
        },
        title: 'Bodø Norway',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSjAxU0dNRFRFWDdYU0I0SkhDQ1g0MzBYL2d1aWRlX2ltYWdlcy84ZDllOTdiYy0zZGM0LTQzOGMtODhjOS05NDA1OGJlYzZlZDQiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjg0My43NSwid2lkdGgiOjE1MDB9fX0=',
        city: 'Bodø',
        state: 'Norway',
        href: 'https://www.travelpass.com/guides/01J01YARQ96Y64KTC9MQKWEWKF?name=Bod%C3%B8-Norway',
      },
      {
        id: 5,
        owner: {
          name: 'Raphael Garnica',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1FRR1FFQUI0UzBWMDE3NllNMjZHNFpWL3Byb2ZpbGVfaW1hZ2VzLzA5YTk3ODU5LWYwZmMtNDNhMy1iNWYwLTA1MTFiZWU5NzUyMSIsImVkaXRzIjp7InJvdGF0ZSI6bnVsbCwicmVzaXplIjp7ImZpdCI6ImNvdmVyIiwiaGVpZ2h0IjozMDAsIndpZHRoIjozMDB9fX0=',
        },
        title: 'My Calgary Highlights',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1FRR1FFQUI0UzBWMDE3NllNMjZHNFpWL2d1aWRlX2ltYWdlcy9iMDM1N2RiMC1lNDhjLTQ3YjItODk2NS00YjdlMjI4NmFjODAiLCJlZGl0cyI6eyJyb3RhdGUiOm51bGwsInJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjE1MDAsIndpZHRoIjoxNTAwfX19',
        city: 'Calgary',
        state: 'Canada',
        href: 'https://www.travelpass.com/guides/01HWBPC39MG9C14NF2XH63P62Y?name=My-Calgary-Highlights',
      },
    ],
    winterSports: [
      {
        id: 1,
        owner: {
          name: 'Carol Hudson',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJORDNZRTBSQkI3OEpWUzkyVlZSQzhWL3Byb2ZpbGVfaW1hZ2VzLzZlNjk2ZTFlLTQ0NWEtNGZjNC1hOThhLTY5YjViNDI2NzY0YSIsImVkaXRzIjp7InJvdGF0ZSI6bnVsbCwicmVzaXplIjp7ImZpdCI6ImNvdmVyIiwiaGVpZ2h0IjozMDAsIndpZHRoIjozMDB9fX0=',
        },
        title: "Carol's Favorite Ski Resorts",
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJORDNZRTBSQkI3OEpWUzkyVlZSQzhWL2d1aWRlX2ltYWdlcy8wMzVmZWI5Yi03NTMwLTQzODQtYmZlOC1kOThmMjAyZTc0MGEiLCJlZGl0cyI6eyJyb3RhdGUiOm51bGwsInJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6ODQzLjc1LCJ3aWR0aCI6MTUwMH19fQ==',
        city: '',
        state: 'United States',
        href: 'https://www.travelpass.com/guides/01JBNDEDCDFKA0?name=Carol%27s-Favorite-Ski-Resorts',
      },
      {
        id: 2,
        owner: {
          name: 'Alex Temus',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkFDMDdaQ1JaMjQzWUhSQzc1NDdYQVhOL3Byb2ZpbGVfaW1hZ2VzL2NhNTlkZjkyLWMzYTYtNGEyYS1iMDA2LTk1NGU5NjcxZTQxYiIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Ice Climbing in Ouray',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkFDMDdaQ1JaMjQzWUhSQzc1NDdYQVhOL2d1aWRlX2ltYWdlcy83OTljOWRkMi05ZjY1LTQwYWMtYTE5NC0xMzJjMzdiZDEwYjciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: 'Ouray',
        state: 'Colorado',
        href: 'https://www.travelpass.com/guides/01JB8YAFVMJ3Q0?name=Ice-Climbing-in-Ouray',
      },
      {
        id: 3,
        owner: {
          name: 'Caleb Calder',
          profileImage:
            'https://lh3.googleusercontent.com/a/ACg8ocKiFmAH3uKpgIwBkLALPHD39ROtZG_305yYDKNfUfbd1QQ_GhI=s192-c',
        },
        title: 'Best Snowboarding Ever',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkFCSDdCOUNaUDg5MTQ4RFo4NkJIVkI4L2d1aWRlX2ltYWdlcy9mNDQwMThjMi0wZTUyLTQ5YmYtYWZkNi1iNDU1NGY3MDUwZDAiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: '',
        state: 'United States',
        href: 'https://www.travelpass.com/guides/01JB0JYTF08HW0?name=Best-Snowboarding-Ever',
      },
      {
        id: 4,
        owner: {
          name: 'Marcus Lane',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR0YxQTE1UFJSTkVHOE5WTVlSSjlSMVlKL3Byb2ZpbGVfaW1hZ2VzLzE3NWM4ZmEwLWM5MDItNGQ4Yi1iODE1LTRmMDExMGQ2NTBmZiIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Best Ice Fishing Spots Around the World',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR0YxQTE1UFJSTkVHOE5WTVlSSjlSMVlKL2d1aWRlX2ltYWdlcy82MmU5MjVhNC1hNzY3LTQxZDgtOWNiMy00NGExMWEzODFiZmUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: '',
        state: 'Finland',
        href: 'https://www.travelpass.com/guides/01J97NJDKJTQE1?name=Best-Ice-Fishing-Spots-Around-the-World',
      },
      {
        id: 5,
        owner: {
          name: 'Marcus Lane',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR0YxQTE1UFJSTkVHOE5WTVlSSjlSMVlKL3Byb2ZpbGVfaW1hZ2VzLzE3NWM4ZmEwLWM5MDItNGQ4Yi1iODE1LTRmMDExMGQ2NTBmZiIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Yuri on Ice Real-World Inspirations',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR0YxQTE1UFJSTkVHOE5WTVlSSjlSMVlKL2d1aWRlX2ltYWdlcy8wZjE4ZDBiMC1mODI2LTQ1OTAtODA4Yy1lZjU1ODM0MjBjMjAiLCJlZGl0cyI6eyJyb3RhdGUiOm51bGwsInJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjE1MDAsIndpZHRoIjoxNTAwfX19',
        city: '',
        state: 'Japan',
        href: 'https://www.travelpass.com/guides/01JAE4ZDS9JAB1?name=Yuri-on-Ice-Real-World-Inspirations',
      },
    ],
    tropicalEscapes: [
      {
        id: 1,
        owner: {
          name: 'Devyn Nainoa Lopez',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSFdCMVhXNlJCQlBNS0dKMjJUNlQzRVNYL3Byb2ZpbGVfaW1hZ2VzL2ZmMzIzNDczLTU1MDctNGNiNi04NWQyLTE3YzlhMGYwYTVhMSIsImVkaXRzIjp7InJvdGF0ZSI6bnVsbCwicmVzaXplIjp7ImZpdCI6ImNvdmVyIiwiaGVpZ2h0IjozMDAsIndpZHRoIjozMDB9fX0=',
        },
        title: 'Our Trip to Costa Rica',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSFdCMVhXNlJCQlBNS0dKMjJUNlQzRVNYL2d1aWRlX2ltYWdlcy9iNzhiNDg2Mi04MmI5LTRmNDItYmI2Ni1iMGMwZWQ1MTg0ZjgiLCJlZGl0cyI6eyJyb3RhdGUiOm51bGwsInJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjE1MDAsIndpZHRoIjoxNTAwfX19',
        city: '',
        state: 'Costa Rica',
        href: 'https://www.travelpass.com/guides/01JAXGEC5Y4TF1?name=Our-Trip-to-Costa-Rica',
      },
      {
        id: 2,
        owner: {
          name: 'Katie Loveland',
          profileImage:
            'https://lh3.googleusercontent.com/a/ACg8ocLZxRb-MM1_lEmp8qjChljg1pP83UFMtxSOsTnW_pMqdLvQMx_oFw=s192-c',
        },
        title: 'Hidden Gems of Kauai',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSFg1VjU5MUJGN0c4S1BQQzYzQTlDOFJOL2d1aWRlX2ltYWdlcy83OTM4ODhlZi01NmU1LTRkMDItODBhZS1lN2I4YTFiNzVjMmUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjEwMDAsIndpZHRoIjoxMDAwfX19',
        city: 'Kauai',
        state: 'Hawaii',
        href: 'https://www.travelpass.com/guides/01JA6MME10HYN1?name=Hidden-Gems-of-Kauai',
      },
      {
        id: 3,
        owner: {
          name: 'David Essuman',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJOQlFRVkdCOUQ3NVBaU0pSMEg1NzdHL3Byb2ZpbGVfaW1hZ2VzL2FlNTVjMjUzLTA1YjUtNDRlMi05NDdhLWQ2NTAwZDM5OGIxZCIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: 'Top places to check out in Miami',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJOQlFRVkdCOUQ3NVBaU0pSMEg1NzdHL2d1aWRlX2ltYWdlcy82MWZkNDY2ZS1iYjBlLTRmZjktYmIyOC01YTg3NzU5MWFkNGMiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjg0My43NSwid2lkdGgiOjE1MDB9fX0=',
        city: 'Miami',
        state: 'Florida',
        href: 'https://www.travelpass.com/guides/01JC2KDYZVYBV0?name=Top-places-to-check-out-in-Miami',
      },
      {
        id: 4,
        owner: {
          name: 'Noah Te',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJIWUNYREVQNlJDWDk4SkY2SFA1TUZOL3Byb2ZpbGVfaW1hZ2VzLzA2NTYwMmEwLWNjOGYtNDdhNC1iZTY0LWRmNzQyOTAyN2E1ZCIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6MzAwLCJ3aWR0aCI6MzAwfX19',
        },
        title: '24 hours in Bonaire',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxSkJIWUNYREVQNlJDWDk4SkY2SFA1TUZOL2d1aWRlX2ltYWdlcy85MmNlYzA1OC02NzU0LTQyMGItYTRhYy02YjVmOWJhN2VkMzYiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJoZWlnaHQiOjg0My43NSwid2lkdGgiOjE1MDB9fX0=',
        city: 'Bonaire',
        state: 'Caribbean Netherlands',
        href: 'https://www.travelpass.com/guides/01JBM59RQF08V0?name=24-hours-in-Bonaire',
      },
      {
        id: 5,
        owner: {
          name: 'Dany',
          profileImage:
            'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1pQNEtHU1FNNDgyREozUDc5WDZGQk1RL3Byb2ZpbGVfaW1hZ2VzL2I1Njc0OTZkLTlmODktNGM5MC05ZjdiLTk3YjE1MGE0ZTI3ZSIsImVkaXRzIjp7InJvdGF0ZSI6bnVsbCwicmVzaXplIjp7ImZpdCI6ImNvdmVyIiwiaGVpZ2h0IjozMDAsIndpZHRoIjozMDB9fX0=',
        },
        title: 'Key Lime Pie Tour',
        image:
          'https://d39ul2s1l1555v.cloudfront.net/eyJidWNrZXQiOiJ0cGctdXNlci11cGxvYWRlZC1wcmQiLCJrZXkiOiJpbWFnZXMvdXNyXzAxR1pQNEtHU1FNNDgyREozUDc5WDZGQk1RL2d1aWRlX2ltYWdlcy8wZDE0M2I5NC0xZTg2LTQwM2ItOWJjOS1kYjNhNjliMWIzOWQiLCJlZGl0cyI6eyJyb3RhdGUiOm51bGwsInJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsImhlaWdodCI6ODQzLjc1LCJ3aWR0aCI6MTUwMH19fQ==',
        city: 'Key West',
        state: 'Florida',
        href: 'https://www.travelpass.com/guides/01HWBC8J8GN4GTMHAS8GYSTJ7A?name=Key-Lime-Pie-Tour',
      },
    ],
  },
]

const featuredGuides = [
  {
    id: 1,
    owner: {
      name: 'Alexis',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ42GCHBFS0Z6MDMNPYBXT2G/profile_images/2fa792ee-bdb4-409b-af9c-a101b1bbca87',
    },
    title: 'Boston Musts',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ42GCHBFS0Z6MDMNPYBXT2G/guide_images/51d7d270-5154-467f-8c9f-941cd0dd9519',
    city: 'Boston',
    state: 'Massachusetts',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdSN1NZNEIyWjdRTVpHSlFHRTJISzJa/Boston-Musts',
  },
  {
    id: 2,
    owner: {
      name: 'Lisa Squires',
      profileImage:
        'https://static.travelpass.com/assets/users/default-avatar-lg.webp',
    },
    title: "Let's Go to Brevard, NC!",
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GH225X0Y4EYAGSG763ETFN4E/guide_images/64d348a1-c424-4a20-97ee-5772ee7113b2',
    city: 'Brevard',
    state: 'North Carolina',
    href: "https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjIxV0NHM1lBS003NUJIR1g2QThYSFY4/Let's-Go-to-Brevard%2C-NC!",
  },
  {
    id: 3,
    owner: {
      name: 'April H',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PV440KMSSA94ZT16GKCRA/profile_images/5bf3add5-63be-45d7-abcf-679cc8db096b',
    },
    title: 'Quick trip to Portland',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PV440KMSSA94ZT16GKCRA/guide_images/8be3eb29-8fa9-4ed1-9518-322f7e127267',
    city: 'Portland',
    state: 'Oregon',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdRVDlDWUhNRTJSMFhNUlZKRDFFSjRZ/Quick-trip-to-Portland',
  },

  {
    id: 4,
    owner: {
      name: 'Robert Jenkins',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1MR04KP5CTJMP9NCM3DZ2J/profile_images/8b36901d-b4b9-4d48-aad5-e07e20be05d5',
    },
    title: 'Things to do in Greenville',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1MR04KP5CTJMP9NCM3DZ2J/guide_images/b9a050a5-6672-461c-85e0-b11ca17c6b58',
    city: 'Greenville',
    state: 'South Carolina',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCNEJCNUIyNDgzRUdTR0E5ODFXV05O/Things-to-do-in-Greenville',
  },
  {
    id: 5,
    owner: {
      name: 'Alexis',
      profileImage:
        'https://lh3.googleusercontent.com/a/ALm5wu3XYtsYxkT8WzR4dG310IIeuTdLT-C2ptwRujXH=s192-c',
    },
    title: 'Bookstore Guide to Manhattan',
    image:
      'https://travelpass-guides-prd.s3.us-west-2.amazonaws.com/bookstore-guide-to-manhattan-1.webp',
    city: 'New York',
    state: 'New York',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdWSjg5Q1E1UUVaUk0wWjhQMU1LTjZC/Bookstore-Guide-to-Manhattan',
  },
  {
    id: 6,
    owner: {
      name: 'Nick',
      profileImage:
        'https://lh3.googleusercontent.com/a/ACg8ocJmiicZJUag-BvmSImotW5ub-ImomTASXFgS3U08Mwx3w=s192-c',
    },
    title: 'Things to do in Asheville',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GTAHHM0JQRJBQ6QHA5TGJQ51/guide_images/bae3c605-2627-4490-829c-c332485f4888',
    city: 'Asheville',
    state: 'North Carolina',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFczUTFGM1JDWkJEUFkzMkVDWEpRTjEy/Things-to-do-in-Asheville',
  },
  {
    id: 7,
    owner: {
      name: 'Emily Henkel',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ22PZY8G730VDDQ693R2B6B/profile_images/a3838818-8c70-4889-b148-9a6193fbc440',
    },
    title: 'I Go To Kanab A Lot',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ22PZY8G730VDDQ693R2B6B/guide_images/0aedd3cf-7b1d-4091-944a-1235c44e3c84',
    city: 'Kanab',
    state: 'Utah',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCMk1FTlJGQlBBWU0wWlM0QUJaQUhF/I-Go-To-Kanab-A-Lot',
  },
  {
    id: 8,
    owner: {
      name: 'Kendrick Morris',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/travelpass-profiles-prd/profile_images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB',
    },
    title: 'Years of Yosemite Experience',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB/guide_images/c41747f5-d02e-4fc6-91b9-0c4b2d6e81ac',
    city: 'California',
    state: 'United States',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjFGVFkyMVdHOTY4SFdYTlJYMUEyMzRY/Years-of-Yosemite-Experience',
  },
  {
    id: 9,
    owner: {
      name: 'Rachael Folland',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GYDAXFJ2Q9ZD5BVV9YVP2NQT/profile_images/45910efa-1f65-4069-a6af-8825dedb3879',
    },
    title: 'All About Alabama',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GYDAXFJ2Q9ZD5BVV9YVP2NQT/guide_images/f4bc8996-2590-406f-8523-f7e5bf21cdb6',
    city: 'Alabama',
    state: 'United States',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFo4Q0QxWFZZWDRCMDBUVkFDRENKU0ZZ/All-About-Alabama',
  },
  {
    id: 10,
    owner: {
      name: 'Morgan Milovich',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1NX9G0DJ8JB7CT9HXYW5EB/profile_images/03e6b85e-b31c-4c0d-ab04-831becd92f4d',
    },
    title: 'Redfish Lake',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1NX9G0DJ8JB7CT9HXYW5EB/guide_images/88d784ff-e24f-4a2a-80c2-8d234763a97a',
    city: 'Stanley',
    state: 'Idaho',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjIwMThYV1IyUlpSTTBNMkhITjdURjY5/Redfish-Lake',
  },
  {
    id: 11,
    owner: {
      name: 'Jessica',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GNK7GS41FMFS1WW7X05C5VYN/profile_images/5613499d-21f0-433f-b1f4-ded926bf7e13',
    },
    title: 'Savannah',
    image: 'https://static.travelpass.com/assets/trip-state-pics/georgia.webp',
    city: 'Savannah',
    state: 'Georgia',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFZUNzMxQU5OQVRUREg5VEU1RVc5UDhZ/Savannah',
  },
  {
    id: 12,
    owner: {
      name: 'Syd',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/profile_images/6ac2a80f-e6fa-4b9d-a8be-8351badbd4ae',
    },
    title: 'New Orleans',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/guide_images/6b227edb-cfa0-4e5f-9d2a-3be3191e929d',
    city: 'New Orleans',
    state: 'Louisiana',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjNRWDhYOFlYTjJENUM5NE44WTA4M0NW/New-Orleans',
  },
  {
    id: 13,
    owner: {
      name: 'Angela Jensen',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HDEXG1PMR4WRMC3F9FHF7EJH/profile_images/0e16bb68-1fb7-4bc1-8a03-9575fe60f803',
    },
    title: 'Best Restaurants in Denver',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HDEXG1PMR4WRMC3F9FHF7EJH/guide_images/e9ef358f-3b6f-4946-8b33-be5086e3d85b',
    city: 'Denver',
    state: 'Colorado',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFZBNjRaWjMzTkMxWjVZUjkyRkQyNU1L/Best-Restaurants-in-Denver',
  },
  {
    id: 14,
    owner: {
      name: 'tanner paige',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ7179MPZQ461T793NY9FX81/profile_images/7f811ff8-8e08-4e60-9d73-0990909986a8',
    },
    title: 'Philadelphia for the win',
    image:
      'https://static.travelpass.com/assets/trip-state-pics/pennsylvania.webp',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFg5UkE2V05GWjVWWUdaV1lNMEtLTUYz/Philadelphia-for-the-win',
  },
  {
    id: 15,
    owner: {
      name: 'Sarah Dorich',
      profileImage:
        'https://lh3.googleusercontent.com/a/ALm5wu2uAriDp_6lcAAmkyewAkQJkQtj_RqRF5o189LTvQ=s192-c',
    },
    title: 'A fun day in Boise, Idaho',
    image: 'https://static.travelpass.com/assets/trip-state-pics/idaho.webp',
    city: 'Boise',
    state: 'Idaho',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCNDk5VjVIM1NXVDZGQTlBM0FRNFFY/A-fun-day-in-Boise-Idaho',
  },
]

export { christmasGuides, featuredGuides }

import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_EXPERIENCE_TO_GUIDE = gql(`
  mutation AddExperienceToGuideOld($input: AddExperienceToGuideDraftInput!) {
    addExperienceToGuideDraft(input: $input) {
      guideDraft {
        id
        publishedEvents {
          id
          externalTypeId
          productId
        }
      }
    }
  }
`)

export const useAddExperienceToGuideOld = (options?) =>
  useMutation(ADD_EXPERIENCE_TO_GUIDE, options)

import { useState } from 'react'
import {
  Avatar,
  Divider,
  Icon,
  Illustration,
  Link,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { exploreSegment, travelersSegment } from 'src/constants'
import { FollowButtonOld } from 'src/pages/profile-old/components/FollowButtonOld/FollowButtonOld'
import { getImageResized, getProfilePageUrl } from 'src/utils'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardAchievementsFollowersProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
}

export const DashboardAchievementsFollowers = ({
  dashboardData,
}: DashboardAchievementsFollowersProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { currentUser } = dashboardData ?? {}
  const { followersConnection } = currentUser?.userProfile ?? {}

  if (!followersConnection?.edges?.length)
    return (
      <section className='space-y-3 text-center'>
        <div className='w-25 m-auto'>
          <Illustration name='notes' />
        </div>
        <p className='c-forest-light type-h5-desktop'>No recent followers</p>
        <p className='type-small-text-desktop c-grey-800'>
          Connect with more travelers to discover new Guides, share your travel
          style, and more.
        </p>
        <Link
          endIcon='arrowForward'
          onClick={() => window.open(`${exploreSegment}/${travelersSegment}`)}
        >
          Explore Travelers
        </Link>
      </section>
    )

  return (
    <section className='space-y-6'>
      <header className='space-y-2'>
        <h5 className='type-h5-desktop c-black [&>i]:c-valley flex flex-row items-center'>
          <Icon name='navigationCompass' size='small' />
          <span className='m-l-2'>Last 7 Days</span>
        </h5>
        <Divider className='m-b-2' />
      </header>
      <div
        className={classNames('space-y-6', {
          '[&>:nth-child(n+4)]:hidden': !isExpanded,
        })}
      >
        {followersConnection?.edges?.map(({ node }) => (
          <div key={node?.id} className='flex flex-row items-center gap-2'>
            <a
              className='hover:op-80 no-underline transition-opacity ease-linear'
              href={`${window.location.origin}${getProfilePageUrl(node?.accountHandle)}`}
              rel='noreferrer'
              target='_blank'
            >
              <Avatar
                border='none'
                size='xs'
                src={getImageResized({
                  imageUrl: node?.profileImageUrl,
                  shape: 'square',
                  width: 300,
                })}
              />
            </a>
            <div className='grow'>
              <h6 className='type-body-1-medium-desktop flex flex-row items-center justify-between gap-2'>
                <a
                  className='c-forest-dark transition-color hover:c-new-forest no-underline ease-linear'
                  href={`${window.location.origin}${getProfilePageUrl(node?.accountHandle)}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  {node?.displayName}
                </a>
                {!node?.isFollowed && (
                  <FollowButtonOld
                    accountHandle={node?.accountHandle}
                    displayName={node?.displayName}
                    fullWidth={false}
                    isFollowed={node?.isFollowed}
                    label='Follow'
                    userId={node?.userId}
                    variant='text'
                  />
                )}
              </h6>
              <p className='type-small-text-desktop c-grey-800'>
                Started following you
              </p>
            </div>
          </div>
        ))}
      </div>
      {followersConnection?.totalCount > 3 && (
        <div className='text-center'>
          <Link
            aria-pressed={isExpanded}
            label={isExpanded ? 'Show Less' : 'Show More'}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      )}
    </section>
  )
}

import { useState } from 'react'
import {
  Button,
  Link,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { firebaseSignOut } from 'src/config/firebase/firebaseUtils'
import { privacyPath } from 'src/constants'
import { useDeleteDashboardAccountSettingsFormNotificationsMutation } from '../../../../useDeleteDashboardAccountSettingsFormSecurityMutation'

export const DashboardAccountSettingsFormSecurityDelete = () => {
  const { getValues } = useFormContext()
  const [email, id] = getValues(['email', 'id'])
  const [deleteUser] =
    useDeleteDashboardAccountSettingsFormNotificationsMutation()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onDelete = async () => {
    try {
      const result = await deleteUser({
        variables: { input: { id, deleted: true } },
      })
      const { isDeleted, email } = { ...result?.data?.deleteUser?.user }
      if (isDeleted) {
        addSuccessSnack({ title: 'Account deletion initiated.' })
        onModalClose()
        pushDataToDataLayer('soft_delete', { email })
        firebaseSignOut()
      }
    } catch (event) {
      console.error(event)
      addErrorSnack({ title: 'Error deleting account. Please try again.' })
    }
  }

  const onModalClose = () => setIsModalOpen(false)

  return (
    <>
      <section className='space-y-6'>
        <h5 className='type-h6'>Account</h5>
        <p className='type-body-1-desktop c-grey-700'>
          Permanently delete your account and all of your content.
        </p>
        <Button
          label='Delete your account'
          variant='error'
          onClick={() => setIsModalOpen(true)}
        />
      </section>
      {isModalOpen && (
        <Modal
          size='medium'
          title='We are sad to see you leave!'
          onDismiss={onModalClose}
        >
          <ModalScrollContents>
            <div className='space-y-6'>
              <p className='type-body-1 line-height-relaxed'>
                Your account will be deactivated immediately, and your data will
                be deleted in accordance with applicable law and our{' '}
                <Link
                  href={privacyPath}
                  label='Privacy Policy'
                  target='_blank'
                />{' '}
                within the next 90 days. A confirmation will then be sent to{' '}
                <b>{email}</b> when the deletion request is complete. Deleting
                your account will <b>NOT</b> cancel any existing bookings. If
                you need to cancel a booking,{' '}
                <em>please do so before deleting your account.</em>
              </p>
              <p className='type-body-1 line-height-relaxed'>
                Do you still want to proceed with account deletion?
              </p>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button label='Yes, delete account' onClick={onDelete} />
            <Button label='Cancel' variant='outlined' onClick={onModalClose} />
          </ModalActions>
        </Modal>
      )}
    </>
  )
}

import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetUsersLikeGuideQueryInGuideQuery,
  Scalars,
} from 'src/__generated__/graphql'

const getGuideQuery = gql(`
  query GetUsersLikeGuideQueryInGuide($after: String, $guideId: ID!) {
    node(id: $guideId) {
      ... on Guide {
        id
        paginatedGuidesLikedByUsers(after: $after, first: 25) {
          edges {
            node {
              id
              userProfile {
                id
                accountHandle
                displayName
                isFollowed
                isUserOwner
                profileImageUrl
                userId
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`)

type UseGetUsersLikeGuideQuery = {
  guideLikesData: Extract<
    GetUsersLikeGuideQueryInGuideQuery['node'],
    { __typename?: 'Guide' }
  >
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

const useGetUsersLikeGuideQuery = (
  guideId: string
): UseGetUsersLikeGuideQuery => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
  } = useQuery(getGuideQuery, {
    skip: !guideId,
    variables: {
      guideId,
    },
  })

  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: { after },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult

          const previousNode =
            previousResult?.node as UseGetUsersLikeGuideQuery['guideLikesData']
          const fetchMoreNode =
            fetchMoreResult?.node as UseGetUsersLikeGuideQuery['guideLikesData']

          const previousEdges =
            previousNode?.paginatedGuidesLikedByUsers?.edges || []
          const fetchMoreEdges =
            fetchMoreNode?.paginatedGuidesLikedByUsers?.edges || []

          return {
            node: {
              ...previousResult.node,
              paginatedGuidesLikedByUsers: {
                edges: [...previousEdges, ...fetchMoreEdges],
                pageInfo: fetchMoreNode?.paginatedGuidesLikedByUsers?.pageInfo,
              },
            },
          }
        },
      })
    } catch (error) {
      console.error('Server error during fetchMore:', error)
    }
  }

  return {
    guideLikesData: data?.node as UseGetUsersLikeGuideQuery['guideLikesData'],
    hasError,
    isLoading,
    onGetMoreResults,
  }
}

export type { UseGetUsersLikeGuideQuery }
export { useGetUsersLikeGuideQuery }

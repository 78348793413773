//TODO: REPLACE WITH NEW RADIO BUTTON
import { Radio } from '@travelpass/design-system'
import classNames from 'classnames'
import { useFlag } from 'src/common/hooks'
import type { PaymentTypeOptions } from './hotelRoomRateUtils'

interface RoomRatesPaymentOptionsRadioProps {
  paymentTypeOptions: PaymentTypeOptions
  selectedRateToken: string
  onClick: (rateToken: string) => void
  selectedOptionGrandTotal: string
  label: string
}

export const RoomRatesPaymentOptionsRadio = ({
  paymentTypeOptions,
  selectedRateToken,
  onClick,
  selectedOptionGrandTotal,
  label,
}: RoomRatesPaymentOptionsRadioProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  const { isDisabled, rateToken, grandtotal } = paymentTypeOptions
  const isChecked = rateToken === selectedRateToken

  const priceDifference = !isChecked
    ? Math.round(Number(grandtotal)) -
      Math.round(Number(selectedOptionGrandTotal))
    : 0

  if (isDisabled && newHotelDetails) return null

  const onRadioClick = () => {
    rateToken != selectedRateToken && !isDisabled ? onClick(rateToken) : null
  }

  return (
    <div
      className={classNames('rd-2 border-1 b-grey300 flex border-solid p-3', {
        'bg-grey-100 color-grey-600': isDisabled,
        'bg-#F0F6F7 color-forest-dark b-transparent': isChecked,
      })}
      role='button'
      tabIndex={0}
      onClick={onRadioClick}
    >
      <Radio
        isChecked={isChecked}
        isDisabled={isDisabled}
        label={label}
        onClick={onRadioClick}
      />
      <div
        className={classNames('flex-grow text-right', {
          'c-valleyDark': priceDifference < 0,
        })}
      >
        {isChecked || isDisabled || isNaN(priceDifference)
          ? null
          : `${priceDifference >= 0 ? '+' : '-'}$${Math.abs(priceDifference)}`}
      </div>
    </div>
  )
}

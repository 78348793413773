import classNames from 'classnames'
import { EventType } from 'src/__generated__/graphql'
import { MarkerWrapper } from 'src/common/components/Map'
import type { ProfileOwner } from 'src/pages/profile/types'
import { StyledMarker } from 'src/pages/trips/components'
import { stringToNumber } from 'src/utils'
import { GuideDraftMapMarkerPopup } from './GuideDraftMapMarkerPopup'
import type { GuideDraftPublishedEvent } from '../../types'

interface GuideDraftMapMarkerProps {
  isHovered: boolean
  isSelected: boolean
  onClick: (point: GuideDraftPublishedEvent) => void
  onMouseEnter: VoidFunction
  onMouseLeave: VoidFunction
  onPopupClick: (id: GuideDraftPublishedEvent['id']) => void
  owner: ProfileOwner
  point: GuideDraftPublishedEvent
}

export const GuideDraftMapMarker = ({
  isHovered,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onPopupClick,
  owner,
  point,
}: GuideDraftMapMarkerProps) => {
  const { addresses, id, type } = point ?? {}
  const [address] = addresses ?? []
  const { lat: initialLat, long: initialLng } = address ?? {}
  const lat = stringToNumber(initialLat)
  const lng = stringToNumber(initialLng)

  if (!lat || !lng) return

  return (
    <>
      <MarkerWrapper
        isHovered={isHovered || isSelected}
        position={{
          lat,
          lng,
        }}
        onClick={() => onClick(point)}
      >
        <div
          className='relative'
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <StyledMarker
            isHovered={isHovered || isSelected}
            isSelected={isSelected}
            type={type ?? EventType.Miscellaneous}
          />
          {isHovered && (
            <div
              className={classNames(
                'absolute top-[calc(100%+8px)] hidden -translate-x-2/4 md:block'
              )}
            >
              <GuideDraftMapMarkerPopup
                owner={owner}
                point={point}
                onClick={() => onPopupClick(id)}
              />
            </div>
          )}
        </div>
      </MarkerWrapper>
      {isHovered && (
        <div className='z-2 absolute bottom-0 left-0 right-0 md:hidden'>
          <GuideDraftMapMarkerPopup
            owner={owner}
            point={point}
            onClick={() => onPopupClick(id)}
          />
        </div>
      )}
    </>
  )
}

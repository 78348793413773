import { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Carousel,
  ModalNext,
  SkeletonDots,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Confetti from 'react-confetti'
import { GuideRecommendedCard } from 'src/common/components'
import {
  useFlag,
  useFollowMutation,
  useUnfollowMutation,
} from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { FollowingDropdown } from './FollowingDropdown'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
import { useGetDivDimensions } from './hooks/useGetDivDimensions'
import {
  USER_PROFILE_FOR_VOTE_MODAL,
  useUserProfileForVoteModal,
} from './hooks/useUserProfileForVoteModal'

interface YouVotedModalProps {
  onClose: VoidFunction
  userProfile: ToggleVoteModalProps
}

export const VoteModalNew = ({ onClose, userProfile }: YouVotedModalProps) => {
  const votingModalV3 = useFlag('votingModalV3')
  const { isTabletScreen, isDesktopScreen } = useScreenQuery()
  const [ref, { height, width }] = useGetDivDimensions()

  const { displayName, isUserOwner, profileImageUrl, userId } =
    userProfile ?? {}
  const { data, loading: profileLoading } = useUserProfileForVoteModal(userId)
  const { isFollowed, user } = data?.viewUserProfile ?? {}
  const userGuides = user?.userGuides?.edges?.map(({ node }) => node) ?? []
  const [follow] = useFollowMutation({
    refetchQueries: [USER_PROFILE_FOR_VOTE_MODAL],
  })
  const [unfollow, { loading: unfollowLoading }] = useUnfollowMutation({
    refetchQueries: [USER_PROFILE_FOR_VOTE_MODAL],
  })
  const variables = { input: { userId } }

  const [isConfettiEnabled, setIsConfettiEnabled] = useState(true)
  const [confettiPieces, setConfettiPieces] = useState(150)
  const [replaceBtnWithDropdown, setReplaceBtnWithDropdown] = useState(false)
  const [statusMessage, setStatusMessage] = useState<{
    type: 'info' | 'success' | 'error'
    message: string
  }>(null)

  useEffect(() => {
    const handleCardClick = event => {
      const anchorTag = event.target.closest('a')
      if (anchorTag) {
        const hrefValue = anchorTag.href
        if (hrefValue) {
          pushDataToDataLayer('guide_click_competition_voting', {
            guide_url: hrefValue,
          })
        }
      }
    }

    document.addEventListener('click', handleCardClick)

    return () => {
      document.removeEventListener('click', handleCardClick)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiPieces(0)
    }, 2200)

    return () => clearTimeout(timer)
  }, [])

  const pushToDataLayer = (event: string) => {
    pushDataToDataLayer(event, {
      voted_user_id: userId,
      voted_user_name: displayName,
      timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
    })
  }

  const onFollowClick = async () => {
    if (isFollowed) {
      setStatusMessage({
        type: 'info',
        message: 'Would you like to unfollow this user?',
      })
      setReplaceBtnWithDropdown(true)
      return
    }
    try {
      const response = await follow({ variables })
      if (response?.data) {
        pushToDataLayer('follow_competition_voting')
        setStatusMessage({
          type: 'success',
          message: `You are now following ${displayName}!`,
        })
      }
    } catch {
      setStatusMessage({ type: 'error', message: 'An error occurred' })
    } finally {
      setTimeout(() => {
        setStatusMessage(null)
      }, 2000)
    }
  }

  const onUnfollowChoice = async (choice: 'Yes' | 'No') => {
    if (choice === 'No') {
      setReplaceBtnWithDropdown(false)
      setStatusMessage(null)
      return
    }
    try {
      const response = await unfollow({
        variables,
      })
      if (response?.data) {
        setStatusMessage({
          type: 'success',
          message: `Successfully unfollowed ${displayName}!`,
        })
      }
    } catch {
      setStatusMessage({ type: 'error', message: 'An error occurred' })
    } finally {
      setTimeout(() => {
        setReplaceBtnWithDropdown(false)
        setStatusMessage(null)
      }, 2000)
    }
  }

  return (
    <ModalNext
      actions={
        votingModalV3 ? (
          <section className='w-full py-2 md:w-fit md:py-4'>
            <Button fullWidth label='Done' size='large' onClick={onClose} />
          </section>
        ) : (
          !isUserOwner && (
            <div className='flex flex-col items-center gap-6 pb-16 pt-12 md:pb-8'>
              <div className='relative flex flex-col items-center justify-center gap-2 lg:flex-row-reverse'>
                <p
                  className={classNames(
                    `type-body-2 absolute left-0 right-0 top--12 ${statusMessage === null ? 'invisible' : ''}`,
                    {
                      'c-success': statusMessage?.type === 'success',
                      'c-error': statusMessage?.type === 'error',
                      'c-grey-800': statusMessage?.type === 'info',
                    }
                  )}
                >
                  {statusMessage?.message}
                </p>
                {replaceBtnWithDropdown ? (
                  <FollowingDropdown
                    loading={unfollowLoading}
                    onUnfollowChoice={onUnfollowChoice}
                  />
                ) : (
                  <Button
                    aria-label={`Follow ${displayName}'s profile`}
                    isDisabled={profileLoading}
                    size={
                      isDesktopScreen
                        ? 'large'
                        : isTabletScreen
                          ? 'medium'
                          : 'small'
                    }
                    startIcon={
                      isFollowed ? 'checkCircleOutline' : 'personOutline'
                    }
                    variant='filled'
                    onClick={onFollowClick}
                  >
                    {isFollowed ? 'Following' : 'Follow'}
                  </Button>
                )}
              </div>
            </div>
          )
        )
      }
      /**@desc overriding the design system to center the button inside the modal's actions */
      className={`text-center ${votingModalV3 || !isUserOwner ? 'md:[&>div>div:nth-child(3)]:justify-center' : ''}`}
      contentRef={ref}
      size='medium'
      title={`You Voted For ${displayName}!`}
      onClose={onClose}
    >
      <div className={`relative ${votingModalV3 ? 'pb-2' : ''}`}>
        {isConfettiEnabled && (
          <Confetti
            gravity={0.05}
            height={height}
            numberOfPieces={confettiPieces}
            run={true}
            width={width}
            onConfettiComplete={() => setIsConfettiEnabled(false)}
          />
        )}
        <img
          alt=''
          className={`-mr-5 inline hidden md:inline-block ${votingModalV3 ? 'max-h-98px max-w-98px mr-0' : ''}`}
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-left.svg'
        />
        <span className={votingModalV3 ? 'mx-3 hidden md:inline' : ''}>
          <Avatar size='lg' src={profileImageUrl} />
        </span>
        <img
          alt=''
          className={`-ml-5 inline hidden md:inline-block ${votingModalV3 ? 'max-h-98px max-w-98px ml-0' : ''}`}
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-right.svg'
        />
        <h6 className='type-h6-desktop pb-2 pt-4'>Thanks for voting!</h6>
        <p className='type-body-1-desktop pb-4 pt-2'>
          {votingModalV3
            ? `Check out some of ${displayName}'s Guides!`
            : `You can follow ${displayName} or check out some of ${displayName}'s
          other Guides:`}
        </p>
        <section
          className={`grid gap-4 px-6 pt-4 max-md:grid-cols-1 md:grid-cols-3 ${votingModalV3 ? 'min-h-271px hidden md:grid' : ''}`}
        >
          {profileLoading ? (
            <div className='col-start-2 my-auto'>
              <SkeletonDots />
            </div>
          ) : (
            userGuides?.map(guide => {
              return (
                <div key={guide?.id}>
                  <GuideRecommendedCard
                    guideRecommendedCardData={guide}
                    hideAddTo={true}
                    showOwnerBadge={false}
                    smallSize={true}
                  />
                </div>
              )
            })
          )}
        </section>
        {votingModalV3 ? (
          <section className='[&_li>div>div>div]:min-h-271px of-x-hidden px-10 md:hidden [&_li>div]:pb-9'>
            {profileLoading ? (
              <div className='my-auto'>
                <SkeletonDots />
              </div>
            ) : (
              <Carousel hideArrows hideDots={false}>
                {userGuides?.map(guide => {
                  return (
                    <div key={guide?.id}>
                      <GuideRecommendedCard
                        guideRecommendedCardData={guide}
                        hideAddTo={true}
                        showOwnerBadge={false}
                        smallSize={true}
                      />
                    </div>
                  )
                })}
              </Carousel>
            )}
          </section>
        ) : null}
      </div>
    </ModalNext>
  )
}

import { useState } from 'react'
import { Button, Illustration } from '@travelpass/design-system'
import type { GetViewUserProfileInProfileQuery } from 'src/__generated__/graphql'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'

interface ProfileGuidesEmptyProps {
  profileData: GetViewUserProfileInProfileQuery['viewUserProfile']
}

export const ProfileGuidesEmpty = ({
  profileData,
}: ProfileGuidesEmptyProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (profileData?.isUserOwner)
    return (
      <>
        <section className='bg-beach rounded-3 flex flex-col items-center gap-8 p-8 md:flex-row-reverse md:gap-4'>
          <div className='min-w-70 md:w-70'>
            <Illustration name='jeep' />
          </div>
          <div className='flex grow flex-col items-center space-y-6 text-center md:items-start md:text-left'>
            <h3 className='type-h3-desktop'>
              Share your travel tips with the world
            </h3>
            <p className='type-body-1'>
              Showcase your adventures and reveal incredible destinations by
              publishing your trips
            </p>
            <Button
              label='Create a Guide'
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </section>
        {isModalOpen && (
          <GuideDraftCreateModal
            triggerButton='profile'
            onDismiss={() => setIsModalOpen(false)}
          />
        )}
      </>
    )

  return (
    <section className='space-y-4 md:space-y-8'>
      <div className='m-a w-40 md:w-48'>
        <Illustration name='cardLady' />
      </div>
      <div className='space-y-2 text-center md:space-y-4'>
        <h3 className='type-subtitle-2 c-grey-800'>
          Looks like {profileData?.displayName || profileData?.accountHandle}{' '}
          hasn&apos;t published any guides yet.
        </h3>
        <p className='type-subtitle-2 c-grey-800'>
          But hey, everyone starts somewhere!
        </p>
      </div>
    </section>
  )
}

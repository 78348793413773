import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { GuideStatus } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const archiveGuide = gql(`
  mutation ArchiveGuideMutationInGuideDraft($guideInput: ArchiveGuideInput!, $userGuidesArgs: UserGuidesArgs!) {
    archiveGuide(guideInput: $guideInput) {
      guide {
        id
        name
        owner {
          id
          userGuides(first: 5, userGuidesArgs: $userGuidesArgs) {
            edges {
              node {
                id
              }
            }
            totalCount
          }
        }
        status
        updatedAt
      }
    }
  }
`)

export const useArchiveGuideDraftMutation = () =>
  useMutation(archiveGuide, {
    onCompleted: data => {
      const { guide } = data?.archiveGuide ?? {}

      pushDataToDataLayer('guide_delete', {
        guide_id: guide?.id,
        guide_name: guide?.name,
        guide_status: GuideStatus.Archived,
        trigger_url: window.location.href,
        updated_at: guide?.updatedAt,
        user_id: guide?.owner?.id,
      })
    },
  })

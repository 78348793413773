import type { OperationVariables } from '@apollo/client'
import { Breadcrumbs } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { destinationsSegment, hotelsPath } from 'src/constants'
import { useHotelReviewsSummaryListHotelReviewsQuery } from 'src/pages/hotels/details/hooks'
import { BookingPaymentFields } from '../BookingPaymentLayout'
import { BookingSidebar } from '../BookingSidebar/BookingSidebar'

interface BookingDetailsProps {
  bookingHotelData: BookingHotelDetailsQuery
  bookingHotelLoading: boolean
  bookingRateData: BookingValidateRateQuery
  bookingRateLoading: boolean
  createHotelBooking(options?: OperationVariables): unknown
}

export const BookingLayout = ({
  bookingHotelData,
  bookingHotelLoading,
  bookingRateData,
  bookingRateLoading,
  createHotelBooking,
}: BookingDetailsProps) => {
  const checkoutV2 = useFlag('checkoutV2')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const guests = searchParams.get('guests')
  const hotelId = searchParams.get('hotelId')

  const { lodging } = bookingHotelData ?? {}
  const {
    city: hotelCity,
    name: hotelName,
    state: hotelState,
    id,
  } = lodging ?? {}
  const hotelBookingPath = `/${destinationsSegment}/${hotelState}/${hotelCity}${hotelsPath}/${hotelId}/${hotelName}?arrival=${arrival}&departure=${departure}&adults=${guests}`
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery(Number(id))

  const breadcrumbs = [
    {
      label: 'Home',
      onClick: () => {
        navigate('/', { replace: true })
      },
    },
    {
      label: 'Hotel Booking',
      onClick: () => {
        navigate(hotelBookingPath, { replace: true })
      },
    },
    {
      label: 'Checkout',
    },
  ]

  return (
    <div className='flex flex-col pb-8 pt-3 lg:pb-16 lg:pt-4'>
      {!isMobile && !checkoutV2 && (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className='pb-8 pt-6'>
            <h1 className='type-h3'>Checkout</h1>
          </div>
        </>
      )}
      {checkoutV2 && !bookingHotelLoading && !bookingRateLoading && (
        <>
          <h1 className='type-h3 c-forestLight mt-5 text-center'>
            {hotelReviewsSummaryData?.averageOverall >= 9
              ? 'Perfect Pick!'
              : hotelReviewsSummaryData?.averageOverall >= 7
                ? 'Score!'
                : 'Nice Work!'}
          </h1>
          <p className='type-body-2 mb-8 text-center'>
            {hotelReviewsSummaryData?.averageOverall >= 9
              ? "You're booking a top-rated stay."
              : hotelReviewsSummaryData?.averageOverall >= 7
                ? "You've found a great hotel for your trip."
                : 'You found a great rate for this hotel.'}
          </p>
        </>
      )}
      <div className='flex flex-col gap-8 lg:flex-row lg:gap-6'>
        <div className='order-0 lg:max-w-1/2 w-full max-w-full p-0 lg:order-1 lg:px-8'>
          <BookingSidebar
            bookingHotelLoading={bookingHotelLoading}
            bookingRateLoading={bookingRateLoading}
            hotelData={bookingHotelData}
            hotelReviewsSummaryData={hotelReviewsSummaryData}
            rateData={bookingRateData}
          />
        </div>
        <form
          noValidate
          className='lg:max-w-1/2 w-full max-w-full space-y-8'
          id='hotel-booking'
          onSubmit={createHotelBooking}
        >
          <BookingPaymentFields
            bookingHotelData={bookingHotelData}
            bookingRateData={bookingRateData}
          />
        </form>
      </div>
    </div>
  )
}

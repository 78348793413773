import { Button, Divider, useSnackbar } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'
import { firebaseGoogleSignIn } from 'src/config/firebase/firebaseUtils'
import { isWebView } from 'src/utils/isWebView'
import type { UpdateUserInfoProps } from './types'

export const LinkCredentials = ({
  onCloseModal,
  updateUserInfo,
  setAttemptingCreation,
}: {
  onCloseModal(): void
  updateUserInfo?(arg0: UpdateUserInfoProps): void
  setAttemptingCreation?(arg0: boolean): void
}): JSX.Element => {
  const { addSuccessSnack } = useSnackbar()
  const isNewSignUpEnabled = useFlag('newSignUpFlow')
  const showSuccessSnack = () => {
    addSuccessSnack({ title: 'Successfully signed in' })
  }

  const updateUser = user => {
    const { uid, displayName, email, phoneNumber } = user ?? {}
    const [firstName, lastName] = displayName.split(' ')

    updateUserInfo?.({
      externalId: uid,
      email,
      firstName,
      lastName,
      phoneNumber,
      ...(isNewSignUpEnabled && { displayName }),
    })
  }

  const onGoogleSignIn = async () => {
    isNewSignUpEnabled && setAttemptingCreation && setAttemptingCreation(true)
    const response = await firebaseGoogleSignIn()
    const { createdNewAccount, user, error } = response ?? {}

    if (error) {
      setAttemptingCreation(false)
      return
    } else {
      if (createdNewAccount) updateUser(user)
      else {
        onCloseModal()
        showSuccessSnack()
      }
    }
  }

  return (
    <div className='flex flex-col space-y-7'>
      <section className='space-y-3'>
        <div>
          <Button
            fullWidth
            data-testid='sign-in-google'
            isDisabled={isWebView()}
            label='Continue with Google'
            size='large'
            startIcon='coloredGoogle'
            variant='outlined'
            onClick={onGoogleSignIn}
          />
          {isWebView() && (
            <p className='type-body-1 c-red'>
              Sorry, signing in with Google isn&apos;t available for in-app
              browsers. To log in or create an account with Google, open
              travelpass.com in your phone&apos;s native browser (Chrome,
              Safari, or Firefox).
            </p>
          )}
        </div>
      </section>
      <section className='flex flex-row items-center justify-evenly text-center'>
        <Divider />
        <h3 className='type-body-1 mx-4'>or</h3>
        <Divider />
      </section>
    </div>
  )
}

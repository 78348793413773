import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import type { HotelReviewsListHotelReviewsQuery } from 'src/__generated__/graphql'
import { HotelGradeReview } from 'src/common/components/HotelGradeReview'
import { formatDate } from 'src/utils'
import { getTravelerType } from '../../HotelReviewsApollo/hotelReviewsApolloUtils'
import { HotelReviewHighlightedText } from '../HotelReviewHighlightedText'

interface HotelReviewComment {
  icon: AvailableIcons
  isHidden: boolean
  title?: string
}

interface HotelReviewProps {
  hotelReviewData: HotelReviewsListHotelReviewsQuery['listHotelReviews']['reviews'][0]
  showExcerpt?: boolean
  keyword?: string
}

export const HotelReview = ({
  hotelReviewData,
  showExcerpt = false,
  keyword = '',
}: HotelReviewProps) => {
  const {
    addedDate,
    averageRate,
    name,
    negativeComments,
    positiveComments,
    travelerType,
  } = hotelReviewData ?? {}
  const formatedAddedDate = formatDate(dayjs(addedDate), 'MMM DD, YYYY')
  const hotelReviewTravelerType = getTravelerType(travelerType)?.title
  const comments: HotelReviewComment[] = [
    {
      icon: 'sentimentSatisfiedAlt',
      isHidden: !positiveComments,
      title: positiveComments,
    },
    {
      icon: 'sentimentDissatisfied',
      isHidden: !negativeComments,
      title: negativeComments,
    },
  ]

  const commentsSection = comments.map(
    ({ icon, isHidden, title }, index) =>
      !isHidden && (
        <p key={index} className='color-grey-800 type-body-2 flex gap-1.5'>
          <Icon name={icon} />
          <span
            className={classNames('pt-3px', { 'line-clamp-2': showExcerpt })}
          >
            <HotelReviewHighlightedText keyword={keyword} text={title} />
          </span>
        </p>
      )
  )

  return (
    <div className='flex flex-col gap-2'>
      <div className='sm:flex-justify-between flex sm:flex-row-reverse lg:flex-col lg:gap-2'>
        <HotelGradeReview grade={averageRate} />
        <div className='grid-items-center sm:grid-items-start flex-col gap-1 gap-3 sm:flex lg:flex'>
          <p className='type-body-1-medium'>{name}</p>
          <span className='color-grey-800 type-body-1 flex'>
            {formatedAddedDate}
            {hotelReviewTravelerType && (
              <span className='sm:hidden lg:flex'>
                - {hotelReviewTravelerType}
              </span>
            )}
          </span>
        </div>
      </div>
      <div className='flex flex-col gap-3'>{commentsSection}</div>
    </div>
  )
}

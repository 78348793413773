enum HotelSearchParams {
  adults = 'adults',
  arrival = 'arrival',
  departure = 'departure',
  eventId = 'eventId',
  hotelId = 'hotelId',
  kids = 'kids',
  tripId = 'tripId',
}

const hotelTabs = [
  'Overview',
  'Rooms',
  'Nearby',
  'Amenities',
  'Reviews',
  'Policies',
]

const HOTEL_ROOMS_DIV_ID = 'hotel-rooms-div-id'

export { HotelSearchParams, hotelTabs, HOTEL_ROOMS_DIV_ID }

import { Illustration } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { constructCityAndState } from 'src/common/components/Geocoder/geocoderUtils'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { Experiences } from './Experiences'
import { Guides } from './Guides'
import { Header } from './Header'
import { Hotels } from './Hotels'

export const DestinationPage = () => {
  const [searchParams] = useSearchParams()
  const displayName = searchParams.get('displayName')
  const location = searchParams.get('location')
  const { cityAndState, city } = constructCityAndState(location)

  return (
    <>
      <Helmet pageName={`Book Top Hotels in ${cityAndState || city}`} />
      <div className='md:space-y-25 mb-8 space-y-16'>
        <Header displayLocation={displayName} />
        <>
          <div className='md:space-y-25 peer space-y-16'>
            <Hotels />
            <Experiences />
            <Guides />
          </div>
          <div className='h-100 md:w-100 mx-auto hidden space-y-6 text-center peer-[:empty]:block'>
            <h6 className='c-grey-800 type-h6'>
              No content for this location yet!
            </h6>
            <Illustration name='guide' />
          </div>
        </>
      </div>
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </>
  )
}

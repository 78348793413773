import isEmpty from 'lodash.isempty'
import type { GetViewUserProfileInProfileQuery } from 'src/__generated__/graphql'
import { ProfileGuideCard } from './ProfileGuideCard'
import { ProfileGuidesEmpty } from './ProfileGuidesEmpty'
import { ProfileGuidesLoadMore } from './ProfileGuidesLoadMore'
import { ProfileGuidesLoading } from './ProfileGuidesLoading'
import type { ProfileOwner } from '../../types'
import { useGetProfileGuidesQuery } from '../../useGetProfileGuidesQuery'

interface ProfileGuidesProps {
  owner: ProfileOwner
  profileData: GetViewUserProfileInProfileQuery['viewUserProfile']
}

export const ProfileGuides = ({ owner, profileData }: ProfileGuidesProps) => {
  const { profileGuidesData, hasError, isLoading, onGetMoreResults } =
    useGetProfileGuidesQuery(profileData?.accountHandle)

  if (isLoading) return <ProfileGuidesLoading />

  if (hasError || isEmpty(profileGuidesData))
    return <ProfileGuidesEmpty profileData={profileData} />

  return (
    <section className='space-y-4'>
      {profileGuidesData?.edges?.map(guide => (
        <ProfileGuideCard
          key={guide?.node?.id}
          guide={guide?.node}
          owner={owner}
        />
      ))}
      <ProfileGuidesLoadMore
        profileGuidesData={profileGuidesData}
        onGetMoreResults={onGetMoreResults}
      />
    </section>
  )
}
